import React, { useEffect, useState } from 'react'
import { getApi, postApi } from '../../service/employService'
import { useNavigate } from 'react-router-dom';

const PaymentDueModal = ({ restrictModal }) => {
    const navigate = useNavigate();
    const [viewPlanList, setViewPlanList] = useState([])
    const [btnDisable, setBtnDisable] = useState(false)

    const viewActivePlanlist = async () => {
        try {
            const response = await getApi('/wfm-plan-list')
            if (response.status) {
                console.log(response)
                setViewPlanList(response.plansWithProducts)
            } else {
                console.log(response)
            }
        } catch (error) {
            console.log(error)
        }

    }


    // console.log(restrictModal, "*********************")

    useEffect(() => {
        if (!restrictModal) {
            viewActivePlanlist()
        }
    }, [])

    // const navigate = useNavigate()
    const HandlePayment = async (planId) => {
        try {
            setBtnDisable(true)
            const response = await postApi('/stripe-check-out', { plan_id: planId })
            if (response.status) {
                window.location.href = response.payment_url;

            } else {
                console.log(response)
            }
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <div>
            {viewPlanList.length > 0 &&
                <div className="modal fade show" style={{ display: 'block', boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)' }} id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-xl">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="staticBackdropLabel" >Payment Due - <span style={{ textTransform: 'none', color: 'rgb(114 113 113)' }} >(Your Payment is due, In order to continue accessing thirdroc Please choose plan those are mentioned below)</span> </h5>
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => {
                                        localStorage.removeItem("employer");
                                        navigate('/employer/login');
                                    }}
                                >Log Out</button>
                            </div>
                            <div className="modal-body">
                                <h3 className='d-flex justify-content-center'>Choose your pricing plan</h3>
                                <div className={`mt-1 d-flex ${viewPlanList.length <= 2 ? 'justify-content-center' : ''}`} style={{ Height: '400px', overflowX: 'auto' }}>
                                    {/* <div className="mt-1 d-flex" style={{ Height: '400px', overflowX: 'auto' }}> */}
                                    {viewPlanList && viewPlanList.length > 0 && viewPlanList.map((Item, index) => {

                                        const benefits = Item?.benefits ? JSON.parse(Item.benefits) : [];
                                        const benefitsList = benefits?.map((benefit, index) => <li key={index}>{benefit}</li>);
                                        return (
                                            <>
                                                <div className="priceTable">
                                                    <div className="priceInner">
                                                        <h4>{Item?.product.name}</h4>
                                                        <p style={{ fontSize: '2rem', margin: '5px 0' }}>{Item?.amount ? "$" + Item?.amount / 100 : "--"}</p>
                                                        <p>{Item?.interval}</p>
                                                        <p>{Item?.product?.description}</p>
                                                        <button className="btn btn-success" style={{ height: '40px' }}
                                                            disabled={btnDisable}
                                                            onClick={() => HandlePayment(Item?.id)}
                                                        >Ready to Pay</button>
                                                        {benefits.length > 0 &&
                                                            <ul className='priceInnerPragraph'>{benefitsList}</ul>}
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    })}


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {viewPlanList.length == 0 &&
                <>
                    <div className="modal fade show" style={{ display: 'block', boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)' }} id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header">

                                    <h5 className="modal-title" id="staticBackdropLabel">Payment Due</h5>
                                </div>

                                <div className='d-flex text-center my-3'>
                                    <div className="modal-body">
                                        <p style={{ textTransform: 'none' }}>Your Payment is due, In order to continue accessing thirdroc connect with admin support</p>
                                        <button type="button"
                                            className="btn btn-primary"
                                            onClick={() => {
                                                localStorage.removeItem("employer");
                                                navigate('/employer/login')
                                            }}
                                        >Log Out</button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="modal-backdrop fade show"></div>
                </>
            }
            <div className="modal-backdrop fade show"></div>
        </div>
    )
}

export default PaymentDueModal