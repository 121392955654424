import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'
import { deleteApi, getApi } from '../../../Service/jobService'
import { Image } from 'antd'

const EmployerDashboard = () => {
    const prefix = process.env.REACT_APP_JOBS_PRIFIX
    const employerPrefix = process.env.REACT_APP_JOBS_EMPLOYER_PRIFIX
    const [viewPostDetail, setViewPostDetail] = useState([])
    const [despription, setDespription] = useState("")
    const [search, setSearch] = useState('')
    const [preview, setPreview] = useState([])

    const GetPostDetail = async () => {
        try {
            const response = await getApi('/employer/get-job-post', { search: search });
            if (response.status) {
                setViewPostDetail(response.data.rows);
            } else {
                toast.error(response.message);
            }
        } catch (error) {
            console.error("🚀 ~ file: addEmployer.js:66 ~ GetCountry ~ error:", error)
        }
    }

    useEffect(() => {
        GetPostDetail();
    }, [search]);



    // const truncateText = (text, length) => {
    //     if (text.length > length) {
    //         const truncatedText = text.substring(0, length) + '...';
    //         return <span dangerouslySetInnerHTML={{ __html: truncatedText }} />;
    //     } else {
    //         return <span>{text}</span>;
    //     }
    // }
    // const truncateText = (text, length) => {
    //     if (text.length > length) {
    //         const truncatedText = text.substring(0, length) + '...';
    //         return truncatedText;
    //     } else {
    //         return text;
    //     }
    // }

    const stripHtmlTags = (text) => {
        return text.replace(/<[^>]*>/g, '');
    };

    const truncateText = (text) => {
        const plainText = stripHtmlTags(text);
        return plainText;

    };

    const truncateText1 = (text, maxLength) => {
        if (text.length > maxLength) {
            return text.substring(0, maxLength) + '...';
        }
        return text;
    };

    const HandleDeletePost = (id) => {
        // console.log(id)
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                const response = await deleteApi(`/employer/delete-job-post/${id}`)
                if (response?.status) {
                    Swal.fire(
                        'Deleted!',
                        'Post has been deleted Successfully.',
                        'success'
                    )
                    GetPostDetail();
                    toast.success(response.message)
                }
                else {
                    toast.error(response.message)
                }
            }
        })
    }

    // console.log(preview, '***************')
    return (
        <div className="main-container container-fluid">
            <div className="page-header" style={{ marginTop: '2.5rem' }}>
                <div className="page-leftheader">
                    <h4 className="page-title mb-0 text-primary">Dashboard</h4>
                </div>
                <div className="page-rightheader">
                    <Link className="btn btn-primary" style={{ width: '120px', }} to={prefix + "/employer/add_jobs"}>Create Job</Link>
                </div>   </div>
            <div className="row">
                {/* <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                    <div className="form-group">
                        <label className="form-label">Search</label>
                        <div className="row g-xs">
                            <div className="input-group">
                                <input type="text" className="form-control" placeholder="Search By Keyword"
                                    value={search}
                                    onChange={(e) => { setSearch(e.target.value) }}
                                />
                                <span className="input-group-append"> <button className="btn btn-primary" type="button"><i className="fa fa-search"></i></button> </span>
                            </div>
                        </div>
                    </div>
                </div> */}

            </div>

            <div class="row">
                {viewPostDetail.length > 0 && viewPostDetail?.map((Items, index) => {
                    return (
                        <div key={index} class="col-sm-4">
                            <div className="card" style={{ border: '1px solid #ccc', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', minHeight: '210px' }}>
                                <div class="card-body">
                                    <div className='d-flex justify-content-between'>
                                        <img
                                            // src='/assets/img/logo-thirdroc1.png'
                                            src="https://thirdroc1.s3.ap-southeast-2.amazonaws.com/logo/8i5h6h8a.jpg"
                                            // width={100} 
                                            style={{ width: 40, height: 30, }}
                                            alt='...' />
                                        <i /* class="fa-regular fa-bookmark" */ className='fas fa-trash text-danger' onClick={() => HandleDeletePost(Items?.id)}></i>                                        </div>
                                    <h6 class="card-title my-1"><i className='fas fa-briefcase' /> {Items?.job_title}</h6>
                                    <div className='d-flex'>
                                        <p className="card-description"
                                            dangerouslySetInnerHTML={{
                                                __html: truncateText(Items?.job_description),
                                            }}
                                        />
                                    </div>
                                    <p style={{ fontSize: "0.8rem" }}>{
                                        Items?.skills && Items.skills != "null"
                                            ? (JSON.parse(Items.skills).join(', ').length >= 45
                                                ? JSON.parse(Items.skills).join(', ').substring(0, 45) + "..."
                                                : JSON.parse(Items.skills).join(', ')
                                            )
                                            : "--"
                                    }</p>

                                    <div className='d-flex justify-content-start'>
                                        <span className='card-city text-primary'>
                                            <i className='fa-solid fa-location-dot'></i>{' '}
                                            {truncateText1(Items?.city || '', 20)}
                                        </span>
                                        <span className='card-salary-type' >
                                            {(Items?.salary_type == 'Per-Day' || Items?.salary_type == 'Per-Hour') ? <span>
                                                {Items?.currency_type == 'INR' ? "₹" : <i className="fa-solid fa-dollar"></i>}
                                                {Items?.min_annual_salary || '--'} - {Items?.max_annual_salary || '--'}</span> :
                                                <span>
                                                    {/* {Items?.currency_type == 'INR' ? "₹" : <i className="fa-solid fa-dollar"></i>} */}
                                                    {Items?.salary_type == "DND" ? <span> Do not disclose</span> : <span> {"$" + Items?.fixed_annual_salary} </span> || '--'}</span>
                                            }
                                        </span>
                                    </div>
                                    <div className='d-flex justify-content-right' style={{ marginTop: '0.3rem' }}>
                                        <Link to={employerPrefix + `/edit_jobs/${Items.id}`}
                                            state={{ data: Items, from: 'EditJobs' }} className="btn btn-dark me-2">Edit</Link>
                                        <button
                                            data-target="#jobDescriptionModal"
                                            data-toggle="modal"
                                            onClick={() => { setDespription(Items?.job_description); setPreview(Items) }}
                                            class="btn btn-primary">Preview</button>
                                    </div>
                                </div>
                            </div>

                            <div class="modal fade" id="jobDescriptionModal" tabindex="-1" role="dialog" aria-labelledby="jobDescriptionModalLabel" aria-hidden="true">
                                <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl" role="document">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h5 class="modal-title" id="jobDescriptionModalLabel">Post Preview</h5>

                                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">
                                                    <i class="fa-solid fa-rectangle-xmark" style={{ fontSize: '22px' }}></i>
                                                </span>
                                            </button>
                                        </div>
                                        <div class="modal-body">
                                            <div className='row'>
                                                <div className='col-md-8'>

                                                    <div class="boxCardNewEmployer">
                                                        <h6 class="card-title mt-1 text-primary">
                                                            <i className='fas fa-briefcase' /> {preview?.job_title}
                                                        </h6>

                                                        <div dangerouslySetInnerHTML={{ __html: despription }} /></div>
                                                </div>

                                                <div className='col-md-4'>
                                                    <small className='rightSideModelTxt my-2'><b>Valid Till: </b>{preview?.valid_till && preview?.valid_till}</small>
                                                    <div className='my-3 rightSideModelTxt'>{preview?.city ? <span><b>Location: </b>{preview?.city}  {/* <i className='fas fa-location-dot' /> */} </span> : "--"}</div>
                                                    <div className='my-3 rightSideModelTxt'><b>No of Openings: </b> {preview?.no_of_openings ? <span > <i className='fas fa-briefcase' /> {preview?.no_of_openings}</span> : "--"}</div>
                                                    <div className='my-3 rightSideModelTxt'><b>Experience Required: </b> {preview?.years_of_experience ? <span > {preview?.years_of_experience}</span> : "0"}<span className='fs-9'>{preview?.month_of_experience && "." + preview?.month_of_experience} year</span></div>
                                                    <div className='my-3 rightSideModelTxt'><b>Contract Type: </b> {preview?.contract_type ? preview?.contract_type : "--"}</div>
                                                    <div className='my-3 rightSideModelTxt'><b>Work Type: </b>{preview?.work_type ? preview?.work_type : "--"}</div>
                                                    <div className='my-3 rightSideModelTxt'><b>Shift Type: </b>{preview?.shift_type ? preview?.shift_type : "--"}</div>
                                                    <div className='my-3 rightSideModelTxt'><b>Designation: </b>{preview?.designation ? preview?.designation : "--"}</div>
                                                    <div className='my-3 rightSideModelTxt'> {(preview?.salary_type == 'Per-Day' || preview?.salary_type == 'Per-Hour') ? <span><b>Salary: </b> {preview?.currency_type == 'INR' ? "₹" : <i className="fa-solid fa-dollar"></i>}
                                                        {preview?.min_annual_salary || '--'} - {preview?.max_annual_salary || '--'} {preview?.salary_type}</span> :
                                                        <span><b>Salary: </b> {preview?.salary_type == "DND" ? <span> Do not disclose</span> : <span> {"$" + preview?.fixed_annual_salary + "LPA"} </span> || '--'} </span>
                                                    }</div>
                                                    <div className='my-3 rightSideModelTxt'>{preview?.industry && preview.industry != "null" ?
                                                        <span><b> Industry: </b>  {JSON.parse(preview.industry).join(', ')} </span> : "--"}</div>
                                                    <div className='my-3 rightSideModelTxt'>{preview?.skills && preview.skills != "null" ?
                                                        <span><b> Skills: </b>  {JSON.parse(preview.skills).join(', ')} </span> : "--"}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    )
                })}
                {viewPostDetail?.length == 0 && <div>
                    No Data Available
                </div>}
            </div>

        </div>

    )
}

export default EmployerDashboard