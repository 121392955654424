import { useEffect, useMemo, useReducer, useState } from "react"
import UseInput from "./UseInput"
import moment from "moment";


const Mycomponent = () => {
    const [date, setDate] = useState('2022-07-25');

    // const formattedDate = useMemo(() => {
    //     return moment(date).format('MMMM Do, YYYY');
    // }, [date]);


    useEffect(() => {
        console.log(moment(date).format('MMMM Do, YYYY'))
    }, [date])
    // const [handleSearch, setHandleSearch] = useState()

    // const initialState = { count: 0 };

    // const reducer = (state, action) => {
    //     switch (action.type) {
    //         case 'INCREMENT':
    //             return { count: state.count + 1 };
    //         case 'DECREMENT':
    //             return { count: state.count - 1 };
    //         default:
    //             return state;
    //     }
    // };
    // const [state, dispatch] = useReducer(reducer, initialState);



    // let handleclear = null

    // const HandleSearch = (e) => {
    //     console.log(e.target.value)
    //     const data = e.target.value

    //     if (handleclear) {
    //         clearTimeout(handleclear)
    //     }

    //     handleclear = setTimeout(() => {
    //         console.log(data)
    //         setHandleSearch(data)
    //     }, [2000])
    // }



    // console.log(factorial(5))

    // function factorial(n) {
    //     if (n === 0) {
    //         return 1;
    //     } else {
    //         return n * factorial(n - 1);
    //     }
    // }

    // console.log(factorial(5)); // Output: 120

    // for (let i = 0; i < 5; i++) {
    //     console.log(i ** 2)
    // }

    // const datass = [{
    //     name: "John Doe",
    //     age: 30,
    //     city: "New York"
    // },
    // {
    //     name: "AAdil Doe",
    //     age: 3,
    //     city: "aew York"
    // },
    // {
    //     name: "lokendra Doe",
    //     age: 25,
    //     city: "dew York"
    // }]

    // const datass2 = datass.sort((Item, index) => Item.city.localeCompare(index.city))



    console.log(date, "****************")

    return (
        <div className="mt-5">
            {/* <input onChange={HandleSearch} /> */}
            {/* 
            <p>Count: {state.count}</p>
            <button onClick={() => dispatch({ type: 'INCREMENT' })}>Increment</button>
            <button onClick={() => dispatch({ type: 'DECREMENT' })}>Decrement</button> */}
            <p>Formatted Date: {date}</p>
            <button onClick={() => setDate('2022-08-01')}>Change Date</button>

        </div>
    )
}

export default Mycomponent