import { Link, useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import toast, { Toaster } from 'react-hot-toast';
import { UseAppContext } from '../../../../Context/AppContext';
import { getApi, GetCountry, postApi } from '../../../Service/jobService';
import Loader from '../../../../components/Loader/loader';
import { formData } from '../../../Component/FormData';

const EditProfile = (props) => {
    const [profileData, setprofileData] = useState({});
    const [country, setCountry] = useState([]);
    const employerPrefix = process.env.REACT_APP_JOBS_EMPLOYER_PRIFIX
    const navigate = useNavigate();
    const [loader, setloader] = useState(true)
    let HeaderLogo = UseAppContext()
    const [logoValue, setLogoValue] = useState()
    const [updatelogo, setUpdatelogo] = useState()
    const employerJobs = JSON.parse(localStorage.getItem('jobs'))

    const getProfile = async () => {
        const response = await getApi("/employer/my-profile");
        if (response.status == true) {
            setTimeout(() => {
                setprofileData(response.data);
                setLogoValue(response.data?.logo);
                setUpdatelogo(response.data?.logo);
                setloader(false)
            }, 2000);
        } else if (response.message === 'Session expired') {
            localStorage.removeItem("jobs");
            navigate('jobs/login')
        } else {
            console.log("get employees data response", response);
        }
    }

    const getCountry = async () => {
        const response = await GetCountry();
        if (response.status == true) {
            setCountry(response.data);
        } else {
            console.log("get Country data response", response);
        }
    }

    useEffect(() => {
        getProfile()
        getCountry();
    }, [])


    const EditForm = useFormik({
        initialValues: {
            full_name: profileData?.full_name ? profileData?.full_name : "",
            country: profileData?.country_id ? profileData?.country_id : "",
            email: profileData?.email ? profileData?.email : "",
            mobile_number: profileData?.mobile_number ? profileData?.mobile_number : "",
            company_phone_no: profileData?.company_phone_no ? profileData?.company_phone_no : "",
            headquater: profileData?.headquater ? profileData?.headquater : "",
            industry_type: profileData?.industry_type ? profileData?.industry_type : "",
            website: profileData?.website ? profileData?.website : "",
            established_in_year: profileData?.established_in_year ? profileData?.established_in_year : "",
            logo: profileData?.logo,
        },
        enableReinitialize: true,
        validationSchema: yup.object({
            // full_name: yup.string().required('Please enter your name'),
            // address: yup.string().required('Please enter address'),
            // email: yup.string().email("Invalid email address format").required('Please enter valid email address'),
            // mobile_number: yup.number().typeError('please enter a valid number').required('Please enter mobile number').positive(),

        }),
        onSubmit: async values => {
            const formDatas = formData(updatelogo, EditForm);
            const response = await postApi("/employer/update-profile", formDatas, true);
            if (response.status === true) {
                setprofileData(response.data);
                localStorage.setItem("jobs", JSON.stringify({ ...employerJobs, ...response?.data }))

                toast.success(response.message);
                navigate(employerPrefix + '/view_profile');
            } else {
                console.log('error', response);
                toast.error(response);
            }
        }
    });


    const updateAndSaveEmployerRecord = async (e) => {
        try {
            const file = e.target.files[0];
            const fileUrl = URL.createObjectURL(file);
            setUpdatelogo(file)
            setLogoValue(fileUrl);
            // const updatedProfileData = { ...profileData, logo: fileUrl };
            // setprofileData(updatedProfileData);
        } catch (error) {
            console.error("Error uploading file:", error);
        }
    };

    return (
        <>
            <Toaster position="top-right" reverseOrder={false} />
            {loader == true ?
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100vh'
                    }} >
                    <Loader />
                </div> :
                <div className="main-container container-fluid mt-5">
                    <div className="page-header">
                        <div className="page-leftheader">

                            <h4 className="page-title mb-0 text-primary">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link className='breadcrumbslink' to="/jobs/employer/view_profile" >Employer Profile</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">Edit Profile</li>
                                    </ol>
                                </nav>
                            </h4>
                        </div>
                    </div>
                    <form className="row" id='registerForm' onSubmit={EditForm.handleSubmit}>
                        <div className="row">
                            <div className="col-xl-3 col-lg-3 col-md-3 col-xm-12">
                                <div className="form-group">
                                    <label className="form-label">Name Of Contact Person</label>
                                    <div className="row g-xs">
                                        <div className="input-group">
                                            <input type="text" className="form-control" name='full_name' placeholder="Enter name" {...EditForm.getFieldProps("full_name")} />
                                        </div>
                                        {EditForm.touched.full_name && EditForm.errors.full_name ?
                                            <div className="invalid-feedback" style={{ display: "block" }}>{EditForm.errors.full_name}</div> : ''}
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-3 col-xm-12">
                                <div className="form-group">
                                    <label className="form-label">Country</label>
                                    <div className="row g-xs">
                                        <div className="input-group">
                                            <select className="form-control" name="country" {...EditForm.getFieldProps("country")}>
                                                <option value="">Select Country</option>
                                                {country.map(item => (<option key={item.id} value={item.id}>{item.name}</option>))}

                                            </select>
                                        </div>
                                        {EditForm.touched.country && EditForm.errors.country ?
                                            <div className="invalid-feedback" style={{ display: "block" }}>{EditForm.errors.country}</div> : ''}
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-3 col-lg-3 col-md-3 col-xm-12">
                                <div className="form-group">
                                    <label className="form-label">Email</label>
                                    <div className="row g-xs">
                                        <div className="input-group">
                                            <input type="text" className="form-control" placeholder="Enter email" {...EditForm.getFieldProps("email")} />
                                        </div>
                                        {EditForm.touched.email && EditForm.errors.email ?
                                            <div className="invalid-feedback" style={{ display: "block" }}>{EditForm.errors.email}</div> : ''}
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-3 col-xm-12">
                                <div className="form-group">
                                    <label className="form-label">Mobile Number</label>
                                    <div className="row g-xs">
                                        <div className="input-group">
                                            <input type="number" className="form-control"
                                                placeholder="Enter Mobile Number" value={profileData?.mobile_number} disabled />
                                        </div>
                                        {EditForm.touched.mobile_number && EditForm.errors.mobile_number ?
                                            <div className="invalid-feedback" style={{ display: "block" }}>{EditForm.errors.mobile_number}</div> : ''}
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-3 col-xm-12">
                                <div className="form-group">
                                    <label className="form-label">Company Number</label>
                                    <div className="row g-xs">
                                        <div className="input-group">
                                            <input type="number" className="form-control" placeholder="Enter Company Number" {...EditForm.getFieldProps("company_phone_no")} />
                                        </div>
                                        {EditForm.touched.company_phone_no && EditForm.errors.company_phone_no ?
                                            <div className="invalid-feedback" style={{ display: "block" }}>{EditForm.errors.company_phone_no}</div> : ''}
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-3 col-xm-12">
                                <div className="form-group">
                                    <label className="form-label">Headquater Location</label>
                                    <div className="row g-xs">
                                        <div className="input-group">
                                            <input type="text" className="form-control" placeholder="Enter Headquater Location" {...EditForm.getFieldProps("headquater")} />
                                        </div>
                                        {EditForm.touched.headquater && EditForm.errors.headquater ?
                                            <div className="invalid-feedback" style={{ display: "block" }}>{EditForm.errors.headquater}</div> : ''}
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-3 col-xm-12">
                                <div className="form-group">
                                    <label className="form-label">Industy Type</label>
                                    <div className="row g-xs">
                                        <div className="input-group">
                                            <input type="text" className="form-control" placeholder="Enter Industy Type" {...EditForm.getFieldProps("industry_type")} />
                                        </div>
                                        {EditForm.touched.industry_type && EditForm.errors.industry_type ?
                                            <div className="invalid-feedback" style={{ display: "block" }}>{EditForm.errors.industry_type}</div> : ''}
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-3 col-xm-12">
                                <div className="form-group">
                                    <label className="form-label">Website</label>
                                    <div className="row g-xs">
                                        <div className="input-group">
                                            <input type="text" className="form-control" placeholder="Enter Website" {...EditForm.getFieldProps("website")} />
                                        </div>
                                        {EditForm.touched.website && EditForm.errors.website ?
                                            <div className="invalid-feedback" style={{ display: "block" }}>{EditForm.errors.website}</div> : ''}
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-3 col-xm-12">
                                <div className="form-group">
                                    <label className="form-label">Established Year</label>
                                    <div className="row g-xs">
                                        <div className="input-group">
                                            <input type="text" className="form-control" placeholder="Enter Established Year" {...EditForm.getFieldProps("established_in_year")} />
                                        </div>
                                        {EditForm.touched.established_in_year && EditForm.errors.established_in_year ?
                                            <div className="invalid-feedback" style={{ display: "block" }}>{EditForm.errors.established_in_year}</div> : ''}
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 col-xm-12">
                                <label className="form-label">Logo</label>
                                <div className="upload__box">
                                    <div className="upload__btn-box">
                                        <label className="upload__btn">
                                            <i className="fa-solid fa-arrow-up-from-bracket fa-fw"></i>
                                            <p>Upload images <span className="addmore-plus"><i
                                                className="fa-solid fa-plus fa-fw"></i></span>
                                                <small
                                                    style={{ display: "block", color: "#a5a5a5" }}>(JPG,
                                                    PNG only)</small>
                                            </p>
                                            <input type="file" accept=".jpg, .png"
                                                onChange={(e) => updateAndSaveEmployerRecord(e)}
                                                data-max_length="1"
                                                className="upload__inputfile" />
                                        </label>
                                    </div>
                                </div>
                                {logoValue ? (
                                    <ul className="imgpreview-newbx">
                                        <li className="registerboximg">
                                            <img src={logoValue} alt="" />
                                            <Link to="" onClick={() => { setLogoValue(null); setUpdatelogo(null) }} className="upload__img-close">
                                                <i className="fa-solid fa-close fa-fw"></i>
                                            </Link>
                                            {/* <p>img preview name here</p> */}
                                        </li>
                                    </ul>
                                ) : (
                                    <p>No logo uploaded</p>
                                )}
                            </div>

                            <div className="col-md-12">
                                <button type="submit" className="btn btn-primary addBtnNew">Submit</button>
                            </div>
                        </div>
                    </form>
                </div>}
        </>
    )
}
export default EditProfile;

