const getApi = (path, parameter = '', object = {}) => {
    const Timezone = (Intl.DateTimeFormat().resolvedOptions().timeZone)
    return new Promise(function (resolve, reject) {
        const JObs = JSON.parse(localStorage.getItem("jobs"));
        let headers = { 'Authorization': `Bearer ${JObs.jobs_token}` }
        headers = { ...headers, 'Accept': 'application/json', 'Content-Type': 'application/json', 'Timezone': Timezone }
        if (object) {
            headers = { ...headers, ...object }
        }

        const queryParams = parameter ? '?' + new URLSearchParams(parameter) : '';

        fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_JOBS_PRIFIX + '' + path + queryParams, {
            method: 'GET',
            headers: headers
        })
            .then((response) => {
                if (!response.ok) {
                    response.json().then(json => {
                        reject(json)
                    })
                } else {
                    response.json().then(json => {
                        resolve(json)
                    })
                }
            }).catch((error) => {
                console.log(error)
            });
    });
}


const postApi = (path, body = {}, is_formData = false, object = { 'Authorization': `Bearer ${JSON.parse(localStorage.getItem("jobs"))?.jobs_token}` }) => {
    const Timezone = (Intl.DateTimeFormat().resolvedOptions().timeZone)
    return new Promise(function (resolve, reject) {
        let headers = {}
        if (is_formData == false) {
            headers = { ...headers, 'Accept': 'application/json', 'Content-Type': 'application/json', 'Timezone': Timezone }
        } else {
            headers = { ...headers, 'Timezone': Timezone /* 'Content-Type': 'multipart/form-data' */ }
        }
        if (object) {
            headers = { ...headers, ...object, 'Timezone': Timezone }
        }
        fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_JOBS_PRIFIX + '' + path, {
            method: 'POST',
            headers: headers,
            body: is_formData ? body : JSON.stringify(body)
        })
            .then((response) => {
                if (!response.ok) {
                    response.json().then(json => {
                        resolve(json)
                    })
                } else {
                    response.json().then(json => {
                        resolve(json)
                    })
                }
            }).catch((response) => {
                console.log(response)
            });
    });
}

const GetCountry = async (token = "") => {
    const Timezone = (Intl.DateTimeFormat().resolvedOptions().timeZone)
    try {

        let requestOptions = {
            method: 'GET',
            headers: {
                'Timezone': Timezone
            },
            redirect: 'follow'
        };

        const res = await fetch(process.env.REACT_APP_API_URL + "/get-country", requestOptions);
        // console.log(res)
        const result = await res.json();
        return result;
    } catch (error) {
        console.log("🚀 ~ file: employService.js:500 ~ GetCountry ~ error:", error)
    }
}

const deleteApi = (path, body = {}, object = { 'Authorization': `Bearer ${JSON.parse(localStorage.getItem("jobs"))?.jobs_token}` }) => {
    const Timezone = (Intl.DateTimeFormat().resolvedOptions().timeZone)
    return new Promise(function (resolve, reject) {
        let headers = {}
        headers = { ...headers, 'Accept': 'application/json', 'Content-Type': 'application/json', 'Timezone': Timezone }

        if (object) {
            headers = { ...headers, ...object }
        }
        fetch(process.env.REACT_APP_API_URL + process.env.REACT_APP_JOBS_PRIFIX  + '' + path, {
            method: 'DELETE',
            headers: headers,
            body: JSON.stringify(body)
        })
            .then((response) => {
                if (!response.ok) {
                    response.json().then(json => {
                        resolve(json)
                    })
                } else {
                    response.json().then(json => {
                        resolve(json)
                    })
                }
            }).catch((response) => {
                console.log(response)
            });
    });
}

export { postApi, getApi, GetCountry, deleteApi }