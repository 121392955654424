import { Link, useLocation, useNavigate } from 'react-router-dom';
import React, { useState, useEffect, useRef } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import toast from 'react-hot-toast';
import "react-datepicker/dist/react-datepicker.css";
import { GetCountry, getApi, postApi } from '../../service/employService';
import AddionalInfo from './AddionalInfo';
import { useDispatch, useSelector } from 'react-redux';
import { setEmployerSystemType } from '../../../Redux/modalerrorReducer';

const AddEmployee = () => {
    const employer = JSON.parse(localStorage.getItem("employer"));
    const [viewTeamMember, SetViewTeamMember] = useState()
    const [country, setCountry] = useState([]);
    const prefix = process.env.REACT_APP_EMPLOYER_PRIFIX;
    const [data1, setData1] = useState([])
    const [viewAdditionalInfo, setViewAdditionalInfo] = useState({})
    const [department, setDepartment] = useState([]);
    const [getSublocationDetail, setSublocationdetail] = useState([])
    const [viewSkills, setViewSkills] = useState([]);
    const HandleCloseEditModal = useRef()
    const [data, setData] = useState([])
    const location = useLocation();
    const [Updateloader, setUpdateLoader] = useState(false)
    const [showAdditionalInfoModal, setShowAdditionalInfoModal] = useState(false)
    const [complianceDetail, setComplianceDetail] = useState([])
    const navigate = useNavigate();
    const { pathname, state } = location;
    const parts = pathname?.split('/');
    let id = ""
    if (parts != "") {
        id = parts[parts.length - 1];
    }
    const dispatch = useDispatch()
    const EmployerSystemType = useSelector(state => state.ModalError.EmployersystemType);
    useEffect(() => {
        if (EmployerSystemType.length == 0) {
            dispatch(setEmployerSystemType(employer.employer_system_type));
        }
    }, [])

    const GetTypeLocation = async () => {
        try {

            const response = await getApi("/get-job-location");

            if (response.status) {
                setData(response.data.rows);
            }
        } catch (error) {
            toast.error("error :", error);
            console.error("🚀 ~ file: addEmployer.js:66 ~ GetCountry ~ error:", error)
        }
    }

    useEffect(() => {
        GetTypeLocation();
    }, []);

    useEffect(() => {
        // console.log(location, "location")
        if (state?.data) {
            // setUpdateLoader(false)
            SetViewTeamMember(state.data);
            if (state?.data?.skills) {
                setViewSkills(JSON.parse(state?.data?.skills));
            }
        }
    }, [])


    useEffect(() => {
        if (id != "add_employee") {
            setUpdateLoader(true)
            // getEmployeeDetailByID()

        }
    }, [])

    const GetJobType = async () => {
        try {
            const response = await getApi("/get-job-type");

            if (response.status) {
                setData1(response.data.rows);
            }
        } catch (error) {
            toast.error("error :", error);
            console.error("🚀 ~ file: addEmployer.js:66 ~ GetCountry ~ error:", error)
        }
    }

    useEffect(() => {
        GetJobType();
    }, []);

    // console.log(viewTeamMember, "***************")

    const GetcomplianceDetail = async () => {
        try {
            const response = await getApi("/compliance-list");
            if (response.status) {
                // console.log(response.data)
                const dataFilter = response.data.filter(Item => Item.compliance_type == "minimum_per_hour_wages")
                setComplianceDetail(dataFilter[0].compliance_value);
            } else if (response.message == "Access denied! you're not eligible for NFC") {
                toast.error(response.message)
                navigate('/employer/dashboard')
            } else if (response.message == 'Session expired') {
                localStorage.removeItem("employer");
                navigate('/employer/login')
            }
        } catch (error) {
            toast.error("error :", error);
            if (error.message == 'Session expired') {
                localStorage.removeItem("employer");
                navigate('/employer/login')
            }
        }
    }
    useEffect(() => {
        GetcomplianceDetail()
    }, [])

    const AddForm = useFormik({
        initialValues: {

            first_name: viewTeamMember?.first_name ? viewTeamMember?.first_name : "",
            last_name: viewTeamMember?.last_name ? viewTeamMember?.last_name : "",
            mobile_number: "",
            reside_type: viewTeamMember?.reside_type ? viewTeamMember?.reside_type : "",
            email: viewTeamMember?.personal_email ? viewTeamMember?.personal_email : "",
            Country: viewTeamMember?.country_id ? viewTeamMember?.country_id : "",


            employee_code: viewTeamMember?.employee_code ? viewTeamMember?.employee_code : "",
            monthly_salary: viewTeamMember?.salary ? viewTeamMember?.salary : "0",
            deduction_amount_limit: viewTeamMember?.deduction_amount_limit ? viewTeamMember?.deduction_amount_limit : "0",
            Pay_Cycle: viewTeamMember?.pay_cycle ? viewTeamMember?.pay_cycle : "",
            salary_type: viewTeamMember?.salary_type ? viewTeamMember?.salary_type : "",

            job_id: viewTeamMember?.job_id ? viewTeamMember.job_id : "",
            sub_job_id: viewTeamMember?.sub_job_id ? viewTeamMember.sub_job_id : "",
            subL2_job_id: viewTeamMember?.sub_l2_job_id ? viewTeamMember?.sub_l2_job_id : "",
            location_id: viewTeamMember?.location_id ? viewTeamMember.location_id : "",
            sub_location_id: viewTeamMember?.sub_location_id ? viewTeamMember.sub_location_id : "",


            account_name: viewTeamMember?.account_name ? viewTeamMember.account_name : "",
            bank_name: viewTeamMember?.bank_name ? viewTeamMember.bank_name : "",
            account_number: viewTeamMember?.account_number ? viewTeamMember.account_number : "",
            branch: viewTeamMember?.branch ? viewTeamMember.branch : "",


            department_id: viewTeamMember?.department_id ? viewTeamMember.department_id : "",
            password: "",
            skills: "",

            NT_total_hrs: viewTeamMember?.NT_total_hrs ? viewTeamMember?.NT_total_hrs : "0",
            NT_per_hrs_wages: viewTeamMember?.NT_per_hrs_wages ? viewTeamMember?.NT_per_hrs_wages : "0",
            TH_total_hrs: viewTeamMember?.TH_total_hrs ? viewTeamMember?.TH_total_hrs : "0",
            TH_per_hrs_wages: viewTeamMember?.TH_per_hrs_wages ? viewTeamMember?.TH_per_hrs_wages : "0",
            MEAL_total_hrs: viewTeamMember?.MEAL_total_hrs ? viewTeamMember?.MEAL_total_hrs : "0",
            MEAL_per_hrs_wages: viewTeamMember?.MEAL_per_hrs_wages ? viewTeamMember?.MEAL_per_hrs_wages : "0",
            DT_total_hrs: viewTeamMember?.DT_total_hrs ? viewTeamMember?.DT_total_hrs : "0",
            DT_per_hrs_wages: viewTeamMember?.DT_per_hrs_wages ? viewTeamMember?.DT_per_hrs_wages : "0"

        },
        enableReinitialize: true,
        validationSchema: yup.object({
            first_name: yup.string().required('Please Enter First Name'),
            last_name: yup.string().required('Please Enter Last Name'),
            mobile_number: id == "add_employee" && yup.string().required('Please Enter Mobile Number'),
            email: yup.string().email('Please enter a valid email address').required('Please enter email address'),
            reside_type: yup.string().required('Please select Resident Type'),
            Country: yup.string().required('Please select Country'),

            employee_code: yup.string().required('Please Enter Employee Code'),
            monthly_salary: yup.string().required('Please Enter Monthly Salary').max(7, 'Monthly Salary Amount max upto 7 Digit'),
            deduction_amount_limit: yup.string().required('Please Enter Deduction Amount Limit').max(7, 'Deduction Amount Limit max upto 7 Digit'),
            Pay_Cycle: yup.string().required('Please select Pay Cycle'),
            salary_type: yup.string().required('Please select Salary Type'),
            department_id: yup.string().required('Please Select Department'),


            location_id: EmployerSystemType === "WFM" && yup.string().required('Please Select Location'),
            job_id: EmployerSystemType === "WFM" && yup.string().required('Please Select Job type'),


            account_name: yup.string().required('Please Enter Account Name'),
            bank_name: yup.string().required('Please Enter Bank Name'),
            account_number: yup.string().required('Please Enter Account Number'),
            branch: yup.string().required('Please Enter Branch'),
            NT_total_hrs: yup.string().required('Total Hrs is required'),
            NT_per_hrs_wages: yup.number().required('Per Hrs is required')
                .test('min-amount', `Minimum Per Hrs Wages cannot be less than ${complianceDetail}`, function (value) {
                    const complianceValue = complianceDetail;
                    if (complianceValue == 0) {
                        return true;
                    }
                    const Amount = value;
                    return Amount >= complianceValue;
                }),
            TH_total_hrs: yup.string().required('Total Hrs is required'),
            TH_per_hrs_wages: yup.string().required('Per Hrs is required'),
            MEAL_total_hrs: yup.string().required('Meal Hrs is required'),
            MEAL_per_hrs_wages: yup.string().required('Per Meal Rate is required'),
            DT_total_hrs: yup.string().required('Total Hrs is required'),
            DT_per_hrs_wages: yup.string().required('Per Hrs is required'),
            // skills: viewSkills.length == 0 && yup.string().required('Please Add Skill'),

        }),

        onSubmit: async (values, { resetForm }) => {
            /* const response = await postApi(`/edit-team-member/${currentCustomerID}`, */
            const data = {
                first_name: values.first_name,
                last_name: values.last_name,
                // employee_mobile_number: values.mobile_number,
                email: values.email,
                country_id: values.Country,
                reside_type: values.reside_type,


                department_id: values.department_id,
                employee_code: values.employee_code,
                salary_type: values.salary_type,
                salary: values.monthly_salary,
                deduction_amount_limit: values.deduction_amount_limit,
                skills: JSON.stringify(viewSkills) != "[]" ? JSON.stringify(viewSkills) : "",


                job_id: values.job_id,
                location_id: values.location_id,
                sub_location_id: values.sub_location_id,
                sub_job_id: values.sub_job_id,
                subL2_job_id: values.subL2_job_id,

                account_name: values.account_name,
                bank_name: values.bank_name,
                account_number: values.account_number,
                branch: values.branch,


                NT_per_hrs_wages: values.NT_per_hrs_wages,
                NT_total_hrs: values.NT_total_hrs,
                TH_per_hrs_wages: values.TH_per_hrs_wages,
                TH_total_hrs: values.TH_total_hrs,
                MEAL_per_hrs_wages: values.MEAL_per_hrs_wages,
                MEAL_total_hrs: values.MEAL_total_hrs,
                DT_per_hrs_wages: values.DT_per_hrs_wages,
                DT_total_hrs: values.DT_total_hrs
            }
            if (id === "add_employee") {
                data.employee_mobile_number = values.mobile_number;
                data.pay_cycle = values.Pay_Cycle;
            } else {
                data.password = values.password;
                data.Pay_Cycle = values.Pay_Cycle
            }

            const response = id === "add_employee" ? await postApi('/add-team-member', { ...data }) :
                await postApi(`/edit-team-member/${id}`, { ...data });
            if (response.status == true) {
                toast.success(response.message);
                resetForm({ values: '' });
                if (state?.from === "Scheduler") {
                    navigate('/employer/scheduler');
                } else if (state?.datas == "payroll") {
                    navigate("/employer/payroll");
                } else {
                    navigate("/employer/employees");
                }

                // getEmployeesData();
                HandleCloseEditModal.current.click()
            } else {
                console.log('inside abhi', response);
                toast.error(response.message);
            }
        }
    }
    );

    // const updateNumber = (value, path, digit) => {
    //     let regex = /[^0-9]/g
    //     if (regex.test(value)) {
    //         AddForm.setFieldValue(path, value.replace(/[^0-9]/g, ''))
    //     }
    //     else {
    //         if (value.length > digit) {
    //             value = value.substring(0, digit);
    //             AddForm.setFieldValue(path, value.replace(/[^0-9]/g, ''))

    //             // updateRecord({target:{value:value}},path)
    //         }
    //         else {
    //             AddForm.setFieldValue(path, value.replace(/[^0-9]/g, ''))
    //             // form.setFieldValue("aadhar_number_true",false)
    //         }
    //     }
    // }

    const updateNumber = (value, path, digit) => {
        let regex = /[^0-9\.]/g
        if (regex.test(value)) {
            AddForm.setFieldValue(path, value.replace(/[^0-9\.]/g, ''))
        } else {
            if (value.includes('.')) {
                const decimalPlaces = value.split('.')[1].length
                if (decimalPlaces > 2) {
                    value = value.substring(0, value.indexOf('.') + 3)
                    AddForm.setFieldValue(path, value)
                } else {
                    AddForm.setFieldValue(path, value)
                }
            } else {
                if (value.length > digit) {
                    value = value.substring(0, digit)
                    AddForm.setFieldValue(path, value)
                } else {
                    AddForm.setFieldValue(path, value)
                }
            }
        }
    }


    useEffect(() => {
        if (AddForm.values.location_id == "") {
            setSublocationdetail([])
            AddForm.setFieldValue('sub_location', "")
        }
        else {
            const foundItem = data.find(item => item.id == AddForm.values.location_id);
            if (foundItem) {
                setSublocationdetail(foundItem.subLocations);
            }
        }
    }, [AddForm.values.location_id, data]);


    const [subJobType, setSubJobType] = useState([])
    const [subL2JobType, setSubL2JobType] = useState([])

    useEffect(() => {

        if (AddForm.values.job_id == "") {
            setSubJobType([])
            AddForm.setFieldValue('sub_job_id', "")
            AddForm.setFieldValue('subL2_job_id', "");
        }
        else {
            const foundItem = data1.find(item => item.id == AddForm.values.job_id);

            if (foundItem) {
                setSubJobType(foundItem.subJobType);
                const foundSubJobType = foundItem.subJobType.find(subJobType => subJobType.id == AddForm.values.sub_job_id
                );
                if (foundSubJobType) {
                    setSubL2JobType(foundSubJobType.subL2JobType);
                } else {
                    setSubL2JobType([]);
                    AddForm.setFieldValue('subL2_job_id', "");
                }
            }
        }
    }, [AddForm.values.job_id, AddForm.values.sub_job_id, data1]);

    const GetCountrys = async () => {
        try {
            const response = await GetCountry(employer.employer_token);
            // console.log('response', response);
            if (response.status) {
                setCountry(response.data);
            }
        } catch (error) {
            toast.error("error :", error);
            console.error("🚀 ~ file: addEmployer.js:66 ~ GetCountry ~ error:", error)
        }
    }

    useEffect(() => {
        GetCountrys();
    }, []);

    const addSkill = () => {
        const newSkill = AddForm.values.skills;
        setViewSkills(prevSkills => [...prevSkills, newSkill]);

        AddForm.setFieldValue('skills', "");
        console.log("🚀 ~ addSkill ~ viewSkills:", viewSkills)
    }

    const removeName = (index) => {
        const newNames = [...viewSkills];
        newNames.splice(index, 1);
        setViewSkills(newNames);
    };
    const GetDepartment = async (token) => {
        try {
            const response = await getApi("/get-nfc-site-list");
            console.log('response', response);
            if (response.status == true) {
                setDepartment(response.data);

            } else if (response.message == "Access denied! you're not eligible for NFC") {
                toast.error(response.message)
                navigate('/employer/dashboard')
            } else if (response.message == 'Session expired') {
                localStorage.removeItem("employer");
                navigate('/employer/login')
            }
        } catch (error) {
            toast.error("error :", error);
            console.error("🚀 ~ file: addEmployer.js:66 ~ GetCountry ~ error:", error)
        }
    }

    useEffect(() => {
        GetDepartment();
    }, []);


    const ViewEmployeeAdditionalInfo = async () => {
        try {
            const response = await getApi(`/view_additional_information/${id}`)
            if (response.status) {
                // console.log("first")
                setViewAdditionalInfo(response.data)

            } else {
                console.log(response.status)
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        if (id != "add_employee") {
            ViewEmployeeAdditionalInfo()
        }
    }, [])


    return (
        <>
            <div className="main-container container-fluid px-0">
                <div className="page-header">
                    <div className="page-leftheader">
                        {/* <h4 className="page-title mb-0 text-primary">Add Employee</h4> */}
                        <h4 className="page-title mb-0 text-primary">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    {state?.datas == "payroll" ?
                                        <li className="breadcrumb-item"><Link className='breadcrumbslink' to="/employer/payroll" >Payroll</Link></li>
                                        : <li className="breadcrumb-item"><Link className='breadcrumbslink' to="/employer/employees" >Employee</Link></li>
                                    }
                                    <li className="breadcrumb-item active" aria-current="page">{id === "add_employee" ? "Add Employee" : "Edit Employee"}</li>
                                </ol>
                            </nav>
                        </h4>
                    </div>
                    <div className="page-rightheader">
                        <div className="btn-list">
                            {id != "add_employee" && showAdditionalInfoModal &&
                                <button type="button" className="btn btn-danger mt-2 me-2" onClick={() => setShowAdditionalInfoModal(!showAdditionalInfoModal)} > Show Basic Detail </button>}
                            {state?.datas == "payroll" ? <Link className="btn btn-success mt-2 me-2" to={"/employer/payroll"} >Back</Link> :
                                <Link className="btn btn-success mt-2 me-2" to={state?.from === "Scheduler" ? ("/employer/scheduler") : ("/employer/employees")} >Back</Link>}
                            {id == "add_employee" && <Link to={prefix + "/upload_employees"} className="btn btn-primary mt-2" ><i className="fa fa-plus me-2"></i> Upload Bulk Employee</Link>}
                        </div>
                    </div>
                </div>
                {!showAdditionalInfoModal &&
                    <form onSubmit={AddForm.handleSubmit}>
                        <h6 className="mb-3 text-primary">Personal Details</h6>
                        <div className='col-md-12 row mb-2'>
                            <div className="col-md-3">
                                <label>First Name {/* {AddForm.touched.first_name && !AddForm.errors.first_name && <i className="fa-solid fa-circle-check"></i>} */}</label>
                                <input type="text" className="form-control" placeholder="Enter Name" {...AddForm.getFieldProps("first_name")} />
                                {AddForm.touched.first_name && AddForm.errors.first_name ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.first_name}</div> : ''}
                            </div>
                            <div className="col-md-3">
                                <label>Last Name {/* {AddForm.touched.last_name && !AddForm.errors.last_name && <i className="fa-solid fa-circle-check"></i>} */}</label>
                                <input type="text" className="form-control" placeholder="Enter Name" {...AddForm.getFieldProps("last_name")} />
                                {AddForm.touched.last_name && AddForm.errors.last_name ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.last_name}</div> : ''}
                            </div>
                            {id == "add_employee" && <div className="col-md-3">
                                <label>Mobile Number {/* {AddForm.touched.email && !AddForm.errors.email && <i className="fa-solid fa-circle-check"></i>} */}</label>
                                <input type="text" className="form-control" placeholder="Enter Mobile Number"
                                    {...AddForm.getFieldProps("mobile_number")}
                                    onChange={(e) => { updateNumber(e.target.value, 'mobile_number', 15) }} />
                                {AddForm.touched.mobile_number && AddForm.errors.mobile_number ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.mobile_number}</div> : ''}
                            </div>}
                            <div className="col-md-3">
                                <label>Email {/* {AddForm.touched.email && !AddForm.errors.email && <i className="fa-solid fa-circle-check"></i>} */}</label>
                                <input type="email" className="form-control" placeholder="Enter Email" {...AddForm.getFieldProps("email")} />
                                {AddForm.touched.email && AddForm.errors.email ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.email}</div> : ''
                                }
                            </div>
                            <div className="col-md-3 mb-3">
                                <label>Country {/* {AddForm.touched.Country && !AddForm.errors.Country && <i className="fa-solid fa-circle-check"></i>} */}</label>
                                <select className="form-control mb-0" name="Country" {...AddForm.getFieldProps("Country")} >
                                    <option>Select Country</option>
                                    {country.map((e) => {
                                        return (
                                            <option key={e.id} value={e.id}>{e.name}</option>
                                        )
                                    })
                                    }
                                </select>

                                {AddForm.touched.Country && AddForm.errors.Country ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.Country}</div> : ''}
                            </div>
                            <div className="col-md-3 mb-3">
                                <label>Resident Type </label>
                                <select className="form-control mb-0" name="Resident Type" {...AddForm.getFieldProps("reside_type")} >
                                    <option>Select Resident Type</option>
                                    <option value="Resident">Resident</option>
                                    <option value="Non-resident">Non-resident</option>
                                </select>

                                {AddForm.touched.reside_type && AddForm.errors.reside_type ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.reside_type}</div> : ''}
                            </div>
                        </div>
                        <h6 className="mb-3 text-primary">Work Details</h6>
                        <div className='col-md-12 row mb-3'>
                            <div className="col-md-3">
                                <label>Employee Code {/* {AddForm.touched.employee_code && !AddForm.errors.employee_code && <i className="fa-solid fa-circle-check"></i>} */}</label>
                                <input type="text" className="form-control" placeholder="Enter Employee Code" {...AddForm.getFieldProps("employee_code")} />
                                {AddForm.touched.employee_code && AddForm.errors.employee_code ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.employee_code}</div> : ''}
                            </div>
                            <div className="col-md-3">
                                <label>Monthly Salary {/* {AddForm.touched.monthly_salary && !AddForm.errors.monthly_salary && <i className="fa-solid fa-circle-check"></i>} */}</label>
                                <input type="text" className="form-control" placeholder="Enter Monthly Salary"
                                    {...AddForm.getFieldProps("monthly_salary")} onChange={(e) => updateNumber(e.target.value, 'monthly_salary', 7)} />
                                {AddForm.touched.monthly_salary && AddForm.errors.monthly_salary ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.monthly_salary}</div> : ''}
                            </div>
                            <div className="col-md-3">
                                <label>Deduction Amount Limit {/* {AddForm.touched.monthly_salary && !AddForm.errors.monthly_salary && <i className="fa-solid fa-circle-check"></i>} */}</label>
                                <input type="text" className="form-control" placeholder="Enter Deduction Amount Limit"
                                    {...AddForm.getFieldProps("deduction_amount_limit")} onChange={(e) => updateNumber(e.target.value, 'deduction_amount_limit', 7)} />
                                {AddForm.touched.deduction_amount_limit && AddForm.errors.deduction_amount_limit ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.deduction_amount_limit}</div> : ''}
                            </div>
                            <div className="col-md-3">
                                <label>Pay cycle {/* {AddForm.touched.Pay_Cycle && !AddForm.errors.Pay_Cycle && <i className="fa-solid fa-circle-check"></i>} */}</label>
                                <select className="form-control mb-0" name="Pay_Cycle" {...AddForm.getFieldProps("Pay_Cycle")} >
                                    <option value="">Select Pay Cycle</option>
                                    <option value='weekly'>Weekly</option>
                                    <option value='Fortnightly'>Fortnightly</option>
                                    <option value='monthly'>Monthly</option>
                                </select>

                                {AddForm.touched.Pay_Cycle && AddForm.errors.Pay_Cycle ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.Pay_Cycle}</div> : ''}
                            </div>
                            <div className="col-md-3">
                                <label>Salary Type {/* {AddForm.touched.Pay_Cycle && !AddForm.errors.Pay_Cycle && <i className="fa-solid fa-circle-check"></i>} */}</label>
                                <select className="form-control mb-0" name="salary_type" {...AddForm.getFieldProps("salary_type")} >
                                    <option value="">Select Salary Type</option>
                                    <option value='monthly'>Monthly</option>
                                    <option value='wages_based'>Wages Based</option>
                                </select>

                                {AddForm.touched.salary_type && AddForm.errors.salary_type ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.salary_type}</div> : ''}
                            </div>
                            <div className="col-md-3">
                                <label>Department</label>
                                <div style={{ position: 'relative' }}>
                                    <select
                                        className="form-control mb-0"
                                        name="Department"
                                        {...AddForm.getFieldProps('department_id')}
                                    >
                                        <option value="">Select Department</option>
                                        {department.map((items, index) => (
                                            <option key={index} value={items.site_id}>
                                                {items.nfc_site_name}
                                            </option>
                                        ))}
                                    </select>

                                </div>
                                {AddForm.touched.department_id && AddForm.errors.department_id ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.department_id}</div> : ''}
                            </div>

                            {id !== "add_employee" && <div className="col-md-3">
                                <label>Password {/* {AddForm.touched.employee_code && !AddForm.errors.employee_code && <i className="fa-solid fa-circle-check"></i>} */}</label>
                                <input type="password" className="form-control" placeholder="Enter Employee Password" {...AddForm.getFieldProps("password")} />
                                {AddForm.touched.password && AddForm.errors.password ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.password}</div> : ''}
                            </div>}


                            <div className="col-md-6">
                                <label>Skills {/* {AddForm.touched.skills && !AddForm.errors.skills && <i className="fa-solid fa-circle-check"></i>} */}</label>
                                <div style={{ position: 'relative' }}>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Add Skill"
                                        // value={inputNames}
                                        // onChange={(e) => setInputNames([e.target.value])}
                                        {...AddForm.getFieldProps('skills')}
                                        style={{ paddingRight: '30px' }} // Add padding for the icon
                                    />
                                    {AddForm.values.skills.replace(/\s/g, "").length > 0 && <i
                                        className="fas fa-plus text-primary "
                                        style={{ position: 'absolute', top: '50%', right: '10px', transform: 'translateY(-50%)', cursor: 'pointer' }}
                                        onClick={() => addSkill()}
                                    />}
                                    {AddForm.touched.skills && AddForm.errors.skills ?
                                        <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.skills}</div> : ''}
                                </div>
                                {viewSkills != null && viewSkills.length > 0 && viewSkills.map((item, index) => {
                                    return (
                                        <span
                                            key={index}
                                            className="m-1 px-1 py-1 rounded border text-white text-wrap"
                                            style={{ cursor: 'pointer', fontSize: '12px', backgroundColor: '#0F2F81' }}
                                        >
                                            {item} <span onClick={() => removeName(index)}>&times;</span>
                                        </span>
                                    );
                                })}


                            </div>
                        </div>
                        <h6 className="mb-3 text-primary">Location & Job Type</h6>
                        <div className='col-md-12 row mb-3'>
                            <div className="col-md-3">
                                <label>Location</label>
                                <div style={{ position: 'relative' }}>
                                    <select
                                        className="form-control mb-0"
                                        name="location_id"
                                        {...AddForm.getFieldProps('location_id')}
                                    // value={departmentInput}
                                    // onChange={handleSelectChange}
                                    >
                                        <option value="">Select Location</option>
                                        {data.map((e) => (
                                            <option key={e.id} value={e.id}>
                                                {e.job_location}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                {AddForm.touched.location_id && AddForm.errors.location_id ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.location_id}</div> : ''}
                            </div>

                            <div className="col-md-3">
                                <label>Sub Location</label>
                                <div style={{ position: 'relative' }}>
                                    <select
                                        className="form-control mb-0"
                                        name="location_id"
                                        {...AddForm.getFieldProps('sub_location_id')}
                                    // value={departmentInput}
                                    // onChange={handleSelectChange}
                                    >
                                        <option value="">Select Sub Location</option>
                                        {getSublocationDetail.map((data) => (
                                            <option key={data.id} value={data.id}>
                                                {data.sub_location}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>

                            <div className="col-md-3">
                                <label>JOB Type</label>
                                <div style={{ position: 'relative' }}>
                                    <select
                                        className="form-control mb-0"
                                        name="job_id"
                                        {...AddForm.getFieldProps('job_id')}
                                    // value={departmentInput}
                                    // onChange={handleSelectChange}
                                    >
                                        <option value="">Select Job Type</option>
                                        {data1.map((data) => (
                                            <option key={data.id} value={data.id}>
                                                {data.job_type}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                {AddForm.touched.job_id && AddForm.errors.job_id ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.job_id}</div> : ''}
                            </div>
                            <div className="col-md-3">
                                <label>Sub Job Type</label>
                                <div style={{ position: 'relative' }}>
                                    <select
                                        className="form-control mb-0"
                                        name="sub_job_id"
                                        {...AddForm.getFieldProps('sub_job_id')}
                                    // value={departmentInput}
                                    // onChange={handleSelectChange}
                                    >
                                        <option value="">Select Sub Job Type</option>
                                        {subJobType.map((data) => (
                                            <option key={data.id} value={data.id}>
                                                {data.sub_job_type}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>

                            <div className="col-md-3">
                                <label>Sub L2 Job Type</label>
                                <div style={{ position: 'relative' }}>
                                    <select
                                        className="form-control mb-0"
                                        name="subL2_job_id"
                                        {...AddForm.getFieldProps('subL2_job_id')}
                                    // value={departmentInput}
                                    // onChange={handleSelectChange}
                                    >
                                        <option value="">Select Sub Job Type</option>
                                        {subL2JobType.map((data) => (
                                            <option key={data.id} value={data.id}>
                                                {data.sub_l2_job_type}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>


                        <h6 className="mb-3 text-primary">Bank Detail</h6>
                        <div className='col-md-12 row mb-3'>
                            <div className="col-md-3">
                                <label>Account Name </label>
                                <input type="text" className="form-control" placeholder="Enter Account Name"
                                    {...AddForm.getFieldProps("account_name")} />
                                {AddForm.touched.account_name && AddForm.errors.account_name ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.account_name}</div> : ''}
                            </div>
                            <div className="col-md-3">
                                <label>Bank Name </label>
                                <input type="text" className="form-control" placeholder="Enter Bank Name"
                                    {...AddForm.getFieldProps("bank_name")} />
                                {AddForm.touched.bank_name && AddForm.errors.bank_name ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.bank_name}</div> : ''}
                            </div>
                            <div className="col-md-3">
                                <label>Account Number </label>
                                <input type="number" className="form-control" placeholder="Enter Account Number"
                                    {...AddForm.getFieldProps("account_number")} />
                                {AddForm.touched.account_number && AddForm.errors.account_number ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.account_number}</div> : ''}
                            </div>
                            <div className="col-md-3">
                                <label>Branch Name </label>
                                <input type="text" className="form-control" placeholder="Enter Branch Name"
                                    {...AddForm.getFieldProps("branch")} />
                                {AddForm.touched.branch && AddForm.errors.branch ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.branch}</div> : ''}
                            </div>
                        </div>




                        <h6 className="mb-3 text-primary">Hours & Wages</h6>
                        <div className="col-md-12 row">
                            <div className='d-flex' style={{ justifyContent: 'space-between' }}>
                                <h6 className='cbg-primary p-2 rounded'>Normal Hours</h6>
                                <h6 className='cbg-primary p-2 rounded'/*  style={{ marginLeft: '0em' }} */>Time & Half</h6>
                                <h6 />
                            </div>
                            <div className="col-md-3">
                                <label>Total No Of Hrs{/* {AddForm.touched.NT_total_hrs && !AddForm.errors.NT_total_hrs && <i className="fa-solid fa-circle-check"></i>} */}</label>
                                <input type="text" className="form-control" placeholder="Total No Of Hrs"
                                    {...AddForm.getFieldProps("NT_total_hrs")}
                                    onChange={(e) => updateNumber(e.target.value, 'NT_total_hrs', 5)} />
                                {AddForm.touched.NT_total_hrs && AddForm.errors.NT_total_hrs ?

                                    <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.NT_total_hrs}</div> : ''}
                            </div>

                            <div className="col-md-3">
                                <label>Per Hr Wages{/* {AddForm.touched.NT_total_hrs && !AddForm.errors.NT_total_hrs && <i className="fa-solid fa-circle-check"></i>} */}</label>

                                <input type="text" className="form-control" placeholder="Per Hour Wages"
                                    {...AddForm.getFieldProps("NT_per_hrs_wages")}
                                    onChange={(e) => updateNumber(e.target.value, 'NT_per_hrs_wages', 5)}
                                />

                                {AddForm.touched.NT_per_hrs_wages && AddForm.errors.NT_per_hrs_wages ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.NT_per_hrs_wages}</div> : ''}
                            </div>

                            <div className="col-md-3" >
                                <label>Total No Of Hr {/* {AddForm.touched.TH_total_hrs && !AddForm.errors.TH_total_hrs && <i className="fa-solid fa-circle-check"></i>} */}</label>
                                <input type="text" className="form-control" placeholder="Total No Of Hrs"
                                    {...AddForm.getFieldProps("TH_total_hrs")}
                                    onChange={(e) => updateNumber(e.target.value, 'TH_total_hrs', 5)} />

                                {AddForm.touched.TH_total_hrs && AddForm.errors.TH_total_hrs ?

                                    <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.TH_total_hrs}</div> : ''}
                            </div>

                            <div className="col-md-3" >
                                <label>Per Hour Wages {/* {AddForm.touched.TH_per_hrs_wages && !AddForm.errors.TH_per_hrs_wages && <i className="fa-solid fa-circle-check"></i>} */}</label>

                                <input type="text" className="form-control" placeholder="Per Hour Wages"
                                    {...AddForm.getFieldProps("TH_per_hrs_wages")}
                                    onChange={(e) => updateNumber(e.target.value, 'TH_per_hrs_wages', 5)} />

                                {AddForm.touched.TH_per_hrs_wages && AddForm.errors.TH_per_hrs_wages ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.TH_per_hrs_wages}</div> : ''}
                            </div>
                        </div>
                        <div className="col-md-12 row">
                            <div className='d-flex' style={{ justifyContent: 'space-between' }}>
                                <h6 className='cbg-primary p-2 rounded'>Meal</h6>
                                <h6 className='cbg-primary p-2 rounded' style={{ marginLeft: '3em' }}>Double Time</h6>
                                <h6 />
                            </div>
                            <div className="col-md-3" >
                                <label>Meal Hours {/* {AddForm.touched.MEAL_total_hrs && !AddForm.errors.MEAL_total_hrs && <i className="fa-solid fa-circle-check"></i>} */}</label>
                                <input type="text" className="form-control" placeholder="Meal Hour"
                                    {...AddForm.getFieldProps("MEAL_total_hrs")}
                                    onChange={(e) => updateNumber(e.target.value, 'MEAL_total_hrs', 5)} />

                                {AddForm.touched.MEAL_total_hrs && AddForm.errors.MEAL_total_hrs ?

                                    <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.MEAL_total_hrs}</div> : ''}
                            </div>
                            <div className="col-md-3" >
                                <label>Meal Rate {/* {AddForm.touched.MEAL_per_hrs_wages && !AddForm.errors.MEAL_per_hrs_wages && <i className="fa-solid fa-circle-check"></i>} */}</label>

                                <input type="text" className="form-control" placeholder="Per Meal Rate"
                                    {...AddForm.getFieldProps("MEAL_per_hrs_wages")}
                                    onChange={(e) => updateNumber(e.target.value, 'MEAL_per_hrs_wages', 5)} />

                                {AddForm.touched.MEAL_per_hrs_wages && AddForm.errors.MEAL_per_hrs_wages ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.MEAL_per_hrs_wages}</div> : ''}
                            </div>

                            <div className="col-md-3" >
                                <label>Total No.of Hrs {/* {AddForm.touched.DT_total_hrs && !AddForm.errors.DT_total_hrs && <i className="fa-solid fa-circle-check"></i>} */}</label>
                                <input type="text" className="form-control" placeholder="Total No Of Hrs"
                                    {...AddForm.getFieldProps("DT_total_hrs")}
                                    onChange={(e) => updateNumber(e.target.value, 'DT_total_hrs', 5)} />

                                {AddForm.touched.DT_total_hrs && AddForm.errors.DT_total_hrs ?

                                    <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.DT_total_hrs}</div> : ''}
                            </div>

                            <div className="col-md-3" >
                                <label> Per Hr Wages {/* {AddForm.touched.DT_per_hrs_wages && !AddForm.errors.DT_per_hrs_wages && <i className="fa-solid fa-circle-check"></i>} */}</label>

                                <input type="text" className="form-control" placeholder="Per Hour Wages"
                                    {...AddForm.getFieldProps("DT_per_hrs_wages")}
                                    onChange={(e) => updateNumber(e.target.value, 'DT_per_hrs_wages', 5)} />

                                {AddForm.touched.DT_per_hrs_wages && AddForm.errors.DT_per_hrs_wages ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.DT_per_hrs_wages}</div> : ''}
                            </div>
                        </div>
                        <div className="modal-footer" style={{ justifyContent: 'space-between', }}>
                            {id != "add_employee" &&
                                <button type="button" className="btn btn-success" onClick={() => setShowAdditionalInfoModal(!showAdditionalInfoModal)} > Show Additional Information </button>}
                            <button type="submit" className="btn btn-primary">
                                {id === "add_employee" ? "Add Employee" : "Update Details"}</button>
                        </div>
                    </form>
                }

                {showAdditionalInfoModal && <AddionalInfo EmployerID={viewTeamMember?.employer_id} CustomerID={viewTeamMember?.customer_id} viewAdditionalInfo={viewAdditionalInfo} />}
            </div>
        </>
    )
}

export default AddEmployee;
