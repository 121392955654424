import { Link, useNavigate } from 'react-router-dom';
import React from 'react';
import { UseAppContext } from '../../Context/AppContext';

export const Employerheader = (props) => {
    const employerPrefix = process.env.REACT_APP_JOBS_EMPLOYER_PRIFIX
    const { prefix } = props;
    // const [profileData, setprofileData] = useState({});
    const navigate = useNavigate();
    const { header, ToggleHeader, logo } = UseAppContext();
    const Jobs = JSON.parse(localStorage.getItem("jobs"));


    const logOut = () => {
        localStorage.removeItem("jobs");
        navigate(prefix + '/login')
    }


    return (
        <>
            <div className={header ? "app-header header sticky app_header_res" : "app-header header sticky"}>
                <div className="container-fluid main-container">
                    <div className="d-flex">
                        <div /* className="app-sidebar__toggle d-flex align-items-center" data-bs-toggle="sidebar" */>
                            {/* <Link className="open-toggle" to="" onClick={() => ToggleHeader()}>
                                <svg xmlns="http://www.w3.org/2000/svg" className="feather feather-align-left header-icon" width="24" height="24" viewBox="0 0 24 24">
                                    <path d="M4 6h16v2H4zm0 5h16v2H4zm0 5h16v2H4z"></path>
                                </svg>
                            </Link> */}
                        </div>
                        <div className='d-flex mt-4 ms-5'>
                            <Link className={props.locationName == employerPrefix + '/dashboard' ? "text-primary" : " text-muted"} to={employerPrefix + '/dashboard'}>Dashboard</Link>
                            <Link className="text-muted ms-5">Candidates</Link>

                            {/* <p className='ms-5 text-muted'>Find Jobs</p> */}
                            {/* <p className='ms-5 text-muted'>Blogs</p>
                            <p className='ms-5 text-muted'>Pages</p>  "side-menu__item active" : ""     */}
                        </div>
                        <Link className="logo-horizontal " to={prefix}>
                            <img
                                src={process.env.PUBLIC_URL + "/assets/img/logo-thirdroc.png"}
                                className="header-brand-img desktop-lgo" alt="logo" />
                            <img src={process.env.PUBLIC_URL + "/assets/img/logo-thirdroc.png"} className="header-brand-img dark-logo" alt="logo" />
                        </Link>
                        <div className="d-flex order-lg-2 ms-auto main-header-end">
                            {/* <button className='btn btn-primary mt-3' style={{ height: '40px' }}>Job Post</button> */}

                            <div className="navbar navbar-expand-lg navbar-collapse responsive-navbar p-0">
                                <div className="collapse navbar-collapse" id="navbarSupportedContent-4">
                                    <div className='text-primary' style={{ marginTop: 'auto' }}>
                                        <h6 >{Jobs?.full_name}</h6> <div>
                                            <h6 >{Jobs?.mobile_number}</h6>
                                        </div>
                                    </div>
                                    <div className="d-flex order-lg-2">
                                        {/* <div className="dropdown header-notify d-flex">
                              <Link className="nav-link icon" data-bs-toggle="dropdown"> <svg xmlns="http://www.w3.org/2000/svg" className="header-icon" width="24" height="24" viewBox="0 0 24 24"> <path d="M19 13.586V10c0-3.217-2.185-5.927-5.145-6.742C13.562 2.52 12.846 2 12 2s-1.562.52-1.855 1.258C7.185 4.074 5 6.783 5 10v3.586l-1.707 1.707A.996.996 0 0 0 3 16v2a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1v-2a.996.996 0 0 0-.293-.707L19 13.586zM19 17H5v-.586l1.707-1.707A.996.996 0 0 0 7 14v-4c0-2.757 2.243-5 5-5s5 2.243 5 5v4c0 .266.105.52.293.707L19 16.414V17zm-7 5a2.98 2.98 0 0 0 2.818-2H9.182A2.98 2.98 0 0 0 12 22z"> </path> </svg>
                                 <span className="badge bg-orange side-badge">5</span> </Link>
                           </div> */}
                                        <div className="dropdown profile-dropdown d-flex">
                                            <Link to={prefix} className="nav-link pe-0 leading-none" data-bs-toggle="dropdown">
                                                <span className="header-avatar1">
                                                    {Jobs?.logo ?
                                                        <img className="avatar avatar-md brround"
                                                            src={Jobs?.logo}
                                                            alt='...'
                                                        />
                                                        :
                                                        <img
                                                            src={process.env.PUBLIC_URL + '/assets/img/images.png'}
                                                            // src="https://thirdroc1.s3.ap-southeast-2.amazonaws.com/logo/8i5h6h8a.jpg"
                                                            alt="img" className="avatar avatar-md brround" />
                                                    }
                                                </span> </Link>

                                            <div className="dropdown-menu dropdown-menu-end dropdown-menu-arrow animated">
                                                <Link to={employerPrefix + '/view_profile'} className="dropdown-item d-flex" >
                                                    <div className="fs-13"> <i className="fa-solid fa-user fa-fw header-icon me-1"></i> Profile</div>
                                                </Link>
                                                <Link className="dropdown-item d-flex" to={prefix} onClick={() => logOut()}>
                                                    {/* <svg className="header-icon me-2" xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="24" viewBox="0 0 24 24" width="24"> <g>
                                                        <rect fill="none" height="24" width="24"></rect> </g> <g>
                                                            <path d="M11,7L9.6,8.4l2.6,2.6H2v2h10.2l-2.6,2.6L11,17l5-5L11,7z M20,19h-8v2h8c1.1,0,2-0.9,2-2V5c0-1.1-0.9-2-2-2h-8v2h8V19z"> </path> </g>
                                                    </svg> */}

                                                    <div className="fs-13"><i className="fa-solid fa-sign-out-alt fa-fw header-icon me-2" />Sign Out</div>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div className="sticky is-expanded">
                {/* <div className="app-sidebar__overlay active" data-bs-toggle="sidebar" ></div> */}
                {/* <aside className={header ? "app-sidebar open ps ps--active-y res_aside" : "app-sidebar open ps ps--active-y"}> */}
                <div className={header ? "app-sidebar__logo res_aside " : "app-sidebar__logo"}>
                    <Link className="header-brand">
                        <img src={process.env.PUBLIC_URL + "/assets/img/logo-thirdroc.png"} className="header-brand-img desktop-lgo" alt="logo" />
                        <img src={process.env.PUBLIC_URL + "/assets/img/logo-thirdroc.png"} className="header-brand-img mobile-logo" alt="Azea logo" />
                    </Link>
                </div>

            </div>

        </>
    )
}

export default Employerheader;