import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import PropTypes from 'prop-types';

const Editor = ({ placeholder, setEditorHtml, editorHtml }) => {

    const handleChange = (html) => {
        setEditorHtml(html);
    };

    console.log(editorHtml, "*****************")


    const modules = {
        toolbar: [
            [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
            [{ size: [] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' },
            { 'indent': '-1' }, { 'indent': '+1' }],
            ['link', 'image', 'video'],
            // ['clean']
        ],
        clipboard: {
            matchVisual: false,
        }
    };

    const formats = [
        'header', 'font', 'size',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image', 'video'
    ];

    return (
        <div>
            <ReactQuill
                onChange={handleChange}
                value={editorHtml}
                modules={modules}
                formats={formats}
                bounds={'.app'}
                placeholder={placeholder}
            />
        </div>
    );
};

Editor.propTypes = {
    placeholder: PropTypes.string,
};

export default Editor;