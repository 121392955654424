import { Link, useLocation, useParams, useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { UseAppContext } from '../../Context/AppContext';
import { GetPermission, GetProfile, getApi } from '../service/employService';
import { useSelector, useDispatch } from 'react-redux';
import { setEmployerBusinessType, setEmployerSystemType, setPublishShiftData } from '../../Redux/modalerrorReducer';
import Swal from 'sweetalert2';
import moment from 'moment';

export const Header = (props) => {
   const { header, ToggleHeader, logo } = UseAppContext();
   const [profileData, setprofileData] = useState({});
   const [employerName, setEmployerName] = useState()
   const [employermobile, setEmployermobile] = useState()
   const [settinngSubmenu, setSettingSubmenu] = useState(false)
   const [settinngSubmenuthirdroc, setSettingSubmenuthirdroc] = useState(false)
   const [settinngSubmenuWFM, setSettingSubmenuWFM] = useState(false)
   const [settingSubmenuWFMReport, setSettingSubmenuWFMReport] = useState(false)
   const [roles, setRoles] = useState(false)
   const [NFCSubmenu, setNFCSubmenu] = useState(false)
   const navigate = useNavigate();
   const employers = JSON.parse(localStorage.getItem("employer"));
   const [permission_data, setPermission_data] = useState([])
   const [employee_view, setemployee_view] = useState(false)
   const [ewa_attendance_view, setewa_attendance_view] = useState(false)
   const [nfc_view, setnfc_view] = useState(false)
   const [repayment_view_bnpl_list, setrepayment_view_bnpl_list] = useState(false)
   const [repayment_view_ewa_list, setrepayment_view_ewa_list] = useState(false)
   const [settlement_bnpl_transaction, setsettlement_bnpl_transaction] = useState(false)
   const [settlement_ewa_transaction, setsettlement_ewa_transaction] = useState(false)
   const [settingSubmenuWFMapproval, setSettingSubmenuWFMapproval] = useState(false)
   const dispatch = useDispatch()
   const user_type = employers.user_type
   // const is_nfc_active = employers.is_nfc_active
   const [nfc_status, setnfc_status] = useState()
   const prefix = "/employer";
   let { pathname } = useLocation()

   const publishShiftData = useSelector(state => state.ModalError.publishShiftData.length);
   const EmployerSystemType = useSelector(state => state.ModalError.EmployersystemType);
   const employerBusinessType = useSelector(state => state.ModalError.EmployerBusinessType);

   useEffect(() => {
      if (EmployerSystemType?.length == 0) {
         dispatch(setEmployerSystemType(employer.employer_system_type));
      }
      if (employerBusinessType?.length == 0) {
         dispatch(setEmployerBusinessType(employer.data?.business_type));
      }
   }, [])

   const logOut = () => {
      // Check if employer item exists in local storage
      if (localStorage.getItem("employer")) {
         localStorage.removeItem("employer");
      }
      navigate('/employer/login');
   }

   const employer = JSON.parse(localStorage.getItem("employer"));


   // console.log(employer)
   const showSubMenu = async () => {

      var dropdown = document.getElementsByClassName("dropdown-container");
      let ele = [...dropdown]

      if (ele[0].style.display == "none") {
         dropdown[0].style.display = "block"
         setSettingSubmenu(true)
      } else {
         dropdown[0].style.display = "none"
         setSettingSubmenu(false)
      }
   }

   const ViewNFC = async () => {

      var dropdown = document.getElementsByClassName("dropdown-containerNFC");
      let ele = [...dropdown]

      if (ele[0].style.display == "none") {
         dropdown[0].style.display = "block"
         setNFCSubmenu(true)
      } else {
         dropdown[0].style.display = "none"
         setNFCSubmenu(false)
      }
   }

   const paytothirdroc = async () => {

      var dropdown = document.getElementsByClassName("dropdown-containerthird");
      let ele = [...dropdown]
      // console.log(ele);
      //paytoEMI console.log(ele[0].style.display);

      if (ele[0].style.display == "none") {
         dropdown[0].style.display = "block"
         setSettingSubmenuthirdroc(true)
      } else {
         dropdown[0].style.display = "none"
         setSettingSubmenuthirdroc(false)
      }
   }

   const WFM = async () => {

      var dropdown = document.getElementsByClassName("dropdown-containerWFM");
      let ele = [...dropdown]
      // console.log(ele);
      //paytoEMI console.log(ele[0].style.display);

      if (ele[0].style.display == "none") {
         dropdown[0].style.display = "block"
         setSettingSubmenuWFM(true)
      } else {
         dropdown[0].style.display = "none"
         setSettingSubmenuWFM(false)
      }
   }

   const WFMReport = async () => {

      var dropdown = document.getElementsByClassName("dropdown-containerWFMReport");
      let ele = [...dropdown]
      // console.log(ele);
      //paytoEMI console.log(ele[0].style.display);

      if (ele[0].style.display == "none") {
         dropdown[0].style.display = "block"
         setSettingSubmenuWFMReport(true)
      } else {
         dropdown[0].style.display = "none"
         setSettingSubmenuWFMReport(false)
      }
   }


   const WFMapproval = async () => {

      var dropdown = document.getElementsByClassName("dropdown-containerWFMapproval");
      let ele = [...dropdown]

      if (ele[0].style.display == "none") {
         dropdown[0].style.display = "block"
         setSettingSubmenuWFMapproval(true)
      } else {
         dropdown[0].style.display = "none"
         setSettingSubmenuWFMapproval(false)
      }
   }

   const RolesandPermissions = async () => {
      var dropdown = document.getElementsByClassName("dropdown-containerPermission");
      let ele = [...dropdown]
      if (ele[0].style.display == "none") {
         dropdown[0].style.display = "block"
         setRoles(true)
      } else {
         dropdown[0].style.display = "none"
         setRoles(false)
      }
   }
   // console.log(employer)

   const getProfile = async (token) => {
      // const response = await GetProfile(token);
      const response = await getApi("/get-profile");
      // console.log("get employees data response", response);
      if (response.status == true) {
         setprofileData(response.data);
         setEmployerName(response.data.company_name)
         setEmployermobile(response.data.mobile_number)
      } else {
         console.log("get employees data response", response);

      }
   }
   useEffect(() => {
      getProfile(employer.employer_token)
   }, [])

   let role
   const GetPermissions = async (token) => {
      try {
         const response = await GetPermission(token);
         // console.log(response)
         if (response.status) {
            // console.log(response.is_nfc_active, "gfdgdfgdfgdflkgjdf")
            setnfc_status(response.is_nfc_active)
            role = JSON.parse(response.data.permissions);
            setPermission_data(role);

            return role
         }
      } catch (error) {
         console.error("🚀 ~ file: GetPermissions: 110 ~ error:", error)
      }
   }

   useEffect(() => {
      if (employer.user_type == "employer") {
         // console.log(employer.user_type == "employer")
         GetPermissions(employer.employer_token)
      }
   }, [])



   const handlePermissionAPI = async () => {
      if (employer.user_type == "SUB-EMPLOYER") {
         let option
         await GetPermissions(employer.employer_token).then((data) => {
            option = data;
         })

         setemployee_view(false)
         setewa_attendance_view(false)
         setnfc_view(false)
         setrepayment_view_bnpl_list(false)
         setrepayment_view_ewa_list(false)
         setsettlement_bnpl_transaction(false)
         setsettlement_ewa_transaction(false)
         if (permission_data) {
            if (option.length > 0) {
               option.forEach((option) => {

                  if (
                     option.key === "employee_view" ||
                     option.key === "employee_upload_bulk" ||
                     option.key === "employee_add" ||
                     option.key === "employee_edit"
                  ) {
                     setemployee_view(true);
                  }
                  if (
                     option.key === "ewa_attendance_view" ||
                     option.key === "ewa_mark_attendance" ||
                     option.key === "ewa_in_out_time" ||
                     option.key === "ewa_bulk_upload_attendance" ||
                     option.key === "ewa_view_attendance"
                  ) {
                     setewa_attendance_view(true);
                  }
                  if (option.key === "nfc_view" || option.key === "nfc_active_inactive") {
                     setnfc_view(true);
                  }
                  if (
                     option.key === "repayment_view_bnpl_list" ||
                     option.key === "repayment_pay_to_lender_bnpl" ||
                     option.key === "repayment_list_export"
                  ) {
                     setrepayment_view_bnpl_list(true);
                  }
                  if (
                     option.key === "repayment_view_ewa_list" ||
                     option.key === "repayment_pay_to_lender_ewa" ||
                     option.key === "repayment_list_export"
                  ) {
                     setrepayment_view_ewa_list(true);
                  }
                  if (
                     option.key === "settlement_bnpl_transaction" ||
                     option.key === "settlement_transaction_export"
                  ) {
                     setsettlement_bnpl_transaction(true);
                  }
                  if (
                     option.key === "settlement_ewa_transaction" ||
                     option.key === "settlement_transaction_export"
                  ) {
                     setsettlement_ewa_transaction(true);
                  }
               });
            }

         }
      }
   }

   useEffect(() => {
      handlePermissionAPI()
      GeValidTillDate()
      // GetPermissions(employer.employer_token)
   }, []);


   const GeValidTillDate = async () => {
      try {
         const response = await getApi('/get-valid-till')
         if (response.status) {
            // console.log(response)
            const localStorageEmployer = { ...employer, plan_expire_on: response.valid_till }
            localStorage.setItem("employer", JSON.stringify(localStorageEmployer))

            if (response.valid_till < moment().format("YYYY-MM-DD")) {
               navigate('/employer/dashboard')
            }

         } else {
            console.log(response)
         }
      } catch (error) {
         console.log(error)
      }
   }



   const handleDashboardClick = (url) => {
      GeValidTillDate()
      // console.log(url, "url")
      if (publishShiftData > 0) {
         // const confirmation = window.confirm("You have unsaved data. Are you sure you want to leave?");
         Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to restore this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, vanish it!"
         }).then((result) => {
            if (result.isConfirmed) {
               // Swal.fire({
               //    title: "Vanished!",
               //    text: "Your Draft data has been deleted.",
               //    icon: "success"
               // });
               dispatch(setPublishShiftData([]));
               navigate(prefix + url)
            }
         });
      } else {
         dispatch(setPublishShiftData([]));
      }
   };


   const futureDateFromApi = employer?.plan_expire_on;

   const today = moment().format('YYYY-MM-DD')
   const futureDate = today ? moment(futureDateFromApi) : null
   var remainingDays = futureDate ? futureDate.diff(today, 'days') : null

   return (
      <>
         <div className={header ? "app-header header sticky app_header_res" : "app-header header sticky"}>
            <div className="container-fluid main-container">
               <div className="d-flex">
                  <div className="app-sidebar__toggle d-flex align-items-center" data-bs-toggle="sidebar">
                     <Link tabIndex={-1} className="open-toggle" to="" onClick={() => ToggleHeader()}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="feather feather-align-left header-icon" width="24" height="24" viewBox="0 0 24 24">
                           <path d="M4 6h16v2H4zm0 5h16v2H4zm0 5h16v2H4z"></path>
                        </svg>
                     </Link>
                  </div>
                  <Link tabIndex={-1} className="logo-horizontal " to={prefix}>
                     <img src={process.env.PUBLIC_URL + "/assets/img/logo-thirdroc.png"} className="header-brand-img desktop-lgo" alt="logo" />
                     <img src={process.env.PUBLIC_URL + "/assets/img/logo-thirdroc.png"} className="header-brand-img dark-logo" alt="logo" />
                  </Link>


                  <div className="d-flex order-lg-2 ms-auto main-header-end">
                     <div className="navbar navbar-expand-lg navbar-collapse responsive-navbar p-0">
                        <div className="collapse navbar-collapse" id="navbarSupportedContent-4">
                           <div className='text-primary' style={{ marginTop: 'auto' }}>
                              <div><span >{employer.company_name ? employer.company_name : employer.email}</span>
                                 <span >{employer?.mobile_number ? " - " + employer?.mobile_number : ""}</span></div>
                              <div style={{ fontSize: '0.8rem' }}>
                                 {remainingDays < 0 ?
                                    <span className='badge rounded-pill bg-danger'>Plan Expired</span>
                                    : remainingDays <= 7 && remainingDays >= 4

                                       ? <span className='badge rounded-pill bg-warning'>Plan Expiring {remainingDays} Days Left</span>
                                       : remainingDays <= 3 && remainingDays >= 0

                                          ? <span className='badge rounded-pill bg-danger'>Plan Expiring {remainingDays == 0 ? 'today' : remainingDays + ' Days Left'}</span>
                                          : remainingDays > 7 &&

                                          <span className='badge rounded-pill bg-success '>Valid Till : {futureDate?.format('YYYY-MM-DD')}</span>
                                 }
                              </div>
                           </div>
                           <div className="d-flex order-lg-2">
                              {/* <div className="dropdown header-notify d-flex">
                              <Link className="nav-link icon" data-bs-toggle="dropdown"> <svg xmlns="http://www.w3.org/2000/svg" className="header-icon" width="24" height="24" viewBox="0 0 24 24"> <path d="M19 13.586V10c0-3.217-2.185-5.927-5.145-6.742C13.562 2.52 12.846 2 12 2s-1.562.52-1.855 1.258C7.185 4.074 5 6.783 5 10v3.586l-1.707 1.707A.996.996 0 0 0 3 16v2a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1v-2a.996.996 0 0 0-.293-.707L19 13.586zM19 17H5v-.586l1.707-1.707A.996.996 0 0 0 7 14v-4c0-2.757 2.243-5 5-5s5 2.243 5 5v4c0 .266.105.52.293.707L19 16.414V17zm-7 5a2.98 2.98 0 0 0 2.818-2H9.182A2.98 2.98 0 0 0 12 22z"> </path> </svg>
                                 <span className="badge bg-orange side-badge">5</span> </Link>
                           </div> */}
                              <div className="dropdown profile-dropdown d-flex">
                                 <Link tabIndex={-1} to={prefix} className="nav-link pe-0 leading-none" data-bs-toggle="dropdown">
                                    <span className="header-avatar1">
                                       {sessionStorage.getItem("employerlogo") ? <img className="avatar avatar-md brround" src={sessionStorage.getItem("employerlogo")} /> : profileData.logo ?
                                          <img className="avatar avatar-md brround" src={profileData.logo} alt=".hhf" />
                                          :
                                          <img src={process.env.PUBLIC_URL + '/assets/img/images.png'} alt="img" className="avatar avatar-md brround" />


                                       }

                                    </span> </Link>
                                 <div className="dropdown-menu dropdown-menu-end dropdown-menu-arrow animated">
                                    {/* <div className="text-left"><div className="text-left user font-weight-bold ps-sm-4">SMT Lab </div> 
				        <div className="dropdown-divider"></div> 
				      </div>  */}
                                    {/* <Link className="dropdown-item d-flex" to={prefix}>
                                    <svg className="header-icon me-2" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"> <path d="M0 0h24v24H0V0z" fill="none"></path> <path d="M19.43 12.98c.04-.32.07-.64.07-.98 0-.34-.03-.66-.07-.98l2.11-1.65c.19-.15.24-.42.12-.64l-2-3.46c-.09-.16-.26-.25-.44-.25-.06 0-.12.01-.17.03l-2.49 1c-.52-.4-1.08-.73-1.69-.98l-.38-2.65C14.46 2.18 14.25 2 14 2h-4c-.25 0-.46.18-.49.42l-.38 2.65c-.61.25-1.17.59-1.69.98l-2.49-1c-.06-.02-.12-.03-.18-.03-.17 0-.34.09-.43.25l-2 3.46c-.13.22-.07.49.12.64l2.11 1.65c-.04.32-.07.65-.07.98 0 .33.03.66.07.98l-2.11 1.65c-.19.15-.24.42-.12.64l2 3.46c.09.16.26.25.44.25.06 0 .12-.01.17-.03l2.49-1c.52.4 1.08.73 1.69.98l.38 2.65c.03.24.24.42.49.42h4c.25 0 .46-.18.49-.42l.38-2.65c.61-.25 1.17-.59 1.69-.98l2.49 1c.06.02.12.03.18.03.17 0 .34-.09.43-.25l2-3.46c.12-.22.07-.49-.12-.64l-2.11-1.65zm-1.98-1.71c.04.31.05.52.05.73 0 .21-.02.43-.05.73l-.14 1.13.89.7 1.08.84-.7 1.21-1.27-.51-1.04-.42-.9.68c-.43.32-.84.56-1.25.73l-1.06.43-.16 1.13-.2 1.35h-1.4l-.19-1.35-.16-1.13-1.06-.43c-.43-.18-.83-.41-1.23-.71l-.91-.7-1.06.43-1.27.51-.7-1.21 1.08-.84.89-.7-.14-1.13c-.03-.31-.05-.54-.05-.74s.02-.43.05-.73l.14-1.13-.89-.7-1.08-.84.7-1.21 1.27.51 1.04.42.9-.68c.43-.32.84-.56 1.25-.73l1.06-.43.16-1.13.2-1.35h1.39l.19 1.35.16 1.13 1.06.43c.43.18.83.41 1.23.71l.91.7 1.06-.43 1.27-.51.7 1.21-1.07.85-.89.7.14 1.13zM12 8c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 6c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z"> </path> </svg>
                                    <div className="fs-13">Setting</div>
                                 </Link> */}
                                    <Link tabIndex={-1}
                                       className="dropdown-item d-flex" to={prefix} onClick={() => logOut()}>
                                       <svg className="header-icon me-2" xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="24" viewBox="0 0 24 24" width="24"> <g> <rect fill="none" height="24" width="24"></rect> </g> <g> <path d="M11,7L9.6,8.4l2.6,2.6H2v2h10.2l-2.6,2.6L11,17l5-5L11,7z M20,19h-8v2h8c1.1,0,2-0.9,2-2V5c0-1.1-0.9-2-2-2h-8v2h8V19z"> </path> </g> </svg> <div className="fs-13">Sign Out</div>
                                    </Link> </div>
                              </div>
                           </div>
                        </div>
                     </div>

                  </div>
               </div>
            </div>
         </div>

         <div className="sticky is-expanded">
            <div className="app-sidebar__overlay active" data-bs-toggle="sidebar"></div>
            <aside className={header ? "app-sidebar open ps ps--active-y res_aside" : "app-sidebar open ps ps--active-y"}>
               <div className={header ? "app-sidebar__logo res_aside" : "app-sidebar__logo"}>
                  <Link tabIndex={-1} className="header-brand" /* to={prefix} */>
                     <img src={process.env.PUBLIC_URL + "/assets/img/logo-thirdroc.png"} className="header-brand-img desktop-lgo" alt="logo" />
                     <img src={process.env.PUBLIC_URL + "/assets/img/logo-thirdroc.png"} className="header-brand-img mobile-logo" alt="Azea logo" />
                  </Link>
               </div>
               <div className="main-sidemenu is-expanded">
                  <div className="slide-left disabled active is-expanded" id="slide-left">
                     <svg xmlns="http://www.w3.org/2000/svg" fill="#7b8191" width="24" height="24" viewBox="0 0 24 24">
                        <path d="M13.293 6.293 7.586 12l5.707 5.707 1.414-1.414L10.414 12l4.293-4.293z"></path>
                     </svg>
                  </div>
                  <ul className="side-menu app-sidebar3 open" style={{ marginRight: "0px" }}>
                     <li onClick={() => { localStorage.setItem("search_result", ""); handleDashboardClick('/dashboard') }} className="slide is-expanded">
                        <span onClick={() => handleDashboardClick('/dashboard')}>
                           <Link tabIndex={-1} style={{ "color": pathname == '/employer/dashboard' ? "#0f2f82" : " " }} className={props.locationName == prefix + '/dashboard' ? "side-menu__item active" : "side-menu__item"} to={publishShiftData == 0 && prefix + '/dashboard'}>
                              <svg xmlns="http://www.w3.org/2000/svg" className={header ? "side-menu__icon res_icon" : "side-menu__icon"} width="24" height="24" viewBox="0 0 24 24">
                                 <path d="M3 13h1v7c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2v-7h1a1 1 0 0 0 .707-1.707l-9-9a.999.999 0 0 0-1.414 0l-9 9A1 1 0 0 0 3 13zm7 7v-5h4v5h-4zm2-15.586 6 6V15l.001 5H16v-5c0-1.103-.897-2-2-2h-4c-1.103 0-2 .897-2 2v5H6v-9.586l6-6z"></path>
                              </svg>
                              <span onClick={() => handlePermissionAPI} className={header ? "side-menu__label d-none mt-1" : "side-menu__label mt-1"}>Dashboard</span>
                           </Link></span>
                     </li>
                     {/* {employee_view &&  <li onClick={()=>{localStorage.setItem("search_result","")}} className="slide is-expanded">
                        <Link style={{ "color":pathname == '/employer/employees' ? "#0f2f82":" "}} className={props.locationName == prefix + '/employees' ? "side-menu__item active" : "side-menu__item"} to={prefix + '/employees'}>
                           <i className={header ? "fa-regular fas fa-users fa-fw side-menu__icon fs-18 res_icon" : "fa-regular fas fa-users fa-fw side-menu__icon fs-18"}></i>
                           <span   className={header ? "side-menu__label d-none mt-1" : "side-menu__label mt-1"}>Employee</span>
                        </Link>
                     </li>}
                     {user_type == 'employer' &&  <li onClick={()=>{localStorage.setItem("search_result","")}} className="slide is-expanded">
                        <Link style={{ "color":pathname == '/employer/employees' ? "#0f2f82":" "}} className={props.locationName == prefix + '/employees' ? "side-menu__item active" : "side-menu__item"} to={prefix + '/employees'}>
                           <i className={header ? "fa-regular fas fa-users fa-fw side-menu__icon fs-18 res_icon" : "fa-regular fas fa-users fa-fw side-menu__icon fs-18"}></i>
                           <span   className={header ? "side-menu__label d-none mt-1" : "side-menu__label mt-1"}>Employee</span>
                        </Link>
                     </li>} */}
                     {/* {(employee_view || user_type === 'employer') && (
                        <li onClick={() => { localStorage.setItem("search_result", "") }} className="slide is-expanded">
                           <Link style={{ "color": pathname === '/employer/employees' ? "#0f2f82" : " " }} className={props.locationName === prefix + '/employees' ? "side-menu__item active" : "side-menu__item"} to={prefix + '/employees'}>
                              <i className={header ? "fa-regular fas fa-users fa-fw side-menu__icon fs-18 res_icon" : "fa-regular fas fa-users fa-fw side-menu__icon fs-18"}></i>
                              <span onClick={handlePermissionAPI} className={header ? "side-menu__label d-none mt-1" : "side-menu__label mt-1"}>Employee</span>
                           </Link>
                        </li>
                     )} */}
                     {(employee_view || user_type === 'employer' && EmployerSystemType === "WFM") && (<li onClick={() => { localStorage.setItem("search_result", "") }} className="slide is-expanded">
                        <i className={header ? "fa-regular fa-handshake fa-fw side-menu__icon fs-18 res_icon" : "fa-regular fa-handshake fa-fw side-menu__icon fs-18"}></i>
                        <span className={header ? "side-menu__label d-none " : "side-menu__label"} onClick={() => { RolesandPermissions() }}
                           style={{ marginLeft: 2, fontWeight: 600, color: '#555b6d', fontSize: 13, cursor: 'pointer' }}>WFM
                           <i className={roles ? "fa fa-caret-down" : "fa fa-caret-right"} style={{ marginTop: 2, marginLeft: 8 }}></i></span>
                        <div className="dropdown-containerPermission mb-10New" style={{ display: "none", marginLeft: 40, paddingLeft: 8 }}>

                           {/* New Changes start */}
                           {(settlement_bnpl_transaction || settlement_ewa_transaction || user_type == 'employer') && (<li onClick={() => { localStorage.setItem("search_result", "") }} className="slide is-expanded">

                              <span className={header ? "side-menu__label d-none " : "side-menu__label"} onClick={() => { WFM() }}
                                 style={{ marginLeft: 10, fontWeight: 600, color: '#555b6d', fontSize: 13, cursor: 'pointer' }}>Manage
                                 <i className={settinngSubmenuWFM ? "fa fa-caret-down" : "fa fa-caret-right"} style={{ marginTop: 2, marginLeft: 8 }}></i></span>
                              <div className="dropdown-containerWFM mb-10New" style={{ display: "none", marginLeft: 10, paddingLeft: 8 }}>
                                 {(settlement_bnpl_transaction || user_type == 'employer') && (
                                    <>
                                       <span onClick={() => handleDashboardClick('/employee_shift')}>
                                          <Link tabIndex={-1} style={{ "color": pathname == '/employer/employee_shift' ? "#0f2f82" : " " }} className={props.locationName == props.prefix + '/employee_shift' ? "side-menu__item active" : "side-menu__item"}

                                             to={publishShiftData == 0 && props.prefix + '/employee_shift'} >
                                             <span className='side-menu__label' style={{ display: "block", cursor: 'pointer' }}> Shifts</span></Link>
                                       </span>
                                       <span onClick={() => handleDashboardClick('/department')}>
                                          <Link tabIndex={-1} style={{ "color": pathname == '/employer/department' ? "#0f2f82" : " " }} className={props.locationName == prefix + '/department' ? "side-menu__item active" : "side-menu__item"}
                                             to={publishShiftData == 0 && prefix + '/department'}
                                          >
                                             <span className={header ? "side-menu__label d-none " : "side-menu__label"}> Department</span>
                                          </Link>
                                       </span>
                                       <span onClick={() => handleDashboardClick('/jobtype&location')}>
                                          <Link tabIndex={-1} style={{ "color": pathname == '/employer/jobtype&location' ? "#0f2f82" : " " }} className={props.locationName == prefix + '/jobtype&location' ? "side-menu__item active" : "side-menu__item"}
                                             to={publishShiftData == 0 && prefix + '/jobtype&location'}>
                                             <span className={header ? "side-menu__label d-none " : "side-menu__label"}>Job & Location</span>
                                          </Link></span>
                                       <span onClick={() => handleDashboardClick('/manage_Leave')}>
                                          <Link tabIndex={-1} style={{ "color": pathname == '/employer/manage_Leave' ? "#0f2f82" : " " }} className={props.locationName == prefix + '/manage_Leave' ? "side-menu__item active" : "side-menu__item"}
                                             to={publishShiftData == 0 && prefix + '/manage_Leave'}>
                                             <span className={header ? "side-menu__label d-none " : "side-menu__label"}>  Leaves</span>
                                          </Link></span>
                                       <span tabIndex={-1} onClick={() => handleDashboardClick('/employees')}>
                                          <Link tabIndex={-1} style={{ "color": pathname == '/employer/employees' ? "#0f2f82" : " " }} className={props.locationName == props.prefix + '/employees' ? "side-menu__item active" : "side-menu__item"}
                                             to={publishShiftData == 0 && props.prefix + '/employees'}    >
                                             <span className='side-menu__label' style={{ display: "block", cursor: 'pointer' }}>Employees</span></Link>
                                       </span>
                                       <span onClick={() => handleDashboardClick('/compliance')}>
                                          <Link tabIndex={-1} style={{ "color": pathname == '/employer/compliance' ? "#0f2f82" : " " }} className={props.locationName == props.prefix + '/compliance' ? "side-menu__item active" : "side-menu__item"}
                                             to={publishShiftData == 0 && props.prefix + '/compliance'}    >
                                             <span className='side-menu__label' style={{ display: "block", cursor: 'pointer' }}>Compliance</span></Link>
                                       </span>
                                    </>)}
                              </div>
                           </li>)}



                           <span onClick={() => handleDashboardClick('/scheduler')}>
                              <Link tabIndex={-1} style={{ marginLeft: 10, "color": pathname == '/employer/scheduler' ? "#0f2f82" : " " }} className={props.locationName == prefix + '/scheduler' ? "side-menu__item active" : "side-menu__item"}
                                 to={publishShiftData == 0 && prefix + '/scheduler'}
                              >
                                 <span className={header ? "side-menu__label d-none " : "side-menu__label"}>Schedule</span>
                              </Link></span>

                           {/* new changes End */}

                           <li onClick={() => { localStorage.setItem("search_result", "") }} className="slide is-expanded">

                              <span className={header ? "side-menu__label d-none " : "side-menu__label"} onClick={() => { WFMReport() }}
                                 style={{ marginLeft: 10, fontWeight: 600, color: '#555b6d', fontSize: 13, cursor: 'pointer' }}>Reports
                                 <i className={settingSubmenuWFMReport ? "fa fa-caret-down" : "fa fa-caret-right"} style={{ marginTop: 2, marginLeft: 8 }}></i></span>

                              <div className="dropdown-containerWFMReport mb-10New" style={{ display: "none", marginLeft: 40, paddingLeft: 8 }}>
                                 <span onClick={() => handleDashboardClick('/roster_efficiency_report')}>
                                    <Link tabIndex={-1} style={{ marginLeft: "-30px", "color": pathname == '/employer/roster_efficiency_report' ? "#0f2f82" : " " }} className={props.locationName == prefix + '/roster_efficiency_report' ? "side-menu__item active" : "side-menu__item"}
                                       to={publishShiftData == 0 && prefix + '/roster_efficiency_report'}>
                                       <span className={header ? "side-menu__label d-none " : "side-menu__label"}>Roster Efficiency</span>
                                    </Link></span>
                                 <span onClick={() => handleDashboardClick('/attendance')}>
                                    <Link tabIndex={-1} style={{ marginLeft: "-30px", "color": pathname == '/employer/attendance' ? "#0f2f82" : " " }} className={props.locationName == prefix + '/attendance' ? "side-menu__item active" : "side-menu__item"}
                                       to={publishShiftData == 0 && prefix + '/attendance'}>
                                       <span className={header ? "side-menu__label d-none " : "side-menu__label"}>Attendance</span>
                                    </Link></span>
                                 {/* <span onClick={() => handleDashboardClick('/violence')}>
                                    <Link style={{ marginLeft: "-30px", "color": pathname == '/employer/violence' ? "#0f2f82" : " " }} className={props.locationName == prefix + '/violence' ? "side-menu__item active" : "side-menu__item"}
                                       to={publishShiftData == 0 && prefix + '/violence'}>
                                       <span className={header ? "side-menu__label d-none " : "side-menu__label"}>Violence</span>
                                    </Link></span> */}

                              </div>

                           </li>

                           <li onClick={() => { localStorage.setItem("search_result", "") }} className="slide is-expanded">

                              <span className={header ? "side-menu__label d-none " : "side-menu__label"} onClick={() => { WFMapproval() }}
                                 style={{ marginLeft: 10, fontWeight: 600, color: '#555b6d', fontSize: 13, cursor: 'pointer' }}>Approval
                                 <i className={settingSubmenuWFMapproval ? "fa fa-caret-down" : "fa fa-caret-right"} style={{ marginTop: 2, marginLeft: 8 }}></i></span>

                              <div className="dropdown-containerWFMapproval mb-10New" style={{ display: "none", marginLeft: 40, paddingLeft: 8 }}>
                                 <span onClick={() => handleDashboardClick('/leaves_request')}>
                                    <Link tabIndex={-1} style={{ marginLeft: "-30px", "color": pathname == '/employer/leaves_request' ? "#0f2f82" : " " }} className={props.locationName == prefix + '/leaves_request' ? "side-menu__item active" : "side-menu__item"}
                                       to={publishShiftData == 0 && prefix + '/leaves_request'}>
                                       <span className={header ? "side-menu__label d-none " : "side-menu__label"}>Leaves Request</span>
                                    </Link>
                                    <Link tabIndex={-1} style={{ marginLeft: "-30px", "color": pathname == '/employer/timesheet' ? "#0f2f82" : " " }} className={props.locationName == prefix + '/timesheet' ? "side-menu__item active" : "side-menu__item"}
                                       to={publishShiftData == 0 && prefix + '/timesheet'}>
                                       <span className={header ? "side-menu__label d-none " : "side-menu__label"}>Timesheet</span>
                                    </Link>
                                 </span>
                              </div>
                           </li>



                        </div>
                     </li>)}
                     {(ewa_attendance_view || user_type === 'employer' && EmployerSystemType === "PAYROLL") && (<li className="slide is-expanded">
                        <span onClick={() => handleDashboardClick('/payroll')}>
                           <Link tabIndex={-1} style={{ "color": pathname == '/employer/payroll' ? "#0f2f82" : " " }} className={props.locationName == prefix + '/payroll' ? "side-menu__item active" : "side-menu__item"} to={publishShiftData == 0 && prefix + '/payroll'}>
                              <i className={header ? "fa-regular fas fa-users fa-fw side-menu__icon fs-18 res_icon" : "fa-regular fas fa-users fa-fw side-menu__icon fs-18"}></i>
                              <span onClick={handlePermissionAPI} className={header ? "side-menu__label d-none mt-1" : "side-menu__label mt-1"}>Payroll</span>
                           </Link></span>
                     </li>)}
                     {(ewa_attendance_view || user_type === 'employer') && (<li className="slide is-expanded">
                        <span onClick={() => handleDashboardClick('/employees_attendance')}>
                           <Link tabIndex={-1} style={{ "color": pathname == '/employer/employees_attendance' ? "#0f2f82" : " " }} className={props.locationName == prefix + '/employees_attendance' ? "side-menu__item active" : "side-menu__item"} to={publishShiftData == 0 && prefix + '/employees_attendance'}>
                              <i className={header ? "fa-regular fas fa-users fa-fw side-menu__icon fs-18 res_icon" : "fa-regular fas fa-users fa-fw side-menu__icon fs-18"}></i>
                              <span onClick={handlePermissionAPI} className={header ? "side-menu__label d-none mt-1" : "side-menu__label mt-1"}>EWA Attendance</span>
                           </Link></span>
                     </li>)}
                     {/* <li className="slide is-expanded">
                        <span onClick={() => handleDashboardClick('/manage_jobs')}>
                           <Link style={{ "color": pathname == '/employer/manage_jobs' ? "#0f2f82" : " " }} className={props.locationName == prefix + '/manage_jobs' ? "side-menu__item active" : "side-menu__item"} to={publishShiftData == 0 && prefix + '/manage_jobs'}>
                              <i className={header ? "fa-regular fas fa-users fa-fw side-menu__icon fs-18 res_icon" : "fa-regular fas fa-users fa-fw side-menu__icon fs-18"}></i>
                              <span onClick={handlePermissionAPI} className={header ? "side-menu__label d-none mt-1" : "side-menu__label mt-1"}>Manage Jobs</span>
                           </Link>
                           </span>
                     </li> */}

                     {(nfc_status == "1") && (<span>
                        {(nfc_view || user_type === 'employer') &&
                           (<li onClick={() => { localStorage.setItem("search_result", "") }} className="slide is-expanded">
                              <i className={header ? "fas fa-folder-plus fa-fw side-menu__icon fs-18 res_icon" : "fas fa-folder-plus fa-fw side-menu__icon fs-18"}></i>
                              <span className={header ? "side-menu__label d-none " : "side-menu__label"} onClick={() => { ViewNFC() }}
                                 style={{ marginLeft: 3, fontWeight: 600, color: '#555b6d', fontSize: 13, cursor: 'pointer' }}>NFC
                                 <i className={NFCSubmenu ? "fa fa-caret-down" : "fa fa-caret-right"} style={{ marginTop: 2, marginLeft: 8 }}></i></span>
                              <div className="dropdown-containerNFC mb-10New" style={{ display: "none", marginLeft: 40, paddingLeft: 8 }}>
                                 {/* <Link style={{ "color":pathname == '/employer/add_nfc' ? "#0f2f82":" "}} className={props.locationName == prefix + '/add_nfc' ? "side-menu__item active" : "side-menu__item"} to={prefix + '/add_nfc'}>
                              <span className={header ? "side-menu__label d-none mt-1" : "side-menu__label mt-1"}> Add NFC</span>
                           </Link> view_department*/}

                                 <span onClick={() => handleDashboardClick('/view_nfc')}>
                                    <Link tabIndex={-1} style={{ "color": pathname == '/employer/view_nfc' ? "#0f2f82" : " " }} className={props.locationName == prefix + '/view_nfc' ? "side-menu__item active" : "side-menu__item"}
                                       to={publishShiftData == 0 && prefix + '/view_nfc'}
                                    >
                                       <span onClick={handlePermissionAPI} className={header ? "side-menu__label d-none mt-1" : "side-menu__label mt-1"}> View NFC Tags</span>
                                    </Link>
                                 </span>
                                 <span onClick={() => handleDashboardClick('/add_nfc_employees')}>
                                    <Link tabIndex={-1} style={{ "color": pathname == '/employer/add_nfc_employees' ? "#0f2f82" : " " }} className={props.locationName == props.prefix + '/add_nfc_employees' ? "side-menu__item active" : "side-menu__item"}
                                       to={publishShiftData == 0 && props.prefix + '/add_nfc_employees'}      >
                                       <span onClick={handlePermissionAPI} className='side-menu__label' style={{ display: "block", cursor: 'pointer' }}>View Employees</span></Link>
                                 </span>
                              </div>
                           </li>)}
                     </span>)}


                     {/* <li className="slide is-expanded">
                        <Link  style={{ "color":pathname == '/employer/repayments' ? "#0f2f82":" "}}  className={props.locationName == prefix + '/repayments' ? "side-menu__item active" : "side-menu__item"} to={prefix + '/repayments'}>
                           <i className={header ? "fas fa-file-alt fa-fw side-menu__icon fs-18 res_icon" : "fas fa-file-alt fa-fw side-menu__icon fs-18"}></i>
                           <span className={header ? "side-menu__label" : "side-menu__label"}>Repayments</span>
                        </Link>
                     </li>  */}

                     {/* <li className="slide is-expanded">
                        <Link  style={{ "color":pathname == '/employer/PaytoThirdroc' ? "#0f2f82":" "}}  className={props.locationName == prefix + '/PaytoThirdroc' ? "side-menu__item active" : "side-menu__item"} to={prefix + '/PaytoThirdroc'}>
                           <i className={header ? "fas fa-file-alt fa-fw side-menu__icon fs-18 res_icon" : "fas fa-file-alt fa-fw side-menu__icon fs-18"}></i>
                           <span className={header ? "side-menu__label" : "side-menu__label"}>Pay to Thirdroc</span>
                        </Link>
                     </li> */}
                     {(repayment_view_bnpl_list || repayment_view_ewa_list || user_type == 'employer') && (<li onClick={() => { localStorage.setItem("search_result", "") }} className="slide is-expanded">
                        <i className={header ? "fas fa-file-alt fa-fw side-menu__icon fs-18 res_icon" : "fas fa-file-alt fa-fw side-menu__icon fs-18"}></i>
                        <span className={header ? "side-menu__label d-none " : "side-menu__label"} onClick={() => { showSubMenu() }}
                           style={{ marginLeft: 3, fontWeight: 600, color: '#555b6d', fontSize: 13, cursor: 'pointer' }}>Repayments
                           <i className={settinngSubmenu ? "fa fa-caret-down" : "fa fa-caret-right"} style={{ marginTop: 2, marginLeft: 8 }}></i></span>
                        <div className="dropdown-container mb-10New" style={{ display: "none", marginLeft: 40, paddingLeft: 8 }}>
                           {(repayment_view_bnpl_list || user_type == 'employer') &&
                              (

                                 <span tabIndex={-1} onClick={() => handleDashboardClick('/bnpl_repayment')}>
                                    <Link tabIndex={-1} style={{ "color": pathname == '/employer/bnpl_repayment' ? "#0f2f82" : " " }} className={props.locationName == prefix + '/bnpl_repayment' ? "side-menu__item active" : "side-menu__item"}
                                       to={publishShiftData == 0 && prefix + '/bnpl_repayment'}
                                    >
                                       <span onClick={handlePermissionAPI} className={header ? "side-menu__label d-none mt-1" : "side-menu__label mt-1"}> BNPL Pending</span>
                                    </Link></span>)}
                           {(repayment_view_ewa_list || user_type == 'employer') && (
                              <span onClick={() => handleDashboardClick('/paytothirdroc')}>
                                 <Link tabIndex={-1} style={{ "color": pathname == '/employer/paytothirdroc' ? "#0f2f82" : " " }} className={props.locationName == props.prefix + '/paytothirdroc' ? "side-menu__item active" : "side-menu__item"}
                                    to={publishShiftData == 0 && props.prefix + '/paytothirdroc'}   ><span onClick={handlePermissionAPI} className='side-menu__label' >EWA Pending</span></Link>
                              </span>)}
                        </div>
                     </li>)}
                     {/* <li className="slide is-expanded">
                        <Link  style={{ "color":pathname == '/employer/settlement' ? "#0f2f82":" "}}  className={props.locationName == prefix + '/settlement' ? "side-menu__item active" : "side-menu__item"} to={prefix + '/settlement'}>
                           <i className={header ? "fa-regular fa-handshake fa-fw side-menu__icon fs-18 res_icon" : "fa-regular fa-handshake fa-fw side-menu__icon fs-18"}></i>
                           <span className={header ? "side-menu__label" : "side-menu__label settlement"}>Settlement</span>
                        </Link>
                     </li> */}
                     {(settlement_bnpl_transaction || settlement_ewa_transaction || user_type == 'employer') && (
                        <li onClick={() => { localStorage.setItem("search_result", "") }} className="slide is-expanded">
                           <i className={header ? "fa-regular fa-handshake fa-fw side-menu__icon fs-18 res_icon" : "fa-regular fa-handshake fa-fw side-menu__icon fs-18"}></i>
                           <span className={header ? "side-menu__label d-none " : "side-menu__label"} onClick={() => { paytothirdroc() }}
                              style={{ marginLeft: 3, fontWeight: 600, color: '#555b6d', fontSize: 13, cursor: 'pointer' }}>Settlement
                              <i className={settinngSubmenuthirdroc ? "fa fa-caret-down" : "fa fa-caret-right"} style={{ marginTop: 2, marginLeft: 8 }}></i></span>
                           <div className="dropdown-containerthird mb-10New" style={{ display: "none", marginLeft: 40, paddingLeft: 8 }}>
                              {(settlement_bnpl_transaction || user_type == 'employer') && (
                                 <span onClick={() => handleDashboardClick('/settlement')}>
                                    <Link onFocus={(e) => e.preventDefault()} style={{ "color": pathname == '/employer/settlement' ? "#0f2f82" : " " }} className={props.locationName == prefix + '/settlement' ? "side-menu__item active" : "side-menu__item"}
                                       to={publishShiftData == 0 && prefix + '/settlement'}
                                    >
                                       <span onClick={handlePermissionAPI} className={header ? "side-menu__label d-none mt-1" : "side-menu__label mt-1"}> BNPL Transaction</span>
                                    </Link></span>)}
                              {(settlement_ewa_transaction || user_type == 'employer') && (
                                 <span onClick={() => handleDashboardClick('/paidtransaction')}>
                                    <Link style={{ "color": pathname == '/employer/paidtransaction' ? "#0f2f82" : " " }} className={props.locationName == props.prefix + '/paidtransaction' ? "side-menu__item active" : "side-menu__item"}
                                       to={publishShiftData == 0 && props.prefix + '/paidtransaction'}     >
                                       <span onClick={handlePermissionAPI} className='side-menu__label' style={{ display: "block", cursor: 'pointer' }}>EWA Transaction</span></Link>
                                 </span>)}</div>
                        </li>
                     )}
                     {/* {user_type == "employer" && <li onClick={() => { localStorage.setItem("search_result", "") }} className="slide is-expanded">
                        <i className={header ? "fa-regular fa-handshake fa-fw side-menu__icon fs-18 res_icon" : "fa-regular fa-handshake fa-fw side-menu__icon fs-18"}></i>
                        <span className={header ? "side-menu__label d-none " : "side-menu__label"} onClick={() => { RolesandPermissions() }}
                           style={{ marginLeft: 3, fontWeight: 600, color: '#555b6d', fontSize: 13, cursor: 'pointer' }}>Role & Permission
                           <i className={roles ? "fa fa-caret-down" : "fa fa-caret-right"} style={{ marginTop: 2, marginLeft: 8 }}></i></span>
                        <div className="dropdown-containerPermission mb-10New" style={{ display: "none", marginLeft: 40, paddingLeft: 8 }}>
                           <Link style={{ "color": pathname == '/employer/managerole' ? "#0f2f82" : " " }} className={props.locationName == props.prefix + '/managerole' ? "side-menu__item active" : "side-menu__item"}
                              to={props.prefix + '/managerole'} >
                              <span className='side-menu__label' style={{ display: "block", cursor: 'pointer' }}>Manage Roles</span></Link>
                           <Link style={{ "color": pathname == '/employer/manageuser' ? "#0f2f82" : " " }} className={props.locationName == prefix + '/manageuser' ? "side-menu__item active" : "side-menu__item"} to={prefix + '/manageuser'}>
                              <span className={header ? "side-menu__label d-none mt-1" : "side-menu__label mt-1"}> Manage Users</span>
                           </Link>
                        </div>
                     </li>} */}
                     {user_type === 'employer' &&
                        <li className="slide is-expanded">
                           <span onClick={() => handleDashboardClick('/manage_role')}>
                              <Link tabIndex={-1} style={{ "color": pathname == '/employer/manage_role' ? "#0f2f82" : " " }} className={props.locationName == prefix + '/manage_role' ? "side-menu__item active" : "side-menu__item"}
                                 to={publishShiftData == 0 && prefix + '/manage_role'}>
                                 <i className={header ? "fa-solid fa-user-lock side-menu__icon fs-18 res_icon" : "fa-solid fa-user-lock side-menu__icon fs-18"}></i>
                                 <span className={header ? "side-menu__label" : "side-menu__label settlement"}>Manage Roles</span>
                              </Link></span>
                        </li>}

                     {/* <li className="slide is-expanded">
                        <Link style={{ "color":pathname == '/employer/addrole' ? "#0f2f82":" "}} className={props.locationName == prefix + '/addrole' ? "side-menu__item active" : "side-menu__item"} to={prefix + '/addrole'}>
                           <i className={header ? "fa-regular fa-address-card fa-fw side-menu__icon fs-18 res_icon" : "fa-regular fa-address-card fa-fw side-menu__icon fs-18"}></i>
                           <span className={header ? "side-menu__label d-none mt-1" : "side-menu__label mt-1"}>User Management</span>
                        </Link>
                     </li> */}
                     <li onClick={() => { localStorage.setItem("search_result", "") }} className="slide is-expanded">
                        <span onClick={() => handleDashboardClick('/profile')}>
                           <Link tabIndex={-1} style={{ "color": pathname == '/employer/profile' ? "#0f2f82" : " " }} className={props.locationName == prefix + '/profile' ? "side-menu__item active" : "side-menu__item"} to={publishShiftData == 0 && prefix + '/profile'}>
                              <i className={header ? "fa-regular fa-address-card fa-fw side-menu__icon fs-18 res_icon" : "fa-regular fa-address-card fa-fw side-menu__icon fs-18"}></i>
                              <span onClick={handlePermissionAPI} className={header ? "side-menu__label d-none mt-1" : "side-menu__label mt-1"}>Profile</span>
                           </Link></span>
                     </li>
                     <li className="slide is-expanded logout" >
                        <Link tabIndex={-1} className="dropdown-item d-flex" to={prefix} onClick={() => logOut()}>
                           <svg className=" me-2" xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="20" viewBox="0 0 24 24" width="20"> <g> <rect fill="none" height="20" width="20"></rect> </g> <g> <path d="M11,7L9.6,8.4l2.6,2.6H2v2h10.2l-2.6,2.6L11,17l5-5L11,7z M20,19h-8v2h8c1.1,0,2-0.9,2-2V5c0-1.1-0.9-2-2-2h-8v2h8V19z"> </path> </g> </svg> <div className="fs-13">Sign Out</div>
                        </Link>
                     </li>

                     {/* <li className="slide is-expanded">
                        <Link className={props.locationName == prefix + '/case' ? "side-menu__item active" : "side-menu__item"} to={prefix + '/case'}>
                           <i className="fa-regular fa-address-card fa-fw side-menu__icon fs-18"></i>
                           <span className={header?"side-menu__label d-none":"side-menu__label"}>Case</span>
                        </Link>
                     </li> */}

                     {/* =======================>>>>>>>>>> this is working logout ================>>>>>>>>>>>>>>*/}
                     <li className="slide is-expanded">
                        <Link tabIndex={-1} className={props.locationName == prefix + '/case' ? "side-menu__item active" : "side-menu__item"} to={prefix} onClick={() => logOut()}>
                           <i className={header ? "fa-regular fas fa-sign-out fa-fw side-menu__icon fs-18 res_icon" : "fa-regular fas fa-sign-out fa-fw side-menu__icon fs-18"}></i>
                           <span className={header ? "side-menu__label d-none" : "side-menu__label"}
                           // onClick={() => logOut()}
                           >Logout</span>
                        </Link>
                     </li>

                     {/* <li className="slide is-expanded">
                        <Link className={props.locationName == prefix + '/api' ? "side-menu__item active" : "side-menu__item"} to={prefix + '/api'}>
                           <i className="fa-solid fa-key fa-fw fa-fw side-menu__icon fs-18"></i>
                           <span className={header?"side-menu__label d-none":"side-menu__label"}>Api</span>
                        </Link>
                     </li> */}


                  </ul>
                  <div className="slide-right" id="slide-right">
                     <svg xmlns="http://www.w3.org/2000/svg" fill="#7b8191" width="24" height="24" viewBox="0 0 24 24">
                        <path d="M10.707 17.707 16.414 12l-5.707-5.707-1.414 1.414L13.586 12l-4.293 4.293z"></path>
                     </svg>
                  </div>
               </div>
               <div className="ps__rail-x" style={{ left: "0px", bottom: "-300px" }}>
                  <div className="ps__thumb-x" tabIndex="0" style={{ left: "0px", width: "0px" }}></div>
               </div>
               <div className="ps__rail-y" style={{ top: "300px", height: "657px", right: "0px" }}>
                  <div className="ps__thumb-y" tabIndex="0" style={{ top: "204px", height: "446px" }}></div>
               </div>
            </aside>
         </div>

      </>
   )
}

export default Header;