import React, { useEffect, useRef, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getApi, postApi } from '../../../service/employService';
import Loader from '../../../../components/Loader/loader';
import { Breadcrumbs } from '../../../../components';
import toast from 'react-hot-toast';
import Swal from 'sweetalert2';

const BulkGeneratePayslip = () => {
    const prefix = process.env.REACT_APP_EMPLOYER_PRIFIX;
    const [totalPagesCount, setTotalPagesCount] = useState('');
    const [dataArray, setDataArray] = useState([]);
    const [search, setSearch] = useState('');
    const [dsaDetail, setdsaDetail] = useState(false);
    const [loader, setloader] = useState(true)
    const [sortedBy, setSortedBy] = useState("updatedAt")
    const [orderBy, setOrderBy] = useState('desc')
    const tableRef = useRef(null);
    const navigate = useNavigate();
    const location = useLocation()

    const { state } = location

    // console.log(state)

    const getEmployeesData = async () => {
        const response = await getApi("/employee-list-for-payslip"/* , { search: search, sortby: sortedBy, orderby: orderBy } */);
        try {
            if (response.status == true) {
                setTimeout(() => {
                    const modofiedArray = response?.data?.map((item) => {
                        return {
                            ...item,
                            deductionArray: [
                                {
                                    deductionName: '',
                                    deductionAmount: 0
                                }
                            ]
                        }
                    })
                    setDataArray(modofiedArray);
                    setTotalPagesCount(response.allEmployeeCount)
                    setloader(false)
                }, 2000);
                setloader(true)
            } else if (response.message === 'Session expired') {
                localStorage.removeItem("employer");
                navigate('/employer/login')
            } else {
                console.log("get employees data response", response);
            }
        } catch (error) {
            console.log(error)
            if (error.message == 'Session expired') {
                localStorage.removeItem("employer");
                navigate('/employer/login')
            }
        }
    }

    const sortChange = (col) => {
        if (col === sortedBy) {
            setSortedBy(col);
            orderBy === "asc" ? setOrderBy("desc") : setOrderBy("asc")
        } else {
            setSortedBy(col)
            setOrderBy("desc")
        }
    }

    // DELETE LATER
    // useEffect(() => {
    //     console.log("===========================", dataArray)
    // }, [dataArray]);

    const GenerateBulkPayslip = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "it will override all generated pay slip for the current month!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Generate it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const response = await postApi("/bulk-employee-payslip-generate", {
                        employeesArray: dataArray
                    })
                    if (response?.status) {
                        Swal.fire(
                            'Generated!',
                            'Payslip has been generated Successfully.',
                            'success'
                        )
                        toast.success(response.message)
                        navigate('/employer/employees')
                    }
                    else {
                        toast.error(response.message)
                    }
                } catch (error) {
                    console.log(error)
                }
            }
        })
    }


    // const GenerateBulkPayslip = async () => {
    //     // console.log("first")
    //     try {
    //         const response = await postApi("/bulk-employee-payslip-generate", {
    //             employeesArray: dataArray
    //         })
    //         if (response.status) {
    //             console.log(response)
    //             toast.success(response.message)
    //             navigate('/employer/employees')
    //         } else {
    //             console.log(response)
    //             toast.error(response.message)
    //         }

    //     } catch (error) {
    //         console.log(error)
    //     }
    // }

    useEffect(() => {
        getEmployeesData();
    }, [search, sortedBy, orderBy]);


    // console.log("=============>>>>>>>>", dataArray)


    return (
        <>
            {!dsaDetail &&
                <div className="main-container container-fluid px-0">
                    <div className="page-header my-3 py-2">
                        <div className="page-leftheader">
                            <h4 className="page-title mb-0 text-primary">
                                {state?.data == "payroll" ?
                                    <Breadcrumbs order={{ first: { name: "Payroll", path: '/employer/payroll' }, second: { name: "Bulk Generate Payslip" } }} /> :

                                    <Breadcrumbs order={{ first: { name: "Employee" }, second: { name: "Employee List", path: '/employer/employees' }, third: { name: "Bulk Generate Payslip" } }} />
                                }</h4>
                        </div>
                        <div className="page-rightheader">
                            <div className="btn-list">
                                <button className='btn btn-primary' onClick={() => GenerateBulkPayslip()}>Export Payslip</button>
                            </div>
                        </div>
                    </div>
                    <div className="row">

                        <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                            <div className="form-group">
                                <label className="form-label">Search</label>
                                <div className="row g-xs">
                                    <div className="input-group">
                                        <input type="text" className="form-control" placeholder="Search By Keyword" value={search} onChange={(e) => setSearch(e.target.value)} />
                                        <span className="input-group-append"> <button className="btn btn-primary" type="button"><i className="fa fa-search"></i></button> </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body p-0">
                                    <div className="table-responsive">
                                        <table className="table table-hover card-table table-vcenter text-nowrap" ref={tableRef}>
                                            <thead className="border-bottom-0 pt-3 pb-3">
                                                <tr>
                                                    <th className="font-weight-bold">Employee Details  {sortedBy == "name" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('name') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('name') }}></i>}</th>
                                                    <th className="font-weight-bold">Pay Cycle  {sortedBy == "pay_cycle" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('pay_cycle') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('pay_cycle') }}></i>}</th>
                                                    <th className="font-weight-bold">Salary</th>
                                                    <th className="font-weight-bold">Deduction Name <span style={{ marginLeft: '170px' }}>Deduction Value</span></th>
                                                    {/* <th className="font-weight-bold"></th> */}
                                                    {/* <th className="font-weight-bold">Action</th> */}
                                                </tr>
                                            </thead>
                                            {loader == true ? <tbody>
                                                <td />
                                                <div >
                                                    <Loader /> </div> </tbody> :
                                                <>
                                                    <tbody>
                                                        {dataArray?.length > 0 &&
                                                            dataArray?.map((e, i) => {
                                                                const { name, customer_id, salary, pay_cycle, mobile_number, first_name, last_name, personal_email, country_name } = e;

                                                                return (
                                                                    <tr key={i}>
                                                                        <td> <Link className="text-primary" to={{ pathname: `${prefix}/viewemployee_detail/${customer_id}`, state: { customer_id } }} ><span className="font-weight-normal1">{first_name ? first_name : "--"}&nbsp;{e?.last_name ? last_name : ""}<br />{e?.mobile_number ? mobile_number : "--"}<br />{e?.country_name ? country_name : "--"}<br />{e?.personal_email ? personal_email : "--"}</span></Link></td>
                                                                        <td>{e?.pay_cycle ? pay_cycle : "--"}</td>
                                                                        <td>{e?.salary ? "$" + salary : "--"}</td>
                                                                        <td><span className='d-flex flex-column'>
                                                                            {e?.deductionArray?.map((inputField, index) => (
                                                                                <div key={index} className="d-flex">
                                                                                    <input
                                                                                        type="text"
                                                                                        name="value"
                                                                                        value={inputField.deductionName}
                                                                                        className="form-control mt-2 me-2"
                                                                                        placeholder="Enter Name"
                                                                                        onChange={event => {
                                                                                            const updatedDeductionArray = [...e?.deductionArray];
                                                                                            updatedDeductionArray[index].deductionName = event.target.value;

                                                                                            const modofiedArray = dataArray?.map((item) => {
                                                                                                // console.log("item")
                                                                                                if (item.customer_id === e.customer_id) {
                                                                                                    return {
                                                                                                        ...item,
                                                                                                        deductionArray: updatedDeductionArray
                                                                                                    }
                                                                                                }
                                                                                                else {
                                                                                                    return item
                                                                                                }
                                                                                            })

                                                                                            setDataArray(modofiedArray)
                                                                                        }}
                                                                                    />
                                                                                    <input
                                                                                        type="number"
                                                                                        name="deductionAmount"
                                                                                        value={inputField.deductionAmount}
                                                                                        className="form-control mt-2 me-2"
                                                                                        placeholder="Enter Deduction Value"
                                                                                        onChange={event => {

                                                                                            const updatedDeductionArray = [...e?.deductionArray];
                                                                                            updatedDeductionArray[index].deductionAmount = event.target.value;
                                                                                            console.log(updatedDeductionArray[index].deductionAmount = event.target.value, "+=====================")

                                                                                            const modofiedArray = dataArray?.map((item) => {
                                                                                                // console.log("item")
                                                                                                if (item.customer_id === e.customer_id) {
                                                                                                    return {
                                                                                                        ...item,
                                                                                                        deductionArray: updatedDeductionArray
                                                                                                    }
                                                                                                }
                                                                                                else {
                                                                                                    return item
                                                                                                }
                                                                                            })

                                                                                            setDataArray(modofiedArray)
                                                                                        }}
                                                                                    />

                                                                                    {e?.deductionArray?.length > 1 && index !== 0 && (
                                                                                        <button
                                                                                            type="button"
                                                                                            className="Adjust-button btn btn-danger fa fa-trash"
                                                                                            // onClick={() => handleRemoveFields(index)}
                                                                                            onClick={() => {
                                                                                                const updatedDeductionArray = [...e?.deductionArray];
                                                                                                updatedDeductionArray.splice(index, 1);

                                                                                                const modofiedArray = dataArray?.map((item) => {
                                                                                                    // console.log("item")
                                                                                                    if (item.customer_id === e.customer_id) {
                                                                                                        return {
                                                                                                            ...item,
                                                                                                            deductionArray: updatedDeductionArray
                                                                                                        }
                                                                                                    }
                                                                                                    else {
                                                                                                        return item
                                                                                                    }
                                                                                                })

                                                                                                setDataArray(modofiedArray)
                                                                                            }}
                                                                                        >

                                                                                        </button>
                                                                                    )}

                                                                                    {index === 0 && <button
                                                                                        type="button"
                                                                                        className="Adjust-button btn btn-success"
                                                                                        onClick={() => {

                                                                                            const modofiedArray = dataArray?.map((item) => {
                                                                                                // console.log("item")
                                                                                                if (item.customer_id === e.customer_id) {
                                                                                                    return {
                                                                                                        ...item,
                                                                                                        deductionArray: [
                                                                                                            ...item.deductionArray,
                                                                                                            { deductionName: "", deductionAmount: 0 }
                                                                                                        ]
                                                                                                    }
                                                                                                }
                                                                                                else {
                                                                                                    return item
                                                                                                }
                                                                                            })

                                                                                            setDataArray(modofiedArray)
                                                                                        }}
                                                                                    >
                                                                                        Add More
                                                                                    </button>
                                                                                    }
                                                                                </div>
                                                                            ))}
                                                                        </span>

                                                                        </td>


                                                                    </tr>
                                                                )
                                                            })
                                                        } </tbody>
                                                    <tbody>
                                                        {dataArray?.length == 0 &&
                                                            <tr>
                                                                <td colSpan={10} className='text-center'>No Data Available</td>
                                                            </tr>
                                                        }
                                                    </tbody> </>}
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            }
        </>
    )
}

export default BulkGeneratePayslip