import React from 'react'
import { Link, useLocation } from 'react-router-dom';

const ApplybyId = () => {
  const location = useLocation()
  const { state } = location;

  const viewDetail = state
  return (
    <>

      <div className='container-fluid'>
        <div className="page-header">
          <div className="page-leftheader">
            {/* <h6 className="page-title mb-0 text-primary">New Borrower</h6> */}
            <h6 className="page-title mb-0 text-primary">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to='/jobs/dashboard'>Dashboard</Link></li>
                  <li className="breadcrumb-item active" aria-current="page">View Description</li>
                </ol>
              </nav>
            </h6>
          </div>

          <div className="page-rightheader">
            <div className="btn-list">
              <button className='btn btn-primary'><i class="fa-solid fa-thumbs-up "></i> Ready To Apply</button>
              <Link className='btn btn-dark' to='/jobs/dashboard' style={{ float: 'right' }}>Back</Link>
            </div>
          </div>
        </div>

        <div className='row mt-3'>
          <div className='col-md-8' style={{ maxHeight: '418px', overflowY: 'auto' }}>
            <div className='container-fluid d-flex flex-column align-viewDetail-start p-0'>
              <div className='d-flex align-viewDetail-center'>
                <img className='me-2 jobapplyPage'
                  // src={viewDetail?.employerDetail?.logo} 
                  src={viewDetail?.employerDetail?.logo ? viewDetail?.employerDetail?.logo : process.env.PUBLIC_URL + "/assets/img/logo-thirdroc.png"}
                  srcSet={`${viewDetail?.employerDetail?.logo ? viewDetail?.employerDetail?.logo : process.env.PUBLIC_URL + "/assets/img/logo-thirdroc.png"} 2x`}
                  width={100}
                  alt='...' />
                <div class="">
                  <h4 className='m-0'>{viewDetail?.employerDetail?.company_name}</h4>
                  <h6 className='text-left mt-0 mb-1'>{viewDetail?.job_title}</h6>
                  <span className='text-primary'>{viewDetail?.employerDetail?.website && <> <i class="fa-regular fa-envelope"></i> {viewDetail?.employerDetail?.website}</>} </span>
                </div>
              </div>

            </div>
            {/* <div className='text-center '><i className='fas fa-briefcase' /> On-site Full-time</div> */}
            {/* <div className='mt-1'> */}
            {viewDetail?.job_description ? (
              <span>
                Description:{" "}
                <span dangerouslySetInnerHTML={{ __html: viewDetail?.job_description }} />
              </span>
            ) : (
              "--"
            )}
          </div>
          {/* </div> */}
          {/* <div className='ms-1'>
            <i class="fa-solid fa-list-check "></i> Skills: {viewDetail?.skills ? JSON.parse(viewDetail?.skills).join(', ') : "--"}
            <span className='text-muted' style={{ float: 'right' }}>{viewDetail?.valid_till ? "valid till: " + viewDetail?.valid_till : "--"}</span>
          </div> */}
          <div className='col-md-4' style={{
            border: '1px solid #e7e5e5',
            borderRadius: '4px',
            padding: '1rem 1rem',
            position: 'relative',
            // marginLeft: '0.1rem',
            backgroundColor: '#ffffff'
          }}>
            <small className='rightSideModelTxt my-2'><b>Valid Till: </b>{viewDetail?.valid_till && viewDetail?.valid_till}</small>
            <div className='my-3 rightSideModelTxt'><b>Location: </b> {viewDetail?.city ? <span > <i className='fas fa-location-dot' /> {viewDetail?.city}</span> : "--"}</div>
            <div className='my-3 rightSideModelTxt'><b>No of Openings: </b> {viewDetail?.no_of_openings ? <span > <i className='fas fa-briefcase' /> {viewDetail?.no_of_openings}</span> : "--"}</div>
            <div className='my-3 rightSideModelTxt'><b>Experience Required: </b> {viewDetail?.years_of_experience ? <span > {viewDetail?.years_of_experience}</span> : "0"}<span className='fs-9'>{viewDetail?.month_of_experience && "." + viewDetail?.month_of_experience} year</span></div>

            <div className='my-3 rightSideModelTxt'><b>Contract Type: </b> {viewDetail?.contract_type ? viewDetail?.contract_type : "--"}</div>
            <div className='my-3 rightSideModelTxt'><b>Work Type: </b>{viewDetail?.work_type ? viewDetail?.work_type : "--"}</div>
            <div className='my-3 rightSideModelTxt'><b>Shift Type: </b>{viewDetail?.shift_type ? viewDetail?.shift_type : "--"}</div>
            <div className='my-3 rightSideModelTxt'><b>Designation: </b>{viewDetail?.designation ? viewDetail?.designation : "--"}</div>
            <div className='my-3 rightSideModelTxt'> {(viewDetail?.salary_type == 'Per-Day' || viewDetail?.salary_type == 'Per-Hour') ? <span><b>Salary: </b> {viewDetail?.currency_type == 'INR' ? "₹" : <i className="fa-solid fa-dollar"></i>}
              {viewDetail?.min_annual_salary || '--'} - {viewDetail?.max_annual_salary || '--'} {viewDetail?.salary_type}</span> :
              <span><b>Salary: </b> {viewDetail?.salary_type == "DND" ? <span> Do not disclose</span> : <span> {"$" + viewDetail?.fixed_annual_salary + "LPA"} </span> || '--'} </span>
            }</div>
            <div className='my-3 rightSideModelTxt'>{viewDetail?.industry && viewDetail.industry != "null" ?
              <span><b> Industry: </b>  {JSON.parse(viewDetail.industry).join(', ')} </span> : "--"}</div>
            <div className='my-3 rightSideModelTxt'>{viewDetail?.skills && viewDetail.skills != "null" ?
              <span><b> Skills: </b>  {JSON.parse(viewDetail.skills).join(', ')} </span> : "--"}</div>
          </div>
        </div>

      </div>
    </>
  )
}

export default ApplybyId