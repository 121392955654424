import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-hot-toast';
import moment from 'moment-timezone'
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { getApi, GetPermission, postApi } from '../../../service/employService';
import { Breadcrumbsecond } from '../../../../components';
import Loader from '../../../../components/Loader/loader';
import PaginationComponent from '../../../../PaginationComponent';
import { useFormik } from 'formik';
import * as yup from 'yup';
import * as XLSX from 'xlsx';
import Swal from 'sweetalert2';
import DeductionAmountByID from '../Manage/DeductionAmountByID';

const Payroll = () => {
    const ref = useRef()
    const inputref = useRef()
    const handleModal = useRef()
    const tableRef = useRef(null);
    const navigate = useNavigate();
    const prefix = process.env.REACT_APP_EMPLOYER_PRIFIX;
    const exportfix = process.env.REACT_APP_API_URL + process.env.REACT_APP_EMPLOYER_PRIFIX
    const current_date_format = new moment().format("YYYY-MM-DD")
    const date_Format = (Intl.DateTimeFormat().resolvedOptions().timeZone)
    const employer = JSON.parse(localStorage.getItem("employer"));
    const employer_name = employer.user_name;
    const [pagesCount, setpagesCount] = useState(1);
    const [totalPagesCount, setTotalPagesCount] = useState(10);
    const [dataArray, setDataArray] = useState([]);
    const [dataArray1, setDataArray1] = useState([]);
    let [search, setSearch] = useState('');
    const [dsaDetail, setdsaDetail] = useState(false);
    const [loader, setloader] = useState(true)
    const [sortedBy, setSortedBy] = useState("status")
    const [orderBy, setOrderBy] = useState('desc')
    const [updatedate, setUpdatedate] = useState()
    const [Departmentname1, setDepartmentname1] = useState('')
    const [endDate, setendDate] = useState('');
    const [startDate, setstartDate] = useState('');
    const [Departmentname, setDepartmentname] = useState('')
    const [date, setDate] = useState("");
    const [open, setOpen] = useState(false);
    const [excelFile, setExcelFile] = useState(null);
    const [fileToupload, setFileToUpload] = useState('');
    const [sendEmployeeID, setSendEmployeeID] = useState()


    const getEmployeesAttendance = async (token, data) => {
        if (data?.length) {
            search = data
        }
        const response = await getApi(`/attendance-list`, { page_number: pagesCount, search: search, sortby: sortedBy, orderby: orderBy, start_date: startDate, end_date: endDate, userTimezone: date_Format, department: Departmentname, department_id: Departmentname1 });

        try {
            if (response.status == true) {
                setTimeout(() => {
                    setDataArray(response.data.rows);
                    setTotalPagesCount(response.data.count)
                    if (response.data.rows.length > 0) {
                        setUpdatedate(response.data.rows[0].attendance_date)
                    }
                    setloader(false)
                }, 2000);
                setloader(true)
            } else if (response.message === 'Session expired') {
                localStorage.removeItem("employer");
                navigate('/employer/login')
            } else {
            }
        } catch (error) {
            console.log(error)
            if (error.message == 'Session expired') {
                localStorage.removeItem("employer");
                navigate('/employer/login')
            }
        }
    }
    const [permission_data, setPermission_data] = useState([])

    const GetPermissions = async (token) => {
        try {
            const response = await GetPermission(token);
            // console.log('response', response.data);
            if (response.status) {
                setPermission_data(response.data);
            }
        } catch (error) {
            // toast.error("error :", error.message);
            console.error("🚀 ~ file: addEmployer.js:66 ~ GetCountry ~ error:", error)
        }
    }

    useEffect(() => {
        if (user_type == "SUB-EMPLOYER") {
            GetPermissions(employer.employer_token);
        }
    }, []);

    const employerId = employer.user_id;
    const user_type = employer.user_type
    // const role = JSON.parse(employer.role?.permissions)
    const permissions = permission_data?.permissions;
    if (permissions) {
        var role = JSON.parse(permissions);

    } else {

    }

    const [ewa_attendance_view, setewa_attendance_view] = useState(false)
    const [ewa_mark_attendance, setewa_mark_attendance] = useState(false)
    const [ewa_in_out_time, setewa_in_out_time] = useState(false)
    const [ewa_bulk_upload_attendance, setewa_bulk_upload_attendance] = useState(false)
    const [ewa_view_attendance, setewa_view_attendance] = useState(false)
    useEffect(() => {
        if (role) {
            // console.log(role, "role")
            const options = role;
            // console.log(options, "options")
            options.length > 0 && options.forEach((option) => {
                // console.log(option, "option")
                if (option.key == "ewa_attendance_view") {
                    setewa_attendance_view(true);
                }
                if (option.key == "ewa_mark_attendance") {
                    setewa_mark_attendance(true);
                }
                if (option.key == "ewa_in_out_time") {
                    setewa_in_out_time(true);
                }
                if (option.key == "ewa_bulk_upload_attendance") {
                    setewa_bulk_upload_attendance(true);
                }
                if (option.key == "ewa_view_attendance") {
                    setewa_view_attendance(true);
                }
            });
        }
    }, [role]);

    useEffect(() => {
        getEmployeesAttendance(employer.employer_token)
    }, [pagesCount, search, sortedBy, orderBy, date, startDate, endDate, Departmentname, Departmentname1])

    const sortChange = (col) => {
        if (col === sortedBy) {
            setSortedBy(col);
            orderBy === "asc" ? setOrderBy("desc") : setOrderBy("asc")
        } else {
            setSortedBy(col)
            setOrderBy("desc")
        }
    }

    const handleCallback = (event, picker) => {
        const startDate = picker.startDate.format('YYYY-MM-DD');
        const endDate = picker.endDate.format('YYYY-MM-DD');

        // Calculate the difference in days
        const diffInDays = picker.endDate.diff(picker.startDate, 'days');

        if (diffInDays > 31) {
            // If the selected range is more than 31 days, adjust the end date
            const newEndDate = picker.startDate.clone().add(31, 'days');
            picker.setEndDate(newEndDate);
            picker.element.val(startDate + ' - ' + newEndDate.format('YYYY-MM-DD'));
            setstartDate(startDate);
            setendDate(newEndDate.format('YYYY-MM-DD'));
        } else {
            picker.element.val(startDate + ' - ' + endDate);
            setstartDate(startDate);
            setendDate(endDate);
        }
    };

    const handleCallbackOnCancel = (event, picker) => {
        picker.element.val('');
        setstartDate('')
        setendDate('')
    }

    const [nfcsitename, setnfcsitename] = useState([])
    const GetNFC = async () => {
        try {
            const response = await getApi("/get-nfc-list", { search: "", page_number: "", sortby: "", orderby: "" });
            if (response.status == true) {
                setnfcsitename(response.data);
            }
            else if (response.message == 'Session expired') {
                localStorage.removeItem("employer");
                navigate('/employer/login')
            }
        } catch (error) {
            toast.error("error :", error);
            console.error("🚀 ~ file: addEmployer.js:66 ~ GetCountry ~ error:", error)
        }
    }

    useEffect(() => {
        GetNFC();
    }, []);

    const GetDepartment = async (token) => {
        try {
            // const response = await GetDepartmentDetail(token, search, pagesCount, sortedBy, orderBy);
            const response = await getApi("/get-nfc-site-list");
            // console.log('response', response);
            if (response.status == true) {
                setDataArray1(response.data);

            } else if (response.message == "Access denied! you're not eligible for NFC") {
                toast.error(response.message)
                navigate('/employer/dashboard')
            } else if (response.message == 'Session expired') {
                localStorage.removeItem("employer");
                navigate('/employer/login')
            }
        } catch (error) {
            toast.error("error :", error);
            console.error("🚀 ~ file: addEmployer.js:66 ~ GetCountry ~ error:", error)
        }
    }

    useEffect(() => {
        GetDepartment();
    }, []);

    const readExcel = (file) => {
        // let selectedFile = e.target.files[0];
        if (file) {

            setFileToUpload(file)
            uploadExcelForm.setFieldValue('isExcelFile', true)

            // uploadEmployees
            const promise = new Promise((resolve, reject) => {
                // console.log('file.type', file.type);
                if (file) {
                    let reader = new FileReader();
                    reader.readAsArrayBuffer(file);

                    reader.onload = (e) => {
                        const bufferArray = e.target.result;
                        // setExcelFileError(null);
                        const workBook = XLSX.read(bufferArray, { type: 'buffer' });
                        const workSheetName = workBook.SheetNames[0];
                        const workSheet = workBook.Sheets[workSheetName];
                        const data = XLSX.utils.sheet_to_json(workSheet);
                        // const data2 = XLSX.utils.s
                        // setExcelFile(e.target.result)
                        resolve(data)


                    }
                    reader.onerror = (error) => {
                        reject(error)
                    }
                }
            })
            promise.then((d) => {
                setExcelFile(d)
                // console.log('json data ', d);

            })

        } else {
            console.log('please select your file');
            // setExcelFileError('please select only excelfile')
        }
    }

    const uploadExcelForm = useFormik({
        initialValues: {
            // employer_id: '',
            isExcelFile: false,
            wrong_message: ""
        },
        enableReinitialize: true,
        validationSchema: yup.object({
            // employer_id: yup.string().required('Please enter Amount'),
            // isExcelFile: yup.boolean().oneOf([true], ""),

        }),

        onSubmit: async (values) => {
            // alert(fileToupload)
            // console.log(fileToupload, "************%%%((((((((((")

            //   console.log("loaderrrrrrrrrrrr")


            if (excelFile.length == 0) {
                setOpen(true)
            }
            // console.log(excelFile.length)

            if (fileToupload) {
                const formData = new FormData();
                formData.append("file", fileToupload);

                const employer_id = JSON.parse(localStorage.getItem("employer"));
                const response = await postApi("/attendance-upload", formData, true);

                setOpen(false)

                if (response.status == true) {
                    toast.success(response.message);
                    uploadExcelForm.setFieldValue("isExcelFile", "")

                    inputref.current.value = ""
                    uploadExcelForm.resetForm()

                    ref.current.click()
                    getEmployeesAttendance(employer.employer_token);
                    setFileToUpload("")
                } else {
                    setFileToUpload("")
                    inputref.current.value = ""
                    toast.error(response.message);
                }

            }

        },
    })


    const HandleSampleModal = () => {
        Swal.fire({
            title: 'Please check the Format before uploading the Attendance?',
            html: `For In dateTime and Out dateTime format should be like --> "YYYY-MM-DD HH:mm" and also for the Attendance Date it should be like --> "YYYY-MM-DD"`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Understand!'
        }).then((result) => {
            if (result.isConfirmed) {
                const url = `${exportfix}/attendance-sample-export?username=${employer_name}&time_zone=${date_Format}`;
                const link = document.createElement('a');
                link.href = url;
                link.download = 'attendance-sample.xlsx';
                link.click();
            }
        }).finally(() => {
            Swal.close();
        });
    }

    // const b = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

    // for (let i = 0; i < 10; i++) {
    //     setTimeout(() => console.log(b[i] * 2), 1000);
    // }

    // const nums = [3, 2, 2, 3];
    // const val = 3;

    // var removeElement = function () {
    //     for (var i = 0; i < nums.length; i++) {
    //         if (nums[i] === val) {
    //             nums.splice(i, 1);
    //             i--;
    //         }
    //     }
    //     console.log(nums);
    // };

    // // removeElement();

    // const numss = [0, 0, 1, 1, 1, 2, 2, 5, 3, 3, 4]
    // const FilterArray = numss.filter((array, index) => {
    //     console.log(array, "*************")
    //     return numss.indexOf(array) === index
    // }
    // )

    // console.log(FilterArray.sort())


    return (
        <>
            {!dsaDetail &&
                <div className="main-container container-fluid px-0">

                    <div className="page-header my-3 py-2">
                        <div className="page-leftheader">
                            <h4 className="page-title mb-0 text-primary">
                                <Breadcrumbsecond order={{ first: { name: "Payroll" } }} />
                            </h4>
                        </div>
                        <div className="page-rightheader">
                            <div className="btn-list">
                                {(ewa_bulk_upload_attendance || user_type == "employer") && (
                                    <button type="button" data-toggle="modal" data-target="#modal-center"
                                        disabled={totalPagesCount == 0}
                                        className="btn btn-primary btn-pill mt-3">
                                        <i className="fa fa-plus me-2"></i> Upload Attendance </button>)}
                                <Link to={prefix + "/add_employee"} state={{ datas: 'payroll' }}

                                    className="btn btn-primary btn-pill mt-3" ><i className="fa fa-plus me-2"></i> Add Employee</Link>
                                {/* <a href={exportfix + `/attendance-sample-export?username=${employer_name}&time_zone=${date_Format}`}
                                    className="btn btn-primary btn-pill mt-3" download >
                                    <i className="fa fa-download me-2"></i>Sample Download</a> */}
                                <a
                                    href={exportfix + `/attendance-sample-export?username=${employer_name}&time_zone=${date_Format}`}
                                    className="btn btn-primary btn-pill mt-3"
                                    download
                                    onClick={(e) => {
                                        e.preventDefault();
                                        HandleSampleModal();
                                    }}
                                >
                                    Download Sample
                                </a>
                                <br />
                                <Link to={prefix + "/bulk_generate_payslip"} state={{ data: 'payroll' }}
                                    disabled={totalPagesCount == 0}
                                    className="btn btn-primary btn-pill mt-3" ><i className="fa fa-plus me-2"></i> Bulk Generate Payslip</Link>
                                <a href={exportfix + `/attendance-export?start_date=${startDate ? startDate : current_date_format}&end_date=${endDate ? endDate : current_date_format}&username=${employer_name}&time_zone=${date_Format}`}
                                    disabled={totalPagesCount == 0}
                                    className="btn btn-primary btn-pill mt-3" download >
                                    <i className="fa fa-download me-2"></i> Export Attendance</a>
                            </div>
                        </div>

                        <div className="modal fade" id="modal-center" tabIndex="-1" role="dialog" aria-labelledby="modal-centerLabel" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered emplyoyemodal" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="modal-centerLabel">Upload Attendance</h5>
                                        {/* <button type="submit" className="btn btn-primary" onClick={() => downloadTemplate('employee-template.xlsx')}> Download Templete</button> */}

                                    </div>
                                    <div className="modal-body">
                                        <div className="formbox row">
                                            {/* <div className="form-group form-group-mtb col-md-5 mt-2">
                                                <button type="submit" className="btn btn-primary" onClick={() => downloadTemplate('employee-template.xlsx')}> Download Employee Templete</button>
                                            </div> */}
                                            <form className="" onSubmit={uploadExcelForm.handleSubmit}  >
                                                {uploadExcelForm.values.wrong_message ?
                                                    <div className="invalid-feedback " style={{ display: "block", textAlign: "center" }}>{uploadExcelForm.values.wrong_message}</div>
                                                    : ''}
                                                {open == true ? <div style={{ marginLeft: '100px'  /*, marginTop: '200px' */ }}  >
                                                    <Loader /> </div> :
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            {/* <div className="uploader">
                                                            </div> */}
                                                            <div>
                                                                <div className="page-rightheader" style={{ marginLeft: '10px' }}>
                                                                    <div className="btn-list ">
                                                                        <button className="btn btn-outline-primary uploadbmodal">
                                                                            <label htmlFor="formFile">
                                                                                <i className="fa fa-upload fa-beat me-2"></i>
                                                                            </label>
                                                                            <input ref={inputref} cstyle={{ marginLeft: '10px' }} type="file" id='formFile' onChange={(e) => {
                                                                                const file = e.target.files[0]; readExcel(file);
                                                                            }} />
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-12">
                                                                <div className="modalboxEmployee">
                                                                    <button type="submit" className="btn btn-primary  w-md mb-0 mt-3 mb-0" > Save and Update</button>
                                                                    <button ref={ref} type="button" className="close mt-3" data-dismiss="modal" aria-label="Close"> Close </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>}
                                            </form>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-3 col-lg-6 col-md-6 col-xm-12">
                            <div className="form-group">
                                <label className="form-label">Select Date</label>
                                <div className="row g-xs">
                                    <div className="input-group" >
                                        <DateRangePicker
                                            onApply={handleCallback} onCancel={handleCallbackOnCancel}
                                            initialSettings={{ autoUpdateInput: false, locale: { cancelLabel: 'Clear' } }}>
                                            <input
                                                placeholder="Search By Date"
                                                className="form-control fc-datepicker hasDatepicker"
                                                type="text" defaultValue=""
                                                style={{ cursor: 'pointer' }}
                                            />
                                        </DateRangePicker>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-6 col-md-6 col-xm-12">
                            <div className="form-group">
                                <label className="form-label">Site Name</label>
                                <div className="row g-xs">
                                    <div className="wd-200 mg-b-30">
                                        <div className="input-group">
                                            <select className="form-control mb-0" name="loan_type" value={Departmentname} onChange={(e) => setDepartmentname(e.target.value)} >
                                                <option value="">Select Site Name</option>
                                                {nfcsitename.map(nfcdata => (<option key={nfcdata.id} value={nfcdata.site_name}>{nfcdata.site_name}</option>))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-6 col-md-6 col-xm-12">
                            <div className="form-group">
                                <label className="form-label">Department</label>
                                <div className="row g-xs">
                                    <div className="wd-200 mg-b-30">
                                        <div className="input-group">
                                            <select className="form-control mb-0" name="loan_type" value={Departmentname1} onChange={(e) => setDepartmentname1(e.target.value)} >
                                                <option value="">Select Department</option>
                                                {dataArray1.map(nfcdata => (<option key={nfcdata.site_id} value={nfcdata.site_id}>{nfcdata.nfc_site_name}</option>))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-6 col-md-6 col-xm-12">
                            <div className="form-group">
                                <label className="form-label">Search</label>
                                <div className="row g-xs">
                                    <div className="input-group">
                                        {localStorage.getItem("search_result") ?
                                            <input type="text" defaultValue={localStorage.getItem("search_result")}
                                                className="form-control" placeholder="Search By Keyword"
                                                value={search} onChange={(e) => { setSearch(e.target.value) }} /> :
                                            <input type="text" className="form-control" placeholder="Search By Keyword" value={search} onChange={(e) => { setSearch(e.target.value) }} />}
                                        <span className="input-group-append"> <button className="btn btn-primary" type="button"><i className="fa fa-search"></i></button> </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body p-0">
                                    <div className="table-responsive">
                                        <table className="table table-hover card-table table-vcenter text-nowrap" ref={tableRef}>
                                            <thead className="border-bottom-0 pt-3 pb-3">
                                                <tr>
                                                    <th className="font-weight-bold">Employee Name {sortedBy == "first_name" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('first_name') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('first_name') }}></i>}</th>
                                                    <th className="font-weight-bold">Status {sortedBy == "status" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('status') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('status') }}></i>}</th>
                                                    <th className="font-weight-bold">In/Out Time {sortedBy == "in_time" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('in_time') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('in_time') }}></i>}</th>
                                                    <th className="font-weight-bold">Total Hrs/Date {sortedBy == "customer_id" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('customer_id') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('customer_id') }}></i>}</th>
                                                    <th className="font-weight-bold">Auto Checkout {sortedBy == "auto_check_out" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('auto_check_out') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('auto_check_out') }}></i>}</th>
                                                    <th className="font-weight-bold">Check In/Out From {sortedBy == "in_from" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('in_from') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('in_from') }}></i>}</th>
                                                    <th className="font-weight-bold">In/Out Time GPS Location {sortedBy == "gps_location" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('gps_location') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('gps_location') }}></i>}</th>
                                                    <th className="font-weight-bold">Site Name/Department {sortedBy == "site_name" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('site_name') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('site_name') }}></i>}</th>
                                                    {/* <th className="font-weight-bold">Time Zone  {sortedBy == "time_zone" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('time_zone') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('time_zone') }}></i>}</th> */}
                                                    <th className="font-weight-bold">Action </th>


                                                </tr>
                                            </thead>
                                            {loader == true ? <tbody>
                                                <td /> <td /><td /><td />
                                                <div style={{ /* marginLeft: '400px'  , marginTop: '200px' */ }}  >
                                                    <Loader /> </div> </tbody> :
                                                <>
                                                    <tbody>
                                                        {dataArray.length > 0 && dataArray.map((option, i) => {
                                                            let serial_num = ((10 * (pagesCount - 1)) + i + 1);
                                                            const { status, in_from, out_from, site_name, customer_id, in_time, auto_check_out, out_time, out_gps_location, attendance_date, employee_code, gps_location, mobile_number, first_name, last_name, time_zone, department_name } = option;
                                                            // console.log(i , "checkingi" , serial_num,)                                                        

                                                            var timeZonesIN = moment.utc(option.in_time).format("HH:mm A")
                                                            // var timeZonesOUT = moment.utc(option.out_time).format("HH:mm")
                                                            var timeZonesOUT = moment.utc(option.out_time).format("YYYY-MM-DD HH:mm A");
                                                            const calculateTotalTime = (inTime, outTime) => {
                                                                if (!in_time || !out_time) {
                                                                    return "-";
                                                                }
                                                                const inTimeDate = moment(inTime)
                                                                const outTimeDate = moment(outTime)
                                                                const diffInMinutes = outTimeDate.diff(inTimeDate, 'minute');
                                                                const hours = Math.floor(diffInMinutes / 60);
                                                                const remainingMinutes = diffInMinutes % 60;
                                                                return `${hours} hours ${remainingMinutes} minute`;
                                                            };
                                                            return (
                                                                <tr key={serial_num}>
                                                                    <td><Link className="text-primary" to={{ pathname: `${prefix}/viewemployee_detail/${customer_id}`, state: { customer_id } }} >{first_name ? first_name : "-"}  {last_name ? last_name : ""}<br />{option?.mobile_number && mobile_number}<br />{employee_code ? employee_code : "-"}</Link></td>
                                                                    <td><span className="font-weight-normal1 ">{option?.status ? option?.status : "--"}</span></td>
                                                                    <td>{option.in_time ? timeZonesIN : "-"}<br />{option.out_time ? timeZonesOUT : "-"}</td>
                                                                    <td>{calculateTotalTime(option.in_time, option.out_time)}<br />{attendance_date ? attendance_date : "-"}</td>
                                                                    <td>{auto_check_out == '1' ? <span className='text-danger'>YES </span> : <span className='text-success'>NO</span>}</td>
                                                                    <td><span className="content_fix" style={{ fontSize: 'smaller' }}>{option?.in_from != null ? in_from : "--"}<br />{option?.out_from != null ? out_from : "--"}</span></td>
                                                                    <td><span className="content_fix">{option?.gps_location ? gps_location : "--"}<br />{option?.out_gps_location ? out_gps_location : "--"}</span></td>
                                                                    <td>{option?.site_name ? site_name : "--"}<br />{option?.department_name ? department_name : "--"}</td>
                                                                    {/* <td>{option?.time_zone ? time_zone : "--"}</td> */}
                                                                    <td>
                                                                        <div className="dropdown-action">
                                                                            <button className="btn view-pro dropdown-toggle" type="button" id="dropdownMenu1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                                                                                Action
                                                                                <span className="caret"></span>
                                                                            </button>
                                                                            <ul className="dropdown-menu actionfix" aria-labelledby="dropdownMenu1">
                                                                                {/* <li> <Link className="font-weight-normal1" to={`${prefix}/edit_employee/${customer_id}`} state={{ data: e }}>Edit </Link></li> */}
                                                                                <li> <Link className="font-weight-normal1" data-bs-toggle="modal" to="#exampleModalToggle" role="button" onClick={() => setSendEmployeeID(customer_id)}>Process Payslip</Link></li>
                                                                                <li> <Link className="font-weight-normal1" to={`${prefix}/payslip_by_id/${customer_id}`} state={{ data: 'payroll' }}>View All Payslip</Link></li>
                                                                            </ul>
                                                                        </div>
                                                                    </td>
                                                                </tr>)
                                                        })
                                                        } </tbody>

                                                    <tbody>
                                                        {dataArray.length == 0 &&
                                                            <tr>
                                                                <td colSpan={10} className='text-center'>No Data Available</td>
                                                            </tr>
                                                        }
                                                    </tbody> </>}
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {pagesCount > 0 && dataArray.length > 0 ?
                            <>
                                <div className="col-md-12">
                                    <div className="card-body">
                                        <PaginationComponent className="justify-content-center"
                                            totalItems={totalPagesCount}
                                            pageSize={10}
                                            maxPaginationNumbers={3}
                                            onSelect={(e) => setpagesCount(e)}
                                        />
                                    </div>
                                </div>
                            </>
                            : ''}
                    </div>
                </div>
            }
            <DeductionAmountByID sendEmployeeID={sendEmployeeID} type={"payroll"} />

        </>
    )
}

export default Payroll