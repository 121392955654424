import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { deleteApi, getApi } from '../../service/employService';
import toast from 'react-hot-toast';
import Swal from 'sweetalert2';
import Item from 'antd/es/list/Item';

const ManageJobs = () => {
    // console.log(value, "*******************")
    const [viewPostDetail, setViewPostDetail] = useState([])
    const [despription, setDespription] = useState("")
    const [search, setSearch] = useState('')

    const GetPostDetail = async () => {
        try {
            const response = await getApi('/get-job-post', { search: search });
            if (response.status) {
                setViewPostDetail(response.data.rows);
            } else {
                toast.error(response.message);
            }
        } catch (error) {
            console.error("🚀 ~ file: addEmployer.js:66 ~ GetCountry ~ error:", error)
        }
    }

    useEffect(() => {
        GetPostDetail();
    }, [search]);



    // const truncateText = (text, length) => {
    //     if (text.length > length) {
    //         const truncatedText = text.substring(0, length) + '...';
    //         return <span dangerouslySetInnerHTML={{ __html: truncatedText }} />;
    //     } else {
    //         return <span>{text}</span>;
    //     }
    // }
    // const truncateText = (text, length) => {
    //     if (text.length > length) {
    //         const truncatedText = text.substring(0, length) + '...';
    //         return truncatedText;
    //     } else {
    //         return text;
    //     }
    // }

    const stripHtmlTags = (text) => {
        return text.replace(/<[^>]*>/g, '');
    };

    const truncateText = (text, length) => {
        const plainText = stripHtmlTags(text);
        if (plainText.length > length) {
            return plainText.substring(0, length) + '...';
        } else {
            return plainText;
        }
    };

    const HandleDeletePost = (id) => {
        console.log(id)
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                const response = await deleteApi(`/delete-job-post/${id}`)
                if (response?.status) {
                    Swal.fire(
                        'Deleted!',
                        'Post has been deleted Successfully.',
                        'success'
                    )
                    GetPostDetail();
                    toast.success(response.message)
                }
                else {
                    toast.error(response.message)
                }
            }
        })
    }

    return (
        <div>
            <div className="main-container container-fluid px-0">
                <div className="page-header">
                    <div className="page-leftheader">
                        <h4 className="page-title mb-0 text-primary">Manage Jobs</h4>
                    </div>
                    <div className="page-rightheader">
                        <Link className="btn btn-primary ml-2" to="/employer/add_jobs">Add Job</Link>
                    </div>   </div>
                <div className="row">
                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        <div className="form-group">
                            <label className="form-label">Search</label>
                            <div className="row g-xs">
                                <div className="input-group">
                                    <input type="text" className="form-control" placeholder="Search By Keyword"
                                        value={search}
                                        onChange={(e) => { setSearch(e.target.value) }}
                                    />
                                    <span className="input-group-append"> <button className="btn btn-primary" type="button"><i className="fa fa-search"></i></button> </span>
                                    {/* <button className='btn btn-primary ms-3' onClick={() => UpdateTimesheetStatus()} disabled={multippleArray.length == 0}>Update</button> */}
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="row">
                    {viewPostDetail.length > 0 && viewPostDetail?.map((Items, index) => {
                        return (
                            <div key={index} class="col-sm-6">
                                <div class="card" style={{ border: '1px solid #ccc', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', minHeight: '210px' }}>
                                    <div class="card-body">
                                        <div className='d-flex justify-content-between'>
                                            <img src='/assets/img/logo-thirdroc1.png' width={100} alt='...' />
                                            <i /* class="fa-regular fa-bookmark" */ className='fas fa-trash text-danger' onClick={() => HandleDeletePost(Items?.id)}></i>                                        </div>
                                        <h5 class="card-title mt-1"><i className='fas fa-briefcase' /> {Items?.job_title}</h5>
                                        <div className='d-flex'>
                                            <p class="card-text" style={{ marginBottom: '0rem' }} dangerouslySetInnerHTML={{ __html: truncateText(Items?.job_description, 60) }} />
                                            {/* <button class="btn btn-link mt-0" >...</button> */}
                                        </div>
                                        <p>{Items?.skills && Items.skills != "null" ? JSON.parse(Items.skills).join(', ') : "--"}</p>
                                        {/* <small className='badge rounded-pill bg-info mb-2 fs-10'> {Items.valid_till}</small> */}
                                        <span className='text-primary' style={{ backgroundColor: "#008aff3b", padding: "0.5rem", borderRadius: "0.7rem", marginRight: '1rem' }}><i class="fa-solid fa-location-dot"></i> {Items?.city}</span>
                                        <span className='text-warning' style={{ backgroundColor: "#d6c26a47", padding: "0.5rem", borderRadius: "0.7rem" }} >

                                            {Items?.salary_type == 'range' ? <span> {Items?.currency_type == 'INR' ? "₹" : <i className="fa-solid fa-dollar"></i>}
                                                {Items?.min_annual_salary || '--'} - {Items?.max_annual_salary || '--'}</span> :
                                                <span> {Items?.currency_type == 'INR' ? "₹" : <i className="fa-solid fa-dollar"></i>}  {Items?.fixed_annual_salary || '--'}</span>
                                            }
                                        </span>
                                        <br />
                                        <div className='mt-3'>
                                            <Link to={`/employer/edit_jobs/${Items.id}`}
                                                state={{ data: Items, from: 'EditJobs' }} className="btn btn-dark me-2">Edit</Link>
                                            <button
                                                data-target="#jobDescriptionModal"
                                                data-toggle="modal"
                                                onClick={() => setDespription(Items?.job_description)}
                                                class="btn btn-primary">Preview</button>
                                        </div>
                                    </div>
                                </div>

                                <div class="modal fade" id="jobDescriptionModal" tabindex="-1" role="dialog" aria-labelledby="jobDescriptionModalLabel" aria-hidden="true">
                                    <div class="modal-dialog modal-dialog-scrollable modal-xl" role="document">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="jobDescriptionModalLabel">Post Preview</h5>

                                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div className='d-flex justify-content-between'>
                                                    <h6 class="card-title mt-1 text-primary"><i className='fas fa-briefcase' /> {Items?.job_title}</h6>
                                                    <small className='fw-bold'>{Items?.valid_till && 'Valid Till : ' + Items?.valid_till}</small>
                                                </div>
                                                <div dangerouslySetInnerHTML={{ __html: despription }} />
                                                <span>{Items?.skills && Items.skills != "null" ? <span><b> Skills: </b>  {JSON.parse(Items.skills).join(', ')} </span> : "--"}</span><br />
                                                <span><b>Location: </b> {Items?.city ? <span > <i className='fas fa-location-dot' /> {Items?.city}</span> : "--"}</span><br />
                                                <span><b>Experience Required: </b> {Items?.years_of_experience ? <span > {Items?.years_of_experience} Year</span> : "--"}</span><br />
                                                <span><b>No of Openings: </b> {Items?.no_of_openings ? <span > <i className='fas fa-briefcase' /> {Items?.no_of_openings}</span> : "--"}</span><br />
                                                <span> {Items?.salary_type == 'range' ? <span><b>Salary: </b> {Items?.currency_type == 'INR' ? "₹" : <i className="fa-solid fa-dollar"></i>}
                                                    {Items?.min_annual_salary || '--'} - {Items?.max_annual_salary || '--'} PA</span> :
                                                    <span><b>Salary: </b>  {Items?.currency_type == 'INR' ? "₹" : <i className="fa-solid fa-dollar"></i>}  {Items?.fixed_annual_salary || '--'} LPA</span>
                                                }</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        )
                    })}
                </div>

            </div>
        </div>
    )
}

export default ManageJobs