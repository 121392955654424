import { Link, useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { Toaster } from 'react-hot-toast';
import moment from 'moment';
import { getApi } from '../../../Service/jobService';
import Loader from '../../../../components/Loader/loader';

const ViewProfile = () => {
    const employerPrefix = process.env.REACT_APP_JOBS_EMPLOYER_PRIFIX
    const navigate = useNavigate();
    const [profileData, setprofileData] = useState({});
    const [timezone, setTimezone] = useState();
    const [loader, setloader] = useState(true)
    const Jobs = JSON.parse(localStorage.getItem("jobs"));

    const getProfile = async (token) => {
        const response = await getApi("/employer/my-profile");
        if (response.status == true) {
            // console.log(response.data, "employerrrrr")
            setTimeout(() => {
                var stillUtc = moment.utc(response?.data.created_at).toDate();
                setTimezone(moment(stillUtc).local().format('YYYY-MM-DD HH:mm:ss'))
                setprofileData(response.data);
                setloader(false)
            }, 2000);

        } else if (response.message === 'Session expired') {
            localStorage.removeItem("employer");
            navigate('/employer/login')
        }
        else {
            console.log("get employees data response", response);
        }
    }

    useEffect(() => {
        getProfile();
    }, [])

    return (
        <>
            <Toaster position="top-right" reverseOrder={false} />
            {loader == true ?
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh'
                }}  >
                    <Loader /> </div> :
                <div className="main-container container-fluid mt-5">
                    <div className="page-header my-0 mb-3" >
                        <div className="page-leftheader">
                            <h4 className="page-title mb-0 text-primary">Employer Profile</h4>
                        </div>
                        <div className="page-rightheader">
                            <div className="btn-list">
                                <Link to={employerPrefix + '/edit_profile'} className="btn btn-primary btn-pill" ><i className="fa-solid fa-user-pen fa-fw me-2"></i> Edit Profile</Link>

                                {/* <Link to={prefix + '/changePassword/' + profileData.user_id} className="btn btn-primary btn-pill" ><i className="fa-regular fa-pen-to-square fa-fw me-2"></i> Change Password</Link> */}
                            </div>
                        </div>
                    </div>

                    <div className="main-proifle viewProfileBoxNew" style={{ paddingBottom: '25px', }}>
                        <div className="row">
                            {/* <div className="col-lg-12 col-xl-12 p-0"> */}
                            {/* <div className="box-widget widget-user"> */}
                            {/* <div className="widget-user-image1 d-xl-flex d-block flexwrap"> */}
                            <div className="col-md-12">
                                <p className="mb-0"><small className="text-muted">Authorized Person Name: {profileData?.file_id}</small></p>
                                <h4 className="pro-user-username mb-3 font-weight-bold">{profileData?.full_name}</h4>
                            </div>
                            <div className="media mb-3 col-md-4">
                                <div className="media-icon bg-info-transparent text-info me-3"> <i className="fas fa-user-check"></i> </div>
                                <div className="media-body">
                                    <small className="text-muted">Employer Details</small>
                                    <div className="font-weight-normal1">{profileData.company_name}</div>
                                    <div className="font-weight-normal1">{profileData.mobile_number}</div>
                                    <div className="font-weight-normal1">{profileData.email}</div>
                                </div>
                            </div>
                            <div className="media  mb-3 col-md-4">
                                <div className="media-icon bg-warning-transparent text-warning me-3"> <i className="fa-solid fa-globe fa-fw"></i> </div>
                                <div className="media-body">
                                    <small className="text-muted">Website:</small>
                                    <div className="font-weight-normal1">{profileData.website ? profileData.website : "--"}</div>
                                </div>
                            </div>
                            <div className="media  mb-3 col-md-4">
                                <div className="media-icon bg-danger-transparent text-danger me-3"> <i className="fa-solid fa-location-dot fa-fw"></i> </div>
                                <div className="media-body">
                                    <small className="text-muted">Head Quarter:</small>
                                    <div className="font-weight-normal1">{profileData.headquater ? profileData.headquater : "--"}</div>
                                </div>
                            </div>
                            <div className="media  mb-3 col-md-4">
                                <div className="media-icon bg-success-transparent text-success me-3"> <i className="fa-solid fa-bell fa-fw"></i> </div>
                                <div className="media-body">
                                    <small className="text-muted">Company No:</small>
                                    <div className="font-weight-normal1">{profileData.company_phone_no ? profileData.company_phone_no : "--"}</div>
                                </div>
                            </div>
                            <div className="media  mb-3 col-md-4">
                                <div className="media-icon bg-info-transparent text-info me-3"> <i className="fa-solid fa-industry fa-fw"></i> </div>
                                <div className="media-body">
                                    <small className="text-muted">Industry Type:</small>
                                    <div className="font-weight-normal1">{profileData.industry_type ? profileData.industry_type : "--"}</div>
                                </div>
                            </div>
                            <div className="media  mb-3 col-md-4">
                                <div className="media-icon bg-warning-transparent text-warning me-3"> <i className="fa-solid fa-calendar fa-fw"></i> </div>
                                <div className="media-body">
                                    <small className="text-muted">Established Year:</small>
                                    <div className="font-weight-normal1">{profileData.established_in_year ? profileData.established_in_year : "--"}</div>
                                </div>
                            </div>
                            <div className="media col-md-4">
                                <div className="media-icon bg-secondary-transparent text-secondary me-3"> <i className="fa-solid fa-map-marker fa-fw"></i> </div>
                                <div className="media-body">
                                    <small className="text-muted">Country:</small>
                                    <div className="font-weight-normal1">{profileData?.country_name ? profileData.country_name : "--"}</div>
                                </div>
                            </div>
                            <div className="media col-md-4">
                                <div className="media-icon bg-primary-transparent text-primary me-3"> <i className="fa fa-calendar"></i> </div>
                                <div className="media-body">
                                    <small className="text-muted">Created Date:</small>
                                    <div className="font-weight-normal1">{timezone ? timezone : "--"}{/* <Moment format="DD/MM/YYYY hh:mm" >{profileData.created_at}</Moment> */}</div>
                                </div>
                            </div>
                            <div className="media col-md-4">
                                <div className="media-icon bg-secondary-transparent text-secondary me-3">
                                    <i className="fa-solid fa-photo fa-fw" /> </div>
                                <div className="media-body">
                                    {/* <div className="media col-md-4"> */}
                                    <small className="text-muted">Logo:</small>
                                    <div className="font-weight-normal1">
                                        {profileData.logo ?
                                            <img src={profileData.logo} style={{ height: "50px", width: "50px", objectFit: "contain" }} alt='...' />
                                            :
                                            <img src={process.env.PUBLIC_URL + '/assets/img/upload-logo-lender.png'} style={{ height: "50px", width: "50px", objectFit: "contain" }} alt='...' />

                                        }
                                    </div> </div>
                            </div>
                            {/* </div> */}
                            {/* </div> */}
                            {/* </div> */}
                        </div>
                    </div>
                </div>
            }
        </>
    )
}
export default ViewProfile;




