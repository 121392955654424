import moment from 'moment';
import * as yup from 'yup';


const currentDates = moment().format('YYYY-MM-DD')

export const getValidationSchema = ({ fixed, range, selectedContractType, selectedWorkType, selectedShiftType }) => {


    return yup.object({
        job_title: yup.string()
            .matches(/[a-zA-Z0-9]/, 'Title Must Contain At Least One Alphanumeric Character')
            .test('noOnlySpaces', 'Please Enter Job Title', (value) => {
                return value && value.trim() !== '';
            })
            .max(30, 'Job title must be less than or equal to 30 characters')
            .required('Please Enter Job Title'),
        valid_till: yup.string()
            .required('Please Select Date')
            .test('futureDate', 'Date must be on or after the current date', (value) => {
                if (!value) return true; // allow empty values to pass this test
                return moment(value).isSameOrAfter(currentDates);
            }),
        city: yup.string().matches(/[a-zA-Z0-9]/, 'Job Location Must Contain At Least One Alphanumeric Character')
            .test('noOnlySpaces', 'Please Enter Job Location', value => {
                return value && value.trim() !== '';
            }).required('Please Enter Job Location'),
        no_of_openings: yup.number()
            .typeError('Please enter a valid number')
            .required('Please Enter No. of Openings')
            .integer('Decimal values are not allowed')
            .min(1, 'The value must be greater than 0')
            .test('maxDigits', 'The value must be less than or equal to 4 digits', (value) => value?.toString().length <= 4),
        years_of_experience: yup
            .number()
            .typeError('Please enter a valid Year')
            .required('Please Enter Required Experience')
            .min(0, 'Experience must be greater than or equal to 0')
            .test('len', 'Experience must be at most 2 digits', val => {
                return val === 0 || (val && val.toString().length <= 2);
            }),
        salary_type: yup.string().required('Please Select Salary Type'),
        designation: yup.string().matches(/[a-zA-Z0-9]/, 'Designation Must Contain At Least One Alphanumeric Character')
            .test('noOnlySpaces', 'Please Enter Designation', value => {
                return value && value.trim() !== '';
            }).required('Please Enter Designation'),
        fixed_annual_salary: fixed && yup.string().required('Please Enter Fixed Salary')
            .test('len', 'Fixed Salary must be at most 8 digits', val => {
                return val === 0 || (val && val.toString().length <= 8);
            }),
        min_annual_salary: range && yup
            .number()
            .typeError('Please enter a valid number')
            .required('Please Enter Min Salary')
            .min(0, 'Min Salary must be greater than or equal to 0')
            .test('len', 'Min Salary must be at most 6 digits', val => {
                return val === 0 || (val && val.toString().length <= 6);
            }),
        max_annual_salary: range && yup
            .number()
            .typeError('Please enter a valid number')
            .required('Please Enter Max Salary')
            .moreThan(yup.ref('min_annual_salary'), 'Max Salary must be greater than Min Salary')
            .test('len', 'Max Salary must be at most 6 digits', val => {
                return val === 0 || (val && val.toString().length <= 6);
            }),
        contract_type: selectedContractType == "" ? yup.string().required('Please Select Contract Type') : yup.string(),
        work_type: selectedWorkType == "" ? yup.string().required('Please Select Work Type') : yup.string(),
        shift_type: selectedShiftType == "" ? yup.string().required('Please Select Shift Type') : yup.string(),
        // industry: selectedIndustries.length == 0 && yup.string().required('Please Select Contract Type'),
        // job_description: editorHtml == "" && yup.string().required('Please Add Description')
        // skills: viewSkills.length == 0 && yup.string().required('Please Add Skill'),
    });
}