
import React, { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import { UseAppContext } from '../Context/AppContext';
import Header from './layout/header';
import Footer from './layout/footer';
import EmployerDashboard from './User/Employer/Dasboard/Dashboard';
import AddJobs from './User/Employer/Manage Post/AddJobs';
import Employerheader from './layout/employerheader';
// import ViewProfile from './User/EmployerProfile/viewProfile';
// import EditProfile from './User/EmployerProfile/editProfile';
import Dashboard from './User/Job Seeker/Dasboard/dashboard';
import ApplybyId from './User/Job Seeker/Dasboard/ApplybyId';
import Profile from './User/Job Seeker/Profile/Profile';
import ViewProfile from './User/Employer/Profile/viewProfile';
import EditProfile from './User/Employer/Profile/editProfile';


const Index = (props) => {
    const prefix = process.env.REACT_APP_JOBS_PRIFIX;
    const { profile_id, user_id, caseStatus, dsa_id, lender_id, id } = useParams();
    const { header } = UseAppContext();
    const [locationName, setlocationName] = useState(prefix + '/dashboard');
    const location = useLocation();

    let jobsData = JSON.parse(localStorage.getItem("jobs"));
    useEffect(() => {
        setlocationName(location.pathname)
        // console.log(location.pathname)
    }, [location.pathname])

    const employerPrefix = process.env.REACT_APP_JOBS_EMPLOYER_PRIFIX

    return (
        <>
            <Toaster position="top-right" reverseOrder={false} />
            <div className="page flexColumNew">
                <div className="page-main">
                    {jobsData?.user_type == 'JOBSEEKER' ? <Header prefix={prefix} caseStatus={caseStatus} user_id={user_id} /> :
                        <Employerheader prefix={prefix} caseStatus={caseStatus} user_id={user_id} locationName={locationName} />}
                    <div className={header ? "app_content_res app-content main-content" : "app-content main-content"} style={{ marginLeft: '0rem', }}>
                        <div className="side-app  mt-4">
                            {locationName == prefix + (jobsData?.user_type == 'JOBSEEKER' ? '/dashboard' : '/employer/dashboard') || locationName == prefix ?
                                (jobsData?.user_type == 'JOBSEEKER' ? <Dashboard /* api={api} */ toast={toast} prefix={prefix} /> :
                                    <EmployerDashboard /* api={api} */ toast={toast} prefix={prefix} />)
                                // <Dashboard /* api={api} */ toast={toast} prefix={prefix} />
                                // : locationName == prefix + '/employer/dashboard' ?
                                //     <EmployerDashboard /* api={api} */ toast={toast} prefix={prefix} />
                                : locationName == prefix + '/apply/' + id ?
                                    <ApplybyId toast={toast} prefix={prefix} />
                                    : locationName == prefix + '/profile' ?
                                        <Profile toast={toast} prefix={prefix} />
                                        : locationName == employerPrefix + `/add_jobs` || locationName == employerPrefix + `/edit_jobs/${id}` ?
                                            <AddJobs toast={toast} prefix={prefix} />
                                            : locationName == employerPrefix + '/view_profile' ?
                                                <ViewProfile toast={toast} prefix={prefix} />
                                                : locationName == employerPrefix + '/edit_profile' ?
                                                    <EditProfile toast={toast} prefix={prefix} />
                                                    : ''}
                        </div>
                    </div>

                    <Footer />
                </div>
            </div>
        </>

    )



}

export default Index