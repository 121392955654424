export const formData = (updatelogo, EditForm) => {
    const fd = new FormData();
    fd.append("logo", updatelogo);
    fd.append("full_name", EditForm.getFieldProps('full_name').value);
    fd.append("mobile_number", EditForm.getFieldProps('mobile_number').value);
    fd.append("country", EditForm.getFieldProps('country').value);
    fd.append("email", EditForm.getFieldProps('email').value);
    fd.append("company_phone_no", EditForm.getFieldProps('company_phone_no').value);
    fd.append("headquater", EditForm.getFieldProps('headquater').value);
    fd.append("industry_type", EditForm.getFieldProps('industry_type').value);
    fd.append("website", EditForm.getFieldProps('website').value);
    fd.append("established_in_year", EditForm.getFieldProps('established_in_year').value);
    return fd;
};