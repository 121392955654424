import React, { useRef, useState } from 'react'
import { Link } from 'react-router-dom';

const DeductionAmountByID = ({ sendEmployeeID, type }) => {
    const handleModal = useRef(null);
    const prefix = process.env.REACT_APP_EMPLOYER_PRIFIX;
    const [inputFields, setInputFields] = useState([{ deductionName: '', deductionAmount: '' }]);
    const [deductionAmount, setDeductionAmount] = useState(0)

    const UpdateModal = () => {
        handleModal.current.click()
        // setDeductionAmount('')
    }


    const handleAddFields = () => {
        setInputFields([...inputFields, { deductionName: '', deductionAmount: '' }]);
    };

    const handleInputChange = (index, event) => {
        const values = [...inputFields];
        if (event.target.name === "value") {
            values[index].deductionName = event.target.value;
        } else {
            values[index].deductionAmount = event.target.value;
            setDeductionAmount(event.target.value);
        }
        setInputFields(values);
    };

    const handleRemoveFields = (index) => {
        const values = [...inputFields];
        values.splice(index, 1);
        setInputFields(values);
    };

    return (
        <div>
            <div className="modal fade show" id="exampleModalToggle" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabIndex="-1">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalToggleLabel">Deduction Amount</h5>
                            <div style={{ float: 'right' }}>

                                <button type="button" className="btn btn-success" onClick={handleAddFields}>Add More</button>
                                <i type="button" ref={handleModal} className="fas fa-close ms-3" data-bs-dismiss="modal" aria-label="Close"></i>
                            </div>
                        </div>
                        <div className="modal-body">
                            {inputFields.map((inputField, index) => (
                                <div key={index} className="d-flex">
                                    <input
                                        type="text"
                                        name="value"
                                        value={inputField.deductionName}
                                        className="form-control mt-2 me-2"
                                        placeholder="Enter Name"
                                        onChange={event => handleInputChange(index, event)}
                                    />
                                    <input
                                        type="number"
                                        name="deductionAmount"
                                        value={inputField.deductionAmount}
                                        className="form-control mt-2 me-2"
                                        placeholder="Enter Deduction Value"
                                        onChange={event => handleInputChange(index, event)}
                                    />

                                    {inputFields.length > 1 && (
                                        <button
                                            type="button"
                                            className="Adjust-button btn btn-danger fa fa-trash"
                                            onClick={() => handleRemoveFields(index)}
                                        >
                                            {/* &times; */}
                                        </button>
                                    )}
                                </div>
                            ))}

                        </div>
                        <div className="modal-footer">
                            {deductionAmount > 0 ? (
                                <Link
                                    className="btn btn-primary"
                                    onClick={UpdateModal}
                                    to={`${prefix}/pay_slip/${sendEmployeeID}`}
                                    state={{ data: inputFields, from: sendEmployeeID, types: type }}

                                >
                                    Process Payslip
                                </Link>
                            ) : (
                                <button disabled className="btn btn-primary">Process Payslip</button>
                            )}
                        </div>
                    </div>
                </div>
            </div></div>
    )
}

export default DeductionAmountByID