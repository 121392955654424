import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import moment from 'moment-timezone'
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { getApi } from '../../../service/employService';
import { Breadcrumbsecond } from '../../../../components';
import Loader from '../../../../components/Loader/loader';
import PaginationComponent from '../../../../PaginationComponent';
import { Gettime, TimeZone } from '../../../../components/TimeZone/TimeZone';

const Voilence = () => {
    const prefix = process.env.REACT_APP_EMPLOYER_PRIFIX;
    const [pagesCount, setpagesCount] = useState(1);
    const [totalPagesCount, setTotalPagesCount] = useState(10);
    const [dataArray, setDataArray] = useState([]);
    let [search, setSearch] = useState('');
    const [dsaDetail, setdsaDetail] = useState(false);
    const [loader, setloader] = useState(true)
    const [sortedBy, setSortedBy] = useState("status")
    const [orderBy, setOrderBy] = useState('desc')
    const tableRef = useRef(null);
    const navigate = useNavigate();
    const current_date_format = new moment().format("YYYY-MM-DD")
    const date_Format = (Intl.DateTimeFormat().resolvedOptions().timeZone)
    const exportfix = process.env.REACT_APP_API_URL + process.env.REACT_APP_EMPLOYER_PRIFIX
    const employer = JSON.parse(localStorage.getItem("employer"));
    const employer_name = employer.user_name;
    const [shift_date, setshift_date] = useState('')


    const getEmployeesAttendance = async (token, data) => {
        const response = await getApi(`/violence-list`, { shift_date: shift_date, page_number: pagesCount, search: search, sortby: sortedBy, orderby: orderBy, userTimezone: date_Format });
        try {
            if (response.status == true) {
                setTimeout(() => {
                    setDataArray(response.data);
                    setTotalPagesCount(response.data)
                    setloader(false)
                }, 2000);
                setloader(true)
            } else if (response.message === 'Session expired') {
                localStorage.removeItem("employer");
                navigate('/employer/login')
            } else {
            }
        } catch (error) {
            console.log(error)
            if (error.message == 'Session expired') {
                localStorage.removeItem("employer");
                navigate('/employer/login')
            }
        }
    }

    useEffect(() => {
        getEmployeesAttendance(employer.employer_token)
    }, [pagesCount, search, sortedBy, orderBy, shift_date])

    const sortChange = (col) => {
        if (col === sortedBy) {
            setSortedBy(col);
            orderBy === "asc" ? setOrderBy("desc") : setOrderBy("asc")
        } else {
            setSortedBy(col)
            setOrderBy("desc")
        }
    }


    return (
        <>
            {!dsaDetail &&
                <div className="main-container container-fluid px-0">

                    <div className="page-header my-3 py-2">
                        <div className="page-leftheader">
                            <h4 className="page-title mb-0 text-primary">
                                <Breadcrumbsecond order={{ first: { name: "WFM / Reports" }, second: { name: "Violence" } }} />
                            </h4>
                        </div>
                        {/* <div className="page-rightheader">
                            <div className="btn-list">
                                <a href={exportfix + `/attendance-export?start_date=${startDate ? startDate : current_date_format}&end_date=${endDate ? endDate : current_date_format}&username=${employer_name}&time_zone=${date_Format}`}
                                    className="btn btn-primary btn-pill mt-3" style={{ float: 'right' }} download >
                                    <i className="fa fa-download me-2"></i> Export Attendance</a>
                            </div>
                        </div> */}

                    </div>
                    <div className="row">
                        <div className="col-xl-3 col-lg-6 col-md-6 col-xm-12">
                            <div className="form-group">
                                <label className="form-label">Select Date</label>
                                <div className="row g-xs">
                                    <div className="input-group" >
                                        <input
                                            placeholder="Search By Date"
                                            className="form-control fc-datepicker hasDatepicker"
                                            type="date" defaultValue=""
                                            onChange={(e) => setshift_date(e.target.value)}
                                            style={{ cursor: 'pointer' }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-6 col-md-6 col-xm-12">
                            <div className="form-group">
                                <label className="form-label">Search</label>
                                <div className="row g-xs">
                                    <div className="input-group">
                                        {localStorage.getItem("search_result") ?
                                            <input type="text" defaultValue={localStorage.getItem("search_result")}
                                                className="form-control" placeholder="Search By Keyword"
                                                value={search} onChange={(e) => { setSearch(e.target.value) }} /> :
                                            <input type="text" className="form-control" placeholder="Search By Keyword" value={search} onChange={(e) => { setSearch(e.target.value) }} />}
                                        <span className="input-group-append"> <button className="btn btn-primary" type="button"><i className="fa fa-search"></i></button> </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body p-0">
                                    <div className="table-responsive">
                                        <table className="table table-hover card-table table-vcenter text-nowrap" ref={tableRef}>
                                            <thead className="border-bottom-0 pt-3 pb-3">
                                                <tr>
                                                    <th className="font-weight-bold">Employee Detail  {/* {sortedBy == "first_name" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('first_name') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('first_name') }}></i>} */}</th>
                                                    <th className="font-weight-bold"> <span>Total Break Count /<br />Actual Break Count</span>
                                                        {/* {sortedBy == "status" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('status') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('status') }}></i>} */}</th>
                                                    <th className="font-weight-bold"> <span>Total Break Duration /<br />Actual Break Duration</span> {/* {sortedBy == "status" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('status') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('status') }}></i>} */}</th>
                                                    <th className="font-weight-bold"><span>Check In Time /<br />Check Out Time</span> {/* {sortedBy == "status" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('status') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('status') }}></i>} */}</th>
                                                    <th className="font-weight-bold"><span>Check In By /<br />Check Out By</span> {/* {sortedBy == "status" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('status') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('status') }}></i>} */}</th>
                                                    <th className="font-weight-bold"><span>Timesheet Status /<br />Attendance Status</span> {/* {sortedBy == "status" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('status') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('status') }}></i>} */}</th>
                                                    <th className="font-weight-bold">  <span>Attendance Date /<br />TimeZone</span>{/* {sortedBy == "status" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('status') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('status') }}></i>} */}</th>
                                                    <th className="font-weight-bold">  <span>Created At /<br />Updated At</span>{/* {sortedBy == "status" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('status') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('status') }}></i>} */}</th>

                                                </tr>
                                            </thead>
                                            {loader == true ? <tbody>
                                                <td /><td /><td /><td />
                                                <div style={{ /* marginLeft: '400px'  , marginTop: '200px' */ }}  >
                                                    <Loader /> </div> </tbody> :
                                                <>
                                                    <tbody>
                                                        {dataArray.length > 0 && dataArray.map((option, i) => {
                                                            let serial_num = ((10 * (pagesCount - 1)) + i + 1);
                                                            const { customer_id, totalBreakCount, shiftBreakCount, actualBreakDuration, shiftBreakDuration, in_from, out_from, timesheet_status, attendance_date, in_time, out_time, status, created_at, time_zone, updated_at } = option;
                                                            return (
                                                                <tr key={serial_num}>
                                                                    <td><Link className="text-primary" to={{
                                                                        pathname: `${prefix}/viewemployee_detail/${customer_id}`,
                                                                        state: { customer_id }
                                                                    }} >{option?.employeeDetail?.first_name ? option?.employeeDetail?.first_name : "-"}  {option?.employeeDetail?.last_name ? option?.employeeDetail?.last_name : ""}
                                                                        <br />{option?.employeeDetail?.mobile_number && option?.employeeDetail?.mobile_number}
                                                                        {/* <br />{option?.employeeDetail?.email ? option?.employeeDetail?.email : ""} */}
                                                                    </Link></td>
                                                                    <td><span className="font-weight-normal1 ">{option?.totalBreakCount ? totalBreakCount + " times" : "--"}<br />
                                                                        <span className="text-danger">{option?.shiftBreakCount ? shiftBreakCount + " times" : "--"}</span></span></td>
                                                                    <td><span className="font-weight-normal1 ">{option?.shiftBreakDuration ? shiftBreakDuration + " Minutes" : "--"}<br />
                                                                        <span className="text-danger">{option?.actualBreakDuration ? actualBreakDuration + " Minutes" : "--"}</span></span></td>
                                                                    <td><span className="font-weight-normal1 ">{option?.in_time ? TimeZone(in_time).timeZone : "--"}<br />{option?.out_time ? TimeZone(out_time).timeZone : "--"}</span></td>
                                                                    <td><span className="font-weight-normal1 ">{option?.in_from ? in_from : "--"}<br />{option?.out_from ? out_from : "--"}</span></td>
                                                                    <td><span className="font-weight-normal1 ">{option?.timesheet_status ? timesheet_status : "--"}
                                                                        <br />{option?.status == "P" ? <span className='text-primary'>Present</span> : <span className='text-danger'>Absent</span>}</span></td>
                                                                    <td><span className="font-weight-normal1 ">{option?.attendance_date ? attendance_date : "--"}<br />
                                                                        {option?.time_zone ? time_zone : "--"}</span></td>
                                                                    <td><span className="font-weight-normal1 ">{option?.created_at ? TimeZone(created_at).timeZone : "--"}
                                                                        <br /> {option?.updated_at ? TimeZone(updated_at).timeZone : "--"}
                                                                    </span></td>
                                                                </tr>)
                                                        })
                                                        } </tbody>

                                                    <tbody>
                                                        {dataArray.length == 0 &&
                                                            <tr>
                                                                <td colSpan={10} className='text-center'>No Data Available</td>
                                                            </tr>
                                                        }
                                                    </tbody> </>}
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {pagesCount > 0 && dataArray.length > 0 ?
                            <>
                                <div className="col-md-12">
                                    <div className="card-body">
                                        <PaginationComponent className="justify-content-center"
                                            totalItems={totalPagesCount}
                                            pageSize={10}
                                            maxPaginationNumbers={3}
                                            onSelect={(e) => setpagesCount(e)}
                                        />
                                    </div>
                                </div>
                            </>
                            : ''}
                    </div>
                </div>
            }
        </>
    )
}

export default Voilence