import React, { useEffect, useRef, useState } from 'react'
import toast from 'react-hot-toast'
import { getApi } from '../../../Service/jobService'
import { Link, useNavigate } from 'react-router-dom'
import moment from 'moment'
import PaginationComponent from '../../../../PaginationComponent'

const Dashboard = () => {
    let jobsData = localStorage.getItem("jobs");
    const searchInputRef = useRef();
    const navigate = useNavigate()
    const [viewPostDetail, setViewPostDetail] = useState([])
    const [totalPagesCount, setTotalPagesCount] = useState(10);
    const [pagesCount, setpagesCount] = useState(1);
    const [search, setSearch] = useState('')
    const [salary_type, setSalary_type] = useState('')
    const dropdownRef = useRef(null);
    const [isOpen, setIsOpen] = useState(false);
    const [selectedIndustries, setSelectedIndustries] = useState([]);
    const [selectedContractType, setSelectedContractType] = useState("")
    const [selectedWorkType, setSelectedWorkType] = useState("");
    const [selectedShiftType, setSelectedShiftType] = useState("");
    const [ValidPagination, setValidPagination] = useState(false)
    const [contractType, setContractType] = useState([
        {
            key: 'Permanent',
            value: 'Permanent'
        },
        {
            key: 'On-Hire',
            value: 'On-Hire'
        },
        {
            key: 'Contractual',
            value: 'Contractual'
        }
    ]);
    const [workType, setWorkType] = useState([
        {
            key: 'On-Site',
            value: 'On-Site'
        },
        {
            key: 'Remote',
            value: 'Remote'
        },
        {
            key: 'Hybrid',
            value: 'Hybrid'
        }
    ]);
    const [shiftType, setshiftType] = useState([
        {
            key: 'Single-Shift',
            value: 'Single-Shift'
        },
        {
            key: 'Multiple-Shifts',
            value: 'Multiple-Shifts'
        }
    ]);
    const GetPostDetail = async () => {
        try {

            // shift_type
            const response = await getApi('/jobs-post', { limit: '9', page_number: pagesCount, search: search, industry: selectedIndustries, salary_type: salary_type, contract_type: selectedContractType, work_type: selectedWorkType, shift_type: selectedShiftType });
            if (response.status) {
                setViewPostDetail(response.data.rows);
                setTotalPagesCount(response.data.count)
            } else if (response.message == "Session expired") {
                localStorage.removeItem("jobs");
                navigate('/jobs/login')
            } else {
                toast.error(response.message);
                console.log("first")

            }
        } catch (error) {
            console.error("🚀 ~ file: addEmployer.js:66 ~ GetCountry ~ error:", error)
        }
    }

    useEffect(() => {
        // if (jobsData?.user_type == 'Job Seeker') {
        GetPostDetail();

        // }
    }, [pagesCount, search, selectedIndustries, salary_type, selectedContractType, selectedWorkType, selectedShiftType]);

    const stripHtmlTags = (text) => {
        return text.replace(/<[^>]*>/g, '');
    };
    const truncateText = (text) => {
        const plainText = stripHtmlTags(text);
        return plainText;

    };

    const handleContractTypeChange = (value) => {
        if (selectedContractType === value) {
            setSelectedContractType('');
        } else {
            setSelectedContractType(value);
        }
    };

    const handleWorkTypeChange = (value) => {
        if (selectedWorkType === value) {
            setSelectedWorkType('');
        } else {
            setSelectedWorkType(value);
        }
    };

    const handleShiftTypeChange = (value) => {
        if (selectedShiftType === value) {
            setSelectedShiftType('');
        } else {
            setSelectedShiftType(value);
        }
    };

    const industries = [
        { value: 'manufacturing', label: 'Manufacturing' },
        { value: 'shipping', label: 'Shipping' },
        { value: 'retail', label: 'Retail' },
        { value: 'hotels-resorts', label: 'Hotels/Resorts' },
        { value: 'restaurants', label: 'Restaurants' },
        { value: 'accounting', label: 'Accounting' },
        { value: 'poultry', label: 'Poultry' },
        { value: 'security', label: 'Security' },
        { value: 'hospitals', label: 'Hospitals' },
        { value: 'pharmacy', label: 'Pharmacy' },
        { value: 'it', label: 'IT' },
        { value: 'telecom', label: 'Telecom' },
        { value: 'wholesale', label: 'Wholesale' },
        { value: 'warehousing', label: 'Warehousing' },
        { value: 'logistics', label: 'Logistics' },
    ];

    const handleCheckboxChange = (event) => {
        const { value, checked } = event.target;

        if (checked) {
            setSelectedIndustries([...selectedIndustries, value]);
        } else {
            setSelectedIndustries(selectedIndustries.filter(industry => industry !== value));
        }
    };

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const HandleClearFilter = () => {
        setSearch('')
        searchInputRef.current.value = '';
        setSelectedIndustries([])
        setSelectedContractType("")
        setSelectedWorkType("")
        setSelectedShiftType("")
        setSalary_type("")
        setpagesCount(1)
    }

    let handleClear = null

    const HandleSearch = (e) => {
        const filterData = e.target.value
        if (handleClear) {
            clearTimeout(handleClear)
        }
        handleClear = setTimeout(() => {
            setSearch(filterData)
        }, 500);
    }

    const truncateText1 = (text, maxLength) => {
        if (text.length > maxLength) {
            return text.substring(0, maxLength) + '...';
        }
        return text;
    };

    return (
        <>

            <div className='d-flex justify-content-center align-items-center '>
                <div className='Job-header-searchbar'>
                    <i className='fa fa-search' style={{ fontSize: '1.4rem' }} />
                    <input
                        type='text'
                        className='form-control mb-0 mx-2'
                        style={{ border: 'none' }}
                        placeholder='Search here...'
                        ref={searchInputRef}
                        // value={search}
                        onChange={(e) => { HandleSearch(e); setpagesCount(1); setValidPagination(!ValidPagination) }}
                    />

                    <div className="mx-4" style={{ borderLeft: "1px solid #0000004d", height: '1.8rem' }}></div>

                    <i className="fa-solid fa-location-dot mx-2" style={{ fontSize: '1.4rem' }} />
                    <select
                        className="form-control mb-0"
                        style={{ border: 'none' }}
                    >
                        <option value="">All Location</option>
                    </select>
                    <button className='btn btn-primary mx-2'>Find Jobs</button>
                </div>

                <button
                    className='btn btn-dark mt-3 h-55 ms-3'
                    onClick={() => { HandleClearFilter() }}
                /*  disabled={!isFilterApplied && Entrieslimit == '10' && search === ""} */
                >
                    <span >Clear filters</span>
                    <i class="fa-solid fa-filter-circle-xmark" />
                </button>
            </div>
            <div className='row mt-3'>
                <div className='col-md-3'>
                    <div className="card" style={{ border: '1px solid #ccc', boxShadow: '0px 0px 4px -2px'/* , overflowY: 'auto', maxHeight: '410px' */ }}>
                        <div className="card-body">
                            {/* <label className='fw-bold'>Search By Job Title</label>
                            <div className="input-group mb-2">
                                <span className="input-group-addon">
                                    <i className='fa fa-search' style={{ fontSize: '1.4rem' }} />
                                </span>
                                <input type="text" className="form-control"
                                    onChange={(e) => setjob_title(e.target.value)}
                                    placeholder="Type Job Title here" />
                            </div>
                            <label className='fw-bold' >Location</label>
                            <div className="input-group mb-2">
                                <span className="input-group-addon">
                                    <i className="fa-solid fa-location-dot" style={{ fontSize: '1.4rem' }} />
                                </span>
                                <input type="text" className="form-control" placeholder="City and postcode" />
                            </div>                           */}

                            <label className='form-label'>Industry</label>
                            <div
                                tabIndex={11}
                                className="dropdown-container"
                                style={{
                                    border: '1px solid #ccc',
                                    borderRadius: '4px',
                                    padding: '0.2rem',
                                    position: 'relative',

                                }}
                                ref={dropdownRef}
                            >
                                <div
                                    className="dropdown-header"
                                    style={{
                                        borderBottom: 'none',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        padding: '0.5rem'
                                    }}
                                    onClick={toggleDropdown}
                                >
                                    <span>{selectedIndustries.length > 0 ? selectedIndustries.length + " Selected" : '-- Select Industries --'}</span>
                                    <i className={`arrow fas ${isOpen ? 'fa-chevron-up' : 'fa-chevron-down'}`} style={{ fontSize: '16px', color: '#a29c9c' }}></i>
                                </div>
                                {isOpen && (
                                    <div className="dropdown-list" style={{ maxHeight: '100px', overflowY: 'auto' }}>
                                        {industries.sort((a, b) => a.label.localeCompare(b.label)).map(industry => (
                                            <div key={industry.value} className="checkbox-option" style={{ display: 'flex', alignItems: 'center', marginBottom: '5px', marginLeft: '10px' }}>
                                                <input
                                                    type="checkbox"
                                                    id={`checkbox-${industry.value}`}
                                                    value={industry.value}
                                                    onChange={handleCheckboxChange}
                                                    checked={selectedIndustries.includes(industry.value)}
                                                    style={{ marginRight: '8px', verticalAlign: 'middle' }}
                                                />
                                                <label htmlFor={`checkbox-${industry.value}`} style={{ verticalAlign: 'middle', margin: 0 }}>
                                                    {industry.label}
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>


                            <label className='fw-bold mt-2' >Salary Type</label>
                            <div className="input-group mb-2">
                                <select className='form-control'
                                    onChange={(e) => setSalary_type(e.target.value)}
                                    value={salary_type}
                                    defaultValue=""
                                >
                                    <option value="">-- Select Type --</option>
                                    <option value="fixed"> Fixed </option>
                                    <option value="Per-Day">Per Day</option>
                                    <option value="Per-Hour">Per Hour</option>
                                    <option value="DND">Do not Disclose</option>
                                </select>
                            </div>
                            <label className='fw-bold'>Contract Type
                            </label>
                            <div className="btnstorage" style={{ gap: '1px' }}>
                                {contractType.map((option, index) => {
                                    return (
                                        <div key={index}>
                                            <input
                                                style={{ display: 'none' }}
                                                type="checkbox"
                                                id={`tool-${option.value}`}
                                                value={option.value}
                                                checked={selectedContractType === option.value}
                                                onChange={() => handleContractTypeChange(option.value)}
                                            />
                                            <label style={{ minWidth: '69px', fontSize: '10px' }}
                                                className={`checkbox-tools fs-bold ${selectedContractType === option.value ? 'active' : ''}`}
                                                htmlFor={`tool-${option.value}`}
                                            >
                                                {option.key}
                                            </label>
                                        </div>
                                    );
                                })}
                            </div>
                            <label className='fw-bold'>Work Type </label>
                            <div className="btnstorage" style={{ gap: '1px' }}>
                                {workType.map((option, index) => {
                                    return (
                                        <div key={index}>
                                            <input

                                                style={{ display: 'none' }}
                                                type="checkbox"
                                                id={`tool-${option.value}`}
                                                value={option.value}
                                                checked={selectedWorkType === option.value}
                                                onChange={() => handleWorkTypeChange(option.value)}
                                            />
                                            <label style={{ minWidth: '69px', fontSize: '10px' }}
                                                className={`checkbox-tools fs-bold ${selectedWorkType === option.value ? 'active' : ''}`}
                                                htmlFor={`tool-${option.value}`}
                                            >
                                                {option.key}
                                            </label>
                                        </div>
                                    );
                                })}
                            </div>
                            <label className='fw-bold'>Shift Type </label>
                            <div className="btnstorage" style={{ gap: '5px' }}>
                                {shiftType.map((option, index) => {
                                    return (
                                        <div key={index}>
                                            <input

                                                style={{ display: 'none' }}
                                                type="checkbox"
                                                id={`tool-${option.value}`}
                                                value={option.value}
                                                checked={selectedShiftType === option.value}
                                                onChange={() => handleShiftTypeChange(option.value)}
                                            />
                                            <label style={{ minWidth: '75px', fontSize: '10px' }}
                                                className={`checkbox-tools fs-bold ${selectedShiftType === option.value ? 'active' : ''}`}
                                                htmlFor={`tool-${option.value}`}
                                            >
                                                {option.key}
                                            </label>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-md-9' style={{ maxHeight: '410px', overflowY: 'auto' }}>
                    <div className='row'>
                        {viewPostDetail.length > 0 && viewPostDetail.map((Items, Index) => {
                            return (
                                <div className='col-md-4'>
                                    <div className="card" style={{ border: '1px solid #ccc', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', height: '15.5rem' }}>
                                        {/* <Link className='text-dark' to={`/jobs/apply/${Items.id}`} state={{ data: Items }}> */}
                                        <div
                                            className="card-body"
                                            style={{ padding: '0.4rem' }}
                                            onClick={(event) => {
                                                if (event.target.className !== 'fa-regular fa-bookmark text-primary p-2' && event.target.className !== 'fa-solid fa-bookmark text-primary p-2') {
                                                    navigate(`/jobs/apply/${Items.id}`, { state: Items })
                                                }
                                            }}
                                        >
                                            <div className='d-flex justify-content-between'>
                                                <img src={Items?.employerDetail?.logo ? Items?.employerDetail?.logo : process.env.PUBLIC_URL + "/assets/img/logo-thirdroc.png"}
                                                    srcSet={`${Items?.employerDetail?.logo ? Items?.employerDetail?.logo : process.env.PUBLIC_URL + "/assets/img/logo-thirdroc.png"} 2x`}
                                                    class="jobsImageNew" alt='...' />

                                                {Items?.isBookMarked
                                                    ? <i
                                                        className="fa-solid fa-bookmark text-primary p-2"
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={() => {
                                                            const updateData = viewPostDetail.map((newData) => {
                                                                if (newData.id == Items.id) {
                                                                    return {
                                                                        ...newData, isBookMarked: false
                                                                    }
                                                                }
                                                                else {
                                                                    return newData
                                                                }
                                                            })
                                                            setViewPostDetail(updateData)
                                                        }}
                                                    ></i>
                                                    : <i
                                                        className='fa-regular fa-bookmark text-primary p-2'
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={() => {
                                                            const updateData = viewPostDetail.map((newData) => {
                                                                if (newData.id == Items.id) {
                                                                    return {
                                                                        ...newData, isBookMarked: true
                                                                    }
                                                                }
                                                                else {
                                                                    return newData
                                                                }
                                                            })
                                                            setViewPostDetail(updateData)
                                                        }}
                                                    ></i>
                                                }

                                            </div>
                                            <h4 class=" card-job-title card-title mb-1 "> {Items?.job_title}</h4>
                                            <div className='d-flex'>
                                                <p class="card-text text-primary"><i className='fas fa-briefcase' /> {Items?.employerDetail?.company_name ? Items?.employerDetail?.company_name : "Thirdroc"}</p>
                                                {/* <button class="btn btn-link mt-0" >...</button> */}
                                            </div>
                                            <div className='d-flex'>
                                                <p
                                                    className="card-job-description card-text"
                                                    dangerouslySetInnerHTML={{
                                                        __html: truncateText(Items?.job_description),
                                                    }}
                                                />
                                            </div>
                                            <p style={{ fontSize: "0.8rem" }}>{
                                                Items?.skills && Items.skills != "null"
                                                    ? (JSON.parse(Items.skills).join(', ').length >= 35
                                                        ? JSON.parse(Items.skills).join(', ').substring(0, 35) + "..."
                                                        : JSON.parse(Items.skills).join(', ')
                                                    )
                                                    : "--"
                                            }</p>
                                            <div className='d-flex justify-content-right'>
                                                <small className='card-job-time fw-bold'>
                                                    {moment(Items.created_at).fromNow()}
                                                </small>
                                                <span className='card-city text-primary'>
                                                    <i className='fa-solid fa-location-dot'></i>{' '}
                                                    {truncateText1(Items?.city || '', 15)}
                                                </span>

                                                {/* <p className='text-muted'>
                                                    {Items?.valid_till ? "Valid Till: " + Items?.valid_till : "--"}
                                                </p> */}


                                                {/* <span className='text-warning' style={{ backgroundColor: "#d6c26a47", padding: "0.5rem", borderRadius: "0.7rem" }} ><i class="fa-solid fa-money "></i> ₹ 10k - ₹ 30k</span>                                       <br /> */}
                                            </div>

                                        </div>
                                        {/* </Link> */}
                                    </div>
                                </div>
                            )
                        })}
                        {viewPostDetail.length == 0 &&
                            <div className='text-primary fs-bold my-3 fs-16'>No Post Available</div>
                        }

                        {viewPostDetail.length > 0 && ValidPagination === true ?
                            <>
                                <div className="col-md-12 ">
                                    <div className="card-body d-flex justify-content-right">
                                        <PaginationComponent className=""
                                            totalItems={totalPagesCount}
                                            pageSize={10}
                                            maxPaginationNumbers={3}
                                            onSelect={(e) => setpagesCount(e)}
                                        />
                                    </div>
                                </div>
                            </>
                            : ''}

                        {viewPostDetail.length > 0 && ValidPagination === false ?
                            <>
                                <div className="col-md-12">
                                    <div className="card-body d-flex justify-content-right">
                                        <PaginationComponent className=""
                                            totalItems={totalPagesCount}
                                            pageSize={10}
                                            maxPaginationNumbers={3}
                                            onSelect={(e) => setpagesCount(e)}
                                        />
                                    </div>
                                </div>
                            </>
                            : ''}
                    </div>

                </div>
            </div>




        </>
    )
}

export default Dashboard