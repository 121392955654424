import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import toast, { Toaster } from 'react-hot-toast';
import Loader from '../../components/Loader/loader';
import { GetCountry, Syncdata, posttoken } from '../service/employService';
import { useDispatch } from 'react-redux';
import { setEmployerSystemType } from '../../Redux/modalerrorReducer'
//Lender Registration API
const Registration = () => {
  // const [Img, setImg] = useState("")
  const urlR = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi
  const [country, setCountry] = useState([]);
  // const S3_BUCKET_URL = process.env.REACT_APP_S3_BUCKET_URL
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const customerId = queryParams.get('customer');
  const [btnLoader, setBtnLoader] = useState(false)
  const dispatch = useDispatch()

  const [apiError, setApiError] = useState({
    email: '',
    mobile_number: ''
  })
  const [values, setValues] = React.useState({
    password: "",
    showPassword: false,
  });
  const [valuesConfP, setValuesConfP] = React.useState({
    password: "",
    showConfPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };
  const handleClickShowConfPassword = () => {
    setValuesConfP({ ...valuesConfP, showConfPassword: !valuesConfP.showConfPassword });
  };
  const eyeBtnStyle = {
    position: 'absolute',
    marginLeft: '0px',
    marginTop: '0px',
    marginRight: '10px'
  }

  const eyeBtnStyle1 = {
    position: 'absolute',
    marginLeft: '221px',
    marginTop: '16px',
  }

  const [logoValue, setDocValue] = useState({})
  const navigate = useNavigate();


  const getCountry = async () => {
    const response = await GetCountry();
    if (response?.status == true) {
      setCountry(response.data);
    } else {
      console.log("get Country data response", response);
    }
  }

  useEffect(() => {
    getCountry();
  }, [])
  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
  const regex = /[A-Za-z]/;

  const checkURL = (url) => {
    return (url.match(/\.(jpeg|jpg|gif|png)$/) != null);
  }
  const form = useFormik({
    initialValues: {
      company_name: "",
      full_name: "",
      address: "",
      mobile_number: "",
      email: "",
      // gst_number: "",
      website: "",
      pay_cycle: "",
      country: "",
      password: "",
      confirmedPassword: "",
      logo: "",
      dsa_doc: "",
      mobile_wrong_message: "",
      email_wrong_message: ""
    },
    enableReinitialize: true,
    validationSchema: yup.object({
      company_name: yup.string().required('Please Enter Company Name').matches(regex, 'Please Enter Only Character Values'),
      full_name: yup.string().required('Please Enter Full Name').matches(regex, 'Please Enter Only Character Values'),
      address: yup.string().required('Please Enter Address').matches(regex, 'Please Enter Only Character Values'),
      mobile_number: yup.string().required('Please Enter Mobile Number')/* .matches(phoneRegExp, 'Please enter only number values') */.min(7, "Mobile Number Must Contain 7 Number").max(15, "Mobile Number Max Contain 15 Number"),
      email: yup.string().email("Invalid Email Address Format").required('Please Enter Email Address'),
      // gst_number: yup.string().required('Please enter GST number'),
      website: yup.string().required('Please Enter Website').matches(urlR, 'Please Enter Valid Website URL'),
      country: yup.string().required('Please Enter Country'),
      pay_cycle: yup.string().required('Please Select Pay Cycle'),
      password: yup.string().required('Please Enter Password')/* .matches(regex, 'Please Enter Only Character Values') */,
      confirmedPassword: yup.string().required('Please Enter Confirm Password')/* .matches(regex, 'Please Enter Only Character Values') */
        .oneOf([yup.ref("password"), null], "Password Must Be Same"),
      //  logo: yup.string().required('Please Upload Your Logo'),
      logo: yup.string().nullable().required('Please upload your logo'),
    }),

    onSubmit: async (values) => {
      setBtnLoader(true)
      setApiError({ ...apiError, mobile_number: '', email: '' });
      const formData = new FormData();
      formData.append("logo", logoValue);
      formData.append("company_name", form.getFieldProps('company_name').value);
      formData.append("full_name", form.getFieldProps('full_name').value);
      formData.append("address", form.getFieldProps('address').value);
      formData.append("mobile_number", form.getFieldProps('mobile_number').value);
      formData.append("country", form.getFieldProps('country').value);
      formData.append("pay_cycle", form.getFieldProps('pay_cycle').value);
      formData.append("email", form.getFieldProps('email').value);
      formData.append("website", form.getFieldProps('website').value);
      formData.append("password", form.getFieldProps('password').value);
      formData.append("confirmedPassword", form.getFieldProps('confirmedPassword').value);

      // const response = await EmployerRegistration(formData);
      const response = await posttoken("/employer-registration", formData, true);

      if (response.status) {
        // toast.success(response.message);
        // toast.success("Registeration Successfull");

        dispatch(setEmployerSystemType(response.employer_system_type));
        let employer = {
          user_type: response.user_type == 'EMPLOYER' ? response.user_type.toLowerCase() : response.user_type,
          employer_token: response.token,
          company_name: response.company_name,
          mobile_number: response.mobile_number,
          logo: response.logo,
          user_name: response.user_name,
          user_id: response.data.user_id,
          business_type: response.data?.business_type,
          is_nfc_active: response.is_nfc_active,
          plan_expire_on: response.plan_expire_on
        }

        if (customerId != "") {
          // console.log("On Cubmit------------", customerId)
          const newResponse = await Syncdata(customerId, response.token)
          // console.log("🚀 ~ customerId: ~ newResponse:", newResponse)

          if (newResponse.status == true) {
            toast.success(newResponse.message);
            localStorage.setItem('employer', JSON.stringify(employer));
            navigate(process.env.REACT_APP_EMPLOYER_PRIFIX + "/signature/" + customerId)
          }
          else {
            toast.error(newResponse.message);
            localStorage.setItem('employer', JSON.stringify(employer));
            navigate(process.env.REACT_APP_EMPLOYER_PRIFIX + '/dashboard')
          }

        }
        else {
          localStorage.setItem('employer', JSON.stringify(employer));
          navigate(process.env.REACT_APP_EMPLOYER_PRIFIX + '/dashboard')
        }

      } else {
        if (response.message == 'mobile_number must be unique') {

          toast.error('Mobile Number Must Be Unique');
        } else {

          toast.error(response.message);
        }
        localStorage.removeItem('employer');
      }
      setBtnLoader(false)

    },
  });

  // const imageUpload = (e) => {
  //   // console.log("event pancard", e);
  //   if (e.target.files) {
  //     setImg(e.target.files[0]);
  //     console.log(e.target.files[0]);
  //   }
  // }
  const uploadLogoFunc = async (e = "") => {
    if (e.target && e.target.files) {
      const file = e.target.files[0] || "";
      if (Object.keys(logoValue).length === 0 && e.target.files[0] &&
        (e.target.files[0].type === "image/png" ||
          e.target.files[0].type === "image/jpeg" ||
          e.target.files[0].type === "image/svg+xml")) {
        setDocValue(file);
        form.setValues({
          ...form.values,
          logo: file,
        });
      } else {
        toast.error("Invalid file type. Please upload a PNG, JPEG, or SVG file.")
        form.setValues({
          ...form.values,
          logo: null,
        });
        setDocValue({});
      }

    }
    else {
      setDocValue({});
      form.setValues({
        ...form.values,
        logo: null,
      });
    }
  };

  const imageUpload = (e) => {
    // console.log("event pancard", e);
    const file = e.target.files[0];
    form.setValues({
      ...form.values,
      logo: file
    })

  }


  return (
    <>
      <Toaster position="top-right" reverseOrder={false} />
      <div className="page">
        <div className="page-single bg-back">
          <div className="container">
            <div className="row">
              <div className="col mx-auto">
                <div className="row justify-content-center">
                  <div className="col-xl-9 col-lg-12">
                    <div className="mb-5 br-7 text-center"><a href='https://thirdroc.ai/' ><img src={process.env.PUBLIC_URL + "/assets/img/logo-thirdroc.png"} className="header-brand-img loginlogo" alt="logo" /></a></div>
                  </div>
                </div>
                <div className="row justify-content-center">
                  <div className="col-xl-10 col-lg-12">
                    <div className="row p-0 m-0 bg-white br-7">
                      <div className="col-lg-5 p-0">
                        <div className="text-justified text-white p-5 register-1 overflow-hidden">
                          <div className="custom-content">
                            <div className="">
                              <div className="fs-22 mb-4 font-weight-bold text-white"> Welcome Back To ThirdRoc ! </div>

                              {customerId &&
                                <>
                                  <h3 style={{ marginTop: "100px", color: "rgb(11 195 145)" }}>Note:</h3>
                                  <span>This is the First Step to apply for Earn Wages Access, after successfull registeration you will be redirect to <strong>Earn Wages Access Agreement</strong>, Kindly read the agreement, update your signature and our team will contact you.</span>
                                </>
                              }
                              {/* <h6>Personal Loans</h6>
                              <div className="mb-2 text-white-50 d-flex"><i className="fa-solid fa-circle-dot fa-fw me-3 postop"></i> No Collateral/Security Required. Personal loans don't need you to provide any collateral such as a house or car to avail a personal loan.</div>
                              <div className="mb-2 text-white-50 d-flex"><i className="fa-solid fa-circle-dot fa-fw me-3 postop"></i> Flexible End Use.</div>
                              <div className="mb-2 text-white-50 d-flex"><i className="fa-solid fa-circle-dot fa-fw me-3 postop"></i> Flexible Tenure.</div>
                              <div className="mb-2 text-white-50 d-flex"><i className="fa-solid fa-circle-dot fa-fw me-3 postop"></i> Minimal Documentation.</div>
                              <div className="mb-2 text-white-50 d-flex"><i className="fa-solid fa-circle-dot fa-fw me-3 postop"></i> Quick Disbursal.</div>
                              <div className="mb-2 text-white-50 d-flex"><i className="fa-solid fa-circle-dot fa-fw me-3 postop"></i> Flexible Loan Amount.</div>

                              <h6 className="mt-5">Business Loans</h6>
                              <div className="mb-2 text-white-50 d-flex"><i className="fa-solid fa-circle-dot fa-fw me-3 postop"></i> Unsecured Business Loans/ No Collateral Required</div>
                              <div className="mb-2 text-white-50 d-flex"><i className="fa-solid fa-circle-dot fa-fw me-3 postop"></i> Minimum KYC</div>
                              <div className="mb-2 text-white-50 d-flex"><i className="fa-solid fa-circle-dot fa-fw me-3 postop"></i> Furnishes your Working Capital Requirement, Manage operational cost, growth & expansion of Business</div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-8 col-lg-7 p-0 mx-auto">
                        <div className="bg-white text-dark br-7 br-tl-0 br-bl-0">
                          <div className="form-group mt-3 d-flex justify-content-end me-5"> <Link to={customerId ? "/employer/login?customer=" + customerId : "/employer/login"}
                            className="font-weight-bold fs-6"> Login</Link></div>
                          <div className="card-body">
                            <div className="text-center mb-3">
                              <h3 className="mb-2">Register As Employer</h3>
                            </div>
                            {/* {form.values.wrong_message ?
                              <div className="invalid-feedback mb-3 mt-2" style={{ display: "block", textAlign: "center" }}>{form.values.wrong_message}</div>
                              : ''} */}
                            <form className="mt-5 row lendersignup" id='registrationForm' onSubmit={form.handleSubmit} autoComplete="off">

                              <div className="input-group mb-4 col-lg-6">
                                <div className="input-group-text">
                                  <i className="fa-solid fa-building fa-fw" /> </div>
                                <input type="text" name="company_name" {...form.getFieldProps("company_name")} autoComplete="false" className="form-control" placeholder="Company Name" />
                                {form.touched.company_name && form.errors.company_name ?
                                  <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.company_name}</div> : ''}
                              </div>
                              <div className="input-group mb-4 col-lg-6">
                                <div className="input-group-text">
                                  <i className="fa-solid fa-user fa-fw" /> </div>
                                <input type="text" name="full_name" {...form.getFieldProps("full_name")} className="form-control" placeholder="Contact Name" />
                                {form.touched.full_name && form.errors.full_name ?
                                  <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.full_name}</div> : ''}
                              </div>
                              <div className="input-group mb-4 col-lg-6">
                                <div className="input-group-text">
                                  <i className="fa-solid fa-phone fa-fw" /> </div>
                                <input type="number" name="mobile_number" {...form.getFieldProps("mobile_number")} className="form-control" placeholder="Mobile Number" autoComplete="off" />
                                {form.touched.mobile_number && form.errors.mobile_number ?
                                  <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.mobile_number}</div> : ''}
                                {apiError.mobile_number ?
                                  <div className="invalid-feedback mb-3 mt-2" style={{ display: "block", textAlign: "center" }}>{apiError.mobile_number}</div>
                                  : ''}
                              </div>
                              <div className="input-group mb-4 col-lg-6">
                                <div className="input-group-text">
                                  <i className="fa-solid fa-envelope fa-fw" /> </div>
                                <input type="text" name="email" {...form.getFieldProps("email")} autoComplete="new-password" className="form-control" placeholder="Email" />
                                {form.touched.email && form.errors.email ?
                                  <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.email}</div> : ''}
                                {apiError.email ?
                                  <div className="invalid-feedback mb-3 mt-2" style={{ display: "block", textAlign: "center" }}>{apiError.email}</div>
                                  : ''}
                              </div>

                              <div className="input-group mb-4 col-lg-6">
                                <a className="input-group-text"> <i className="fa-solid fa-lock fa-fw" aria-hidden="true"></i> </a>
                                <input className="form-control" {...form.getFieldProps("password")} type={values.showPassword ? "text" : "password"} name="password" placeholder="Password" id="password-field" autoComplete="off" />
                                {!values.showPassword ?
                                  <span toggle="#password-field" onClick={handleClickShowPassword} style={eyeBtnStyle} className="fa fa-fw fa-eye field-icon toggle-password hideeyes"></span>
                                  :
                                  <span toggle="#password-field" onClick={handleClickShowPassword} style={eyeBtnStyle1} className="fa fa-fw fa-eye-slash field-icon toggle-password hideeyes1"></span>
                                }
                                {form.touched.password && form.errors.password ?
                                  <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.password}</div> : ''}
                                {/* <span toggle="#password-field" onClick={handleClickShowPassword} style={eyeBtnStyle} className="fa fa-fw fa-eye-slash field-icon toggle-password hideeyes r20"></span> */}
                              </div>
                              <div className="input-group mb-4 col-lg-6">
                                <a className="input-group-text"> <i className="fa-solid fa-lock fa-fw" aria-hidden="true"></i> </a>
                                <input className="form-control" {...form.getFieldProps("confirmedPassword")} type={valuesConfP.showConfPassword ? "text" : "password"} name="confirmedPassword" placeholder="Confirm Password" id="password-field" />
                                {form.touched.confirmedPassword && form.errors.confirmedPassword ?
                                  <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.confirmedPassword}</div> : ''}
                                {!valuesConfP.showConfPassword ?
                                  <span toggle="#password-field" onClick={handleClickShowConfPassword} style={eyeBtnStyle} className="fa fa-fw fa-eye field-icon toggle-password hideeyes"></span>
                                  :
                                  <span toggle="#password-field" onClick={handleClickShowConfPassword} style={eyeBtnStyle1} className="fa fa-fw fa-eye-slash field-icon toggle-password hideeyes1"></span>

                                }
                              </div>
                              <div className="input-group mb-4 col-lg-6">
                                <a className="input-group-text"> <i className="fa-solid fa-globe fa-fw" /></a>
                                <input className="form-control" type="text" name="website" {...form.getFieldProps("website")} placeholder="Website" id="password-field" />
                                {form.touched.website && form.errors.website ?
                                  <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.website}</div> : ''}
                                {/* <span toggle="#password-field" className="fa fa-fw fa-eye-slash field-icon toggle-password hideeyes r20" /> */}
                              </div>
                              <div className="input-group mb-4 col-lg-6">
                                <div className="input-group-text">
                                  <i className="fa-solid fa-earth-americas fa-fw" /> </div>
                                <select className="form-control" name="country" {...form.getFieldProps("country")}>
                                  <option value="">Select Country</option>
                                  {country.map(item => (<option key={item.id} value={item.name}>{item.name}</option>))}

                                </select>
                                {form.touched.country && form.errors.country ?
                                  <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.country}</div> : ''}
                              </div>
                              <div className="input-group mb-4 col-lg-6">
                                <div className="input-group-text">
                                  <i className="fa-solid fa-earth-americas fa-fw" /> </div>
                                <select className="form-control mb-0" name="pay_cycle" /* value={paycycle} onKeyUp={() => setValidate(true)} */
                                  /*  onChange={(e) => setpaycycle(e.target.value)} */ /* onSelect={() => setValidate(true)} */
                                  {...form.getFieldProps("pay_cycle")} >
                                  <option>Select Pay Cycle</option>
                                  <option value='Weekly'>Weekly</option>
                                  <option value='Fortnightly'>Fortnightly</option>
                                  <option value='Monthly'>Monthly</option>
                                </select>
                                {form.touched.pay_cycle && form.errors.pay_cycle ?
                                  <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.pay_cycle}</div> : ''}
                              </div>
                              <div className="input-group mb-4 col-lg-6">
                                <div className="input-group-text">
                                  <i className="fa-solid fa-location-dot fa-fw" /> </div>
                                <input type="text" name="address" {...form.getFieldProps("address")} className="form-control" placeholder="Address" />
                                {form.touched.address && form.errors.address ?
                                  <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.address}</div> : ''}
                              </div>
                              <div className="input-group file-browser mb-0 widthHundred">
                                <input type="text" className="form-control browse-file" placeholder="Upload your logo" />
                                <label className="input-group-text btn btn-primary"> Browse
                                  <input type="file" className="file-browserinput"
                                    onChangeCapture={uploadLogoFunc} accept="image/*"
                                    /* onChange={(e) => imageUpload(e)} */ style={{ display: 'none' }} multiple /> </label>
                                {form.touched.logo && form.errors.logo ?
                                  <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.logo}</div> : ''}
                              </div>

                              {logoValue && logoValue.name ? <ul className="imgpreview-newbx">
                                <li className="registerboximg">
                                  <img src={URL.createObjectURL(logoValue)} alt="" />
                                  <Link to="" onClick={() => { setDocValue({}); uploadLogoFunc() }} className="upload__img-close"><i className="fa-solid fa-close fa-fw"></i></Link>
                                  <p>img preview name here</p>
                                </li>
                              </ul> : ""}

                              {btnLoader
                                ? <div className='d-flex justify-content-center align-items-center mt-2'>
                                  <Loader height={50} width={50} strokeWidthSecondary={4} />
                                </div>
                                : <div type="submit" className="form-group text-center mb-3 mt-3"> <button type='submit' className="btn btn-primary btn-lg w-100 br-7">Register</button> </div>
                              }
                            </form>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default Registration
