import { Link, useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import toast from 'react-hot-toast';
import Editor from './Editor';
import { postApi } from '../../../Service/jobService';
import { Breadcrumbs } from '../../../../components';
import moment from 'moment';
import { getValidationSchema } from '../../../Component/Validation';
// import './AddJob.css'

const AddJobs = () => {
    const employerPrefix = process.env.REACT_APP_JOBS_EMPLOYER_PRIFIX
    const location = useLocation();
    const currentDates = moment().format('YYYY-MM-DD')
    const { pathname, state } = location;
    const [viewSkills, setViewSkills] = useState([]);
    const [editorHtml, setEditorHtml] = useState("");
    const Navigate = useNavigate()
    const [viewTeamMember, SetViewTeamMember] = useState()
    const [fixed, setFixed] = useState(false)
    const [range, setRange] = useState(false)
    const [selectedIndustries, setSelectedIndustries] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [selectedWorkType, setSelectedWorkType] = useState(viewTeamMember?.work_type ? viewTeamMember?.work_type : "");
    const [selectedShiftType, setSelectedShiftType] = useState(viewTeamMember?.shift_type ? viewTeamMember?.shift_type : "");
    const [selectedContractType, setSelectedContractType] = useState(viewTeamMember?.contract_type ? viewTeamMember?.contract_type : "")
    const dropdownRef = useRef(null);
    const [contractType, setContractType] = useState([
        {
            key: 'Permanent',
            value: 'Permanent'
        },
        {
            key: 'On-Hire',
            value: 'On-Hire'
        },
        {
            key: 'Contractual',
            value: 'Contractual'
        }
    ]);
    const [workType, setWorkType] = useState([
        {
            key: 'On-Site',
            value: 'On-Site'
        },
        {
            key: 'Remote',
            value: 'Remote'
        },
        {
            key: 'Hybrid',
            value: 'Hybrid'
        }
    ]);
    const [shiftType, setshiftType] = useState([
        {
            key: 'Single-Shift',
            value: 'Single-Shift'
        },
        {
            key: 'Multiple-Shifts',
            value: 'Multiple-Shifts'
        }
    ]);

    useEffect(() => {
        // console.log(location, "location")
        if (state?.data) {
            // setUpdateLoader(false)
            SetViewTeamMember(state.data);
            setSelectedWorkType(state?.data?.work_type ? state?.data?.work_type : "");
            setSelectedShiftType(state?.data?.shift_type ? state?.data?.shift_type : "");
            setSelectedContractType(state?.data?.contract_type ? state?.data?.contract_type : "")
            if (state?.data?.skills) {
                setViewSkills(JSON.parse(state?.data?.skills));
            }
            if (state?.data?.job_description) {
                setEditorHtml(state?.data?.job_description)
            }
            if (state?.data?.industry) {
                setSelectedIndustries(JSON.parse(state?.data?.industry))
            }
        }
    }, [])



    const handleWorkTypeChange = (value) => {
        // if (selectedWorkType === value) {
        //     setSelectedWorkType('');
        // } else {
        setSelectedWorkType(value);
        // }
    };




    const handleShiftTypeChange = (value) => {
        // if (selectedShiftType === value) {
        //     setSelectedShiftType('');
        // } else {
        setSelectedShiftType(value);
        // }
    };
    const handleContractTypeChange = (value) => {
        // if (selectedContractType === value) {
        //     setSelectedContractType('');
        // } else {
        setSelectedContractType(value);
        // }
    };
    const industries = [
        { value: 'manufacturing', label: 'Manufacturing' },
        { value: 'shipping', label: 'Shipping' },
        { value: 'retail', label: 'Retail' },
        { value: 'hotels-resorts', label: 'Hotels/Resorts' },
        { value: 'restaurants', label: 'Restaurants' },
        { value: 'accounting', label: 'Accounting' },
        { value: 'poultry', label: 'Poultry' },
        { value: 'security', label: 'Security' },
        { value: 'hospitals', label: 'Hospitals' },
        { value: 'pharmacy', label: 'Pharmacy' },
        { value: 'it', label: 'IT' },
        { value: 'telecom', label: 'Telecom' },
        { value: 'wholesale', label: 'Wholesale' },
        { value: 'warehousing', label: 'Warehousing' },
        { value: 'logistics', label: 'Logistics' },
    ];

    const AddForm = useFormik({
        initialValues: {
            job_title: viewTeamMember?.job_title ? viewTeamMember?.job_title : "",
            valid_till: viewTeamMember?.valid_till ? viewTeamMember?.valid_till : "",
            skills: "",
            city: viewTeamMember?.city ? viewTeamMember?.city : "",
            no_of_openings: viewTeamMember?.no_of_openings ? viewTeamMember?.no_of_openings : "",
            month_of_experience: viewTeamMember?.month_of_experience ? viewTeamMember?.month_of_experience : "",
            years_of_experience: viewTeamMember?.years_of_experience ? viewTeamMember?.years_of_experience : "",
            designation: viewTeamMember?.designation ? viewTeamMember?.designation : "",
            salary_type: viewTeamMember?.salary_type ? viewTeamMember?.salary_type : "",
            fixed_annual_salary: viewTeamMember?.fixed_annual_salary ? viewTeamMember?.fixed_annual_salary : "",
            min_annual_salary: viewTeamMember?.min_annual_salary ? viewTeamMember?.min_annual_salary : "",
            max_annual_salary: viewTeamMember?.max_annual_salary ? viewTeamMember?.max_annual_salary : "",
            contract_type: viewTeamMember?.contract_type ? viewTeamMember?.contract_type : "",
            work_type: viewTeamMember?.work_type ? viewTeamMember?.work_type : "",
            shift_type: viewTeamMember?.shift_type ? viewTeamMember?.shift_type : "",
            industry: "",
            job_description: "",

        },
        enableReinitialize: true,
        validationSchema: getValidationSchema(fixed, range, selectedContractType, selectedWorkType, selectedShiftType),
        onSubmit: async (values, { resetForm }) => {
            /* const response = await postApi(`/edit-team-member/${currentCustomerID}`, */
            const data = {
                job_title: values.job_title,
                valid_till: values.valid_till,
                skills: JSON.stringify(viewSkills) != "[]" ? JSON.stringify(viewSkills) : "",
                city: values.city,
                contract_type: selectedContractType ? selectedContractType : "",
                work_type: selectedWorkType ? selectedWorkType : "",
                shift_type: selectedShiftType ? selectedShiftType : "",
                no_of_openings: values.no_of_openings ? values.no_of_openings : null,
                years_of_experience: values.years_of_experience ? values.years_of_experience : null,
                month_of_experience: values.month_of_experience ? values.month_of_experience : null,
                salary_type: values.salary_type,
                designation: values.designation,
                industry: selectedIndustries,
                fixed_annual_salary: values.fixed_annual_salary ? values.fixed_annual_salary : null,
                min_annual_salary: values.min_annual_salary ? values.min_annual_salary : null,
                max_annual_salary: values.max_annual_salary ? values.max_annual_salary : null,
                job_description: editorHtml
            }
            const response = state?.from == "EditJobs" ? await postApi(`/employer/edit-job-post/${state?.data?.id}`, { ...data }) : await postApi(`/employer/add-job-post`, { ...data });
            if (response.status == true) {
                toast.success(response.message);
                resetForm({ values: '' });
                Navigate('/jobs/employer/dashboard')
            } else {
                console.log('inside abhi', response);
                toast.error(response.message);
            }
        }
    }
    );

    const addSkill = () => {
        const newSkill = AddForm.values.skills.trim();
        if (newSkill.length > 0 && !viewSkills.includes(newSkill)) {
            setViewSkills(prevSkills => [...prevSkills, newSkill]);
            AddForm.setFieldValue('skills', ''); // Clear the input field
        }
    }

    const removeName = (index) => {
        const newNames = [...viewSkills];
        newNames.splice(index, 1);
        setViewSkills(newNames);
    };


    useEffect(() => {
        if (AddForm.values.salary_type === "fixed") {
            AddForm.setFieldValue('min_annual_salary', "")
            AddForm.setFieldValue('max_annual_salary', "")
            setFixed(true)
            setRange(false)
        } else if (AddForm.values.salary_type === "Per-Day" || AddForm.values.salary_type === "Per-Hour") {
            AddForm.setFieldValue('fixed_annual_salary', "")
            setFixed(false)
            setRange(true)
        } else if (AddForm.values.salary_type == "" || AddForm.values.salary_type == 'DND') {
            AddForm.setFieldValue('min_annual_salary', "")
            AddForm.setFieldValue('max_annual_salary', "")
            setFixed(false)
            setRange(false)
        }
    }, [fixed, range, AddForm.values.salary_type])


    // const currencies = [
    //     { code: 'USD', name: 'US Dollar' },
    //     { code: 'INR', name: 'Indian Rupee' },
    //     { code: 'AUSD', name: 'Australian Dollar' },
    //     { code: 'NZD', name: 'New Zealand Dollar' },
    //     { code: 'FJD', name: 'FIJI Dollar' },
    // ];

    const handleFieldvalidations = (e, path) => {
        let value = e.target.value
        AddForm.setFieldValue(path, value).then((res) => {
            if (res[path] == undefined) {
                AddForm.setFieldValue(path + "_true", true)
            }
            else {
                AddForm.setFieldValue(path + "_true", false)

            }
        })

    }

    const handleCheckboxChange = (event) => {
        const { value, checked } = event.target;

        if (checked) {
            setSelectedIndustries([...selectedIndustries, value]);
        } else {
            setSelectedIndustries(selectedIndustries.filter(industry => industry !== value));
        }
    };

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    // console.log(selectedWorkType, "***************")

    return (
        <>
            <div className="main-container container-fluid mt-5">
                <div className="page-header" style={{ marginLeft: '0rem', }}>
                    <div className="page-leftheader">
                        <h4 className="page-title mb-0 text-primary">
                            <Breadcrumbs order={{ first: { name: "Dashboard", path: `/jobs/employer/dashboard` }, second: { name: state?.from == "EditJobs" ? "Edit Jobs" : "Add Jobs" } }} />
                        </h4>
                    </div>
                    <div className="page-rightheader">
                        <div className="btn-list">
                        </div>
                    </div>
                </div>

                <form onSubmit={AddForm.handleSubmit}>
                    <div className='row'>
                        <div className="col-md-6">
                            <div className="boxCardNewEmployer">
                                <label className='form-label'>Job Title{/* {AddForm.touched.job_title && !AddForm.errors.job_title && <i className="fa-solid fa-circle-check"></i>} */}</label>
                                <input tabIndex={1} type="text" className="form-control" placeholder="Enter Job Title" {...AddForm.getFieldProps("job_title")} />
                                {AddForm.touched.job_title && AddForm.errors.job_title ?
                                    <div className="invalid-feedback mb-2" style={{ display: "block", fontSize: '0.7em' }}>{AddForm.errors.job_title}</div> : ''}
                                <label className='form-label'>Description</label>
                                <Editor placeholder={'Write something here...'} setEditorHtml={setEditorHtml} editorHtml={editorHtml} />
                                {AddForm.touched.job_description && AddForm.errors.job_description ?
                                    <div className="invalid-feedback mb-2" style={{ display: "block", fontSize: '0.7em' }}>{AddForm.errors.job_description}</div> : ''}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="boxCardNewEmployer">
                                <div className="row">
                                    <div className="col-md-6">
                                        <label className='form-label'>Validity Till <span style={{ color: 'red' }}>*</span></label>
                                        <input type="date" min={currentDates} tabIndex={2} className="form-control" placeholder="Select Date" {...AddForm.getFieldProps("valid_till")} />
                                        {AddForm.touched.valid_till && AddForm.errors.valid_till ?
                                            <div className="invalid-feedback mb-2" style={{ display: "block", fontSize: '0.7em' }}>{AddForm.errors.valid_till}</div> : ''}
                                    </div>

                                    <div className="col-md-6" >
                                        <label>Contract Type
                                            {/* {AddForm.values.employer_system_type && <i className="fa-solid fa-circle-check"></i>} */}
                                        </label>
                                        <div className="btnstorage">
                                            {contractType.map((option, index) => {
                                                return (
                                                    <div key={index}>
                                                        <input
                                                            style={{ display: 'none' }}
                                                            type="checkbox"
                                                            id={`tool-${option.value}`}
                                                            value={option.value}
                                                            checked={selectedContractType === option.value}
                                                            onChange={() => handleContractTypeChange(option.value)}
                                                        />
                                                        <label tabIndex={3}
                                                            className={`checkbox-tools fs-bold ${selectedContractType === option.value ? 'active' : ''}`}
                                                            htmlFor={`tool-${option.value}`}
                                                        >
                                                            {option.key}
                                                        </label>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                        {AddForm.touched.contract_type && AddForm.errors.contract_type && selectedContractType == "" ?
                                            <div className="invalid-feedback my-1" style={{ display: "block", fontSize: '0.7em', marginTop: "-0.75em" }}>{AddForm.errors.contract_type}</div> : ''}
                                    </div>

                                    <div className="col-md-6">
                                        <label className='form-label'>Job Location </label>
                                        <input type="text" tabIndex={4} className="form-control" placeholder="Enter Job Location" {...AddForm.getFieldProps("city")} />
                                        {AddForm.touched.city && AddForm.errors.city ?
                                            <div className="invalid-feedback mb-2" style={{ display: "block", fontSize: '0.7em' }}>{AddForm.errors.city}</div> : ''}
                                    </div>

                                    <div className="col-md-6">
                                        <label>Work Type
                                            {/* {AddForm.values.employer_system_type && <i className="fa-solid fa-circle-check"></i>} */}
                                        </label>
                                        <div className="btnstorage">
                                            {workType.map((option, index) => {
                                                return (
                                                    <div key={index}>
                                                        <input

                                                            style={{ display: 'none' }}
                                                            type="checkbox"
                                                            id={`tool-${option.value}`}
                                                            value={option.value}
                                                            checked={selectedWorkType === option.value}
                                                            onChange={() => handleWorkTypeChange(option.value)}
                                                        />
                                                        <label tabIndex={5}
                                                            className={`checkbox-tools fs-bold ${selectedWorkType === option.value ? 'active' : ''}`}
                                                            htmlFor={`tool-${option.value}`}
                                                        >
                                                            {option.key}
                                                        </label>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                        {AddForm.touched.work_type && AddForm.errors.work_type && selectedWorkType == "" ?
                                            <div className="invalid-feedback my-1" style={{ display: "block", fontSize: '0.7em', marginTop: "-0.75em" }}>{AddForm.errors.work_type}</div> : ''}
                                    </div>

                                    <div className="col-md-6">
                                        <label className='form-label'>No. of Openings </label>
                                        <input type="number" tabIndex={6} className="form-control" placeholder="Enter No. of openings" {...AddForm.getFieldProps("no_of_openings")} />
                                        {AddForm.touched.no_of_openings && AddForm.errors.no_of_openings ?
                                            <div className="invalid-feedback mb-2" style={{ display: "block", fontSize: '0.7em' }}>{AddForm.errors.no_of_openings}</div> : ''}
                                    </div>

                                    <div className="col-md-6">
                                        <label>Shift Type
                                            {/* {AddForm.values.employer_system_type && <i className="fa-solid fa-circle-check"></i>} */}
                                        </label>
                                        <div className="btnstorage">
                                            {shiftType.map((option, index) => {
                                                return (
                                                    <div key={index}>
                                                        <input

                                                            style={{ display: 'none' }}
                                                            type="checkbox"
                                                            id={`tool-${option.value}`}
                                                            value={option.value}
                                                            checked={selectedShiftType === option.value}
                                                            onChange={() => handleShiftTypeChange(option.value)}
                                                        />
                                                        <label tabIndex={7}
                                                            className={`checkbox-tools fs-bold ${selectedShiftType === option.value ? 'active' : ''}`}
                                                            htmlFor={`tool-${option.value}`}
                                                        >
                                                            {option.key}
                                                        </label>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                        {AddForm.touched.shift_type && AddForm.errors.shift_type && selectedShiftType == "" ?
                                            <div className="invalid-feedback my-1" style={{ display: "block", fontSize: '0.7em', marginTop: "-0.75em" }}>{AddForm.errors.shift_type}</div> : ''}
                                    </div>

                                    <div className="col-md-6">
                                        <label className='form-label'>Experience </label>
                                        <div className="input-group">
                                            <input
                                                tabIndex={8}
                                                type="number"
                                                className="form-control"
                                                placeholder="Year"
                                                {...AddForm.getFieldProps("years_of_experience")}
                                            />
                                            <select className="form-control"
                                                tabIndex={9}
                                                {...AddForm.getFieldProps("month_of_experience")}>
                                                <option value="">Month</option>
                                                {[...Array(11).keys()].map((month, index) => (
                                                    <option value={index + 1}>{index + 1}</option>
                                                ))}
                                            </select>
                                        </div>
                                        {AddForm.touched.years_of_experience && AddForm.errors.years_of_experience ?
                                            <div className="invalid-feedback mb-2" style={{ display: "block", fontSize: '0.7em' }}>{AddForm.errors.years_of_experience}</div> : ''}
                                    </div>

                                    <div className=" col-md-6">
                                        <label className='form-label'>Designation </label>
                                        <input type="text" tabIndex={10} className="form-control" placeholder="Enter Designation" {...AddForm.getFieldProps("designation")} />
                                        {AddForm.touched.designation && AddForm.errors.designation ?
                                            <div className="invalid-feedback mb-2" style={{ display: "block", fontSize: '0.7em' }}>{AddForm.errors.designation}</div> : ''}

                                    </div>

                                    <div className="col-md-6">
                                        <label className='form-label'>Industry</label>
                                        <div
                                            tabIndex={11}
                                            className="dropdown-container"
                                            style={{
                                                border: '1px solid #ccc',
                                                borderRadius: '4px',
                                                padding: '0.2rem',
                                                position: 'relative',

                                            }}
                                            ref={dropdownRef}
                                        >
                                            <div
                                                className="dropdown-header"
                                                style={{
                                                    borderBottom: 'none',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'space-between',
                                                    padding: '0.5rem'
                                                }}
                                                onClick={toggleDropdown}
                                            >
                                                <span>{selectedIndustries.length > 0 ? selectedIndustries.length + " Selected" : '-- Select Industries --'}</span>
                                                <i className={`arrow fas ${isOpen ? 'fa-chevron-up' : 'fa-chevron-down'}`} style={{ fontSize: '16px', color: '#a29c9c' }}></i>
                                            </div>
                                            {isOpen && (
                                                <div className="dropdown-list" style={{ maxHeight: '100px', overflowY: 'auto' }}>
                                                    {industries.sort((a, b) => a.label.localeCompare(b.label)).map(industry => (
                                                        <div key={industry.value} className="checkbox-option" style={{ display: 'flex', alignItems: 'center', marginBottom: '5px', marginLeft: '10px' }}>
                                                            <input
                                                                type="checkbox"
                                                                id={`checkbox-${industry.value}`}
                                                                value={industry.value}
                                                                onChange={handleCheckboxChange}
                                                                checked={selectedIndustries.includes(industry.value)}
                                                                style={{ marginRight: '8px', verticalAlign: 'middle' }}
                                                            />
                                                            <label htmlFor={`checkbox-${industry.value}`} style={{ verticalAlign: 'middle', margin: 0 }}>
                                                                {industry.label}
                                                            </label>
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        </div>

                                        {AddForm.touched.industry && AddForm.errors.industry ?
                                            <div className="invalid-feedback mb-2" style={{ display: "block", fontSize: '0.7em' }}>{AddForm.errors.industry}</div> : ''}
                                    </div>

                                    <div className="col-md-6">
                                        <label className='form-label'>Salary Type</label>
                                        <select className='form-control'
                                            tabIndex={12}
                                            // value={(e) => e.target.name}
                                            {...AddForm.getFieldProps('salary_type')}
                                        // onChange={(e) => { handleSalaryRangeChange(e); /* AddForm.setFieldValue('salary_type', e.target.value) */ }}
                                        >
                                            <option value="">-- Select Type --</option>
                                            <option value="fixed"> Fixed </option>
                                            {/* <option value="range">Range</option> */}
                                            <option value="Per-Day">Per Day</option>
                                            <option value="Per-Hour">Per Hour</option>
                                            <option value="DND">Do not Disclose</option>
                                        </select>
                                        {AddForm.touched.salary_type && AddForm.errors.salary_type ?
                                            <div className="invalid-feedback mb-2" style={{ display: "block", fontSize: '0.7em' }}>{AddForm.errors.salary_type}</div> : ''}
                                    </div>
                                    {fixed && <div className="col-md-12">
                                        <label className='form-label'>Salary <small className='text-danger fs-9 fw-bold'>(* Per Annum)</small></label>
                                        <input type="number" className="form-control" placeholder="Enter Salary Amount" {...AddForm.getFieldProps("fixed_annual_salary")} />
                                        {AddForm.touched.fixed_annual_salary && AddForm.errors.fixed_annual_salary ?
                                            <div className="invalid-feedback mb-2" style={{ display: "block", fontSize: '0.7em' }}>{AddForm.errors.fixed_annual_salary}</div> : ''}
                                    </div>}
                                    {range &&
                                        <><div className="col-md-6">
                                            <label className='form-label'>Min Salary <small className='text-danger fs-9 fw-bold'>(* Per Annum)</small></label>
                                            <input type="number" className="form-control" placeholder="Enter Salary Amount" {...AddForm.getFieldProps("min_annual_salary")} />
                                            {AddForm.touched.min_annual_salary && AddForm.errors.min_annual_salary ?
                                                <div className="invalid-feedback mb-2" style={{ display: "block", fontSize: '0.7em' }}>{AddForm.errors.min_annual_salary}</div> : ''}
                                        </div>
                                            <div className="col-md-6">
                                                <label className='form-label'>Max Salary <small className='text-danger fs-9 fw-bold'>(* Per Annum)</small></label>
                                                <input type="number" className="form-control" placeholder="Enter Salary Amount" {...AddForm.getFieldProps("max_annual_salary")} />
                                                {AddForm.touched.max_annual_salary && AddForm.errors.max_annual_salary ?
                                                    <div className="invalid-feedback mb-2" style={{ display: "block", fontSize: '0.7em' }}>{AddForm.errors.max_annual_salary}</div> : ''}
                                            </div>
                                        </>
                                    }
                                    <div className="col-md-12">
                                        <label className='form-label'>Skills<span type="button" className='text-primary' data-bs-toggle="tooltip" data-bs-placement="top" title="Click on + or Press Enter"><i className='mx-1 fa-solid fa-circle-info'></i></span></label>
                                        <div style={{ position: 'relative' }}>
                                            <input
                                                type="text"
                                                tabIndex={13}
                                                className="form-control"
                                                placeholder="Add Skill"
                                                // value={inputNames}
                                                // onChange={(e) => setInputNames([e.target.value])}
                                                {...AddForm.getFieldProps('skills')}
                                                style={{ paddingRight: '30px' }} // Add padding for the icon
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        e.preventDefault(); // Prevents form submission
                                                        addSkill();
                                                    }
                                                }}
                                            />
                                            {AddForm.values.skills.replace(/\s/g, "").length > 0 && <i
                                                className="fas fa-plus text-primary "
                                                style={{ position: 'absolute', top: '50%', right: '10px', transform: 'translateY(-50%)', cursor: 'pointer' }}
                                                onClick={() => addSkill()}

                                            />}
                                            {AddForm.touched.skills && AddForm.errors.skills ?
                                                <div className="invalid-feedback mb-2" style={{ display: "block", fontSize: '0.7em' }}>{AddForm.errors.skills}</div> : ''}
                                        </div>
                                        {viewSkills != null && viewSkills.length > 0 && viewSkills.map((item, index) => {
                                            return (
                                                <span
                                                    key={index}
                                                    className="m-1 px-1 py-1 rounded border text-white text-wrap"
                                                    style={{ cursor: 'pointer', fontSize: '12px', backgroundColor: '#0F2F81' }}
                                                >
                                                    {item} <span onClick={() => removeName(index)}>&times;</span>
                                                </span>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-12">
                            <button type="submit" className="btn btn-primary addBtnNew">{state?.from == "EditJobs" ? "Edit Jobs" : "Add Jobs"} </button>
                        </div>

                    </div>

                </form>
            </div>
        </>
    )
}

export default AddJobs;