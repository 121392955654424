import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import moment from 'moment-timezone';
import { getApi, postApi } from '../../../../service/employService';
import { Breadcrumbsecond } from '../../../../../components';

const Compliance = () => {
    const navigate = useNavigate()
    const [dataArray, setDataArray] = useState([]);
    // const [totalPagesCount, setTotalPagesCount] = useState(10);
    // const [loader, setloader] = useState(true)

    const GetcomplianceDetail = async (token) => {

        try {
            const response = await getApi("/compliance-list",/*  { search: search, page_number: pagesCount, sortby: sortedBy, orderby: orderBy} */);
            if (response.status == true) {
                console.log(response)
                // setTimeout(() => {
                setDataArray(response.data);
                // setTotalPagesCount(response.data.count)
                // setloader(false)
                // }, 2000);
                // setloader(true)
            } else if (response.message == "Access denied! you're not eligible for NFC") {
                toast.error(response.message)
                navigate('/employer/dashboard')
            } else if (response.message == 'Session expired') {
                localStorage.removeItem("employer");
                navigate('/employer/login')
            }
        } catch (error) {
            toast.error("error :", error);
            if (error.message == 'Session expired') {
                localStorage.removeItem("employer");
                navigate('/employer/login')
            }
        }
    }
    useEffect(() => {
        GetcomplianceDetail()
    }, [])


    const updateCompliance = async (value, id) => {
        try {
            const response = await postApi('/update-compliance', {
                id: id,
                value: value
            })
            if (response.status === true) {
                console.log(response)
                GetcomplianceDetail()
                toast.success(response.message)
            } else {
                toast.error(response.message)
            }
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <>
            <div className="main-container container-fluid px-0">
                <div className="page-header mb-0">
                    <div className="page-leftheader">
                        <h4 className="page-title mb-0 text-primary">
                            <Breadcrumbsecond order={{ first: { name: "Employee" }, second: { name: "Compliance" } }} />
                        </h4>
                    </div>
                </div>
            </div>

            <div className="main-container container-fluid px-0">
                <div className="row">
                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12" >

                    </div>

                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body p-0">
                            <div className="table-responsive">
                                <table className="table table-hover card-table table-vcenter text-nowrap" >
                                    <thead className="border-bottom-0 pt-3 pb-3">
                                        <tr>
                                            <th className="font-weight-bold">Complaince Type </th>
                                            <th className="font-weight-bold">Complaince Value</th>
                                            <th className="font-weight-bold">Updated Date </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {dataArray?.length > 0 && dataArray.map((Item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td><span className="font-weight-normal1">{Item.compliance_type ? Item.compliance_type.replace(/_/g, " ") : "--"}</span></td>
                                                    <td><span className="font-weight-normal1">
                                                        <input className='form-control' type='number'
                                                            defaultValue={Item.compliance_value ? Item.compliance_value : "0"}
                                                            onBlur={(e) => updateCompliance(e.target.value, Item.id)}
                                                        ></input>
                                                        <div className='text-wrap text-primary' style={{ textTransform: 'none', fontSize: '11px' }}>
                                                            {Item.compliance_type == "weekly_hours" && (<span> Note: No adult worker shall be required or allowed to work in a factory for more than given by employer in any week.</span>)}
                                                            {Item.compliance_type == "daily_hours" && (<span> Note: No adult worker shall be required or allowed to work in a factory for more than given by employer in any day.</span>)}
                                                            {Item.compliance_type == "interval_for_rest" && (<span> Note: No period shall exceed than given by employer and that no worker shall work for more than given by employer before he has had an interval for rest of at least half an hour.</span>)}
                                                            {Item.compliance_type == "spread_over" && (<span> Note: The periods of work of an adult worker in a factory shall be so arranged that they shall not spread over more than ten and a half hours in any day.</span>)}
                                                            {Item.compliance_type == "night_shifts" && (
                                                                <span> Note: Where a worker in a factory works on a shift which extends beyond midnight, the following day for him shall be deemed to be the period of twenty-four hours beginning when such shift ends, and the hours he has worked after midnight shall be counted in the previous day.
                                                                </span>)}
                                                            {Item.compliance_type == "prohibition_of_overlapping_shifts" &&
                                                                <span> Note: If the factory operates two or more shifts, it should ensure that no worker is engaged in more than one shift per day, preventing overlapping shifts.
                                                                    <br />Overtime Workers who work for more than nine hours a day or more than forty-eight hours a week are entitled to overtime wages at twice their ordinary rate of wages. The "ordinary rate of wages" includes basic wages and allowances but excludes bonuses and overtime wages.
                                                                </span>}
                                                            {Item.compliance_type == "payment_of_wages_during_leave" && (<span> Note:  Wages during leave are calculated at a rate equal to the daily average of the worker's total full-time earnings for the days worked during the month immediately preceding the leave, exclusive of overtime and bonus but inclusive of dearness allowance and the cash equivalent of concessional sales</span>)}
                                                            {Item.compliance_type == "application_and_rant_of_leave" && (
                                                                <span> Note:
                                                                    A worker can apply for leave in writing, at least 15 days in advance (30 days for public utility services).
                                                                    Leave can be taken in no more than three installments during a year.<br />
                                                                    Leave applications cannot be refused if they comply with the provisions.
                                                                </span>
                                                            )}
                                                            {Item.compliance_type == "encashment_of_leave" && (<span> Note: If a worker's employment is terminated before they have taken their entitled leave, the occupier must pay the worker for the leave not taken. Payment must be made within two days of termination or on the next payday if the worker quits</span>)}
                                                            {Item.compliance_type == "leave_carry_forward" && (<span> Note:  Unused leave in a calendar year can be carried forward to the next year, but the total carried forward leave cannot exceed 30 days for adults or 40 days for children. However, if a worker's leave application is refused, the unavailed leave can be carried forward without any limit</span>)}
                                                            {Item.compliance_type == "marked_by" && "--"}

                                                        </div></span>

                                                    </td>
                                                    <td><span className="font-weight-normal1">{moment(Item.updated_at).local().format('YYYY-MM-DD hh:mm A')}</span></td>
                                                </tr>
                                            )
                                        })}

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                {/* {pagesCount > 0 && dataArray.length > 0 && ValidPagination === true ?
                    <>
                        <div className="col-md-12">
                            <div className="card-body">
                                <PaginationComponent className="justify-content-center"
                                    totalItems={totalPagesCount}
                                    pageSize={10}
                                    maxPaginationNumbers={3}
                                    onSelect={(e) => setpagesCount(e)}
                                />
                            </div>
                        </div>
                    </>
                    : ''}
                {pagesCount > 0 && dataArray.length > 0 && ValidPagination === false ?
                    <>
                        <div className="col-md-12">
                            <div className="card-body">
                                <PaginationComponent className="justify-content-center"
                                    totalItems={totalPagesCount}
                                    pageSize={10}
                                    maxPaginationNumbers={3}
                                    onSelect={(e) => setpagesCount(e)}
                                />
                            </div>
                        </div>
                    </>
                    : ''} */}
            </div>


        </>
    )
}

export default Compliance