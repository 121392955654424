import { Link, useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import toast from 'react-hot-toast';
import { postApi } from '../../service/employService';
import Editor from './Editor';
import { Breadcrumbs } from '../../../components';

const AddJobs = () => {

    const location = useLocation();
    const { pathname, state } = location;
    const [viewSkills, setViewSkills] = useState([]);
    const [editorHtml, setEditorHtml] = useState("");
    const Navigate = useNavigate()
    const [viewTeamMember, SetViewTeamMember] = useState()
    const [fixed, setFixed] = useState(false)
    const [range, setRange] = useState(false)
    // console.log(location?.state, "******************  location  *******************")

    // const GetPostDetail = location?.state?.data

    useEffect(() => {
        // console.log(location, "location")
        if (state?.data) {
            // setUpdateLoader(false)
            SetViewTeamMember(state.data);
            if (state?.data?.skills) {
                setViewSkills(JSON.parse(state?.data?.skills));
            }
            if (state?.data?.job_description) {
                setEditorHtml(state?.data?.job_description)
            }
        }
    }, [])



    useEffect(() => {

    }, [])
    const AddForm = useFormik({
        initialValues: {
            job_title: viewTeamMember?.job_title ? viewTeamMember?.job_title : "",
            valid_till: viewTeamMember?.valid_till ? viewTeamMember?.valid_till : "",
            skills:/*  viewTeamMember?.skills ? JSON.parse(viewTeamMember?.skills) : */ "",
            city: viewTeamMember?.city ? viewTeamMember?.city : "",
            no_of_openings: viewTeamMember?.no_of_openings ? viewTeamMember?.no_of_openings : "",
            years_of_experience: viewTeamMember?.years_of_experience ? viewTeamMember?.years_of_experience : "",
            salary_type: viewTeamMember?.salary_type ? viewTeamMember?.salary_type : "",
            fixed_annual_salary: viewTeamMember?.fixed_annual_salary ? viewTeamMember?.fixed_annual_salary : "",
            min_annual_salary: viewTeamMember?.min_annual_salary ? viewTeamMember?.min_annual_salary : "",
            max_annual_salary: viewTeamMember?.max_annual_salary ? viewTeamMember?.max_annual_salary : "",
            job_description: "",

        },
        enableReinitialize: true,
        validationSchema: yup.object({
            job_title: yup.string().required('Please Enter Job Title'),
            valid_till: yup.string().required('Please Select Date'),
            city: yup.string().required('Please Enter City'),
            no_of_openings: yup.string().required('Please Enter No. of Openings'),
            years_of_experience: yup.string().required('Please Enter Required Experience'),
            salary_type: yup.string().required('Please Select Salary Type'),
            fixed_annual_salary: fixed && yup.string().required('Please Enter Fixed Salary'),
            min_annual_salary: range && yup.string().required('Please Enter Min Salary'),
            max_annual_salary: range && yup.string().required('Please Enter Max Salary'),
            // job_description: editorHtml == "" && yup.string().required('Please Add Description')
            // skills: viewSkills.length == 0 && yup.string().required('Please Add Skill'),
        }),
        onSubmit: async (values, { resetForm }) => {
            /* const response = await postApi(`/edit-team-member/${currentCustomerID}`, */
            const data = {
                job_title: values.job_title,
                valid_till: values.valid_till,
                skills: JSON.stringify(viewSkills) != "[]" ? JSON.stringify(viewSkills) : "",
                city: values.city,
                no_of_openings: values.no_of_openings ? values.no_of_openings : null,
                years_of_experience: values.years_of_experience ? values.years_of_experience : null,
                salary_type: values.salary_type,
                fixed_annual_salary: values.fixed_annual_salary ? values.fixed_annual_salary : null,
                min_annual_salary: values.min_annual_salary ? values.min_annual_salary : null,
                max_annual_salary: values.max_annual_salary ? values.max_annual_salary : null,
                job_description: editorHtml
            }

            const response = state?.from == "EditJobs" ? await postApi(`/edit-job-post/${state?.data?.id}`, { ...data }) : await postApi(`/add-job-post`, { ...data });
            if (response.status == true) {
                toast.success(response.message);
                resetForm({ values: '' });
                Navigate('/employer/manage_jobs')
            } else {
                console.log('inside abhi', response);
                toast.error(response.message);
            }
        }
    }
    );


    const addSkill = () => {
        const newSkill = AddForm.values.skills;
        setViewSkills(prevSkills => [...prevSkills, newSkill]);
        AddForm.setFieldValue('skills', "");
    }

    const removeName = (index) => {
        const newNames = [...viewSkills];
        newNames.splice(index, 1);
        setViewSkills(newNames);
    };



    const handleSalaryRangeChange = (event) => {
        if (event.target.value === "fixed") {
            AddForm.setFieldValue('min_annual_salary', "")
            AddForm.setFieldValue('max_annual_salary', "")
            setFixed(true)
            setRange(false)
        } else if (event.target.value === "range") {
            AddForm.setFieldValue('fixed_annual_salary', "")
            setFixed(false)
            setRange(true)
        } else if (event.target.value == "") {
            setFixed(false)
            setRange(false)
        }
    }

    const currencies = [
        { code: 'USD', name: 'US Dollar' },
        { code: 'INR', name: 'Indian Rupee' },
        { code: 'AUSD', name: 'Australian Dollar' },
        { code: 'NZD', name: 'New Zealand Dollar' },
        { code: 'FJD', name: 'FIJI Dollar' },
    ];



    return (
        <>
            <div className="main-container container-fluid px-0">
                <div className="page-header">
                    <div className="page-leftheader">
                        <h4 className="page-title mb-0 text-primary">
                            <Breadcrumbs order={{ first: { name: "Manage Jobs", path: '/employer/manage_jobs' }, second: { name: state?.from == "EditJobs" ? "Edit Jobs" : "Add Jobs" } }} />
                        </h4>
                    </div>
                    <div className="page-rightheader">
                        <div className="btn-list">
                        </div>
                    </div>
                </div>

                <form onSubmit={AddForm.handleSubmit}>
                    <div className='row'>
                        <div className="col-md-3">
                            <label className="col-form-label">Job Title{/* {AddForm.touched.job_title && !AddForm.errors.job_title && <i className="fa-solid fa-circle-check"></i>} */}</label>
                            <input type="text" className="form-control" placeholder="Enter Job Title" {...AddForm.getFieldProps("job_title")} />
                            {AddForm.touched.job_title && AddForm.errors.job_title ?
                                <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.job_title}</div> : ''}
                        </div>
                        <div className="col-md-3">
                            <label className="col-form-label">Valid Till Date {/* {AddForm.touched.valid_till && !AddForm.errors.valid_till && <i className="fa-solid fa-circle-check"></i>} */}</label>
                            <input type="date" className="form-control" placeholder="Select Date" {...AddForm.getFieldProps("valid_till")} />
                            {AddForm.touched.valid_till && AddForm.errors.valid_till ?
                                <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.valid_till}</div> : ''}
                        </div>
                        <div className="col-md-3">
                            <label className="col-form-label">Location </label>
                            <input type="text" className="form-control" placeholder="Enter Location" {...AddForm.getFieldProps("city")} />
                            {AddForm.touched.city && AddForm.errors.city ?
                                <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.city}</div> : ''}
                        </div>
                        <div className="col-md-3">
                            <label className="col-form-label">No. of Openings </label>
                            <input type="number" className="form-control" placeholder="Enter No. of openings" {...AddForm.getFieldProps("no_of_openings")} />
                            {AddForm.touched.no_of_openings && AddForm.errors.no_of_openings ?
                                <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.no_of_openings}</div> : ''}
                        </div>
                        <div className=" col-md-3 mt-2">
                            <label className="col-form-label">Currency Type:</label>
                            <select className="form-control" {...AddForm.getFieldProps('planCurrency')}>
                                <option value=''>Please Select Currencies </option>
                                {currencies.map(currency => (
                                    <option key={currency.code} value={currency.code}>
                                        {/* {currency.code} - */} {currency.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="col-md-3 mt-2">
                            <label className="col-form-label">Salary Type</label>
                            <select className='form-control'
                                // {...AddForm.getFieldProps('salary_type')}
                                onChange={(e) => { handleSalaryRangeChange(e); AddForm.setFieldValue('salary_type', e.target.value) }}
                            >
                                <option value="">-- Select Type --</option>
                                <option value="fixed"> Fixed </option>
                                <option value="range">Range</option>
                            </select>
                            {AddForm.touched.salary_type && AddForm.errors.salary_type ?
                                <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.salary_type}</div> : ''}
                        </div>
                        {fixed && <div className="col-md-3 mt-2">
                            <label className="col-form-label">Salary <small className='text-danger fs-9 fw-bold'>(* Per Annum)</small></label>
                            <input type="number" className="form-control" placeholder="Enter Salary Amount" {...AddForm.getFieldProps("fixed_annual_salary")} />
                            {AddForm.touched.fixed_annual_salary && AddForm.errors.fixed_annual_salary ?
                                <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.fixed_annual_salary}</div> : ''}
                        </div>}
                        {range &&
                            <><div className="col-md-3 mt-2">
                                <label className="col-form-label">Min Salary <small className='text-danger fs-9 fw-bold'>(* Per Annum)</small></label>
                                <input type="number" className="form-control" placeholder="Enter Salary Amount" {...AddForm.getFieldProps("min_annual_salary")} />
                                {AddForm.touched.min_annual_salary && AddForm.errors.min_annual_salary ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.min_annual_salary}</div> : ''}
                            </div>
                                <div className="col-md-3 mt-2">
                                    <label className="col-form-label">Max Salary <small className='text-danger fs-9 fw-bold'>(* Per Annum)</small></label>
                                    <input type="number" className="form-control" placeholder="Enter Salary Amount" {...AddForm.getFieldProps("max_annual_salary")} />
                                    {AddForm.touched.max_annual_salary && AddForm.errors.max_annual_salary ?
                                        <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.max_annual_salary}</div> : ''}
                                </div></>}

                        {/* <ReactQuill theme="snow" value={value} onChange={setValue} /> */}
                    </div>
                    <div className='row'>
                        <div className="col-md-3 mt-2">
                            <label className="col-form-label">Experience <small className='text-danger fs-9 fw-bold'>(* in years)</small></label>
                            <input type="number" className="form-control" placeholder="Enter No. of openings" {...AddForm.getFieldProps("years_of_experience")} />
                            {AddForm.touched.years_of_experience && AddForm.errors.years_of_experience ?
                                <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.years_of_experience}</div> : ''}
                        </div>
                        <div className="col-md-9 mt-2">
                            <label className="col-form-label">Skills {/* {AddForm.touched.skills && !AddForm.errors.skills && <i className="fa-solid fa-circle-check"></i>} */}</label>
                            <div style={{ position: 'relative' }}>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Add Skill"
                                    // value={inputNames}
                                    // onChange={(e) => setInputNames([e.target.value])}
                                    {...AddForm.getFieldProps('skills')}
                                    style={{ paddingRight: '30px' }} // Add padding for the icon
                                />
                                {AddForm.values.skills.replace(/\s/g, "").length > 0 && <i
                                    className="fas fa-plus text-primary "
                                    style={{ position: 'absolute', top: '50%', right: '10px', transform: 'translateY(-50%)', cursor: 'pointer' }}
                                    onClick={() => addSkill()}
                                // onKeyDown={(e) => {addSkill();}}
                                />}
                                {AddForm.touched.skills && AddForm.errors.skills ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.skills}</div> : ''}
                            </div>
                            {viewSkills != null && viewSkills.length > 0 && viewSkills.map((item, index) => {
                                return (
                                    <span
                                        key={index}
                                        className="m-1 px-1 py-1 rounded border text-white text-wrap"
                                        style={{ cursor: 'pointer', fontSize: '12px', backgroundColor: '#0F2F81' }}
                                    >
                                        {item} <span onClick={() => removeName(index)}>&times;</span>
                                    </span>
                                );
                            })}

                        </div></div>
                    <div className="col-md-12 mt-2">
                        <label className="col-form-label">Description</label>
                        <Editor placeholder={'Write something here...'} setEditorHtml={setEditorHtml} editorHtml={editorHtml} />
                        {AddForm.touched.job_description && AddForm.errors.job_description ?
                            <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.job_description}</div> : ''}

                    </div>

                    <button type="submit" className="btn btn-primary w-15 m-3">{state?.from == "EditJobs" ? "Edit Jobs" : "Add Jobs"} </button>
                </form>
            </div>
        </>
    )
}

export default AddJobs;