import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import toast, { Toaster } from 'react-hot-toast';
import { GetCountry, postApi } from '../Service/jobService';

const Login = () => {
    const urlR1 = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
    const [country, setCountry] = useState([]);
    const [otpVerify, setOtpVerify] = useState(0)
    const [minutes, setMinutes] = useState(2);
    const [seconds, setSeconds] = useState(0);
    const [isRunning, setIsRunning] = useState(false);
    const [selected, setSelected] = useState('Job Seeker');
    const [count, setCount] = useState(0)
    const [resendOTP, setResendOTP] = useState(false)
    const [handleButton, setHandleButton] = useState(false)
    const [values, setValues] = React.useState({
        password: "",
        showPassword: false,
    });
    const [valuesConfP, setValuesConfP] = React.useState({
        password: "",
        showConfPassword: false,
    });
    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };
    const handleClickShowConfPassword = () => {
        setValuesConfP({ ...valuesConfP, showConfPassword: !valuesConfP.showConfPassword });
    };
    const navigate = useNavigate();
    const eyeBtnStyle = {
        position: 'absolute',
        marginLeft: '0px',
        marginTop: '0px',
        marginRight: '10px'
    }

    const eyeBtnStyle1 = {
        position: 'absolute',
        marginLeft: '245px',
        marginTop: '17px',
    }


    const getCountry = async () => {
        const response = await GetCountry();
        if (response.status == true) {
            setCountry(response.data);
        } else {
            console.log("get Country data response", response);
        }
    }

    useEffect(() => {
        getCountry();
    }, [])


    const getValidationSchema = (selected) => {
        return yup.object({
            email: yup.string().required('Please Enter Email').matches(urlR1, 'Please Enter Valid Email Url'),
        });
    };

    const form = useFormik({
        initialValues: {
            user_type: '',
            email: '',
        },
        enableReinitialize: true,
        validateOnBlur: false,
        validationSchema: getValidationSchema(selected),
        onSubmit: async (values) => {
            setHandleButton(true)
            const data1 = {
                email: values.email
            };
            const endpoint = selected === "Job Seeker" ? "/register" : "/employer/register";
            const response = await postApi(endpoint, data1);
            if (response.status === true) {
                toast.success(response.message);
                if (response.isExist == false) {
                    setOtpVerify(2)
                    setResendOTP(false)
                    setHandleButton(false)
                    setIsRunning(true);
                    setMinutes(2)
                    setSeconds(0)
                } else if (response.isExist == true) {
                    // setHandleButton(false)
                    setOtpVerify(1)
                }
            } else {
                toast.error(response.message);
                setHandleButton(false)
                console.log(response);
            }
        },

    });


    const formlogin = useFormik({
        initialValues: {
            password: "",
            email: '',
        },
        enableReinitialize: true,
        validationSchema: yup.object({
            password: yup.string().required('Please Enter Your Password '),

        }),
        onSubmit: async (values) => {
            const data = {
                email: form.values.email,
                password: values.password
            }
            const endpoint = selected === 'Job Seeker' ? "/login" : "/employer/login";

            const response = await postApi(endpoint, data);
            if (response.status === true) {
                toast.success(response.message);
                let jobs = {
                    user_type: response.data.user_type,
                    jobs_token: response.data.token,
                    user_id: response.data.user_id,
                    full_name: response.data.full_name,
                    mobile_number: response.data.mobile_number,
                    email: response.data.email,
                    logo: response.data?.logo
                }
                localStorage.setItem('jobs', JSON.stringify(jobs));
                navigate(process.env.REACT_APP_JOBS_PRIFIX + (response.data.user_type == "JOBSEEKER" ? '/dashboard' : '/employer/dashboard'));
            } else {
                console.log(response);
                toast.error(response.message);
            }
        },

    });

    const handleCheckboxChange = (event) => {
        console.log(event.target.value)
        const { value } = event.target;
        setSelected(value);
        form.setTouched({})
        form.setErrors({})
        // form.setFieldValue('user_type', value)
        form.validateForm();
    };


    const otpform = useFormik({
        initialValues: { otp1: '', otp2: '', otp3: '', otp4: '', otp5: '', otp6: '' },
        enableReinitialize: true,
        validationSchema: yup.object({
            otp1: yup.string().required('Please Enter Six digit OTP ')
                .test('maxDigits', 'The value must be equal to 1 digits', (value) => value?.toString().length <= 1),
            otp2: yup.string().when('otp1', {
                is: (value) => value !== '',
                then: yup.string().required('Please Enter Six digit OTP ')
                    .test('maxDigits', 'The value must be equal to 1 digits', (value) => value?.toString().length <= 1),
            }),
            otp3: yup.string().when('otp2', {
                is: (value) => value !== '',
                then: yup.string().required('Please Enter Six digit OTP ')
                    .test('maxDigits', 'The value must be equal to 1 digits', (value) => value?.toString().length <= 1),
            }),
            otp4: yup.string().when('otp3', {
                is: (value) => value !== '',
                then: yup.string().required('Please Enter Six digit OTP ')
                    .test('maxDigits', 'The value must be equal to 1 digits', (value) => value?.toString().length <= 1),
            }),
            otp5: yup.string().when('otp4', {
                is: (value) => value !== '',
                then: yup.string().required('Please Enter Six digit OTP ')
                    .test('maxDigits', 'The value must be equal to 1 digits', (value) => value?.toString().length <= 1),
            }),
            otp6: yup.string().when('otp5', {
                is: (value) => value !== '',
                then: yup.string().required('Please Enter Six digit OTP ')
                    .test('maxDigits', 'The value must be equal to 1 digits', (value) => value?.toString().length <= 1),
            }),
        }),
        onSubmit: async (values) => {
            setHandleButton(true)
            const data = {
                otp: `${values.otp1}${values.otp2}${values.otp3}${values.otp4}${values.otp5}${values.otp6}`,
                email: form.values.email
            }
            const endpoint = selected === 'Job Seeker' ? "/verify-otp" : "/employer/verify-otp"
            const response = await postApi(endpoint, data);
            if (response.status === true) {
                toast.success(response.message);
                setOtpVerify(3)
                setHandleButton(false)
            } else {
                setResendOTP(false)
                setHandleButton(false)
                otpform.setTouched({})
                otpform.setErrors({})
                otpform.validateForm();
                otpform.setFieldValue("otp1", "")
                otpform.setFieldValue("otp2", "")
                otpform.setFieldValue("otp3", "")
                otpform.setFieldValue("otp4", "")
                otpform.setFieldValue("otp5", "")
                otpform.setFieldValue("otp6", "")
                toast.error(response.message);
                setHandleButton(false)
            }
        },
    })

    useEffect(() => {
        let intervalId;
        if (isRunning) {
            intervalId = setInterval(() => {
                if (seconds > 0) {
                    setSeconds(seconds - 1);
                } else if (minutes > 0) {
                    setMinutes(minutes - 1);
                    setSeconds(59);
                } else {
                    setIsRunning(false);
                    // setOtpVerify(0)
                    setResendOTP(true)
                    setHandleButton(false)

                }
            }, 1000);
        }
        return () => clearInterval(intervalId);
    }, [isRunning, minutes, seconds]);

    const formatTime = () => {
        const formattedMinutes = minutes.toString().padStart(2, '0');
        const formattedSeconds = seconds.toString().padStart(2, '0');
        return `${formattedMinutes}:${formattedSeconds}`;
    };

    const registerForm = useFormik({
        initialValues: {
            full_name: "",
            mobile_number: "",
            email: '',
            country: "",
            password: "",
            confirmedPassword: ""
        },
        enableReinitialize: true,
        validationSchema: yup.object({
            full_name: yup.string().required('Please Enter Full Name'),
            mobile_number: yup.string().required('Please Enter Mobile Number').min(7, "Mobile Number Must Contain 7 Number").max(15, "Mobile Number Max Contain 15 Number"),
            // email: selected === "Job Seeker" ? yup.string().required('Please Enter Email').matches(urlR1, 'Please Enter Valid Email Url') : yup.string(),
            country: yup.string().required('Please Select Country'),
            password: yup.string().required('Please Enter Password'),
            confirmedPassword: yup.string().required('Please Enter Confirm Password').oneOf([yup.ref("password"), null], "Password Must Be Same"),
        }),

        onSubmit: async (values) => {
            const data = {
                full_name: values.full_name,
                mobile_number: values.mobile_number,
                email: form.values.email,
                // country_id: selectedCountryData?.id,
                password: values.password,
                country_id: values.country
            }
            const endpoint = selected === 'Job Seeker' ? "/create-account" : "/employer/create-account";
            const response = await postApi(endpoint, data);
            if (response.status === true) {
                toast.success(response.message);
                let jobs = {
                    user_type: response.data?.user_type,
                    jobs_token: response.data?.token,
                    user_id: response.data?.user_id,
                    full_name: response.data?.full_name,
                    mobile_number: response.data?.mobile_number,
                    email: response.data?.email,
                    logo: response.data?.logo
                }
                localStorage.setItem('jobs', JSON.stringify(jobs));
                navigate(process.env.REACT_APP_JOBS_PRIFIX + (response.data.user_type == "JOBSEEKER" ? '/dashboard' : '/employer/dashboard'));

            } else {
                console.log(response);
                toast.error(response.message);
            }

        },
    });

    return (
        <>
            <Toaster position="top-right" reverseOrder={false} />
            <div className="page">
                <div className="page-single bg-back" /*  */ /* style={{ backgroundColor: '#eae6e6' }} */>
                    <div className="container">
                        <div className="row">
                            <div className="col mx-auto">
                                {/* <div className="row justify-content-center">
                                    <div className="col-xl-9 col-lg-12">
                                        <div className="mb-2 br-1 text-center"><a href="">
                                            <img src={process.env.PUBLIC_URL + "/assets/img/logo-thirdroc.png"} className="header-brand-img loginlogo" alt="logo" />
                                        </a></div>
                                    </div>
                                </div> */}
                                <div className="row justify-content-center mb-2">
                                    <div className="col-xl-11 col-lg-12">
                                        <div className="row p-0 m-0 bg-white br-3">
                                            <div className="col-lg-5 p-0">
                                                <div className="text-justified text-white  register-2 overflow-hidden" style={{ padding: '1rem 3rem 4rem 3rem' }}>
                                                    <div className="custom-content">
                                                        <div className="mb-2 br-1">
                                                            <img src={process.env.PUBLIC_URL + "/assets/img/logo-thirdroc1.png"} className="header-brand-img loginlogo" alt="logo" />
                                                        </div>

                                                        <div className="fs-22 mb-4 font-weight-bold text-white"> Start Your Remarkable Journey With Us ! </div>
                                                        {/* <div className="mb-2 text-dark-50 d-flex"><i className="fa-solid fa-circle-dot fa-fw me-3 postop"></i> Start your remarkable journey with us.</div> */}
                                                        {/* <h6>Personal Loans</h6>
                              <div className="mb-2 text-white-50 d-flex"><i className="fa-solid fa-circle-dot fa-fw me-3 postop"></i> No Collateral/Security Required. Personal loans don't need you to provide any collateral such as a house or car to avail a personal loan.</div>
                              <div className="mb-2 text-white-50 d-flex"><i className="fa-solid fa-circle-dot fa-fw me-3 postop"></i> Flexible End Use.</div>
                              <div className="mb-2 text-white-50 d-flex"><i className="fa-solid fa-circle-dot fa-fw me-3 postop"></i> Flexible Tenure.</div>
                              <div className="mb-2 text-white-50 d-flex"><i className="fa-solid fa-circle-dot fa-fw me-3 postop"></i> Minimal Documentation.</div>
                              <div className="mb-2 text-white-50 d-flex"><i className="fa-solid fa-circle-dot fa-fw me-3 postop"></i> Quick Disbursal.</div>
                              <div className="mb-2 text-white-50 d-flex"><i className="fa-solid fa-circle-dot fa-fw me-3 postop"></i> Flexible Loan Amount.</div>

                              <h6 className="mt-5">Business Loans</h6>
                              <div className="mb-2 text-white-50 d-flex"><i className="fa-solid fa-circle-dot fa-fw me-3 postop"></i> Unsecured Business Loans/ No Collateral Required</div>
                              <div className="mb-2 text-white-50 d-flex"><i className="fa-solid fa-circle-dot fa-fw me-3 postop"></i> Minimum KYC</div>
                              <div className="mb-2 text-white-50 d-flex"><i className="fa-solid fa-circle-dot fa-fw me-3 postop"></i> Furnishes your Working Capital Requirement, Manage operational cost, growth & expansion of Business</div> */}
                                                        <div className="mb-2 br-1 text-center">
                                                            <img src="/assets/img/slider5.png" className="" alt="logo" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {otpVerify == 0 &&
                                                <div className="col-md-8 col-lg-7 p-0 mx-auto">
                                                    <div className="bg-white text-dark br-7 br-tl-0 br-bl-0">
                                                        <div className="form-group mt-4 d-flex justify-content-between me-3">
                                                            <div className="text-center fs-22" style={{ marginLeft: '12rem' }}>Login As {selected == "Job Seeker" ? "Job Seeker" : "Company"}</div>
                                                            {/* <Link to={"/jobs/login"} className="font-weight-bold fs-6 mt-1"> Login</Link> */}
                                                        </div>

                                                        <div className="card-body">
                                                            <form className="row lendersignup" id='registrationForm' onSubmit={form.handleSubmit}>
                                                                <div className="row ms-3 mb-3 d-flex justify-content-around">
                                                                    <div className="col-lg-6 ">
                                                                        <label className="form-check-label fs-14 text-muted">You're Creating account as a:</label><br />
                                                                        <input
                                                                            className=""
                                                                            type="checkbox"
                                                                            value="Job Seeker"
                                                                            checked={selected === 'Job Seeker'}
                                                                            onChange={handleCheckboxChange}
                                                                        />
                                                                        <label className="form-check-label ms-3 fs-16">Job Seeker</label>
                                                                        <input
                                                                            className="ms-3"
                                                                            type="checkbox"
                                                                            value="Company"
                                                                            checked={selected === 'Company'}
                                                                            onChange={handleCheckboxChange}
                                                                        />
                                                                        <label className="form-check-label ms-3 fs-16">Company</label>
                                                                    </div>
                                                                </div>
                                                                {/* {selected == "Job Seeker" && <div className="input-group mb-4 col-lg-6">
                                                                    <div className="input-group-text">
                                                                        <i className="fa-solid fa-phone fa-fw" /> </div>
                                                                    <input type="number" name="mobile_number" {...form.getFieldProps("mobile_number")} className="form-control" placeholder="Please Enter Mobile Number" />
                                                                    {form.touched.mobile_number && form.errors.mobile_number ?
                                                                        <div className="invalid-feedback mt-2" style={{ display: "block" }}>{form.errors.mobile_number}</div> : ''}

                                                                </div>} */}
                                                                <div className="input-group mb-4 col-lg-6 w-60" style={{ marginLeft: '8rem' }}>
                                                                    <div className="input-group-text">
                                                                        <i className="fas fa-mail-bulk fa-fw" /></div>
                                                                    <input className="form-control" type="text" name="email" {...form.getFieldProps("email")} placeholder="Please Enter Email ID" id="password-field" />
                                                                    {form.touched.email && form.errors.email ?
                                                                        <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.email}</div> : ''}
                                                                </div>

                                                                <div type="submit" className="form-group text-center mb-3 mt-3">
                                                                    <button type='submit' className="btn btn-primary btn-lg w-50 br-7"
                                                                        disabled={handleButton} >Login</button>
                                                                </div>
                                                            </form>
                                                            <span className='text-muted'>By creating an account, you agree to the  <span className='text-primary'>Terms of Service.</span>  We'll occasionally send you account-related emails</span>
                                                        </div>
                                                    </div>
                                                </div>}
                                            {otpVerify == 1 &&
                                                <div className="col-md-8 col-lg-7 p-0 mx-auto">
                                                    <div className="bg-white text-dark br-7 br-tl-0 br-bl-0">
                                                        <div className="form-group mt-4 d-flex justify-content-between me-3">
                                                            <div className="text-center fs-22" style={{ marginLeft: '12rem' }}>Login As {selected == "Job Seeker" ? "Job Seeker" : "Company"}</div>
                                                            {/* <Link to={"/jobs/login"} className="font-weight-bold fs-6 mt-1"> Login</Link> */}
                                                        </div>

                                                        <div className="card-body">
                                                            <form className="row lendersignup" id='registrationForm' onSubmit={formlogin.handleSubmit}>
                                                                <div className="row ms-3 mb-3 d-flex justify-content-around">
                                                                    <div className="col-lg-6 ">
                                                                        <label className="form-check-label fs-14 text-muted">You're Creating account as a:</label><br />
                                                                        <input
                                                                            className=""
                                                                            type="checkbox"
                                                                            value="Job Seeker"
                                                                            checked={selected === 'Job Seeker'}
                                                                        // onChange={handleCheckboxChange}
                                                                        />
                                                                        <label className="form-check-label ms-3 fs-16">Job Seeker</label>
                                                                        <input
                                                                            className="ms-3"
                                                                            type="checkbox"
                                                                            value="Company"
                                                                            checked={selected === 'Company'}
                                                                        // onChange={handleCheckboxChange}
                                                                        />
                                                                        <label className="form-check-label ms-3 fs-16">Company</label>
                                                                    </div>
                                                                </div>
                                                                <div className="input-group mb-4 col-lg-6 w-60" style={{ marginLeft: '8rem' }}>
                                                                    <div className="input-group-text">
                                                                        <i className="fas fa-mail-bulk fa-fw" /></div>
                                                                    <input className="form-control" type="text" name="email" value={form.values.email} placeholder="Please Enter Email ID" id="password-field" />
                                                                    {/* {form.touched.email && form.errors.email ?
                                                                            <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.email}</div> : ''} */}
                                                                </div>


                                                                <div className="input-group mb-4 col-lg-6 w-60" style={{ marginLeft: '8rem' }}>
                                                                    <div className="input-group-text"> <i className="fa-solid fa-lock fa-fw" aria-hidden="true" /> </div>
                                                                    <input className="form-control" name="password" {...formlogin.getFieldProps("password")} type={values.showPassword ? "text" : "password"} placeholder="Please enter password" id="password-field" autoComplete="off" />
                                                                    {!values.showPassword ?
                                                                        <span toggle="#password-field" onClick={handleClickShowPassword} style={eyeBtnStyle} className="fa fa-fw fa-eye field-icon toggle-password hideeyes"></span>
                                                                        :
                                                                        <span toggle="#password-field" onClick={handleClickShowPassword} style={eyeBtnStyle1} className="fa fa-fw fa-eye-slash field-icon toggle-password hideeyes1"></span>

                                                                    }
                                                                    {formlogin.touched.password && formlogin.errors.password ?
                                                                        <div className="invalid-feedback" style={{ display: "block" }}>{formlogin.errors.password}</div> : ''}
                                                                </div>


                                                                <div type="submit" className="form-group text-center mb-3 mt-3">
                                                                    <button type='submit' className="btn btn-primary btn-lg w-50 br-7"
                                                                    >Login</button>
                                                                </div>
                                                            </form>
                                                            <span className='text-muted'>By creating an account, you agree to the  <span className='text-primary'>Terms of Service.</span>  We'll occasionally send you account-related emails</span>
                                                        </div>
                                                    </div>
                                                </div>}
                                            {otpVerify == 2 &&
                                                <div className="col-md-8 col-lg-7 p-0 mx-auto">
                                                    <div className="bg-white text-dark br-7 br-tl-0 br-bl-0">
                                                        <div className="form-group mt-4 d-flex justify-content-center">
                                                            <div className="text-center fs-20" style={{ marginLeft: '2rem' }}>
                                                                <i className="fas fa-lock fa-fw" /> Great! Almost Done

                                                            </div>
                                                        </div>
                                                        <div className="card-body">
                                                            <form className="row lendersignup" id='registrationForm' onSubmit={otpform.handleSubmit}>
                                                                <div className="text-center mb-4">
                                                                    {selected === "Job Seeker" ? <span> <i className="fas fa-mobile-alt fa-fw" /> Enter the 6-digit code sent to your phone number</span> :
                                                                        <span> <i class="fa-solid fa-envelope"></i> Enter the 6-digit code sent to your Email ID</span>
                                                                    }
                                                                </div>
                                                                <div className="d-flex justify-content-center">
                                                                    <input type="number" className="form-control col-md-1 mb-4"
                                                                        {...otpform.getFieldProps("otp1")}
                                                                        onInput={e => {
                                                                            if (e.target.value === '' || /^\d{0,1}$/.test(e.target.value)) {
                                                                                otpform.setFieldValue("otp1", e.target.value);
                                                                                if (e.target.value.length === 1) {
                                                                                    document.querySelector('input[name="otp2"]').focus();
                                                                                }
                                                                            }
                                                                        }}
                                                                    />
                                                                    <input type="number" name="otp2" {...otpform.getFieldProps("otp2")} className="form-control col-md-1 mb-4 ms-2"
                                                                        onInput={e => {
                                                                            if (e.target.value === '' || /^\d{0,1}$/.test(e.target.value)) {
                                                                                otpform.setFieldValue("otp2", e.target.value);
                                                                                if (e.target.value.length === 1) {
                                                                                    document.querySelector('input[name="otp3"]').focus();
                                                                                }
                                                                            }
                                                                        }}
                                                                    />
                                                                    <input type="number" name="otp3" {...otpform.getFieldProps("otp3")} className="form-control col-md-1 mb-4 ms-2"
                                                                        onInput={e => {
                                                                            if (e.target.value === '' || /^\d{0,1}$/.test(e.target.value)) {
                                                                                otpform.setFieldValue("otp3", e.target.value);
                                                                                if (e.target.value.length === 1) {
                                                                                    document.querySelector('input[name="otp4"]').focus();
                                                                                }
                                                                            }
                                                                        }}
                                                                    />
                                                                    <input type="number" name="otp4" {...otpform.getFieldProps("otp4")} className="form-control col-md-1 mb-4 ms-2"
                                                                        onInput={e => {
                                                                            if (e.target.value === '' || /^\d{0,1}$/.test(e.target.value)) {
                                                                                otpform.setFieldValue("otp4", e.target.value);
                                                                                if (e.target.value.length === 1) { document.querySelector('input[name="otp5"]').focus(); }
                                                                            }
                                                                        }}
                                                                    />
                                                                    <input type="number" name="otp5" {...otpform.getFieldProps("otp5")} className="form-control col-md-1 mb-4 ms-2"
                                                                        onInput={e => {
                                                                            if (e.target.value === '' || /^\d{0,1}$/.test(e.target.value)) {
                                                                                otpform.setFieldValue("otp5", e.target.value);
                                                                                if (e.target.value.length === 1) {
                                                                                    document.querySelector('input[name="otp6"]').focus();
                                                                                }
                                                                            }
                                                                        }}
                                                                    />
                                                                    <input type="number" name="otp6" {...otpform.getFieldProps("otp6")} className="form-control col-md-1 mb-4 ms-2"
                                                                        onChange={e => {
                                                                            if (e.target.value === '' || /^\d{0,1}$/.test(e.target.value)) {
                                                                                otpform.setFieldValue("otp6", e.target.value);
                                                                            }
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div style={{ marginLeft: '195px' }}>
                                                                    {Object.values(otpform.errors).some((error) => error) && (
                                                                        <div className="invalid-feedback" style={{ display: "block" }}>
                                                                            Please Enter Six digit OTP
                                                                        </div>
                                                                    )}
                                                                </div>
                                                                <div className='text-center'>
                                                                    {isRunning && <b>Time remaining: {formatTime()}</b>}
                                                                </div>
                                                                <div type="submit" className="form-group text-center mb-3 mt-3">
                                                                    {!resendOTP && <button type='submit' className="btn btn-primary btn-lg w-60 br-7" disabled={handleButton}>
                                                                        <i className="fas fa-check fa-fw" /> Verify
                                                                    </button>}
                                                                    {resendOTP && count == 3 && <button type='submit' className="btn btn-dark btn-lg w-60 br-7" disabled>Limit Exceeded</button>}
                                                                    {resendOTP && count <= 2 && <button className='btn btn-primary'
                                                                        disabled={handleButton}
                                                                        onClick={() => { form.handleSubmit(); setCount(count + 1) }}>Resend OTP</button>}
                                                                </div>
                                                                {/* <div type="submit" className="form-group text-center mb-3 mt-3"> <button type='submit' className="btn btn-primary btn-lg w-100 br-7">Verify</button> </div> */}
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>}

                                            {otpVerify == 3 &&
                                                <div className="col-md-8 col-lg-7 p-0 mx-auto">
                                                    <div className="bg-white text-dark br-7 br-tl-0 br-bl-0">
                                                        <div className="form-group mt-4 d-flex justify-content-between me-3">
                                                            <div className="text-center fs-22" style={{ marginLeft: '12rem' }}>Register As {selected == "Job Seeker" ? "Job Seeker" : "Company"}</div>
                                                            {/* <Link to={"/jobs/login"} className="font-weight-bold fs-6 mt-1"> Login</Link> */}
                                                        </div>

                                                        <div className="card-body">
                                                            <form className="row lendersignup" id='registrationForm' onSubmit={registerForm.handleSubmit}>
                                                                <div className="input-group mb-4 col-lg-6">
                                                                    <div className="input-group-text">
                                                                        <i className="fa-solid fa-address-card fa-fw" /> </div>
                                                                    <input type="text" name="full_name" {...registerForm.getFieldProps("full_name")} className="form-control" placeholder="Enter Full Name" />
                                                                    {registerForm.touched.full_name && registerForm.errors.full_name ?
                                                                        <div className="invalid-feedback" style={{ display: "block" }}>{registerForm.errors.full_name}</div> : ''}
                                                                </div>
                                                                <div className="input-group mb-4 col-lg-6">
                                                                    <div className="input-group-text">
                                                                        <i className="fa-solid fa-phone fa-fw" /> </div>
                                                                    <input type="number" name="mobile_number"
                                                                        {...registerForm.getFieldProps("mobile_number")}
                                                                        className="form-control" placeholder="Please Enter Mobile Number" />
                                                                    {registerForm.touched.mobile_number && registerForm.errors.mobile_number ?
                                                                        <div className="invalid-feedback ms-6" style={{ display: "block" }}>{registerForm.errors.mobile_number}</div> : ''}

                                                                </div>
                                                                <div className="input-group mb-4 col-lg-6">
                                                                    <div className="input-group-text">
                                                                        <i className="fas fa-mail-bulk fa-fw" /></div>
                                                                    <input className="form-control"
                                                                        value={form.values.email} placeholder="Email ID" id="password-field" />
                                                                    {registerForm.touched.email && registerForm.errors.email ?
                                                                        <div className="invalid-feedback" style={{ display: "block" }}>{registerForm.errors.email}</div> : ''}
                                                                </div>
                                                                <div className="input-group mb-4 col-lg-6">
                                                                    <div className="input-group-text">
                                                                        <i className="fa-solid fa-earth-americas"></i> </div>
                                                                    <select className="form-control" name="country"
                                                                        {...registerForm.getFieldProps("country")}>
                                                                        <option value="">Select Country</option>
                                                                        {country.map(item => (<option key={item.id} value={item.id}>{item.name}</option>))}

                                                                    </select>
                                                                    {registerForm.touched.country && registerForm.errors.country ?
                                                                        <div className="invalid-feedback" style={{ display: "block" }}>{registerForm.errors.country}</div> : ''}
                                                                </div>
                                                                <div className="input-group mb-4 col-lg-6">
                                                                    <span className="input-group-text"> <i className="fa-solid fa-lock fa-fw" aria-hidden="true"></i> </span>
                                                                    <input className="form-control" {...registerForm.getFieldProps("password")} type={values.showPassword ? "text" : "password"} name="password" placeholder="Password" id="password-field" />
                                                                    {!values.showPassword ?
                                                                        <span toggle="#password-field" onClick={handleClickShowPassword} style={eyeBtnStyle} className="fa fa-fw fa-eye field-icon toggle-password hideeyes"></span>
                                                                        :
                                                                        <span toggle="#password-field" onClick={handleClickShowPassword} style={eyeBtnStyle1} className="fa fa-fw fa-eye-slash field-icon toggle-password hideeyes1"></span>

                                                                    }
                                                                    {registerForm.touched.password && registerForm.errors.password ?
                                                                        <div className="invalid-feedback" style={{ display: "block" }}>{registerForm.errors.password}</div> : ''}
                                                                </div>
                                                                <div className="input-group mb-4 col-lg-6">
                                                                    <span className="input-group-text"> <i className="fa-solid fa-lock fa-fw" aria-hidden="true"></i> </span>
                                                                    <input className="form-control" {...registerForm.getFieldProps("confirmedPassword")} type={valuesConfP.showConfPassword ? "text" : "password"} name="confirmedPassword" placeholder="Confirm Password" id="password-field" />
                                                                    {registerForm.touched.confirmedPassword && registerForm.errors.confirmedPassword ?
                                                                        <div className="invalid-feedback" style={{ display: "block" }}>{registerForm.errors.confirmedPassword}</div> : ''}
                                                                    {!valuesConfP.showConfPassword ?
                                                                        <span toggle="#password-field" onClick={handleClickShowConfPassword} style={eyeBtnStyle} className="fa fa-fw fa-eye field-icon toggle-password hideeyes"></span>
                                                                        :
                                                                        <span toggle="#password-field" onClick={handleClickShowConfPassword} style={eyeBtnStyle1} className="fa fa-fw fa-eye-slash field-icon toggle-password hideeyes1"></span>
                                                                    }
                                                                </div>
                                                                <div type="submit" className="form-group text-center mb-3 mt-3"> <button type='submit' className="btn btn-primary btn-lg w-50 br-7">Register</button> </div>
                                                            </form>
                                                            <span className='text-muted'>By creating an account, you agree to the  <span className='text-primary'>Terms of Service.</span>  We'll occasionally send you account-related emails</span>
                                                        </div>
                                                    </div>
                                                </div>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}




export default Login