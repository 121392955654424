import React, { useState, useEffect, useRef } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useFormik } from 'formik';
import * as yup from 'yup';
import toast from 'react-hot-toast';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { GetCountry, deleteApi, getApi, postApi } from '../../service/employService';
import { HandleDeleteJobType, HandleDeleteSubJobType, HandleDeleteSubJobTypeLevel2 } from '../../employerComponent/jobType/DeletejobType';
import { HandleDeleteJobLocation, HandleDeleteSubJobLocation } from '../../employerComponent/Location/DeleteLocation';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { setEmployerBusinessType, setEmployerSystemType } from '../../../Redux/modalerrorReducer'
// import { Department } from '../../../components/Manage department/Department';

const BussinessModal = ({ BasicDetailsUpdates, restrictModals }) => {
    const [EditSubLocationID, setEditSubLocationID] = useState('')
    const [SubLocationPremium, setSubLocationPremium] = useState('')
    const [EditSubLocation, setEditSubLocation] = useState('')
    const [addLocation, setAddLocation] = useState(true)
    const [EditJobTypeID, setEditJobTypeID] = useState('')
    const [editJobType, setEditJobType] = useState('')
    const [editLimit, setEditLimit] = useState('')
    const addlocationref = useRef()
    const editlocationref = useRef()
    const EditSublocationref = useRef()
    const showJobtyperef = useRef()
    const [name, setName] = useState([]);
    const [inputName, setInputName] = useState('');
    const [inputNames, setInputNames] = useState('');
    // const [endDate, setendDate] = useState('');
    // const [startDate, setstartDate] = useState('');
    const employer = JSON.parse(localStorage.getItem("employer"));
    const [dataArray, setDataArray] = useState([]);
    const [nextModal, setNextModal] = useState(0)
    const [data, setData] = useState([])
    const [data1, setData1] = useState([])
    const [Departmentname, setDepartmentname] = useState([])
    const [country, setCountry] = useState([]);
    const [employeeList, setEmployeeList] = useState([]);
    const [selectedEmployeeCount, setSelectedEmployeeCount] = useState([
        {
            key: '0-5',
            value: 5
        },
        {
            key: '5-10',
            value: 10
        },
        {
            key: '10-20',
            value: 20
        },
        {
            key: '20-50',
            value: 50
        },
        {
            key: '50-100',
            value: 100
        },
        {
            key: '100+',
            value: 101
        },
    ]);
    const [selectedEmployeeUseType, setSelectedEmployeeUseType] = useState([
        {
            key: 'WFM',
            value: 'WFM'
        },
        {
            key: 'PAYROLL',
            value: 'PAYROLL'
        }
    ]);
    const [restrictModal, setRestrictModal] = useState(employer?.payment_status)
    const [BasicDetailsUpdate, setBasicDetailsUpdate] = useState(employer?.basic_details_update)
    const navigate = useNavigate()
    const [viewBusiness, setViewBusiness] = useState({})
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const QueryPaymentStatus = queryParams.get('payment');
    const [sublocationevent, setSubLocationEvent] = useState('')
    const [OpenModals, setOpenModals] = useState(false)
    const [OpenSubLocationModals, setOpenSubLocationModals] = useState(false)
    const [OpenEditLocationModals, setOpenEditLocationModals] = useState(false)
    const [OpenEditJobType, setOpenEditJobType] = useState(false)
    const [LocationID, SetLocationID] = useState('')
    const [getSublocationDetail, setSublocationdetail] = useState([])
    const [show, setShow] = useState(false);
    const [showEditLocation, setShowEditLocation] = useState(false);
    const [showEditSubLocation, setShowEditSubLocation] = useState(false);
    const [showEditJobType, setShowEditJobType] = useState(false);
    const handleClose = () => { setOpenModals(!OpenModals); setShow(false) };
    const handleCloseSubLocation = () => { setOpenSubLocationModals(!OpenSubLocationModals); setShowEditSubLocation(false) };
    const handleCloseEditLocation = () => { setOpenEditLocationModals(!OpenEditLocationModals); setShowEditLocation(false) }
    const handleCloseJobType = () => { setOpenEditJobType(!OpenEditJobType); setShowEditJobType(false) }
    const handleShowEditLocation = () => setShowEditLocation(true);
    const handleShowEditJobtype = () => setShowEditJobType(true);
    const handleShow = () => setShow(true);
    const handleShowEditSubLocation = () => setShowEditSubLocation(true);
    const [subLocationValidation, setsubLocationValidation] = useState('false')
    const [locationbyID, setlocationByID] = useState('')
    const [premiumbyID, setpremiumByID] = useState('')
    const [complianceData, setComplianceData] = useState([])
    const [complianceDetail, setComplianceDetail] = useState([])
    const [restInterval, setRestInterval] = useState('')
    const [perHourWages, setPerHourWages] = useState('')
    const [subjobtypeName, setSubjobtypeName] = useState()
    const [subjobtypeLimit, setSubjobtypeLimit] = useState()
    const [L2SubjobtypeName, setL2SubjobtypeName] = useState()
    const [L2SubjobtypeLimit, setL2SubjobtypeLimit] = useState()
    const [showInput, setShowInput] = useState(false);
    const [department, setdepartment] = useState("");
    const [manageSubjobType, setManageSubjobType] = useState({});
    const [subJobTypeId, setSubJobTypeId] = useState(null);
    const [manageSubjobTypeLevel2, setManageSubjobTypeLevel2] = useState({});
    const [subJobTypeIdLevel2, setSubJobTypeIdLevel2] = useState(null);
    const [manageSubjobTypeLevel3, setManageSubjobTypeLevel3] = useState({});
    const [subJobTypeIdLevel3, setSubJobTypeIdLevel3] = useState(null);
    const [checkStatus, setCheckStatus] = useState()
    const HandleModalForSubJobTyperef = useRef()
    const HandleModalForSubJobTyperefLevel2 = useRef()
    const HandleModalForSubJobTyperefLevel3 = useRef()
    const [subJobType, setSubJobType] = useState([])
    const [subL2JobType, setSubL2JobType] = useState([])
    const dispatch = useDispatch()

    const getEmployeesData = async (token) => {
        const response = await getApi("/get-employee", { limit: '5', sortby: 'customer_id', orderby: 'desc' });
        // console.log("🚀  file: epmloyer.js:25  getEmployeesData ~ response:", response)
        try {
            if (response.status == true) {
                setEmployeeList(response.data);
            } else if (response.message === 'Session expired') {
                localStorage.removeItem("employer");
                navigate('/employer/login')
            } else if (response.message === 'Payment due') {
                setRestrictModal(false)
            } else {
                console.log("get employees data response", response);
                // toast.error(response.message)
                // if (response.message == "Access denied! you're not allowed to access this tab") {
                //     navigate('/employer/dashboard')
                // }
            }
        } catch (error) {
            console.log(error)
            if (error.message == 'Session expired') {
                localStorage.removeItem("employer");
                navigate('/employer/login')
            }
        }
    }


    const updatetoken = async () => {

        const response = await getApi("/update-payment-status")

        if (response.status == false) {
            toast.error(response.message);
        }
        if (response.status == true) {
            if (response.status) {
                let employer = {
                    user_type: response.user_type == 'EMPLOYER' ? response.user_type.toLowerCase() : response.user_type,
                    employer_token: response.token,
                    email: response.email,
                    user_name: response.user_name,
                    is_nfc_active: response.is_nfc_active,
                    plan: response.plan,
                    payment_status: response.payment_status,
                    basic_details_update: response.basic_details_update,
                    employer_system_type: response.employer_system_type
                }
                localStorage.setItem('employer', JSON.stringify(employer));
                setRestrictModal(response.payment_status)
                setBasicDetailsUpdate(response.basic_details_update)
                dispatch(setEmployerSystemType(response.employer_system_type));
                dispatch(setEmployerBusinessType(response.data?.business_type));
            } else if (response.message === 'Payment due') {
                setRestrictModal(false)
            } else {
                toast.error(response.message);
                localStorage.removeItem('employer');
            }
        }

    }

    const GetBussinessDetail = async () => {
        try {
            const response = await getApi("/get-business-detail");
            if (response.status) {
                setViewBusiness(response.businessDetail);
            }
            else if (response.message === 'Payment due') {
                setRestrictModal(false)
            }

        } catch (error) {
            toast.error("error :", error);
            console.error("🚀 ~ file: addEmployer.js:66 ~ GetCountry ~ error:", error)
        }
    }

    const handleAddShift = async () => {
        if (!AddForm.isValid) {
            toast.error('Please fix the errors in the form before submitting');
            return;
        }
        const rangeToValue = {
            '15min': '15',
            '30min': "30",
            '45min': '45',
            '1hour': '60'
        };
        const break_duration = rangeToValue[AddForm.values.break_minutes];
        // }
        try {
            const response = await postApi('/add-bulk-shift', {
                bulkShift: [
                    {
                        shift_name: AddForm.values.shift_name,
                        shift_start_time: AddForm.values.shift_start_time,
                        shift_end_time: AddForm.values.shift_end_time,
                        break_minutes: break_duration,
                        break_start_time: AddForm.values.break_start_time
                    }
                ]
            })
            // setShiftData(prevShiftData => [...prevShiftData, data]);
            if (response.status == true) {

                AddForm.setFieldValue('shift_name', "")
                AddForm.setFieldValue('shift_start_time', "")
                AddForm.setFieldValue('shift_end_time', "")
                AddForm.setFieldValue('break_minutes', "")
                AddForm.setFieldValue('break_start_time', "")
                GetAllShift()
                toast.message(response.message)
            }
            else if (response.message === 'Payment due') {
                setRestrictModal(false)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const AddJob = async () => {
        AddForm.setErrors({})
        AddForm.setTouched({})
        let data = {
            job_type: AddForm.values.job_type,
        }
        // setJobType(prevShiftData => [...prevShiftData, data]);
        if (nextModal == 2) {
            const response = await postApi("/add-job-type", {
                type: AddForm.values.job_type,
                limit: AddForm.values.limit
            })

            if (response.status == true) {
                GetJobType();
                toast.success(response.message);
            } else if (response.message === 'Payment due') {
                setRestrictModal(false)
            } else {
                console.log('inside abhi', response);
                toast.error(response.message);
            }
        }

        AddForm.setFieldValue('job_type', "")
    }

    useEffect(() => {
        // if (!BasicDetailsUpdates && restrictModals) {
        GetBussinessDetail();
        GetTypeLocation();
        GetCountrys();
        getEmployeesData()
        GetDepartment();
        GetLeaveDetail();
        GetAllShift();
        GetJobType();
        // }

    }, [])

    const jobLocations = async () => {
        AddForm.setErrors({})
        AddForm.setTouched({})
        let data = {
            location: AddForm.values.job_location,
        }
        // setjobLocation(prevShiftData => [...prevShiftData, data]);

        if (nextModal == 2) {
            const response = await postApi("/add-job-location", {
                location: AddForm.values.job_location,
                premium_value: AddForm.values.premium_value

            })

            if (response.status == true) {
                GetTypeLocation();
                toast.success(response.message);
            } else if (response.message === 'Payment due') {
                setRestrictModal(false)
            } else {
                console.log('inside abhi', response);
                toast.error(response.message);
            }
        }

        AddForm.setFieldValue('job_location', "")
        AddForm.setFieldValue('premium_value', "")
    }

    const AddLeave = async (leaves) => {
        AddForm.setErrors({})
        AddForm.setTouched({})

        if (nextModal == 4) {
            const response = await postApi("/add-leave-type", {
                leavesArray: leaves

            })

            if (response.status == true) {
                GetLeaveDetail();
                toast.success(response.message);
            } else if (response.message == 'Payment due') {
                setRestrictModal(false)
            } else {
                console.log('inside abhi', response);
                toast.error(response.message);
            }
        }

        AddForm.setFieldValue('leave_name', "")
    }

    const GetJobType = async () => {
        try {
            const response = await getApi("/get-job-type");
            if (response.status) {
                setData1(response.data.rows);
            } else if (response.message === 'Payment due') {
                setRestrictModal(false)
            }
        } catch (error) {
            toast.error("error :", error);
            console.error("🚀 ~ file: addEmployer.js:66 ~ GetCountry ~ error:", error)
        }
    }

    const GetTypeLocation = async () => {
        try {

            const response = await getApi("/get-job-location");
            if (response.status) {
                setData(response.data.rows);
            } else if (response.message === 'Payment due') {
                setRestrictModal(false)
            }
        } catch (error) {
            toast.error("error :", error);
            console.error("🚀 ~ file: addEmployer.js:66 ~ GetCountry ~ error:", error)
        }
    }

    const GetCountrys = async () => {
        try {

            const response = await GetCountry(employer.employer_token);
            if (response.status) {
                setCountry(response.data);
            } else if (response.message === 'Payment due') {
                setRestrictModal(false)
            }
        } catch (error) {
            toast.error("error :", error);
            console.error("🚀 ~ file: addEmployer.js:66 ~ GetCountry ~ error:", error)
        }
    }


    const GetAllShift = async () => {
        try {
            const response = await getApi("/get-all-shift");
            if (response.status) {
                setDataArray(response.data.rows);
            } else if (response.message === 'Payment due') {
                setRestrictModal(false)
            }
        } catch (error) {
            toast.error("error :", error);
            console.error("🚀 ~ file: addEmployer.js:66 ~ GetCountry ~ error:", error)
        }
    }


    const AddForm = useFormik({
        initialValues: {
            business_name: viewBusiness?.company_name ? viewBusiness?.company_name : "",
            business_name_true: viewBusiness?.company_name ? viewBusiness?.company_name : "",
            mobile_number: viewBusiness?.mobile_number ? viewBusiness?.mobile_number : "",
            mobile_number_true: viewBusiness?.mobile_number ? viewBusiness?.mobile_number : "",
            employer_country: viewBusiness?.employer_country ? viewBusiness?.employer_country : "",
            employer_country_true: viewBusiness?.employer_country ? viewBusiness?.employer_country : "",
            business_location: viewBusiness?.address ? viewBusiness?.address : "",
            business_location_true: viewBusiness?.address ? viewBusiness?.address : "",
            employer_system_type: viewBusiness?.employer_system_type ? viewBusiness?.employer_system_type : "",
            employer_system_type_true: viewBusiness?.employer_system_type ? viewBusiness?.employer_system_type : "",
            employee_total_count: viewBusiness?.employee_count_upto ? viewBusiness?.employee_count_upto : "",
            employee_total_count_true: viewBusiness?.employee_count_upto ? viewBusiness?.employee_count_upto : "",
            businessType: viewBusiness?.business_type ? viewBusiness?.business_type : "",
            businessType_true: viewBusiness?.business_type ? viewBusiness?.business_type : "",

            job_type: "",
            limit: "0",
            sub_limit: "",
            sub_job_id: viewBusiness?.sub_job_id ? viewBusiness?.sub_job_id : "",
            sub_job_type: "",
            subL2_job_id: viewBusiness?.subL2_job_id ? viewBusiness?.subL2_job_id : "",
            sub_l2_job_type: subjobtypeName ? subjobtypeName : '',
            sub_l2_limit: subjobtypeLimit ? subjobtypeLimit : "",
            sub_l3_job_type: L2SubjobtypeName ? L2SubjobtypeName : "",
            sub_l3_limit: L2SubjobtypeLimit ? L2SubjobtypeLimit : "",
            job_location: "",
            edit_job_type: editJobType ? editJobType : "",
            edit_limit: editLimit ? editLimit : "",
            edit_location: locationbyID ? locationbyID : "",
            edit_location_premuim: premiumbyID ? premiumbyID : "",
            premium_value: '',
            sub_premuim: "",
            sub_location: "",
            edit_sub_location: EditSubLocation ? EditSubLocation : "",
            edit_sub_premium: SubLocationPremium ? SubLocationPremium : "",
            first_name: "",
            last_name: "",
            employee_mobile_number: "",
            email: "",
            department_id: "",
            location_id: "",
            sub_location_id: "",
            job_id: "",
            employee_code: "",
            monthly_salary: "",
            Pay_Cycle: "",
            salary_type: '',
            deduction_amount_limit: "",
            Country: "",
            reside_type: "",
            skill: "",

            leave_name: "",
            wages_name: "",
            wages_hour: "",
            wages_amount: "",

            shift_name: "",
            shift_start_time: "",
            shift_end_time: "",
            break_minutes: "",
            break_start_time: "",

            account_name: "",
            bank_name: "",
            account_number: "",
            branch: "",

            NT_total_hrs: "",
            NT_per_hrs_wages: "",
            TH_total_hrs: "",
            TH_per_hrs_wages: "",
            MEAL_total_hrs: "",
            MEAL_per_hrs_wages: "",
            DT_total_hrs: "",
            DT_per_hrs_wages: ""

        },
        enableReinitialize: true,
        validationSchema: yup.object({

            business_name: nextModal == 0 ? yup.string().required('Please Enter Business Name ') : "",
            mobile_number: nextModal == 0 ? yup.string()/* .typeError('Please enter a valid mobile number') */.required('Please Enter Mobile No.').min(5, 'Mobile number must contain 5 number').max(15, 'Mobile number max contain 15 number') : "",
            employer_country: nextModal == 0 ? yup.string().required('Please select Country') : "",
            business_location: nextModal == 0 ? yup.string().required('Please Enter Business location') : "",
            employer_system_type: nextModal == 0 ? yup.string().required('Please select Employee System Type') : "",
            employee_total_count: nextModal == 0 ? yup.string().required('Please select Number of Employees') : "",
            businessType: nextModal == 0 ? yup.string().required('Please select Business Type') : "",

            // job_type:  nextModal == 2 ? yup.string().required('Please Enter Job Type') : "",
            // job_type: nextModal === 1 && !subLocationValidation && !addLocation ? yup.string().required('Please Enter New Job Type') : "",
            job_type: nextModal == 2 && data1.length == 0 ? yup.string().required('Please Enter New Job Type') : "",

            // job_location: nextModal == 2 && addLocation ? yup.string().required('Location is required') : "",
            job_location: nextModal == 2 && data.length == 0 ? yup.string().required('Location is required') : "",

            // sub_job_type: nextModal == 2 ? yup.string().required('Please Enter Sub Job Type') : "",
            // limit: nextModal == 2 ? yup.string().required('Please Enter Employee Limit') : "",

            // premium_value: nextModal == 2 ? yup.string().required('Premium is required') : "",
            edit_job_type: nextModal == 2 ? yup.string().required('Please Enter Job Type') : "",
            edit_location: nextModal == 2 ? yup.string().required('Please Enter Location') : "",
            // sub_premuim: nextModal == 2 && subLocationValidation ? yup.string().required('Sub-Premium is required') : "",
            sub_location: nextModal == 2 && subLocationValidation ? yup.string().required('Sub-Location is required') : "",
            // shift_name: nextModal == 3 ? yup.string().required('Please Enter Shift Name') : "",
            shift_name: nextModal == 3 ? yup.string().matches(/[a-zA-Z0-9]/, 'Shift Name Must Contain At Least One Character').test('noOnlySpaces', 'Shift Name cannot be empty', value => {
                return value && value.trim() !== '';
            }) : "",
            shift_start_time: nextModal == 3 ? yup.string().required('Please Enter Shift Start Time') : "",
            // shift_end_time: nextModal == 3 ? yup.string().required('Please Enter Shift End Time') : "",

            shift_end_time: nextModal == 3 ? yup.string()
                .required('Please Enter Shift End Time')
                .test('max-time', `Shift end time cannot be more than ${complianceDetail} hours`, function (value) {
                    const complianceValue = complianceDetail * 60;
                    if (complianceValue === 0) {
                        return true;
                    }
                    const startTime = this.parent.shift_start_time;
                    const endTime = value;
                    const startTimeMoment = moment(startTime, 'HH:mm');
                    const endTimeMoment = moment(endTime, 'HH:mm');
                    const durationInMinutes = endTimeMoment.diff(startTimeMoment, 'minutes');
                    return durationInMinutes <= complianceValue;
                }) : "",
            break_minutes: nextModal == 3 ? yup.string().required('Please select Break Duration') : "",
            // break_start_time: nextModal == 3 ? yup.string().required('Please select Break Start Time') : "",
            break_start_time: nextModal == 3 ? yup.string().required('Please select Break Start Time')
                .test('interval-time', `Break Start Time should be within ${restInterval} hours from start time`, function (value) {
                    const complianceValue = restInterval * 60;
                    if (complianceValue === 0) {
                        return true;
                    }
                    const startTime = this.parent.shift_start_time;
                    const endTime = value;
                    const startTimeMoment = moment(startTime, 'HH:mm');
                    const endTimeMoment = moment(endTime, 'HH:mm');
                    const durationInMinutes = endTimeMoment.diff(startTimeMoment, 'minutes');
                    return durationInMinutes <= complianceValue;
                }) : "",

            // leave_name: nextModal == 4 ? yup.string().required('Please Enter Leave Type') : "",
            // leave_name: nextModal == 4 ? yup.string().required('Please Enter Leave Type') : "",
            wages_name: nextModal == 4 ? yup.string().required('Please Enter Wages Name') : "",

            first_name: nextModal == 5 ? yup.string().required('Please Enter First Name') : "",
            last_name: nextModal == 5 ? yup.string().required('Please Enter Last Name') : "",
            employee_mobile_number: nextModal == 5 ? yup.string()/* .typeError('Please enter a valid mobile number') */.required('Please Enter Mobile No.').min(5, 'Mobile number must contain 5 number').max(15, 'Mobile number max contain 15 number') : "",
            employee_code: nextModal == 5 ? yup.string().required('Please Enter Employee Code') : "",
            monthly_salary: nextModal == 5 ? yup.string().required('Please Enter Monthly Salary').max(7, 'Monthly Salary Amount max upto 7 characters') : "",
            Pay_Cycle: nextModal == 5 ? yup.string().required('Please select Pay Cycle') : "",
            salary_type: nextModal == 5 ? yup.string().required('Please Select Salary Type') : "",
            Country: nextModal == 5 ? yup.string().required('Please select Country') : "",
            reside_type: nextModal == 5 ? yup.string().required('Please select Resident Type') : "",
            deduction_amount_limit: nextModal == 5 ? yup.string().required('Please Enter Deduction Amount Limit').max(7, 'Deduction Amount Limit max upto 7 Digit') : "",
            email: nextModal == 5 ? yup.string().email("Invalid Email Address Format").required('Please Enter Email Address') : "",

            department_id: nextModal == 5 ? yup.string().required('Please Select Department') : "",
            location_id: nextModal == 5 ? yup.string().required('Please Select Location') : "",
            job_id: nextModal == 5 ? yup.string().required('Please Select Job type') : "",


            account_name: nextModal == 5 ? yup.string().required('Please Enter Account Name') : "",
            bank_name: nextModal == 5 ? yup.string().required('Please Enter Bank Name') : "",
            account_number: nextModal == 5 ? yup.string().required('Please Enter Account Number') : "",
            branch: nextModal == 5 ? yup.string().required('Please Enter Branch') : "",

            NT_total_hrs: nextModal == 5 ? yup.string().required('Total Hrs is required') : "",
            NT_per_hrs_wages: nextModal == 5 ? yup.number().required('Per Hrs is required')
                .test('min-amount', `Minimum Per Hrs Wages cannot be less than ${perHourWages}`, function (value) {
                    const complianceValue = perHourWages;
                    if (complianceValue == 0) {
                        return true;
                    }
                    const Amount = value;
                    return Amount >= complianceValue;
                }) : '',
            // NT_per_hrs_wages: nextModal == 5 ? yup.string().required('Per Hrs is required') : "",
            TH_total_hrs: nextModal == 5 ? yup.string().required('Total Hrs is required') : "",
            TH_per_hrs_wages: nextModal == 5 ? yup.string().required('Per Hrs is required') : "",
            MEAL_total_hrs: nextModal == 5 ? yup.string().required('Per Meal Hrs is required') : "",
            MEAL_per_hrs_wages: nextModal == 5 ? yup.string().required('Per Meal Rate is required') : "",
            DT_total_hrs: nextModal == 5 ? yup.string().required('Total Hrs is required') : "",
            DT_per_hrs_wages: nextModal == 5 ? yup.string().required('Per Hrs is required') : "",
            // skill: nextModal == 5 && name.length > 0 ? yup.string().required('Please Add Skill') : "",

        }),

        onSubmit: async values => {

            const employer = JSON.parse(localStorage.getItem("employer"))
            if (nextModal == 0) {
                // const rangeToValue = {
                // 	'0-5': '5',
                // 	'5-10': '10',
                // 	'10-20': '20',
                // 	'20-50': '50',
                // 	'50-100': '100',
                // 	'100+': '101'
                // };
                // const employee_count_upto = rangeToValue[values.employee_total_count];
                const response = await postApi("/business-detail-update", {
                    company_name: values.business_name,
                    mobile_number: values.mobile_number,
                    employer_country: values.employer_country,
                    address: values.business_location,
                    employer_system_type: values.employer_system_type,
                    business_type: values.businessType,
                    employee_count_upto: values.employee_total_count

                })

                if (response.status == true) {
                    // setNextModal(nextModal + 1);
                    if (AddForm.values.employer_system_type == "PAYROLL") {
                        updatetoken()
                        navigate('/employer/payroll')
                    } else {
                        setNextModal(nextModal + 1);

                    }

                    // toast.success(response.message);
                    toast.success(response.message);
                    // navigate(process.env.REACT_APP_EMPLOYER_PRIFIX + '/employees');
                } else if (response.message === 'Payment due') {
                    setRestrictModal(false)
                } else {
                    console.log('inside abhi', response);
                    // setNextModal(nextModal + 1);
                    toast.error(response.message);
                }
            }
        }
    });


    const handleClickFunction = () => {
        if (nextModal == 2) {
            if (data.length > 0 && data1.length > 0) {
                setNextModal(nextModal + 1)
                AddForm.values.job_type = "dfdf"
                AddForm.values.job_location = "fdf"
                AddForm.values.premium_value = "gfg"
                AddForm.values.sub_location = 'fgf'
                AddForm.values.sub_premuim = 'fdf'

                AddForm.handleSubmit()
                AddForm.setFieldValue('job_type', "")
                AddForm.setFieldValue('job_location', "")
                AddForm.setFieldValue('premium_value', "")
                AddForm.setFieldValue('sub_location', "")
                AddForm.setFieldValue('sub_premuim', "")
            } else {

                AddForm.handleSubmit()
            }
        } else {
            AddForm.handleSubmit()
            // AddForm.values.job_type = ""
            // AddForm.values.job_location = ""
            setNextModal(nextModal + 1)
        }

    }

    const handleClickFunction1 = () => {
        if (nextModal == 4) {
            if (leave_types.length > 0 && wages_types.length > 0) {
                setNextModal(nextModal + 1)
                AddForm.values.leave_name = "dfdf"
                AddForm.values.wages_name = "fdf"

                AddForm.handleSubmit()
                AddForm.setFieldValue('leave_name', "")
                AddForm.setFieldValue('wages_name', "")
            } else {
                AddForm.handleSubmit()
            }
        } else {
            AddForm.handleSubmit()
            setNextModal(nextModal + 1)
        }

    }

    const handleClickShift = () => {
        if (nextModal == 3) {
            if (dataArray.length > 0) {
                setNextModal(nextModal + 1)
                AddForm.values.shift_name = "dfdf"
                AddForm.values.shift_start_time = "fdf"
                AddForm.values.shift_end_time = "fdf"
                AddForm.values.break_minutes = "fdf"
                AddForm.values.break_start_time = "fdf"

                AddForm.handleSubmit()
                AddForm.setFieldValue('shift_name', "")
                AddForm.setFieldValue('shift_start_time', "")
                AddForm.setFieldValue('shift_end_time', "")
                AddForm.setFieldValue('break_minutes', "")
                AddForm.setFieldValue('break_start_time', "")
            } else {
                AddForm.handleSubmit()
            }
        } else {
            AddForm.handleSubmit()
            // AddForm.values.job_type = ""
            // AddForm.values.job_location = ""
            setNextModal(nextModal + 1)
        }

    }


    useEffect(() => {
        if (nextModal == 2) {
            AddForm.setErrors({})
            AddForm.setTouched({})
        }

    }, [nextModal, AddForm.values.job_location, AddForm.values.job_type, AddForm.values.premium_value])


    const addSkill = () => {

        const updatedNamess = [...name, inputNames];
        setName(updatedNamess);
        setInputNames('');
    }

    const handleInputChangess = async (e) => {
        if (e != "") {
            const updatedNames = inputName;
            const response = await postApi("/add-nfc-site", { nfc_site_name: e });

            if (response.status === true) {
                // setNames([updatedNames]);
                GetDepartment();
                // setDepartmentInput('');
                toast.success(response.message);
                // GetDepartment(employer.employer_token);
            } else if (response.message === 'Payment due') {
                setRestrictModal(false)
            } else if (response.status === false) {
                console.log(response);
                toast.error(response.message);
            }
        }
        // setDepartmentInput(e)

    };

    const removeName = (index) => {
        const newNames = [...name];
        newNames.splice(index, 1);
        setName(newNames);
    };

    const [leave_types, setLeave_types] = useState([
        {
            "leave_name": "BL",
            "leave_count": 0,
        },
        {
            "leave_name": "SL",
            "leave_count": 0,
        },
        {
            "leave_name": "AL",
            "leave_count": 0,
        }
    ])
    useEffect(() => {
        AddForm.setTouched({})
        AddForm.setErrors({})
    }, [leave_types])

    const GetLeaveDetail = async () => {
        try {
            const response = await getApi("/get-leave-types");
            if (response.status) {
                if (response.data.count > 0) {
                    setLeave_types(response.data.rows);
                }
            } else if (response.message === 'Payment due') {
                setRestrictModal(false)
            }
        } catch (error) {
            toast.error("error :", error);
            console.error("🚀 ~ file: addEmployer.js:66 ~ GetCountry ~ error:", error)
        }
    }

    const [wages_types, setWages_types] = useState([
        // 	{
        // 	"wages_name": "Premium",
        // 	"per_hour_amount": "",
        // 	"total_hours": ""
        // },
        {
            "wages_name": "NT",
            "per_hour_amount": "",
            "total_hours": ""
        },
        {
            "wages_name": "TH",
            "per_hour_amount": "",
            "total_hours": ""
        },
        {
            "wages_name": "MEAL",
            "per_hour_amount": "",
            "total_hours": ""
        },
        {
            "wages_name": "DT",
            "per_hour_amount": "",
            "total_hours": ""
        }])

    const GetDepartment = async (token) => {
        try {
            // const response = await GetDepartmentDetail(token, search, pagesCount, sortedBy, orderBy);
            const response = await getApi("/get-nfc-site-list");
            if (response.status == true) {
                setDepartmentname(response.data);
            } else if (response.message === 'Payment due') {
                setRestrictModal(false)
            } else if (response.message == "Access denied! you're not eligible for NFC") {
                toast.error(response.message)
                navigate('/employer/dashboard')
            } else if (response.message == 'Session expired') {
                localStorage.removeItem("employer");
                navigate('/employer/login')
            }
        } catch (error) {
            toast.error("error :", error);
            console.error("🚀 ~ file: addEmployer.js:66 ~ GetCountry ~ error:", error)
        }
    }

    const handleAddTeamMember = async () => {
        try {

            if (employeeList.length == 0 || AddForm.values.first_name != "" || AddForm.values.last_name != "" || AddForm.values.employee_mobile_number != "" || AddForm.values.email != "" || AddForm.values.department_id != "" || AddForm.values.employee_code != "" || AddForm.values.monthly_salary != "" || AddForm.values.Pay_Cycle != "" || AddForm.values.Country != "" || AddForm.values.job_id != "" || AddForm.values.location_id != "" || AddForm.values.sub_location_id != "" || AddForm.values.NT_per_hrs_wages != "" || AddForm.values.NT_total_hrs != "" || AddForm.values.TH_per_hrs_wages != "" || AddForm.values.TH_total_hrs != "" || AddForm.values.DT_per_hrs_wages != "" || AddForm.values.DT_total_hrs != "" || AddForm.values.MEAL_per_hrs_wages != "" || AddForm.values.MEAL_total_hrs != "") {


                if (Object.values(AddForm.errors).length === 0) {

                    const response = await postApi('/add-team-member', {
                        first_name: AddForm.values.first_name,
                        last_name: AddForm.values.last_name,
                        employee_mobile_number: AddForm.values.employee_mobile_number,
                        email: AddForm.values.email,
                        department_id: AddForm.values.department_id,
                        employee_code: AddForm.values.employee_code,
                        salary: AddForm.values.monthly_salary,
                        pay_cycle: AddForm.values.Pay_Cycle,
                        salary_type: AddForm.values.salary_type,
                        country_id: AddForm.values.Country,
                        reside_type: AddForm.values.reside_type,
                        deduction_amount_limit: AddForm.values.deduction_amount_limit,
                        job_id: AddForm.values.job_id,
                        sub_job_id: AddForm.values.sub_job_id,
                        subL2_job_id: AddForm.values.subL2_job_id,
                        location_id: AddForm.values.location_id,
                        sub_location_id: AddForm.values.sub_location_id,
                        skills: JSON.stringify(name),

                        account_name: AddForm.values.account_name,
                        bank_name: AddForm.values.bank_name,
                        account_number: AddForm.values.account_number,
                        branch: AddForm.values.branch,

                        NT_per_hrs_wages: AddForm.values.NT_per_hrs_wages,
                        NT_total_hrs: AddForm.values.NT_total_hrs,
                        TH_per_hrs_wages: AddForm.values.TH_per_hrs_wages,
                        TH_total_hrs: AddForm.values.TH_total_hrs,
                        MEAL_per_hrs_wages: AddForm.values.MEAL_per_hrs_wages,
                        MEAL_total_hrs: AddForm.values.MEAL_total_hrs,
                        DT_per_hrs_wages: AddForm.values.DT_per_hrs_wages,
                        DT_total_hrs: AddForm.values.DT_total_hrs

                    })


                    if (response.status == true) {
                        AddForm.setErrors({})
                        AddForm.setTouched({})
                        getEmployeesData()
                        toast.success(response.message)

                        AddForm.setFieldValue('first_name', "")
                        AddForm.setFieldValue('last_name', "")
                        AddForm.setFieldValue('employee_mobile_number', "")
                        AddForm.setFieldValue('email', "")
                        AddForm.setFieldValue('department_id', "")
                        AddForm.setFieldValue('employee_code', "")
                        AddForm.setFieldValue('monthly_salary', "")
                        AddForm.setFieldValue('Pay_Cycle', "")
                        AddForm.setFieldValue('salary_type', "")
                        AddForm.setFieldValue('Country', "")
                        AddForm.setFieldValue('reside_type', "")
                        AddForm.setFieldValue('deduction_amount_limit', "")
                        AddForm.setFieldValue('job_id', "")
                        AddForm.setFieldValue('sub_job_id', "")
                        AddForm.setFieldValue('sub_l2_job_id', "")
                        AddForm.setFieldValue('location_id', "")
                        AddForm.setFieldValue('sub_location_id', "")
                        AddForm.setFieldValue('NT_per_hrs_wages', "")
                        AddForm.setFieldValue('NT_total_hrs', "")
                        AddForm.setFieldValue('TH_per_hrs_wages', "")
                        AddForm.setFieldValue('TH_total_hrs', "")
                        AddForm.setFieldValue('DT_per_hrs_wages', "")
                        AddForm.setFieldValue('DT_total_hrs', "")
                        AddForm.setFieldValue('MEAL_per_hrs_wages', "")
                        AddForm.setFieldValue('MEAL_total_hrs', "")
                        setName([])

                    } else if (response.message === 'Payment due') {
                        setRestrictModal(false)
                    }
                    else {
                        toast.error(response.message)
                    }
                }
            }
            else {
                setNextModal(nextModal + 1)
            }

        } catch (error) {
            toast.error(error.message)
            console.log(error)
        }
    }

    const AddSublocation = async () => {
        if (AddForm.values.sub_location != "" /* && AddForm.values.sub_premuim != "" */) {
            try {
                const response = await postApi('/add-sub-job-location', {
                    job_location_id: LocationID,
                    sub_location: AddForm.values.sub_location,
                    premium_value: AddForm.values.sub_premuim
                })
                if (response.status) {
                    AddForm.setFieldValue('sub_location', "")
                    handleClose()
                    AddForm.setFieldValue('sub_premuim', "")
                    setsubLocationValidation(!subLocationValidation)
                    toast.success(response.message)
                    setOpenModals(!OpenModals)
                    SetLocationID('')
                    GetTypeLocation()
                    setSubLocationEvent("")
                } else if (response.message === 'Payment due') {
                    setRestrictModal(false)
                } else if (response.status == false) {
                    toast.error(response.message)
                }
            } catch (error) {
                console.log(error)
            }
        }
    }

    useEffect(() => {

        if (AddForm.values.location_id == "") {
            setSublocationdetail([])
            AddForm.setFieldValue('sub_location', "")
        }
        else {
            const foundItem = data.find(item => item.id == AddForm.values.location_id);

            if (foundItem) {
                setSublocationdetail(foundItem.subLocations);
            }
        }
    }, [AddForm.values.location_id]);

    const addNamess = () => {
        setShowInput(!showInput);
    };


    const HandleDeleteShift = async (id) => {
        try {
            const response = await deleteApi(`/delete-shift/${id}`)

            if (response.status) {
                GetAllShift()
                toast.success(response.message)
            } else if (response.message === 'Payment due') {
                setRestrictModal(false)
            }
        } catch (error) {
            console.log(error)
        }

    }


    // useEffect(() => {
    //     AddForm.setFieldValue('break_start_time', '')
    //     AddForm.setFieldValue('break_minutes', '')

    // }, [AddForm.values.shift_start_time, AddForm.values.shift_end_time])

    const handleBreakTime = (e) => {

        let obj = {
            "1hour": 60,
            "45min": 45,
            "30min": 30,
            "15min": 15
        }
        const minTime = AddForm.values.shift_start_time; // Minimum allowed time
        const maxTime = AddForm.values.shift_end_time; // Maximum allowed time

        // Adjust the minTime by adding one minute
        const adjustedMinTime = adjustTime(minTime, 1);
        // Adjust the maxTime by subtracting one minute
        const adjustedMaxTime = adjustTime(maxTime, -(obj[AddForm.values.break_minutes]));

        const { value } = e.target;
        const [hours, minutes] = value.split(':').map(Number);

        // Convert time to minutes for comparison
        const selectedTimeInMinutes = hours * 60 + minutes;
        const minTimeInMinutes = getTimeInMinutes(adjustedMinTime);
        const maxTimeInMinutes = getTimeInMinutes(adjustedMaxTime);

        // Check if the selected time is within the allowed range
        if (selectedTimeInMinutes < minTimeInMinutes || selectedTimeInMinutes > maxTimeInMinutes) {
            // Reset the value to the minimum allowed time if it's outside the range
            // AddForm.setFieldValue('break_start_time', adjustedMinTime);
            AddForm.setFieldValue('break_start_time', "");

            // AddForm.setFieldError("break_start_time",'Break Start Time Should be between Shift Start and End Time')
            AddForm.setFieldValue('finderror', "Break Start Time Should be between Shift Start and End Time");
            // toast.error('Break Start Time Should be between Shift Start and End Time');
        } else {
            // Update the value in the form state
            AddForm.setFieldValue('finderror', "");

            AddForm.setFieldValue('break_start_time', value);
        }
        // AddForm.handleChange(e)
    }
    const adjustTime = (timeStr, minutes) => {
        let [hours, oldMinutes] = timeStr.split(':').map(Number);
        let newMinutes = oldMinutes + minutes;

        // Handle rollover if minutes exceed 59 or become negative
        if (newMinutes >= 60) {
            newMinutes -= 60;
            hours++;
        } else if (newMinutes < 0) {
            newMinutes += 60;
            hours--;
        }

        // Ensure hours stay within 0-23 range
        hours = (hours + 24) % 24;

        // Format the adjusted time string
        return `${hours.toString().padStart(2, '0')}:${newMinutes.toString().padStart(2, '0')}`;
    }
    const getTimeInMinutes = (time) => {
        const [hours, minutes] = time.split(':').map(Number);
        return hours * 60 + minutes;
    };
    useEffect(() => {
        AddForm.setTouched({})
        AddForm.setErrors({})
    }, [nextModal, AddForm.values.shift_start_time, AddForm.values.sh])

    const handleendshifttime = (event) => {
        const { value } = event.target;
        const startTime = AddForm.values.shift_start_time;
        const [selectedHours, selectedMinutes] = value.split(':').map(Number);
        const [startHours, startMinutes] = startTime.split(':').map(Number);

        // Convert selected time to minutes since midnight
        const selectedTotalMinutes = selectedHours * 60 + selectedMinutes;

        // Convert shift start time to minutes since midnight
        const startTotalMinutes = startHours * 60 + startMinutes;

        // Convert end time to minutes since midnight
        let endTotalMinutes = selectedTotalMinutes;

        // Adjust if the selected time is earlier than the start time (negative difference)
        if (endTotalMinutes < startTotalMinutes) {
            // Add 24 hours to the end time to make it the next day
            endTotalMinutes += 24 * 60;
        }

        // Check if the difference is at least 61 minutes (1 hour and 1 minute)
        // and if the selected time is before midnight
        if (endTotalMinutes - startTotalMinutes >= 61 && endTotalMinutes < 24 * 60) {
            AddForm.setFieldValue("shift_end_time", value);
        } else {
            AddForm.setFieldValue("shift_end_time", "");
        }
    }

    const HandleEditJobLocationDetail = async () => {
        if (AddForm.values.edit_location != ""/*  && AddForm.values.edit_location_premuim != "" */) {
            try {
                const response = await postApi(`/edit-job-location/${LocationID}`, {
                    // job_location_id: LocationID,
                    job_location: AddForm.values.edit_location,
                    premium_value: AddForm.values.edit_location_premuim == "" ? "0" : AddForm.values.edit_location_premuim
                })

                if (response.status) {
                    AddForm.setFieldValue('edit_location', "")
                    handleCloseEditLocation()
                    AddForm.setFieldValue('edit_location_premuim', "")
                    setsubLocationValidation(!subLocationValidation)
                    toast.success(response.message)
                    setOpenEditLocationModals(!OpenEditLocationModals)
                    SetLocationID('')
                    GetTypeLocation()
                    setSubLocationEvent("")
                } else if (response.message === 'Payment due') {
                    setRestrictModal(false)
                }
            } catch (error) {
                console.log(error)
            }
        }

    }

    const HandleEditJobType = async () => {
        if (AddForm.values.edit_job_type != "") {
            try {
                const response = await postApi(`/edit-job-type/${EditJobTypeID}`, {
                    job_type: AddForm.values.edit_job_type,
                    limit: AddForm.values.edit_limit
                })

                if (response.status) {
                    AddForm.setFieldValue('edit_job_type', "")
                    handleCloseJobType()
                    setsubLocationValidation(!subLocationValidation)
                    toast.success(response.message)
                    setOpenEditJobType(!OpenEditJobType)
                    setEditJobTypeID('')
                    GetJobType()
                    // setSubLocationEvent("")
                }
            } catch (error) {
                console.log(error)
            }
        }

    }

    useEffect(() => {
        AddForm.setFieldError("break_start_time", 'Break Start Time Should be between Shift Start and End Time')

    }, [AddForm.values.break_start_time])

    const HandleLeavesDelete = async (id) => {
        const response = await deleteApi(`/delete-leave/${id}`)
        if (response.status) {
            toast.success(response.message)
            GetLeaveDetail()
        } else if (response.message === 'Payment due') {
            setRestrictModal(false)
        } else {
            console.log(response)
            toast.error(response.message)

        }
    }

    const HandleEditSubLocation = async () => {
        if (AddForm.values.edit_sub_location != "" /* && AddForm.values.edit_sub_premium != "" */) {
            try {
                const response = await postApi(`/edit-sub-job-location/${EditSubLocationID}`, {
                    "sub_location": AddForm.values.edit_sub_location,
                    "premium_value": AddForm.values.edit_sub_premium == "" ? "0" : AddForm.values.edit_sub_premium

                })

                if (response.status) {
                    AddForm.setFieldValue('edit_sub_location', "")
                    AddForm.setFieldValue('edit_sub_premium', "")
                    handleCloseSubLocation()
                    // setsubLocationValidation(!subLocationValidation)
                    toast.success(response.message)
                    setOpenSubLocationModals(!OpenSubLocationModals)
                    // setEditJobTypeID('')
                    GetTypeLocation()
                    // setSubLocationEvent("")

                } else if (response.message === 'Payment due') {
                    setRestrictModal(false)
                }
            } catch (error) {
                console.log(error)
            }
        }

    }

    const updateNumber = (value, path, digit) => {
        let regex = /[^0-9]/g
        if (regex.test(value)) {
            AddForm.setFieldValue(path, value.replace(/[^0-9]/g, '')).then((res) => {
                if (res.mobile_number == undefined) {
                    AddForm.setFieldValue(path + "_true", true)
                }
                else {
                    AddForm.setFieldValue(path + "_true", false)

                }
            })

        }

        else {
            if (value.length > digit) {
                value = value.substring(0, digit);
                AddForm.setFieldValue(path, value.replace(/[^0-9]/g, '')).then((res) => {
                    if (res.mobile_number == undefined) {
                        AddForm.setFieldValue(path + "_true", true)
                    }
                    else {
                        AddForm.setFieldValue(path + "_true", false)

                    }
                })

                // updateRecord({target:{value:value}},path)
            }
            else {
                AddForm.setFieldValue(path, value.replace(/[^0-9]/g, '')).then((res) => {
                    if (res.mobile_number == undefined) {
                        AddForm.setFieldValue(path + "_true", true)
                    }
                    else {
                        AddForm.setFieldValue(path + "_true", false)

                    }
                })
                // form.setFieldValue("aadhar_number_true",false)
            }
        }
    }

    const handleFieldvalidations = (e, path) => {
        let value = e.target.value
        AddForm.setFieldValue(path, value).then((res) => {
            if (res[path] == undefined) {
                AddForm.setFieldValue(path + "_true", true)
            }
            else {
                AddForm.setFieldValue(path + "_true", false)

            }
        })

    }


    const handleManageSubjobType = (id) => {
        setManageSubjobType(prevState => ({
            ...prevState,
            [id]: !prevState[id]
        }));
        setSubJobTypeId(id);
    };

    const handleCloseSubjobType = (id) => {
        setManageSubjobType(prevState => ({
            ...prevState,
            [id]: false
        }));
    };

    const handleManageSubjobTypeLevel2 = (id, data) => {
        setCheckStatus(data)
        setManageSubjobTypeLevel2(prevState => ({
            ...prevState,
            [id]: !prevState[id]
        }));
        setSubJobTypeIdLevel2(id);
    };

    const handleCloseSubjobTypeLevel2 = (id) => {
        AddForm.setFieldValue('sub_l2_job_type', "")
        AddForm.setFieldValue('sub_l2_limit', "")
        setManageSubjobTypeLevel2(prevState => ({
            ...prevState,
            [id]: false
        }));
    };

    const handleManageSubjobTypeLevel3 = (id) => {
        setManageSubjobTypeLevel3(prevState => ({
            ...prevState,
            [id]: !prevState[id]
        }));
        setSubJobTypeIdLevel3(id);
    };

    const handleCloseSubjobTypeLevel3 = (id) => {
        setManageSubjobTypeLevel3(prevState => ({
            ...prevState,
            [id]: false
        }));
    };

    const AddSubJobType = async () => {
        try {
            if (AddForm.values.sub_job_type && AddForm.values.sub_limit) {
                const response = await postApi('/add-sub-job-type', {
                    job_id: subJobTypeId,
                    sub_job_type: AddForm.values.sub_job_type,
                    sub_limit: AddForm.values.sub_limit
                })
                if (response.status) {
                    toast.success(response.message)
                    HandleModalForSubJobTyperef.current.click()
                    AddForm.setFieldValue('sub_job_type', "")
                    AddForm.setFieldValue('sub_limit', "")
                    // setmanageSubjobType(false)
                    GetJobType()
                } else {
                    toast.error(response.message)
                }
            }
        } catch (error) {
            console.log(error)
        }

    }

    const AddSubJobTypeLevel2 = async () => {
        try {

            if (AddForm.values.sub_l2_job_type && AddForm.values.sub_l2_limit) {
                const data = {
                    sub_job_id: subJobTypeIdLevel2,
                    sub_l2_job_type: AddForm.values.sub_l2_job_type,
                    sub_l2_limit: AddForm.values.sub_l2_limit
                };
                const data1 = {
                    // sub_job_id: subJobTypeIdLevel2,
                    sub_job_type: AddForm.values.sub_l2_job_type,
                    sub_limit: AddForm.values.sub_l2_limit
                };

                const endpoint = checkStatus === 'Add' ? '/add-l2-sub-job-type' : `/edit-sub-job-type/${subJobTypeIdLevel2}`;
                const payload = checkStatus === 'Add' ? data : data1;
                const response = await postApi(endpoint, payload);
                if (response.status) {
                    toast.success(response.message)
                    HandleModalForSubJobTyperefLevel2.current.click()
                    AddForm.setFieldValue('sub_l2_job_type', "")
                    AddForm.setFieldValue('sub_l2_limit', "")
                    setSubJobTypeId('')
                    // setmanageSubjobType(false)
                    GetJobType()
                } else {
                    toast.error(response.message)
                }
            }
        } catch (error) {
            console.log(error)
        }

    }

    const AddSubJobTypeLevel3 = async () => {
        try {
            if (AddForm.values.sub_l3_job_type && AddForm.values.sub_l3_limit) {
                const response = await postApi(`/edit-l2-sub-job-type/${subJobTypeIdLevel3}`, {
                    sub_l2_job_type: AddForm.values.sub_l3_job_type,
                    sub_l2_limit: AddForm.values.sub_l3_limit
                })
                if (response.status) {
                    toast.success(response.message)
                    HandleModalForSubJobTyperefLevel3.current.click()
                    // AddForm.setFieldValue('sub_job_type', "")
                    // AddForm.setFieldValue('sub_limit', "")
                    // setmanageSubjobType(false)
                    GetJobType()
                } else {
                    toast.error(response.message)
                }
            }
        } catch (error) {
            console.log(error)
        }

    }

    useEffect(() => {

        if (AddForm.values.job_id == "") {
            setSubJobType([])
            AddForm.setFieldValue('sub_job_id', "")
            AddForm.setFieldValue('subL2_job_id', "");
        }
        else {
            const foundItem = data1.find(item => item.id == AddForm.values.job_id);

            if (foundItem) {
                setSubJobType(foundItem.subJobType);
                const foundSubJobType = foundItem.subJobType.find(subJobType => subJobType.id == AddForm.values.sub_job_id
                );
                if (foundSubJobType) {
                    setSubL2JobType(foundSubJobType.subL2JobType);
                } else {
                    setSubL2JobType([]);
                    AddForm.setFieldValue('subL2_job_id', "");
                }
            }
        }
    }, [AddForm.values.job_id, AddForm.values.sub_job_id]);


    const GetcomplianceDetail = async (token) => {
        try {
            const response = await getApi("/compliance-list");
            if (response.status == true) {
                const dataFilter = response.data.filter(Item => Item.compliance_type == "daily_hours")
                const dataFilter1 = response.data.filter(Item => Item.compliance_type == "interval_for_rest")
                const dataFilter3 = response.data.filter(Item => Item.compliance_type == "minimum_per_hour_wages")
                setComplianceDetail(dataFilter[0].compliance_value);
                setRestInterval(dataFilter1[0].compliance_value)
                setPerHourWages(dataFilter3[0].compliance_value);
                setComplianceData(response.data);
            } else if (response.message == "Access denied! you're not eligible for NFC") {
                toast.error(response.message)
                navigate('/employer/dashboard')
            } else if (response.message == 'Session expired') {
                localStorage.removeItem("employer");
                navigate('/employer/login')
            }
        } catch (error) {
            toast.error("error :", error);
            if (error.message == 'Session expired') {
                localStorage.removeItem("employer");
                navigate('/employer/login')
            }
        }
    }
    useEffect(() => {
        GetcomplianceDetail()
    }, [])

    const updateCompliance = async (value, id) => {
        try {
            const response = await postApi('/update-compliance', {
                id: id,
                value: value
            })
            if (response.status === true) {
                GetcomplianceDetail()
                toast.success(response.message)
            } else {
                toast.error(response.message)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const logOut = () => {
        if (localStorage.getItem("employer")) {
            localStorage.removeItem("employer");
        }
        navigate('/employer/login');
    }


    return (
        <div>


            <Button ref={addlocationref} style={{ display: "none" }} variant="primary" onClick={handleShow}>
                Add Location
            </Button>

            <Modal show={show} onHide={handleClose} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Location</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12">
                            <label>Add Sub-Location<i className="fa-solid fa-circle-close text-danger m-1"></i> {/* {AddForm.touched.job_location && !AddForm.errors.job_location && AddForm.touched.premium_value && !AddForm.errors.premium_value && <i className="fa-solid fa-circle-check"></i>} */}</label>
                        </div>
                        <div className="col-md-12">
                            <div className="mb-3">
                                <input type="text" className="form-control" placeholder="New Sub-Location" {...AddForm.getFieldProps('sub_location')} />
                                {AddForm.touched.sub_location && AddForm.errors.sub_location ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.sub_location}</div> : ''}
                            </div>
                        </div>
                        <div className="col-md-12">
                            <label>Add Premium Value<i className="fa-solid fa-circle-close text-danger m-1"></i> {/* {AddForm.touched.job_location && !AddForm.errors.job_location && AddForm.touched.premium_value && !AddForm.errors.premium_value && <i className="fa-solid fa-circle-check"></i>} */}</label>
                        </div>
                        <div className="col-md-12">
                            <div className="mb-3">
                                <div className="input-group">
                                    <span className="input-group-text" id="basic-addon1">$</span>
                                    <input type="number" className="form-control" placeholder="Premuim Value" {...AddForm.getFieldProps('sub_premuim')}
                                        aria-label="Username" aria-describedby="basic-addon1" />
                                    {/* {AddForm.touched.sub_premuim && AddForm.errors.sub_premuim ?
											<div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.sub_premuim}</div> : ''} */}
                                </div>
                            </div>
                        </div>

                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={(e) => { AddSublocation(); setsubLocationValidation(true) }}>
                        Add
                    </Button>
                </Modal.Footer>
            </Modal>

            <Button ref={editlocationref} style={{ display: "none" }} variant="primary" onClick={handleShowEditLocation}>
                Edit Location
            </Button>

            <Modal show={showEditLocation} onHide={handleCloseEditLocation} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Location</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12">
                            <label>Edit Location<i className="fa-solid fa-circle-close text-danger m-1"></i> {/* {AddForm.touched.job_location && !AddForm.errors.job_location && AddForm.touched.premium_value && !AddForm.errors.premium_value && <i className="fa-solid fa-circle-check"></i>} */}</label>
                        </div>
                        <div className="col-md-12">
                            <div className="mb-3">
                                <input type="text" className="form-control" placeholder="New Sub-Location" {...AddForm.getFieldProps('edit_location')} />
                                {AddForm.touched.edit_location && AddForm.errors.edit_location ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.edit_location}</div> : ''}
                            </div>
                        </div>
                        <div className="col-md-12">
                            <label>Edit Premium Value<i className="fa-solid fa-circle-close text-danger m-1"></i> {/* {AddForm.touched.job_location && !AddForm.errors.job_location && AddForm.touched.premium_value && !AddForm.errors.premium_value && <i className="fa-solid fa-circle-check"></i>} */}</label>
                        </div>
                        <div className="col-md-12">
                            <div className="mb-3">
                                <div className="input-group">
                                    <span className="input-group-text" id="basic-addon1">$</span>
                                    <input type="number" className="form-control" placeholder="Premuim Value" {...AddForm.getFieldProps('edit_location_premuim')}
                                        aria-label="Username" aria-describedby="basic-addon1" />
                                    {/* {AddForm.touched.sub_premuim && AddForm.errors.sub_premuim ?
										<div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.sub_premuim}</div> : ''} */}
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseEditLocation}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={(e) => { HandleEditJobLocationDetail(); /* setsubLocationValidation(true) */ }}>
                        Edit
                    </Button>
                </Modal.Footer>
            </Modal>


            <Button ref={showJobtyperef} style={{ display: "none" }} variant="primary" onClick={handleShowEditJobtype}>
                Job Type
            </Button>

            <Modal show={showEditJobType} onHide={handleCloseJobType} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Job Type</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-6">
                            <label>Edit Job Type<i className="fa-solid fa-circle-close text-danger m-1"></i> {/* {AddForm.touched.job_location && !AddForm.errors.job_location && AddForm.touched.premium_value && !AddForm.errors.premium_value && <i className="fa-solid fa-circle-check"></i>} */}</label>

                            <input type="text" className="form-control" placeholder="Edit Job Type" {...AddForm.getFieldProps('edit_job_type')} />
                            {AddForm.touched.edit_job_type && AddForm.errors.edit_job_type ?
                                <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.edit_job_type}</div> : ''}

                        </div>
                        <div className="col-md-6">
                            <label>Edit Limit<i className="fa-solid fa-circle-close text-danger m-1"></i></label>
                            <input type="number" className="form-control" placeholder="Edit Limit" {...AddForm.getFieldProps('edit_limit')} />
                            {AddForm.touched.edit_limit && AddForm.errors.edit_limit ?
                                <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.edit_limit}</div> : ''}

                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseJobType}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={(e) => { HandleEditJobType(); /* setsubLocationValidation(true) */ }}>
                        Edit
                    </Button>
                </Modal.Footer>
            </Modal>


            <Button ref={EditSublocationref} style={{ display: "none" }} variant="primary" onClick={handleShowEditSubLocation}>
                Edit Sub Location
            </Button>

            <Modal show={showEditSubLocation} onHide={handleCloseSubLocation} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Sub Location</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12">
                            <label>Edit Sub-Location<i className="fa-solid fa-circle-close text-danger m-1"></i> {/* {AddForm.touched.job_location && !AddForm.errors.job_location && AddForm.touched.premium_value && !AddForm.errors.premium_value && <i className="fa-solid fa-circle-check"></i>} */}</label>
                        </div>
                        <div className="col-md-12">
                            <div className="mb-3">
                                <input type="text" className="form-control" placeholder="Sub-Location" {...AddForm.getFieldProps('edit_sub_location')} />
                                {AddForm.touched.edit_sub_location && AddForm.errors.edit_sub_location ?
                                    <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.edit_sub_location}</div> : ''}
                            </div>
                        </div>
                        <div className="col-md-12">
                            <label>Add Premium Value<i className="fa-solid fa-circle-close text-danger m-1"></i> {/* {AddForm.touched.job_location && !AddForm.errors.job_location && AddForm.touched.premium_value && !AddForm.errors.premium_value && <i className="fa-solid fa-circle-check"></i>} */}</label>
                        </div>
                        <div className="col-md-12">
                            <div className="mb-3">
                                <div className="input-group">
                                    <span className="input-group-text" id="basic-addon1">$</span>
                                    <input type="number" className="form-control" placeholder="Sub Premuim Value" {...AddForm.getFieldProps('edit_sub_premium')}
                                        aria-label="Username" aria-describedby="basic-addon1" />
                                    {/* {AddForm.touched.edit_sub_premium && AddForm.errors.edit_sub_premium ?
										<div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.edit_sub_premium}</div> : ''} */}
                                </div>
                            </div>
                        </div>
                        {/* <div className='col-md-12'>
      <button onClick={(e) => { AddSublocation(); setsubLocationValidation(true) }} type='' className="btn btn-primary " style={{ cursor: 'pointer' }}>Add</button>
    </div> */}
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseSubLocation}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={(e) => { HandleEditSubLocation()/* ; setsubLocationValidation(true) */ }}>
                        Edit
                    </Button>
                </Modal.Footer>
            </Modal>

            <div className="modal fade show" style={OpenModals || OpenEditLocationModals || OpenEditJobType || OpenSubLocationModals ? { display: 'none' } : { display: 'block' }} id="stepmodalonload" aria-hidden="true" aria-labelledby="stepmodalonload" tabindex="-1" >
                <div className="modal-dialog businessDetailModal">
                    <div className="modal-content"  >
                        <div className="modal-body">
                            <form onSubmit={AddForm.handleSubmit}>
                                <fieldset className="mb-3" style={nextModal == 0 ? { display: "" } : { display: "none" }}>
                                    <div className="firstDesigSection">
                                        <div className="row g-0">
                                            <div className="col-md-3">
                                                <div className="leftBoxBusnessDetail">

                                                    <div className="nameLeft fas fa-user" />{/* LK</div> */}
                                                    {/* <p>Lokendra Kushwaha</p> */}
                                                    <ul className="leftMenuBar">
                                                        <li className="firstMenu">Business Details</li>
                                                        <li>Complaince</li>
                                                        <li>Location Premium & Job Type</li>
                                                        <li>Create Shift</li>
                                                        <li>Leaves</li>
                                                        <li>Add Team Member</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-md-9">
                                                <div className="BusinessRightDetail">
                                                    <div className='d-flex justify-content-between'>
                                                        <h3 className="mb-1">Business Details</h3>
                                                        <span className='btn btn-danger' onClick={() => logOut()} style={{ textTransform: 'none' }}>Logout </span>
                                                    </div>
                                                    <p>Tell us a bit more about your business</p>
                                                    <div className="row firstModalForm">
                                                        <div className="col-md-3">
                                                            <label>Your business name {AddForm.values.business_name_true && <i className="fa-solid fa-circle-check"></i>}</label>
                                                            <div style={{ marginBottom: "1em" }} >
                                                                <input type="text" className="form-control" placeholder="Enter Business Name" {...AddForm.getFieldProps("business_name")} onChange={(e) => { handleFieldvalidations(e, "business_name") }} />
                                                                {AddForm.touched.business_name && AddForm.errors.business_name ?
                                                                    <div className="invalid-feedback" style={{ display: "block", marginTop: "-0.75em" }}>{AddForm.errors.business_name}</div> : ''}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <label>Your Phone Number {AddForm.values.mobile_number_true && <i className="fa-solid fa-circle-check"></i>}</label>
                                                            <div style={{ marginBottom: "1em" }} >
                                                                <input type="text" className="form-control" placeholder="Enter Mobile Number"
                                                                    {...AddForm.getFieldProps("mobile_number")}
                                                                    onChange={(e) => { updateNumber(e.target.value, 'mobile_number', 15) }} />
                                                                {AddForm.touched.mobile_number && AddForm.errors.mobile_number ?
                                                                    <div className="invalid-feedback" style={{ display: "block", marginTop: "-0.75em" }}>{AddForm.errors.mobile_number}</div> : ''}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <label>Your business Location {AddForm.values.business_location_true && <i className="fa-solid fa-circle-check"></i>}</label>
                                                            <div style={{ marginBottom: "1em" }} >
                                                                <input type="text" className="form-control" placeholder="Enter Business Location" {...AddForm.getFieldProps("business_location")} onChange={(e) => { handleFieldvalidations(e, "business_location") }} />
                                                                {AddForm.touched.business_location && AddForm.errors.business_location ?
                                                                    <div className="invalid-feedback" style={{ display: "block", marginTop: "-0.75em" }}>{AddForm.errors.business_location}</div> : ''}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <label>Country {AddForm.values.employer_country_true && <i className="fa-solid fa-circle-check"></i>}</label>
                                                            <select className="form-control mb-2" name="Country" {...AddForm.getFieldProps("employer_country")} >
                                                                <option value="">Select Country</option>
                                                                {country.map((e) => {
                                                                    return (
                                                                        <option key={e.id} value={e.name}>{e.name}</option>
                                                                    )
                                                                })
                                                                }
                                                            </select>

                                                            {AddForm.touched.employer_country && AddForm.errors.employer_country ?
                                                                <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.employer_country}</div> : ''}
                                                        </div>

                                                        <div className="col-md-3">
                                                            <label>System Type {AddForm.values.employer_system_type && <i className="fa-solid fa-circle-check"></i>}</label>
                                                            <div style={{ marginBottom: "1em" }} >
                                                                <div className="btnstorage">
                                                                    {selectedEmployeeUseType.map((option, index) => {
                                                                        return (
                                                                            <div key={index}>
                                                                                <input
                                                                                    className="checkbox-tools fs-bold"
                                                                                    type="radio"
                                                                                    name="employer_system_type"
                                                                                    id={`tool-${option.value}`}
                                                                                    value={option.value}
                                                                                    checked={AddForm.values.employer_system_type === option.value}
                                                                                    onChange={(e) => { handleFieldvalidations({ target: { value: option.value } }, "employer_system_type") }}
                                                                                    onBlur={AddForm.handleBlur}
                                                                                />
                                                                                <label className="for-checkbox-tools waves-effect waves-light" htmlFor={`tool-${option.value}`}>{option.key}</label>
                                                                            </div>)
                                                                    })}
                                                                </div>
                                                            </div>
                                                            {AddForm.touched.employer_system_type && AddForm.errors.employer_system_type ?
                                                                <div className="invalid-feedback" style={{ display: "block", marginTop: "-0.75em" }}>{AddForm.errors.employer_system_type}</div> : ''}
                                                        </div>




                                                        <div className="col-md-9">
                                                            <label>Number of employees {AddForm.values.employee_total_count_true && <i className="fa-solid fa-circle-check"></i>}</label>
                                                            <div style={{ marginBottom: "1em" }} >
                                                                <div className="btnstorage">
                                                                    {selectedEmployeeCount.map((option, index) => {
                                                                        return (
                                                                            <div key={index}>
                                                                                <input
                                                                                    className="checkbox-tools"
                                                                                    type="radio"
                                                                                    name="employee_total_count"
                                                                                    id={`tool-${option.value}`}
                                                                                    value={option.value}
                                                                                    checked={AddForm.values.employee_total_count === option.value}
                                                                                    // checked={isChecked}
                                                                                    // onChange={() => AddForm.setFieldValue('employee_total_count', option.value)}
                                                                                    onChange={(e) => { handleFieldvalidations({ target: { value: option.value } }, "employee_total_count") }}
                                                                                    onBlur={AddForm.handleBlur}
                                                                                />
                                                                                <label className="for-checkbox-tools waves-effect waves-light" htmlFor={`tool-${option.value}`}>{option.key}</label>
                                                                            </div>)
                                                                    })}
                                                                </div>
                                                            </div>
                                                            {AddForm.touched.employee_total_count && AddForm.errors.employee_total_count ?
                                                                <div className="invalid-feedback" style={{ display: "block", marginTop: "-0.75em" }}>{AddForm.errors.employee_total_count}</div> : ''}
                                                        </div>

                                                        <div className="col-md-12">
                                                            <label>What best describes your business? {AddForm.values.businessType_true && <i className="fa-solid fa-circle-check"></i>}</label>
                                                            <div style={{ marginBottom: "1em" }} >
                                                                <div className="yourBusiness">
                                                                    {['Security', 'Retail', 'Hospitality', 'Healthcare', 'Manufacturing', 'Logistics & Warehousing', 'Services', 'Other'].map((option, index) => (
                                                                        <div key={index}>
                                                                            <input
                                                                                className="checkbox-tools"
                                                                                type="radio"
                                                                                name="businessType"
                                                                                id={`tool-${option}`}
                                                                                value={option}
                                                                                checked={AddForm.values.businessType === option}
                                                                                // onChange={AddForm.handleChange}
                                                                                onChange={(e) => { handleFieldvalidations({ target: { value: option } }, "businessType") }}
                                                                                onBlur={AddForm.handleBlur}
                                                                            />
                                                                            <label className="for-checkbox-tools waves-effect waves-light" htmlFor={`tool-${option}`}>
                                                                                <span className="circlespan">
                                                                                    {index === 0 && <i className="fa-solid fa-user-shield"></i>}
                                                                                    {index === 1 && <i className="fa-solid fa-shop"></i>}
                                                                                    {index === 2 && <i className="fa-solid fa-bell-concierge"></i>}
                                                                                    {index === 3 && <i className="fa-solid fa-heart-pulse"></i>}
                                                                                    {index === 4 && <i className="fa-solid fa-cubes"></i>}
                                                                                    {index === 5 && <i className="fa-solid fa-warehouse"></i>}
                                                                                    {index === 6 && <i className="fa-solid fa-network-wired"></i>}
                                                                                    {index === 7 && <i className="a-solid fa-up-right-from-square"></i>}

                                                                                </span>
                                                                                {option}
                                                                            </label>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                            {AddForm.touched.businessType && AddForm.errors.businessType ?
                                                                <div className="invalid-feedback" style={{ display: "block", marginTop: "-0.75em" }}>{AddForm.errors.businessType}</div> : ''}
                                                        </div>

                                                        <div className="col-md-4">
                                                            {/* <button onClick={handleContinue} className="continueBtnBusnessBox">Continue</button> */}
                                                            <input type="submit" name="next" className="continueBtnBusnessBox" value="Submit" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>

                                <fieldset className="mb-3" style={nextModal == 1 ? { display: "" } : { display: "none" }}>
                                    <div className="firstDesigSection">
                                        <div className="row g-0">
                                            <div className="col-md-3">
                                                <div className="leftBoxBusnessDetail">
                                                    <div className="nameLeft fas fa-user" />{/* LK</div> */}
                                                    {/* <p>Lokendra Kushwaha</p> */}
                                                    <ul className="leftMenuBar">
                                                        <li className='firstMenu active'>Business Details <span><i className="fa-solid fa-check"></i></span></li>
                                                        <li className="firstMenu">Compliance</li>
                                                        <li>Location Premium & Job Type</li>
                                                        <li>Create Shift</li>
                                                        <li>Leaves</li>
                                                        <li>Add Team Member</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-md-9">
                                                <div className="BusinessRightDetail">
                                                    <div className='d-flex justify-content-between'>
                                                        <h3 className="mb-1">Compliance</h3>
                                                        <span className='btn btn-danger' onClick={() => logOut()} style={{ textTransform: 'none' }}>Logout </span>
                                                    </div>
                                                    <p>Tell us a bit more about Compliance</p>
                                                    <button type="button" name="previous" className="previous action-button-previous btn btn-primary" onClick={() => setNextModal(nextModal - 1)} >Back</button>

                                                    {/* <div className="row firstModalForm " style={{ overflow: 'scroll', height: '500px' }}>
                                                        {complianceData.length > 0 && complianceData.map((Items, index) => {
                                                            return (
                                                                <div className="col-md-4">
                                                                    <label className='fw-bold'>{Items.compliance_type ? Items.compliance_type.replace(/_/g, " ") : "--"}
                                                                        <span
                                                                            data-bs-toggle="tooltip"
                                                                            data-bs-placement="top"
                                                                            title={
                                                                                Items.compliance_type === "weekly_hours"
                                                                                    ? "Note: No adult worker shall be required or allowed to work in a factory for more than given by employer in any week."
                                                                                    : ""
                                                                            }
                                                                        >
                                                                            <i className="mx-1 fa-solid fa-circle-info" />
                                                                        </span>
                                                                    </label>
                                                                    <div style={{ marginBottom: "1em" }} >
                                                                        <input type="text" className="form-control" placeholder="Enter Business Name"
                                                                            defaultValue={Items.compliance_value ? Items.compliance_value : "0"}
                                                                            onBlur={(e) => updateCompliance(e.target.value, Items.id)}
                                                                        />
                                                                        <div className='text-wrap text-primary' style={{ textTransform: 'none', fontSize: '10px' }}>
                                                                            {Items.compliance_type == "weekly_hours" && (<span> Note: No adult worker shall be required or allowed to work in a factory for more than given by employer in any week.</span>)}
                                                                            {Items.compliance_type == "daily_hours" && (<span> Note: No adult worker shall be required or allowed to work in a factory for more than given by employer in any day.</span>)}
                                                                            {Items.compliance_type == "interval_for_rest" && (<span> Note: No period shall exceed than given by employer and that no worker shall work for more than given by employer before he has had an interval for rest of at least half an hour.</span>)}
                                                                            {Items.compliance_type == "spread_over" && (<span> Note: The periods of work of an adult worker in a factory shall be so arranged that they shall not spread over more than ten and a half hours in any day.</span>)}
                                                                            {Items.compliance_type == "night_shifts" && (
                                                                                <span> Note: Where a worker in a factory works on a shift which extends beyond midnight, the following day for him shall be deemed to be the period of twenty-four hours beginning when such shift ends, and the hours he has worked after midnight shall be counted in the previous day.
                                                                                </span>)}
                                                                            {Items.compliance_type == "prohibition_of_overlapping_shifts" &&
                                                                                <span> Note: If the factory operates two or more shifts, it should ensure that no worker is engaged in more than one shift per day, preventing overlapping shifts.
                                                                                    <br />Overtime Workers who work for more than nine hours a day or more than forty-eight hours a week are entitled to overtime wages at twice their ordinary rate of wages. The "ordinary rate of wages" includes basic wages and allowances but excludes bonuses and overtime wages.
                                                                                </span>}
                                                                            {Items.compliance_type == "payment_of_wages_during_leave" && (<span> Note:  Wages during leave are calculated at a rate equal to the daily average of the worker's total full-time earnings for the days worked during the month immediately preceding the leave, exclusive of overtime and bonus but inclusive of dearness allowance and the cash equivalent of concessional sales</span>)}
                                                                            {Items.compliance_type == "application_and_rant_of_leave" && (
                                                                                <span> Note:
                                                                                    A worker can apply for leave in writing, at least 15 days in advance (30 days for public utility services).
                                                                                    Leave can be taken in no more than three installments during a year.<br />
                                                                                    Leave applications cannot be refused if they comply with the provisions.
                                                                                </span>
                                                                            )}
                                                                            {Items.compliance_type == "encashment_of_leave" && (<span> Note: If a worker's employment is terminated before they have taken their entitled leave, the occupier must pay the worker for the leave not taken. Payment must be made within two days of termination or on the next payday if the worker quits</span>)}
                                                                            {Items.compliance_type == "leave_carry_forward" && (<span> Note:  Unused leave in a calendar year can be carried forward to the next year, but the total carried forward leave cannot exceed 30 days for adults or 40 days for children. However, if a worker's leave application is refused, the unavailed leave can be carried forward without any limit</span>)}
                                                                            {Items.compliance_type == "marked_by" && "--"}

                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            )
                                                        })}
                                                        <div className="col-md-4"></div>
                                                        <div className="col-md-4" onClick={() => setNextModal(nextModal + 1)}>
                                                            <button onClick={handleContinue} className="continueBtnBusnessBox">Continue</button>
                                                            <input
                                                                type="submit"
                                                                name="next"

                                                                className="continueBtnBusnessBox"
                                                                value="Submit" />
                                                        </div>
                                                    </div> */}

                                                    <div className="card-body p-0" >
                                                        <div className="table-responsive" style={{ overflow: 'scroll', height: '400px' }} >
                                                            <table className="table table-hover card-table table-vcenter text-nowrap" >
                                                                <thead className="border-bottom-0 pt-3 pb-3" >
                                                                    <tr>
                                                                        <th className="font-weight-bold">Complaince Type </th>
                                                                        <th className="font-weight-bold">Complaince Value</th>
                                                                        <th className="font-weight-bold">Updated Date </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {complianceData?.length > 0 && complianceData.map((Item, index) => {
                                                                        return (
                                                                            <tr key={index}>
                                                                                <td><span className="font-weight-normal1 mt-0">{Item.compliance_type ? Item.compliance_type.replace(/_/g, " ") : "--"}</span></td>
                                                                                <td><span className="font-weight-normal1">
                                                                                    <input className='form-control' type='number'
                                                                                        defaultValue={Item.compliance_value ? Item.compliance_value : "0"}
                                                                                        onBlur={(e) => updateCompliance(e.target.value, Item.id)}
                                                                                    ></input>
                                                                                    <div className='text-wrap text-primary' style={{ textTransform: 'none', fontSize: '10px' }}>
                                                                                        {Item.compliance_type == "weekly_hours" && (<span> Note: No adult worker shall be required or allowed to work in a factory for more than given by employer in any week.</span>)}
                                                                                        {Item.compliance_type == "daily_hours" && (<span> Note: No adult worker shall be required or allowed to work in a factory for more than given by employer in any day.</span>)}
                                                                                        {Item.compliance_type == "interval_for_rest" && (<span> Note: No period shall exceed than given by employer and that no worker shall work for more than given by employer before he has had an interval for rest of at least half an hour.</span>)}
                                                                                        {Item.compliance_type == "spread_over" && (<span> Note: The periods of work of an adult worker in a factory shall be so arranged that they shall not spread over more than ten and a half hours in any day.</span>)}
                                                                                        {Item.compliance_type == "night_shifts" && (
                                                                                            <span> Note: Where a worker in a factory works on a shift which extends beyond midnight, the following day for him shall be deemed to be the period of twenty-four hours beginning when such shift ends, and the hours he has worked after midnight shall be counted in the previous day.
                                                                                            </span>)}
                                                                                        {Item.compliance_type == "prohibition_of_overlapping_shifts" &&
                                                                                            <span> Note: If the factory operates two or more shifts, it should ensure that no worker is engaged in more than one shift per day, preventing overlapping shifts.
                                                                                                <br />Overtime Workers who work for more than nine hours a day or more than forty-eight hours a week are entitled to overtime wages at twice their ordinary rate of wages. The "ordinary rate of wages" includes basic wages and allowances but excludes bonuses and overtime wages.
                                                                                            </span>}
                                                                                        {Item.compliance_type == "payment_of_wages_during_leave" && (<span> Note:  Wages during leave are calculated at a rate equal to the daily average of the worker's total full-time earnings for the days worked during the month immediately preceding the leave, exclusive of overtime and bonus but inclusive of dearness allowance and the cash equivalent of concessional sales</span>)}
                                                                                        {Item.compliance_type == "application_and_rant_of_leave" && (
                                                                                            <span> Note:
                                                                                                A worker can apply for leave in writing, at least 15 days in advance (30 days for public utility services).
                                                                                                Leave can be taken in no more than three installments during a year.<br />
                                                                                                Leave applications cannot be refused if they comply with the provisions.
                                                                                            </span>
                                                                                        )}
                                                                                        {Item.compliance_type == "encashment_of_leave" && (<span> Note: If a worker's employment is terminated before they have taken their entitled leave, the occupier must pay the worker for the leave not taken. Payment must be made within two days of termination or on the next payday if the worker quits</span>)}
                                                                                        {Item.compliance_type == "leave_carry_forward" && (<span> Note:  Unused leave in a calendar year can be carried forward to the next year, but the total carried forward leave cannot exceed 30 days for adults or 40 days for children. However, if a worker's leave application is refused, the unavailed leave can be carried forward without any limit</span>)}
                                                                                        {Item.compliance_type == "marked_by" && "--"}

                                                                                    </div></span>

                                                                                </td>
                                                                                <td><span className="font-weight-normal1">{moment(Item.updated_at).local().format('YYYY-MM-DD hh:mm A')}</span></td>
                                                                            </tr>

                                                                        )
                                                                    })}
                                                                </tbody>

                                                            </table>
                                                        </div>
                                                        <div className="col-md-4" onClick={() => setNextModal(nextModal + 1)}>
                                                            {/* <button onClick={handleContinue} className="continueBtnBusnessBox">Continue</button> */}
                                                            <input
                                                                type="submit"
                                                                name="next"
                                                                className="continueBtnBusnessBox"
                                                                value="Continue" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>

                                <fieldset className="mb-3" style={nextModal == 2 ? { display: "" } : { display: "none" }}>
                                    <div className="secondDesigSection">
                                        <div className="row g-0">
                                            <div className="col-md-3">
                                                <div className="leftBoxBusnessDetail">
                                                    <div className="nameLeft fas fa-user"></div>
                                                    {/* <p>Lokendra Kushwaha</p> */}
                                                    <ul className="leftMenuBar">
                                                        <li className="firstMenu active">Business Details <span><i className="fa-solid fa-check"></i></span></li>
                                                        <li className="firstMenu active">Compliance <span><i className="fa-solid fa-check"></i></span></li>
                                                        <li className="firstMenu">Location Premium & Job Type</li>
                                                        <li>Create Shift</li>
                                                        <li>Leaves</li>
                                                        <li>Add Team Member</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-md-9">
                                                <div className="BusinessRightDetail">
                                                    <div className='d-flex justify-content-between'>
                                                        <h3 className="mb-1">Location Premium & Job Type</h3>
                                                        <span className='btn btn-danger' onClick={() => logOut()} style={{ textTransform: 'none' }}>Logout </span>
                                                    </div>
                                                    <p>You can add location and job type here.</p>
                                                    <button type="button" name="previous" className="previous action-button-previous btn btn-primary" onClick={() => setNextModal(nextModal - 1)} >{/* <i className="fa-solid fa-arrow-left-long fa-fw "></i> */} Back</button>

                                                    <div className="row firstModalForm">

                                                        <div className="col-md-6 row">
                                                            <label>Add Job Type
                                                                <span style={{ marginLeft: '75px' }}>Employee Limit</span>
                                                                {/* {AddForm.touched.job_type && !AddForm.errors.job_type && <i className="fa-solid fa-circle-check text-success"></i>} */}
                                                            </label>
                                                            <div className="col-md-5">
                                                                <input type="text" className="form-control" placeholder="Enter New Job" {...AddForm.getFieldProps("job_type")} />

                                                                {AddForm.touched.job_type && AddForm.errors.job_type ?
                                                                    <div className="invalid-feedback" style={{ display: "block" }}>
                                                                        {AddForm.errors.job_type}
                                                                    </div>
                                                                    : ''
                                                                }
                                                            </div>
                                                            <div className="col-md-5">
                                                                <input type="number" className="form-control" placeholder="Enter Limit" {...AddForm.getFieldProps("limit")} />
                                                            </div>
                                                            <div className='col-md-2'>
                                                                <span onClick={() => { setsubLocationValidation(false); setAddLocation(false) }}>
                                                                    {AddForm.values.job_type.trim() ?
                                                                        <button onClick={(e) => { let data = AddForm.values.job_type ? AddJob(e) : '' }} className="addBtnJob" style={{ cursor: 'pointer' }}>Add</button>
                                                                        :
                                                                        <div disabled onClick={(e) => { let data = AddForm.values.job_type ? "" : '' }} className="addBtnJob" style={{ cursor: 'pointer', opacity: 0.5, pointerEvents: 'none' }}>Add</div>

                                                                    }
                                                                </span>

                                                            </div>
                                                        </div>

                                                        <div className="col-md-6 row">
                                                            <label>Add Location
                                                                {/* {AddForm.touched.job_location && !AddForm.errors.job_location && AddForm.touched.premium_value && !AddForm.errors.premium_value && <i className="fa-solid fa-circle-check"></i>} */}
                                                                <span style={{ marginLeft: '75px' }}>Location Premium</span></label>
                                                            <div className="col-md-5">
                                                                <input type="text" className="form-control" placeholder="New Location" {...AddForm.getFieldProps("job_location")} />
                                                                {AddForm.touched.job_location && AddForm.errors.job_location ?
                                                                    <div className="invalid-feedback" style={{ display: "block" }}>
                                                                        {AddForm.errors.job_location}
                                                                    </div>
                                                                    : ''
                                                                }
                                                            </div>

                                                            <div className="col-md-5">
                                                                <div className="input-group">
                                                                    <span class="input-group-text" id="basic-addon1">$</span>
                                                                    <input type="number" className="form-control" placeholder="Value"
                                                                        {...AddForm.getFieldProps("premium_value")}
                                                                        aria-label="Username" aria-describedby="basic-addon1" />

                                                                </div>
                                                                {AddForm.touched.premium_value && AddForm.errors.premium_value ?
                                                                    <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.premium_value}</div> : ''}
                                                            </div>
                                                            <div className='col-md-2 h-100'
                                                                onClick={() => setAddLocation(true)}
                                                            >
                                                                {AddForm.values.job_location.trim() ?
                                                                    <div
                                                                        type='submit'
                                                                        onClick={(e) => {
                                                                            if (AddForm.values.job_location) {
                                                                                jobLocations(e)
                                                                            }
                                                                        }}
                                                                        className="btn btn-primary h-40"
                                                                        style={{ cursor: 'pointer' }}
                                                                    >Add
                                                                    </div> : <div
                                                                        type=''
                                                                        onClick={(e) => {
                                                                            if (AddForm.values.job_location) {

                                                                            }
                                                                        }}
                                                                        className="btn btn-primary h-40"
                                                                        style={{ cursor: 'pointer', opacity: 0.5, pointerEvents: 'none' }}
                                                                    >Add
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="jobtypeBox">
                                                                <span className='text-bold'>Job Type</span>
                                                                <div className="accordion" id="accordionExample1">
                                                                    {(data1 && data1.length > 0) && data1.map((data, index) => (
                                                                        <div className="accordion-item" key={index}>
                                                                            <h2 className="accordion-header d-flex align-items-center justify-content-between" id={`heading1${index}`}>
                                                                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse1${index}`} aria-expanded="true" aria-controls={`collapse1${index}`}>
                                                                                    <div>
                                                                                        {data?.job_type}<br />
                                                                                        Employee Limit: {data?.limit ? data?.limit : "0"}
                                                                                    </div>
                                                                                </button>

                                                                                <small /*  className="d-flex align-items-center" */>
                                                                                    <div className='m-1' style={{ fontSize: 'small' }}
                                                                                        onClick={() => handleManageSubjobType(data?.id)}
                                                                                    /* onClick={() => { setSubJObTypeId(data?.id); setmanageSubjobType(!manageSubjobType) }} */>
                                                                                        <i className='fas fa-plus text-success' title="Add"></i>
                                                                                    </div>
                                                                                    <div className='m-1' style={{ fontSize: 'small' }} onClick={() => HandleDeleteJobType(data?.id, GetJobType)}>
                                                                                        <i className='fas fa-trash text-danger' title="Delete"></i>
                                                                                    </div>
                                                                                    <div className='m-1' style={{ fontSize: 'small' }} onClick={() => { setEditJobTypeID(data?.id); setEditJobType(data?.job_type); setEditLimit(data?.limit); setOpenEditJobType(!OpenEditJobType); showJobtyperef.current.click(); }}>
                                                                                        <i className='fas fa-edit text-dark' title="Edit"></i>
                                                                                    </div>
                                                                                </small>
                                                                            </h2>
                                                                            {manageSubjobType[data?.id] &&
                                                                                <div className='row'>
                                                                                    <div className='col-md-5 mt-1'>
                                                                                        <input type="text" className="form-control" placeholder="Sub Job Type" {...AddForm.getFieldProps("sub_job_type")} style={{ border: '1px solid rgb(11 49 106)' }} />
                                                                                        {AddForm.touched.sub_job_type && AddForm.errors.sub_job_type ? <div className="invalid-feedback" style={{ display: "block", fontSize: '12px' }}>{AddForm.errors.sub_job_type}</div> : ''}
                                                                                    </div>
                                                                                    <div className='col-md-4 mt-1'>
                                                                                        <input type="number" className="form-control" placeholder="Emp Limit" {...AddForm.getFieldProps("sub_limit")} style={{ border: '1px solid rgb(11 49 106)' }} />
                                                                                        {AddForm.touched.sub_limit && AddForm.errors.sub_limit ? <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.sub_limit}</div> : ''}
                                                                                    </div>
                                                                                    <div className='col-md-3 mt-3 d-flex' style={{ alignItems: 'baseline' }}>
                                                                                        {AddForm.values.sub_job_type && AddForm.values.sub_limit ?

                                                                                            <div type="submit" className='fas fa-plus btn btn-primary'
                                                                                                onClick={() => AddSubJobType()}></div> :
                                                                                            <button type="submit" className='fas fa-plus btn btn-primary ' disabled ></button>}
                                                                                        <button ref={HandleModalForSubJobTyperef} className='fa fa-close btn btn-danger ms-1' onClick={() => handleCloseSubjobType(data?.id)}></button>
                                                                                    </div></div>
                                                                            }
                                                                            <div id={`collapse1${index}`} className="accordion-collapse collapse" aria-labelledby={`heading1${index}`} data-bs-parent="#accordionExample1">
                                                                                <div className="accordion-body" style={{ padding: '10px 0px 0px 10px' }}>
                                                                                    <div className="accordion" id="accordionExample2">
                                                                                        <div className="accordion-item">

                                                                                            {data?.subJobType && data.subJobType.map((Subjobtype, index) => {
                                                                                                return (
                                                                                                    <>
                                                                                                        <h2 className="accordion-header d-flex align-items-center justify-content-between" id={`heading2${index}`}>
                                                                                                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse2${index}`} aria-expanded="true" aria-controls={`collapse2${index}`}>
                                                                                                                <div className='d-flex justify-content-between'>
                                                                                                                    <span className='text-primary'>{Subjobtype?.sub_job_type}<br />
                                                                                                                        Employee Limit: {Subjobtype?.sub_limit}</span>
                                                                                                                </div>
                                                                                                            </button>
                                                                                                            <div /* className='d-flex align-items-center' */>
                                                                                                                <div className='m-1' style={{ fontSize: 'small' }}
                                                                                                                    onClick={() => handleManageSubjobTypeLevel2(Subjobtype?.id, "Add")}
                                                                                                                ><i className='fas fa-plus text-success'></i></div>
                                                                                                                <div className='m-1' title="Delete" style={{ fontSize: 'small' }}
                                                                                                                    onClick={() => HandleDeleteSubJobType(Subjobtype?.id, GetJobType)}
                                                                                                                ><i className='fas fa-trash text-danger'></i></div>
                                                                                                                <div className='m-1' title="Edit" style={{ fontSize: 'small' }}
                                                                                                                    onClick={() => {
                                                                                                                        handleManageSubjobTypeLevel2(Subjobtype?.id, "Edit");
                                                                                                                        setSubjobtypeName(Subjobtype?.sub_job_type); setSubjobtypeLimit(Subjobtype?.sub_limit)
                                                                                                                    }}><i className='fas fa-edit text-dark'></i></div>
                                                                                                            </div>
                                                                                                        </h2>

                                                                                                        {manageSubjobTypeLevel2[Subjobtype?.id] &&
                                                                                                            <div className='row'>
                                                                                                                <div className='col-md-5 mt-1'>
                                                                                                                    <input type="text" className="form-control" placeholder="Sub Job Type" {...AddForm.getFieldProps("sub_l2_job_type")} style={{ border: '1px solid rgb(11 49 106)' }} />
                                                                                                                    {AddForm.touched.sub_l2_job_type && AddForm.errors.sub_l2_job_type ? <div className="invalid-feedback" style={{ display: "block", fontSize: '12px' }}>{AddForm.errors.sub_l2_job_type}</div> : ''}
                                                                                                                </div>
                                                                                                                <div className='col-md-4 mt-1'>
                                                                                                                    <input type="number" className="form-control" placeholder="Emp Limit" {...AddForm.getFieldProps("sub_l2_limit")} style={{ border: '1px solid rgb(11 49 106)' }} />
                                                                                                                    {AddForm.touched.sub_l2_limit && AddForm.errors.sub_l2_limit ? <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.sub_l2_limit}</div> : ''}
                                                                                                                </div>
                                                                                                                <div className='col-md-3 mt-3 d-flex' style={{ alignItems: 'baseline' }}>
                                                                                                                    {AddForm.values.sub_l2_job_type && AddForm.values.sub_l2_limit ?

                                                                                                                        <div type="submit" className='fas fa-plus btn btn-primary'
                                                                                                                            onClick={() => AddSubJobTypeLevel2()}></div> :
                                                                                                                        <button type="submit" className='fas fa-plus btn btn-primary ' disabled ></button>}
                                                                                                                    <button ref={HandleModalForSubJobTyperefLevel2} className='fa fa-close btn btn-danger ms-1' onClick={() => handleCloseSubjobTypeLevel2(Subjobtype?.id)}></button>
                                                                                                                </div></div>
                                                                                                        }
                                                                                                        <div id={`collapse2${index}`} className="accordion-collapse collapse" aria-labelledby={`heading2${index}`} data-bs-parent="#accordionExample2">
                                                                                                            <div className="accordion-body">
                                                                                                                {Subjobtype.subL2JobType && Subjobtype.subL2JobType.map((LevelTwo, index) => {
                                                                                                                    return (
                                                                                                                        <>
                                                                                                                            <div key={index} className='d-flex justify-content-between' style={{ padding: '0px 0px 0px 30px' }}>

                                                                                                                                <span className='text-success'>{LevelTwo?.sub_l2_job_type}<br />
                                                                                                                                    Employee Limit: {LevelTwo?.sub_l2_limit}</span>
                                                                                                                                <div /* className='d-flex' */>

                                                                                                                                    <div className='m-1' style={{ fontSize: 'small' }} title="Delete"
                                                                                                                                        onClick={() => HandleDeleteSubJobTypeLevel2(LevelTwo?.id, GetJobType)}
                                                                                                                                    > <i className='fas fa-trash text-danger me-2'></i></div>
                                                                                                                                    <div className='m-1' style={{ fontSize: 'small' }} title="Edit"
                                                                                                                                        onClick={() => {
                                                                                                                                            handleManageSubjobTypeLevel3(LevelTwo?.id, "Edit");
                                                                                                                                            setL2SubjobtypeName(LevelTwo?.sub_l2_job_type); setL2SubjobtypeLimit(LevelTwo?.sub_l2_limit)
                                                                                                                                        }}
                                                                                                                                    ><i className='fas fa-edit text-dark'></i></div></div>
                                                                                                                            </div>
                                                                                                                            {manageSubjobTypeLevel3[LevelTwo?.id] &&
                                                                                                                                <div className='row'>
                                                                                                                                    <div className='col-md-5 mt-1'>
                                                                                                                                        <input type="text" className="form-control" placeholder="Sub Job Type" {...AddForm.getFieldProps("sub_l3_job_type")} style={{ border: '1px solid rgb(11 49 106)' }} />
                                                                                                                                        {AddForm.touched.sub_l3_job_type && AddForm.errors.sub_l3_job_type ? <div className="invalid-feedback" style={{ display: "block", fontSize: '12px' }}>{AddForm.errors.sub_l3_job_type}</div> : ''}
                                                                                                                                    </div>
                                                                                                                                    <div className='col-md-4 mt-1'>

                                                                                                                                        <input type="number" className="form-control" placeholder="Emp Limit" {...AddForm.getFieldProps("sub_l3_limit")} style={{ border: '1px solid rgb(11 49 106)' }} />
                                                                                                                                        {AddForm.touched.sub_l3_limit && AddForm.errors.sub_l3_limit ? <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.sub_l3_limit}</div> : ''}
                                                                                                                                    </div>
                                                                                                                                    <div className='col-md-3 mt-3 d-flex' style={{ alignItems: 'baseline' }}>
                                                                                                                                        {AddForm.values.sub_l3_job_type && AddForm.values.sub_l3_limit ?

                                                                                                                                            <div type="submit" className='fas fa-plus btn btn-primary'
                                                                                                                                                onClick={() => AddSubJobTypeLevel3()}></div> :
                                                                                                                                            <button type="submit" className='fas fa-plus btn btn-primary ' disabled ></button>}
                                                                                                                                        <button ref={HandleModalForSubJobTyperefLevel3} className='fa fa-close btn btn-danger ms-1' onClick={() => handleCloseSubjobTypeLevel3(LevelTwo?.id)}></button>
                                                                                                                                    </div></div>
                                                                                                                            }
                                                                                                                        </>
                                                                                                                    )
                                                                                                                }
                                                                                                                )}




                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </>
                                                                                                )
                                                                                            })}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="jobtypeBox">
                                                                <span className='text-bold'>Location</span>

                                                                <div class="accordion" id="accordionExample">
                                                                    {(data && data.length > 0) && data.map((item, index) => {
                                                                        return (
                                                                            <div class="accordion-item" key={index}>
                                                                                <h2 class="accordion-header d-flex align-items-center justify-content-between" id={`heading${index}`}>
                                                                                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${index}`} aria-expanded="true" aria-controls={`collapse${index}`}>
                                                                                        <div>
                                                                                            {item.job_location}
                                                                                            <br />
                                                                                            <span className='text-success'>Premium value: ${item?.premium_value ? item?.premium_value : '0'}</span>
                                                                                        </div>
                                                                                    </button>
                                                                                    <small>
                                                                                        <div className="m-1" style={{ fontSize: 'small' }}

                                                                                            onClick={() => { SetLocationID(item?.id); setOpenModals(!OpenModals); addlocationref.current.click() }}><i className="fas fa-add text-success"></i></div>
                                                                                        <div className='m-1' style={{ fontSize: 'small' }} onClick={() => HandleDeleteJobLocation(item?.id, GetTypeLocation)} ><i className='fas fa-trash text-danger' /></div>
                                                                                        <div className="m-1" style={{ fontSize: 'small' }}
                                                                                            onClick={() => {
                                                                                                SetLocationID(item?.id); setOpenEditLocationModals(!OpenEditLocationModals); editlocationref.current.click();
                                                                                                setlocationByID(item?.job_location); setpremiumByID(item?.premium_value)
                                                                                            }}><i className="fas fa-edit text-primary"></i></div>

                                                                                    </small>
                                                                                </h2>
                                                                                <div id={`collapse${index}`} class="accordion-collapse collapse" aria-labelledby={`heading${index}`} data-bs-parent="#accordionExample">
                                                                                    <div class="accordion-body">
                                                                                        {item?.subLocations.map((view, id) => {
                                                                                            return (
                                                                                                <div key={id} className='d-flex' style={{ justifyContent: 'space-between' }}>
                                                                                                    {view?.sub_location !== "" ? (
                                                                                                        <>{view.sub_location}: &nbsp;
                                                                                                        </>
                                                                                                    ) : (
                                                                                                        <span className='text-primary'>"No Data"</span>
                                                                                                    )}
                                                                                                    {view.premium_value ? "$" + view.premium_value : "$0"}
                                                                                                    <div className='d-flex '>


                                                                                                        <span className='fas fa-trash text-danger mx-2' onClick={() => HandleDeleteSubJobLocation(view?.id, GetTypeLocation)} />
                                                                                                        <span className='fas fa-edit text-dark' onClick={() => { setEditSubLocationID(view?.id); setSubLocationPremium(view?.premium_value); setEditSubLocation(view?.sub_location); setOpenSubLocationModals(!OpenSubLocationModals); EditSublocationref.current.click(); }} > </span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            );
                                                                                        })}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        );
                                                                    })}
                                                                </div>



                                                            </div>
                                                        </div>

                                                        <div className="col-md-4">

                                                            <input type='button' name="next" className="continueBtnBusnessBox" value="Continue"
                                                                style={{ cursor: 'pointer' }}
                                                                onClick={() => {
                                                                    handleClickFunction()
                                                                    if (data.length == 0) {
                                                                        setsubLocationValidation(false)
                                                                        setAddLocation(false)
                                                                    }
                                                                }}
                                                            />

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>


                                <fieldset className="mb-3" style={nextModal == 3 ? { display: "" } : { display: "none" }}>
                                    <div className="thirdDesigSection">
                                        <div className="row g-0">
                                            <div className="col-md-3">
                                                <div className="leftBoxBusnessDetail">
                                                    <div className="nameLeft fas fa-user"></div>
                                                    {/* <p>Lokendra Kushwaha</p> */}
                                                    <ul className="leftMenuBar">
                                                        <li className="firstMenu active">Business Details <span><i className="fa-solid fa-check"></i></span></li>
                                                        <li className="firstMenu active">Compliance <span><i className="fa-solid fa-check"></i></span></li>
                                                        <li className="firstMenu active">Location Premium & Job Type <span><i className="fa-solid fa-check"></i></span></li>
                                                        <li className="firstMenu">Create Shift</li>
                                                        <li>Leaves </li>
                                                        <li>Add Team Member</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-md-9">
                                                <div className="BusinessRightDetail">
                                                    <div className="row align-items-center">
                                                        <div className="col-md-9">
                                                            <div className='d-flex justify-content-between'>
                                                                <h3 className="mb-1">Create shift</h3>
                                                                <span className='btn btn-danger' onClick={() => logOut()} style={{ textTransform: 'none' }}>Logout </span>
                                                            </div>
                                                            <p>You can create designated shifts for your employees , you can update it later.</p>
                                                            <button type="button" name="previous" className="previous action-button-previous btn btn-primary" onClick={() => setNextModal(nextModal - 1)} >{/* <i className="fa-solid fa-arrow-left-long fa-fw "></i> */} Back</button>

                                                        </div>
                                                        <div className="col-md-3">
                                                            {(AddForm.values.break_minutes && AddForm.values.shift_name && AddForm.values.shift_start_time &&
                                                                AddForm.values.shift_end_time && AddForm.values.break_start_time) && (<div onClick={handleAddShift} className="antoherShiftBtn" style={{ cursor: 'pointer' }}>Add another shift</div>)}
                                                        </div>
                                                    </div>
                                                    <div className="row firstModalForm">
                                                        {dataArray.length > 0 &&
                                                            <div className="" >
                                                                <table className="table table-hover card-table table-vcenter text-nowrap">
                                                                    <thead className="border-bottom">
                                                                        <tr>
                                                                            <th className="font-weight-bold">shift Name </th>
                                                                            <th className="font-weight-bold">Start Time</th>
                                                                            <th className="font-weight-bold">End Time </th>
                                                                            <th className="font-weight-bold">Total Time </th>
                                                                            <th className="font-weight-bold">Break Duration </th>
                                                                            <th className="font-weight-bold">Break Time </th>
                                                                            <th className="font-weight-bold">Action </th>
                                                                            <th></th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {dataArray.length > 0 && dataArray.map((option, index) => {
                                                                            const shiftStartTime = new Date(`2000-01-01T${option.shift_start_time}`);
                                                                            const shiftEndTime = new Date(`2000-01-01T${option.shift_end_time}`);

                                                                            // Calculate total minutes
                                                                            let totalMinutes = (shiftEndTime - shiftStartTime) / (1000 * 60);

                                                                            // Adjust for overnight shifts
                                                                            if (totalMinutes < 0) {
                                                                                totalMinutes += 24 * 60;
                                                                            }

                                                                            // Calculate hours and minutes
                                                                            const totalHours = Math.floor(totalMinutes / 60);
                                                                            const remainingMinutes = totalMinutes % 60;

                                                                            return (
                                                                                <tr key={index} >
                                                                                    <td><span className="font-weight-normal1">{option?.shift_name}</span> </td>
                                                                                    <td><span className="font-weight-normal1">{option?.shift_start_time}</span></td>
                                                                                    <td><span className="font-weight-normal1">{option?.shift_end_time}</span></td>
                                                                                    <td><span className="font-weight-normal1">{totalHours} hours {remainingMinutes} minutes </span></td>
                                                                                    <td><span className="font-weight-normal1">{option?.break_minutes ? option?.break_minutes + " Min" : ""}</span></td>
                                                                                    <td><span className="font-weight-normal1">{option?.break_start_time}</span></td>
                                                                                    <td><span className="font-weight-normal1 fas fa-trash text-danger" onClick={() => HandleDeleteShift(option?.id)}></span></td>


                                                                                </tr>
                                                                            )
                                                                        }
                                                                        )}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        }
                                                        <div className="col-md-6">
                                                            <label>Shift name {AddForm.values?.shift_name?.length > 0 && <i className="fa-solid fa-circle-check"></i>}</label>
                                                            <div className="inputgrop">
                                                                <input type="text" className="form-control" placeholder="Enter Shift Name" {...AddForm.getFieldProps("shift_name")} />
                                                                {AddForm.touched.shift_name && AddForm.errors.shift_name ?
                                                                    <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.shift_name}</div> : ''}								</div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div style={{ display: "flex" }}>
                                                                <div className="col-md-6 mb-2">
                                                                    <label>Shift Start Time {AddForm.values?.shift_start_time?.length > 0 && <i className="fa-solid fa-circle-check"></i>}</label>
                                                                    <div className="inputgrop">
                                                                        <input type="time" className="form-control" placeholder="Enter Shift Name" {...AddForm.getFieldProps("shift_start_time")} />
                                                                        {AddForm.touched.shift_start_time && AddForm.errors.shift_start_time ?
                                                                            <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.shift_start_time}</div> : ''}
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <label>Shift End Time {AddForm.values?.shift_end_time?.length > 0 && <i className="fa-solid fa-circle-check"></i>}</label>

                                                                    <div className="inputgrop">
                                                                        <input type="time" className="form-control" placeholder="Enter Shift Name" {...AddForm.getFieldProps("shift_end_time")} onChange={handleendshifttime} />
                                                                        {AddForm.touched.shift_end_time && AddForm.errors.shift_end_time ?
                                                                            <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.shift_end_time}</div> : ''}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {AddForm.values.shift_end_time && AddForm.values.shift_end_time &&
                                                            <div className="col-md-12">
                                                                <label>Break Duration {AddForm.values.break_minutes.length > 0 && <i className="fa-solid fa-circle-check"></i>}</label>
                                                                <div className="btnstorage">
                                                                    {['15min', '30min', '45min', '1hour'].map(option => (
                                                                        <div key={option} style={{ display: 'inline-block', marginRight: '10px' }}>
                                                                            <input
                                                                                className="checkbox-tools"
                                                                                type="radio"
                                                                                name="break_minutes"
                                                                                id={`tool-${option}`}
                                                                                value={option}
                                                                                checked={AddForm.values.break_minutes === option}
                                                                                onChange={AddForm.handleChange}
                                                                                onBlur={AddForm.handleBlur}
                                                                            />
                                                                            <label className="for-checkbox-tools waves-effect waves-light" htmlFor={`tool-${option}`}>{option}</label>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                                {AddForm.touched.break_minutes && AddForm.errors.break_minutes ?
                                                                    <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.break_minutes}</div> : ''}

                                                                {AddForm.values.break_minutes && (
                                                                    <div className="col-md-6" style={{ marginTop: '10px' }}>
                                                                        {/* {(() => {
                                                                            if (AddForm.values.finderror) {
                                                                                AddForm.errors.break_start_time = AddForm.values.finderror
                                                                            }
                                                                            else {
                                                                                AddForm.errors.break_start_time = ""
                                                                            }
                                                                        })()} */}
                                                                        <label style={{ display: 'inline-block', marginRight: '10px' }}>Break Start Time {AddForm.touched.break_start_time && !AddForm.errors.break_start_time && AddForm.values.break_start_time && <i className="fa-solid fa-circle-check"></i>}</label>
                                                                        <input
                                                                            type="time"
                                                                            className="form-control"
                                                                            name="break_start_time"
                                                                            // value={AddForm.values.break_start_time}
                                                                            {...AddForm.getFieldProps("break_start_time")}
                                                                            onChange={(e) => { handleBreakTime(e) }}
                                                                            // onBlur={AddForm.handleBlur}
                                                                            style={{ display: 'inline-block' }}
                                                                        // min={AddForm.values.shift_end_time}
                                                                        />
                                                                        {AddForm.touched.break_start_time && AddForm.errors.break_start_time ?
                                                                            <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.break_start_time}</div> : ''}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        }
                                                        <div className="col-md-4">
                                                            {(AddForm.values.break_minutes && AddForm.values.shift_name && AddForm.values.shift_start_time &&
                                                                AddForm.values.shift_end_time && AddForm.values.break_start_time) ? (<div onClick={handleAddShift}
                                                                    style={{ cursor: 'pointer' }} className="continueBtnBusnessBox">Add shift</div>) :
                                                                <input type='button' name="next" className="continueBtnBusnessBox" value="Continue"
                                                                    style={{ cursor: 'pointer' }}
                                                                    onClick={() => handleClickShift()} />}

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </fieldset>

                                <fieldset className="mb-3" style={nextModal == 4 ? { display: "" } : { display: "none" }}>
                                    <div className="secondDesigSection">
                                        <div className="row g-0">
                                            <div className="col-md-3">
                                                <div className="leftBoxBusnessDetail">
                                                    <div className="nameLeft fas fa-user"></div>
                                                    {/* <p>Lokendra Kushwaha</p> */}
                                                    <ul className="leftMenuBar">
                                                        <li className="firstMenu active">Business Details <span><i className="fa-solid fa-check"></i></span></li>
                                                        <li className="firstMenu active">Compliance <span><i className="fa-solid fa-check"></i></span></li>
                                                        <li className="firstMenu active">Location Premium & Job Type <span><i className="fa-solid fa-check"></i></span></li>
                                                        <li className="firstMenu active">Create Shift <span><i className="fa-solid fa-check"></i></span></li>
                                                        <li className="firstMenu">Leaves</li>
                                                        <li>Add Team Member</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-md-9">
                                                <div className="BusinessRightDetail">

                                                    <div className='d-flex justify-content-between'>
                                                        <h3 className="mb-1">Leave</h3>
                                                        <span className='btn btn-danger' onClick={() => logOut()} style={{ textTransform: 'none' }}>Logout </span>
                                                    </div>
                                                    <p>You can manage leaves type here</p>
                                                    <button type="button" name="previous" className="previous action-button-previous btn btn-primary" onClick={() => setNextModal(nextModal - 1)} >{/* <i className="fa-solid fa-arrow-left-long fa-fw "></i> */} Back</button>

                                                    <div className="row">
                                                        {leave_types && leave_types.length > 0 && leave_types.map((data, index) => {

                                                            return (
                                                                <div className="col-md-2" key={index}>
                                                                    <h6>{data?.leave_name}</h6>
                                                                    <div className="number leavesManage d-flex align-items-center justify-content-center">
                                                                        {data?.leave_count == 0 ? <span className="minus" onClick={() => HandleLeavesDelete(data.id)}
                                                                        >
                                                                            <i className="fa-solid fa-trash text-danger"></i>
                                                                        </span> : <span className="minus" onClick={() =>
                                                                            setLeave_types(leave_types.map(item => item.leave_name === data.leave_name ?
                                                                                { ...item, leave_count: item.leave_count - 1 } : item)
                                                                            )}
                                                                        >
                                                                            <i className="fa-solid fa-minus"></i>
                                                                        </span>}
                                                                        <input
                                                                            type='text'
                                                                            value={data.leave_count}
                                                                            onChange={(e) => {
                                                                                let inputValue = e.target.value;
                                                                                if (inputValue === '' || /^\d{0,2}$/.test(inputValue)) {
                                                                                    const parsedValue = inputValue === '' ? '' : parseInt(inputValue);
                                                                                    setLeave_types(
                                                                                        leave_types.map(item =>
                                                                                            item.leave_name === data.leave_name ?
                                                                                                { ...item, leave_count: parsedValue } : item
                                                                                        )
                                                                                    );
                                                                                }
                                                                            }}
                                                                        />


                                                                        {data?.leave_count >= 99 ? <i className='fa-solid fa-close plus'></i> : <span className="plus"
                                                                            onClick={() =>
                                                                                setLeave_types(leave_types.map(item => item.leave_name === data.leave_name ?
                                                                                    { ...item, leave_count: item.leave_count + 1 } : item)
                                                                                )}
                                                                        >
                                                                            <i className="fa-solid fa-plus" ></i>
                                                                        </span>}
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                    <div className="row firstModalForm">
                                                        <div className="col-md-6">
                                                            <label>Add Leave Type {/* {AddForm.values.leave_name.length > 0 && <i className="fa-solid fa-circle-check"></i>} */}</label>

                                                            <div className="inputgrop d-flex">
                                                                <input type="text" className="form-control" placeholder="Enter Leave Type" {...AddForm.getFieldProps("leave_name")} />
                                                                <button
                                                                    onClick={() => {
                                                                        if (AddForm.values.leave_name) {
                                                                            const updatedLeaves = [{
                                                                                "leave_name": AddForm.values.leave_name,
                                                                                "leave_count": 0
                                                                            }, ...leave_types];

                                                                            setLeave_types(updatedLeaves);
                                                                            AddLeave(updatedLeaves);
                                                                        }
                                                                    }}
                                                                    disabled={AddForm.values.leave_name.replace(/\s/g, "").length == 0}
                                                                    className="btn btn-primary"
                                                                    style={{ cursor: 'pointer', height: '45px' }}
                                                                >Add</button></div>


                                                            {/* // onClick={() => {
																					// 	let data = AddForm.values.leave_name
																					// 		? setLeave_types([...leave_types, {
																					// 			"leave_name": AddForm.values.leave_name,
																					// 			"leave_count": 0
																					// 		}])
																					// 		: ''
																					// 	AddForm.setFieldValue("leave_name", "")

																					// }} */}


                                                            {/* {AddForm.touched.leave_name && AddForm.errors.leave_name ?
																				<div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.leave_name}</div> : ''} */}

                                                        </div>

                                                        <div className="col-md-4">
                                                            <input type='button' name="next" className="continueBtnBusnessBox" value="Continue"
                                                                style={{ cursor: 'pointer' }}

                                                                onClick={() => { handleClickFunction1(); AddLeave(leave_types);/* AddWages() */ }} />

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>

                                <fieldset className="mb-3" style={nextModal == 5 ? { display: "" } : { display: "none" }}>
                                    <div className="fifthDesigSection ">

                                        <div className="row g-0">
                                            <div className="col-md-3">
                                                <div className="leftBoxBusnessDetail">
                                                    <div className="nameLeft fas fa-user"></div>
                                                    {/* <p>Lokendra Kushwaha</p> */}
                                                    <ul className="leftMenuBar">
                                                        <li className="firstMenu active">Business Details <span><i className="fa-solid fa-check"></i></span></li>
                                                        <li className="firstMenu active">Compliance <span><i className="fa-solid fa-check"></i></span></li>
                                                        <li className="firstMenu active">Location Premium & Job Type <span><i className="fa-solid fa-check"></i></span></li>
                                                        <li className="firstMenu active">Create Shift <span><i className="fa-solid fa-check"></i></span></li>
                                                        <li className="firstMenu active">Leaves <span><i className="fa-solid fa-check"></i></span></li>
                                                        <li className="firstMenu">Add Team Member</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-md-9">
                                                <div className="BusinessRightDetail">
                                                    <div className="row align-items-center">
                                                        <div className="col-md-9">
                                                            <div className='d-flex justify-content-between'>
                                                                <h3 className="mb-1">Add Team Member</h3>
                                                                <span className='btn btn-danger' onClick={() => logOut()} style={{ textTransform: 'none' }}>Logout </span>
                                                            </div>
                                                            <p>Please add team members to your organisation</p>
                                                            <button type="button" name="previous" className="previous action-button-previous btn btn-primary" onClick={() => setNextModal(nextModal - 1)} >{/* <i className="fa-solid fa-arrow-left-long fa-fw "></i> */} Back</button>

                                                        </div>
                                                        <div className="col-md-3">
                                                            {(AddForm.values.first_name && AddForm.values.last_name && AddForm.values.employee_mobile_number && AddForm.values.department_id &&
                                                                AddForm.values.employee_code && AddForm.values.monthly_salary && AddForm.values.Pay_Cycle && AddForm.values.Country /*&&
																			AddForm.values.skill */) && (<div onClick={handleAddTeamMember} className="antoherShiftBtn" style={{ cursor: 'pointer' }}>Add Another Member</div>)}

                                                            {/* <button className="antoherShiftBtn"  onClick={AddWages}>Add another member</button> */}
                                                        </div>
                                                    </div>

                                                    <div className="row firstModalForm" style={{ overflow: 'scroll', height: '500px' }}>
                                                        {employeeList?.length > 0 &&
                                                            <div className="scrollbar-container" style={{ maxHeight: '200px', overflowY: 'auto' }}>
                                                                <table className="table table-hover card-table table-vcenter text-nowrap">
                                                                    <thead className="border-bottom">
                                                                        <tr>
                                                                            <th className="font-weight-bold">Employee Details  </th>
                                                                            <th className="font-weight-bold">Pay Cycle </th>
                                                                            <th className="font-weight-bold">Salary  </th>
                                                                            <th className="font-weight-bold">Department</th>
                                                                            <th className="font-weight-bold">Country  </th>
                                                                            <th></th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {employeeList.length > 0 && employeeList.slice(0).map((e, index) => {
                                                                            const { user_active_status, department_name, name, customer_id, salary, pay_cycle, deduction_code, deduction_amount_limit, employee_code, mobile_number, email, postal_address, age, first_name, last_name, per_hour_wage, title, ewa_agreement_doc, ewa_status } = e;

                                                                            return (
                                                                                <tr key={index} >
                                                                                    <td> <span className="font-weight-normal1">{first_name ? first_name : "--"}&nbsp;{e?.last_name ? last_name : ""}<br />{e?.mobile_number ? mobile_number : ""}</span></td>
                                                                                    <td>{e?.pay_cycle ? pay_cycle : "--"}</td>
                                                                                    <td>{e?.salary ? "$" + salary : "--"}</td>
                                                                                    <td><span className="font-weight-normal1 ">{department_name ? department_name : "--"}</span></td>
                                                                                    <td>{e?.name ? name : "--"}</td>
                                                                                </tr>
                                                                            )
                                                                        })}
                                                                    </tbody>
                                                                </table>
                                                            </div>

                                                        }
                                                        <hr />
                                                        <h6 className="mb-3 text-primary">Personal Details</h6>
                                                        <div className='col-md-12 row'>
                                                            <div className="col-md-4">
                                                                <label>First Name {AddForm.touched.first_name && !AddForm.errors.first_name && <i className="fa-solid fa-circle-check"></i>}</label>
                                                                <input type="text" className="form-control mb-0" placeholder="Enter Name" {...AddForm.getFieldProps("first_name")} />
                                                                {AddForm.touched.first_name && AddForm.errors.first_name ?
                                                                    <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.first_name}</div> : ''}
                                                            </div>
                                                            <div className="col-md-4">
                                                                <label>Last Name {AddForm.touched.last_name && !AddForm.errors.last_name && <i className="fa-solid fa-circle-check"></i>}</label>
                                                                <input type="text" className="form-control mb-0" placeholder="Enter Last Name" {...AddForm.getFieldProps("last_name")} />
                                                                {AddForm.touched.last_name && AddForm.errors.last_name ?
                                                                    <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.last_name}</div> : ''}
                                                            </div>
                                                            <div className="col-md-4">
                                                                <label>Mobile Number {AddForm.touched.employee_mobile_number && !AddForm.errors.employee_mobile_number && <i className="fa-solid fa-circle-check"></i>}</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control mb-0"
                                                                    placeholder="Enter Mobile Number"
                                                                    {...AddForm.getFieldProps("employee_mobile_number")}
                                                                    onChange={(e) => updateNumber(e.target.value, 'employee_mobile_number', 15)}
                                                                />
                                                                {AddForm.touched.employee_mobile_number && AddForm.errors.employee_mobile_number ?
                                                                    <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.employee_mobile_number}</div> : ''}							</div>
                                                            <div className="col-md-4 mt-2">
                                                                <label>Email {AddForm.touched.email && !AddForm.errors.email && <i className="fa-solid fa-circle-check"></i>}</label>
                                                                <input type="email" className="form-control mb-0" placeholder="Enter Email" {...AddForm.getFieldProps("email")} />
                                                                {AddForm.touched.email && AddForm.errors.email ?
                                                                    <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.email}</div> : ''}
                                                            </div>

                                                            <div className="col-md-4 mt-2">
                                                                <label>Country {AddForm.touched.Country && !AddForm.errors.Country && <i className="fa-solid fa-circle-check"></i>}</label>
                                                                <select className="form-control mb-0" name="Country" {...AddForm.getFieldProps("Country")} >
                                                                    <option>Select Country</option>
                                                                    {country.map((e) => {
                                                                        return (
                                                                            <option key={e.id} value={e.id}>{e.name}</option>
                                                                        )
                                                                    })
                                                                    }
                                                                </select>

                                                                {AddForm.touched.Country && AddForm.errors.Country ?
                                                                    <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.Country}</div> : ''}
                                                            </div>
                                                            <div className="col-md-4 mt-2">
                                                                <label>Resident Type {AddForm.touched.reside_type && !AddForm.errors.reside_type && <i className="fa-solid fa-circle-check"></i>}</label>
                                                                <select className="form-control mb-0" name="Resident Type" {...AddForm.getFieldProps("reside_type")} >
                                                                    <option>Select Resident Type</option>
                                                                    <option value="Resident">Resident</option>
                                                                    <option value="Non-resident">Non-resident</option>
                                                                </select>

                                                                {AddForm.touched.reside_type && AddForm.errors.reside_type ?
                                                                    <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.reside_type}</div> : ''}
                                                            </div>
                                                        </div>
                                                        <h6 className="mb-3 text-primary mt-3">Work Details</h6>
                                                        <div className='col-md-12 row'>
                                                            <div className="col-md-4">
                                                                <label>Employee Code {AddForm.touched.employee_code && !AddForm.errors.employee_code && <i className="fa-solid fa-circle-check"></i>}</label>
                                                                <input type="text" className="form-control mb-0" placeholder="Enter Employee Code" {...AddForm.getFieldProps("employee_code")} />
                                                                {AddForm.touched.employee_code && AddForm.errors.employee_code ?
                                                                    <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.employee_code}</div> : ''}
                                                            </div>
                                                            <div className="col-md-4">
                                                                <label>Monthly Salary {AddForm.touched.monthly_salary && !AddForm.errors.monthly_salary && <i className="fa-solid fa-circle-check"></i>}</label>
                                                                <input type="text" className="form-control mb-0" placeholder="Enter Monthly Salary" {...AddForm.getFieldProps("monthly_salary")}
                                                                    onChange={(e) => updateNumber(e.target.value, 'monthly_salary', 7)} />
                                                                {AddForm.touched.monthly_salary && AddForm.errors.monthly_salary ?
                                                                    <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.monthly_salary}</div> : ''}
                                                            </div>
                                                            <div className="col-md-4">
                                                                <label>Pay cycle {AddForm.touched.Pay_Cycle && !AddForm.errors.Pay_Cycle && <i className="fa-solid fa-circle-check"></i>}</label>
                                                                <select className="form-control mb-0" name="Pay_Cycle" {...AddForm.getFieldProps("Pay_Cycle")} >
                                                                    <option value="">Select Pay Cycle</option>
                                                                    <option value='weekly'>Weekly</option>
                                                                    <option value='Fortnightly'>Fortnightly</option>
                                                                    <option value='monthly'>Monthly</option>
                                                                </select>

                                                                {AddForm.touched.Pay_Cycle && AddForm.errors.Pay_Cycle ?
                                                                    <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.Pay_Cycle}</div> : ''}
                                                            </div>
                                                            <div className="col-md-4">
                                                                <label>Salary Type {/* {AddForm.touched.Pay_Cycle && !AddForm.errors.Pay_Cycle && <i className="fa-solid fa-circle-check"></i>} */}</label>
                                                                <select className="form-control mb-0" name="salary_type" {...AddForm.getFieldProps("salary_type")} >
                                                                    <option value="">Select Salary Type</option>
                                                                    <option value='monthly'>Monthly</option>
                                                                    <option value='wages_based'>Wages Based</option>
                                                                </select>

                                                                {AddForm.touched.salary_type && AddForm.errors.salary_type ?
                                                                    <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.salary_type}</div> : ''}
                                                            </div>
                                                            <div className="col-md-4">
                                                                <label>Deduction Amount Limit {/* {AddForm.touched.monthly_salary && !AddForm.errors.monthly_salary && <i className="fa-solid fa-circle-check"></i>} */}</label>
                                                                <input type="text" className="form-control" placeholder="Enter Deduction Amount Limit"
                                                                    {...AddForm.getFieldProps("deduction_amount_limit")} onChange={(e) => updateNumber(e.target.value, 'deduction_amount_limit', 7)} />
                                                                {AddForm.touched.deduction_amount_limit && AddForm.errors.deduction_amount_limit ?
                                                                    <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.deduction_amount_limit}</div> : ''}
                                                            </div>
                                                            <div className={showInput ? "col-md-6 mt-2" : "col-md-6 mt-2"} >
                                                                <label>Department {AddForm.touched.department_id && !AddForm.errors.department_id && <i className="fa-solid fa-circle-check"></i>}</label>
                                                                <div style={{ position: 'relative' }}>
                                                                    {showInput ? (
                                                                        <>
                                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    placeholder="Add Department"
                                                                                    // value={(e) => e.target.value}
                                                                                    // onChange={(e) => handleInputChangess(e.target.value)}
                                                                                    onChange={(e) => { setdepartment(e.target.value) }}
                                                                                    // onBlur={(e) => { setShowInput(false); }} 
                                                                                    style={{ paddingRight: '30px', marginRight: '10px' }} // Add marginRight to create space between input and button showInput
                                                                                />
                                                                                <i
                                                                                    className="fas fa-close text-primary"
                                                                                    style={{
                                                                                        position: 'absolute',
                                                                                        top: '35%',
                                                                                        right: '75px',
                                                                                        transform: 'translateY(-50%)',
                                                                                        cursor: 'pointer'
                                                                                    }}
                                                                                    onClick={() => setShowInput(false)}
                                                                                />
                                                                                <button
                                                                                    type="button"
                                                                                    name="previous"
                                                                                    className="previous action-button-previous btn btn-primary "
                                                                                    onClick={(e) => { if (department.replace(/\s/g, "") == "") { setShowInput(false); } else { handleInputChangess(department); setShowInput(false); setdepartment("") } }}
                                                                                    disabled={department.replace(/\s/g, "").length == 0}
                                                                                >
                                                                                    Add
                                                                                </button>
                                                                            </div>

                                                                        </>
                                                                    ) : (
                                                                        <select
                                                                            className="form-control mb-0"
                                                                            name="Department"
                                                                            {...AddForm.getFieldProps('department_id')}
                                                                        // value={departmentInput}
                                                                        // onChange={handleSelectChange}
                                                                        >
                                                                            <option value="">Select Department</option>
                                                                            {Departmentname.map((nfcdata) => (
                                                                                <option key={nfcdata.site_id} value={nfcdata.site_id}>
                                                                                    {nfcdata.nfc_site_name}
                                                                                </option>
                                                                            ))}
                                                                        </select>
                                                                    )}
                                                                    { }
                                                                    {!showInput ? (<i
                                                                        className="fas fa-plus text-primary"
                                                                        style={{
                                                                            position: 'absolute',
                                                                            top: '50%',
                                                                            right: '10px',
                                                                            transform: 'translateY(-50%)',
                                                                            cursor: 'pointer'
                                                                        }}
                                                                        onClick={addNamess}
                                                                    />) : ""
                                                                    }
                                                                </div>
                                                                {AddForm.touched.department_id && AddForm.errors.department_id ?
                                                                    <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.department_id}</div> : ''}
                                                            </div>




                                                            <div className="col-md-6 mt-2">
                                                                <label>Skills {inputNames?.replace(/\s/g, "").length > 0 || name.length > 0 /* && !AddForm.errors.skill */ && <i className="fa-solid fa-circle-check"></i>}</label>
                                                                <div style={{ position: 'relative' }}>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder="Add Skill"
                                                                        value={inputNames}
                                                                        // {...AddForm.getFieldProps('location_id')}

                                                                        onChange={(e) => setInputNames(e.target.value)}
                                                                        style={{ paddingRight: '30px' }} // Add padding for the icon
                                                                    />
                                                                    {inputNames?.replace(/\s/g, "").length > 0 && <i
                                                                        className="fas fa-plus text-primary"
                                                                        style={{ position: 'absolute', top: '50%', right: '10px', transform: 'translateY(-50%)', cursor: 'pointer' }}

                                                                        onClick={(e) => addSkill(e)}
                                                                    />}
                                                                    {AddForm.touched.skill && AddForm.errors.skill ?
                                                                        <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.skill}</div> : ''}
                                                                </div>
                                                                {name.length > 0 && name?.map((name, index) => (
                                                                    <span
                                                                        key={index}
                                                                        className="m-1 px-1 py-1 rounded border text-white"
                                                                        style={{ cursor: 'pointer', fontSize: '12px', backgroundColor: '#0F2F81' }}
                                                                    >
                                                                        {name} <span onClick={() => removeName(index)}>&times;</span>
                                                                    </span>
                                                                ))}
                                                            </div>
                                                        </div>
                                                        <h6 className="mb-3 mt-2 text-primary">Location Premium & Job Type</h6>
                                                        <div className='col-md-12 row'>
                                                            <div className="col-md-4">
                                                                <label>Location {AddForm.touched.location_id && !AddForm.errors.location_id && <i className="fa-solid fa-circle-check"></i>}</label>
                                                                <div style={{ position: 'relative' }}>
                                                                    <select
                                                                        className="form-control mb-0"
                                                                        name="location_id"
                                                                        {...AddForm.getFieldProps('location_id')}
                                                                    // value={departmentInput}
                                                                    // onChange={handleSelectChange}
                                                                    >
                                                                        <option value="">Select Location</option>
                                                                        {data.map((e) => (
                                                                            <option key={e.id} value={e.id}>
                                                                                {e.job_location}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                                {AddForm.touched.location_id && AddForm.errors.location_id ?
                                                                    <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.location_id}</div> : ''}
                                                            </div>

                                                            <div className="col-md-4">
                                                                <label>Sub Location  {AddForm.values.sub_location_id && AddForm.values.location_id && <i className="fa-solid fa-circle-check"></i>}</label>
                                                                <div style={{ position: 'relative' }}>
                                                                    <select
                                                                        className="form-control mb-0"
                                                                        name="location_id"
                                                                        {...AddForm.getFieldProps('sub_location_id')}
                                                                    // value={departmentInput}
                                                                    // onChange={handleSelectChange}
                                                                    >
                                                                        <option value="">Select Sub Location</option>
                                                                        {getSublocationDetail.map((data) => (
                                                                            <option key={data.id} value={data.id}>
                                                                                {data.sub_location}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-4">
                                                                <label>Job Type {AddForm.touched.job_id && !AddForm.errors.job_id && <i className="fa-solid fa-circle-check"></i>}</label>
                                                                <div style={{ position: 'relative' }}>
                                                                    <select
                                                                        className="form-control mb-0"
                                                                        name="job_id"
                                                                        {...AddForm.getFieldProps('job_id')}
                                                                    // value={departmentInput}
                                                                    // onChange={handleSelectChange}
                                                                    >
                                                                        <option value="">Select JOB Type</option>
                                                                        {data1.map((data) => (
                                                                            <option key={data.id} value={data.id}>
                                                                                {data.job_type}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                                {AddForm.touched.job_id && AddForm.errors.job_id ?
                                                                    <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.job_id}</div> : ''}
                                                            </div>
                                                            <div className="col-md-4 mt-2">
                                                                <label>Sub Job Type {AddForm.values.sub_job_id > 0 /* && !AddForm.errors.sub_job_id */ && <i className="fa-solid fa-circle-check"></i>}</label>
                                                                <div style={{ position: 'relative' }}>
                                                                    <select
                                                                        className="form-control"
                                                                        name="sub_job_id"
                                                                        {...AddForm.getFieldProps('sub_job_id')}
                                                                    // value={departmentInput}
                                                                    // onChange={handleSelectChange}
                                                                    >
                                                                        <option value="">Select Sub Job Type</option>
                                                                        {subJobType.map((data) => (
                                                                            <option key={data.id} value={data.id}>
                                                                                {data.sub_job_type}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-4 mt-2">
                                                                <label>Sub L2 Job Type {AddForm.values.subL2_job_id > 0 /* && !AddForm.errors.subL2_job_id */ && <i className="fa-solid fa-circle-check"></i>}</label>
                                                                <div style={{ position: 'relative' }}>
                                                                    <select
                                                                        className="form-control mb-0"
                                                                        name="subL2_job_id"
                                                                        {...AddForm.getFieldProps('subL2_job_id')}
                                                                    // value={departmentInput}
                                                                    // onChange={handleSelectChange}
                                                                    >
                                                                        <option value="">Select Sub Job Type</option>
                                                                        {subL2JobType.map((data) => (
                                                                            <option key={data.id} value={data.id}>
                                                                                {data.sub_l2_job_type}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <h6 className="mb-3 text-primary mt-3">Bank Detail</h6>
                                                        <div className='col-md-12 row mb-3 '>
                                                            <div className="col-md-4">
                                                                <label>Account Name </label>
                                                                <input type="text" className="form-control" placeholder="Enter Account Name"
                                                                    {...AddForm.getFieldProps("account_name")} />
                                                                {AddForm.touched.account_name && AddForm.errors.account_name ?
                                                                    <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.account_name}</div> : ''}
                                                            </div>
                                                            <div className="col-md-4">
                                                                <label>Bank Name </label>
                                                                <input type="text" className="form-control" placeholder="Enter Bank Name"
                                                                    {...AddForm.getFieldProps("bank_name")} />
                                                                {AddForm.touched.bank_name && AddForm.errors.bank_name ?
                                                                    <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.bank_name}</div> : ''}
                                                            </div>
                                                            <div className="col-md-4">
                                                                <label>Account Number </label>
                                                                <input type="number" className="form-control" placeholder="Enter Account Number"
                                                                    {...AddForm.getFieldProps("account_number")} />
                                                                {AddForm.touched.account_number && AddForm.errors.account_number ?
                                                                    <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.account_number}</div> : ''}
                                                            </div>
                                                            <div className="col-md-4">
                                                                <label>Branch Name </label>
                                                                <input type="text" className="form-control" placeholder="Enter Branch Name"
                                                                    {...AddForm.getFieldProps("branch")} />
                                                                {AddForm.touched.branch && AddForm.errors.branch ?
                                                                    <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.branch}</div> : ''}
                                                            </div>
                                                        </div>

                                                        <h6 className="mb-3 text-primary mt-3">Hours & Wages</h6>
                                                        <div className="col-md-12 row">
                                                            <div className='d-flex' style={{ justifyContent: 'space-between' }}>
                                                                <h6 className='.cbg-primary1 p-2 rounded'>Normal Hours</h6>
                                                                <h6 className='.cbg-primary1 p-2 rounded'/*  style={{ marginLeft: '0em' }} */>Time & Half</h6>
                                                                <h6 />
                                                            </div>
                                                            <div className="col-md-3 ">
                                                                <label>Total No Of Hrs {AddForm.touched.NT_total_hrs && !AddForm.errors.NT_total_hrs && <i className="fa-solid fa-circle-check"></i>}</label>
                                                                <input type="text" className="form-control mb-0" placeholder="Total No Of Hrs"
                                                                    {...AddForm.getFieldProps("NT_total_hrs")}
                                                                    onChange={(e) => updateNumber(e.target.value, 'NT_total_hrs', 5)} />
                                                                {AddForm.touched.NT_total_hrs && AddForm.errors.NT_total_hrs ?
                                                                    <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.NT_total_hrs}</div> : ''}
                                                            </div>

                                                            <div className="col-md-3 ">
                                                                <label>Per Hr Wages {AddForm.touched.NT_per_hrs_wages && !AddForm.errors.NT_per_hrs_wages && <i className="fa-solid fa-circle-check"></i>}</label>
                                                                <input type="text" className="form-control mb-0" placeholder="Per Hour Wages"
                                                                    {...AddForm.getFieldProps("NT_per_hrs_wages")}
                                                                    onChange={(e) => updateNumber(e.target.value, 'NT_per_hrs_wages', 5)} />
                                                                {AddForm.touched.NT_per_hrs_wages && AddForm.errors.NT_per_hrs_wages ?
                                                                    <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.NT_per_hrs_wages}</div> : ''}
                                                            </div>

                                                            <div className="col-md-3" >
                                                                <label>Total No Of Hr {AddForm.touched.TH_total_hrs && !AddForm.errors.TH_total_hrs && <i className="fa-solid fa-circle-check"></i>}</label>
                                                                <input type="text" className="form-control mb-0" placeholder="Total No Of Hrs"
                                                                    {...AddForm.getFieldProps("TH_total_hrs")}
                                                                    onChange={(e) => updateNumber(e.target.value, 'TH_total_hrs', 5)} />
                                                                {AddForm.touched.TH_total_hrs && AddForm.errors.TH_total_hrs ?
                                                                    <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.TH_total_hrs}</div> : ''}
                                                            </div>

                                                            <div className="col-md-3" >
                                                                <label>Per Hour Wages {AddForm.touched.TH_per_hrs_wages && !AddForm.errors.TH_per_hrs_wages && <i className="fa-solid fa-circle-check"></i>}</label>

                                                                <input type="text" className="form-control mb-0" placeholder="Per Hour Wages"
                                                                    {...AddForm.getFieldProps("TH_per_hrs_wages")}
                                                                    onChange={(e) => updateNumber(e.target.value, 'TH_per_hrs_wages', 5)} />

                                                                {AddForm.touched.TH_per_hrs_wages && AddForm.errors.TH_per_hrs_wages ?
                                                                    <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.TH_per_hrs_wages}</div> : ''}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12 row mt-3">
                                                            <div className='d-flex' style={{ justifyContent: 'space-between' }}>
                                                                <h6 className='.cbg-primary1 p-2 rounded'>Double Time</h6>
                                                                <h6 className='.cbg-primary1 p-2 rounded' style={{ marginRight: '3em' }} >Meal</h6>
                                                                <h6 />
                                                            </div>


                                                            <div className="col-md-3" >
                                                                <label>Total No.of Hrs {AddForm.touched.DT_total_hrs && !AddForm.errors.DT_total_hrs && <i className="fa-solid fa-circle-check"></i>}</label>
                                                                <input type="text" className="form-control" placeholder="Total No Of Hrs"
                                                                    {...AddForm.getFieldProps("DT_total_hrs")}
                                                                    onChange={(e) => updateNumber(e.target.value, 'DT_total_hrs', 5)} />

                                                                {AddForm.touched.DT_total_hrs && AddForm.errors.DT_total_hrs ?

                                                                    <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.DT_total_hrs}</div> : ''}
                                                            </div>

                                                            <div className="col-md-3" >
                                                                <label> Per Hr Wages {AddForm.touched.DT_per_hrs_wages && !AddForm.errors.DT_per_hrs_wages && <i className="fa-solid fa-circle-check"></i>}</label>

                                                                <input type="text" className="form-control" placeholder="Per Hour Wages"
                                                                    {...AddForm.getFieldProps("DT_per_hrs_wages")}
                                                                    onChange={(e) => updateNumber(e.target.value, 'DT_per_hrs_wages', 5)} />

                                                                {AddForm.touched.DT_per_hrs_wages && AddForm.errors.DT_per_hrs_wages ?
                                                                    <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.DT_per_hrs_wages}</div> : ''}
                                                            </div>
                                                            <div className="col-md-3" >
                                                                <label>Meal Hours {AddForm.touched.MEAL_total_hrs && !AddForm.errors.MEAL_total_hrs && <i className="fa-solid fa-circle-check"></i>}</label>
                                                                <input type="text" className="form-control" placeholder="Meal Hours"
                                                                    {...AddForm.getFieldProps("MEAL_total_hrs")}
                                                                    onChange={(e) => updateNumber(e.target.value, 'MEAL_total_hrs', 5)} />

                                                                {AddForm.touched.MEAL_total_hrs && AddForm.errors.MEAL_total_hrs ?

                                                                    <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.MEAL_total_hrs}</div> : ''}
                                                            </div>
                                                            <div className="col-md-3" >
                                                                <label>Meal Rate {AddForm.touched.MEAL_per_hrs_wages && !AddForm.errors.MEAL_per_hrs_wages && <i className="fa-solid fa-circle-check"></i>}</label>

                                                                <input type="text" className="form-control" placeholder="Meal rate"
                                                                    {...AddForm.getFieldProps("MEAL_per_hrs_wages")}
                                                                    onChange={(e) => updateNumber(e.target.value, 'MEAL_per_hrs_wages', 5)} />

                                                                {AddForm.touched.MEAL_per_hrs_wages && AddForm.errors.MEAL_per_hrs_wages ?
                                                                    <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.MEAL_per_hrs_wages}</div> : ''}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            {(AddForm.values.first_name && AddForm.values.last_name && AddForm.values.employee_mobile_number && AddForm.values.department_id &&
                                                                AddForm.values.employee_code && AddForm.values.monthly_salary && AddForm.values.Pay_Cycle && AddForm.values.Country &&
                                                                AddForm.values.NT_total_hrs && AddForm.values.NT_per_hrs_wages && AddForm.values.TH_total_hrs && AddForm.values.TH_per_hrs_wages &&
                                                                AddForm.values.MEAL_total_hrs && AddForm.values.MEAL_per_hrs_wages && AddForm.values.DT_total_hrs && AddForm.values.DT_per_hrs_wages
																			/* &&	AddForm.values.skill */) ? (<div onClick={() => handleAddTeamMember()}
                                                                style={{ cursor: 'pointer' }} className="continueBtnBusnessBox">Add Member</div>) :
                                                                <input type='submit' name="next" className="continueBtnBusnessBox" value="Continue"
                                                                    style={{ cursor: 'pointer' }}
                                                                    onClick={() => handleAddTeamMember()} />}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>

                                <fieldset className="mb-3" style={nextModal == 6 ? { display: "" } : { display: "none" }}>
                                    <div className="fifthDesigSection">
                                        <div className="row g-0">
                                            <div className="col-md-3">
                                                <div className="leftBoxBusnessDetail">
                                                    <div className="nameLeft fas fa-user"></div>
                                                    {/* <p>Lokendra Kushwaha</p> */}
                                                    <ul className="leftMenuBar">
                                                        <li className="firstMenu active">Business Details <span><i className="fa-solid fa-check"></i></span></li>
                                                        <li className="firstMenu active">Compliance <span><i className="fa-solid fa-check"></i></span></li>
                                                        <li className="firstMenu active">Location Premium & Job Type <span><i className="fa-solid fa-check"></i></span></li>
                                                        <li className="firstMenu active">Create Shift <span><i className="fa-solid fa-check"></i></span></li>
                                                        <li className="firstMenu active">Leaves <span><i className="fa-solid fa-check"></i></span></li>
                                                        <li className="firstMenu active">Add Team Member <span><i className="fa-solid fa-check"></i></span></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-md-9">
                                                <div className="BusinessRightDetail">
                                                    <div className="row align-items-center">
                                                        <div className="col-md-12">
                                                            <div className='d-flex justify-content-between'>
                                                                <h3 className="mb-1">Well Done!!</h3>
                                                                <span className='btn btn-danger' onClick={() => logOut()} style={{ textTransform: 'none' }}>Logout </span>
                                                            </div>
                                                            <p>You are done with the basic details.</p>
                                                            {/* <button type="button" name="previous" className="previous action-button-previous" onClick={() => setNextModal(nextModal - 1)} ><i className="fa-solid fa-arrow-left-long fa-fw"></i> Back</button> */}

                                                        </div>
                                                    </div>

                                                    <div className="row firstModalForm">
                                                        <div className="col-md-12">
                                                            <img className="lastBtnGo" src={process.env.PUBLIC_URL + "/assets/img/welldone.png"} alt="" />
                                                        </div>
                                                        <div className="col-md-12">
                                                            <p className="letCraeteSched">Let’s create scheduler for your employees</p>
                                                        </div>
                                                        <div className="col-md-12" onClick={() => { updatetoken() }}>
                                                            <Link to={'/employer/scheduler'} className="continueBtnBusnessBox lastBtnGo">Go to Scheduler</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                            </form>

                        </div>
                    </div>
                </div>
            </div>

            <div className="modal-backdrop fade show"></div>

        </div>
    )
}

export default BussinessModal