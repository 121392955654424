import React, { useEffect, useState } from 'react'
import { Tab, Tabs } from 'react-bootstrap'
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom'
import { GetCountry, postApi } from '../../../Service/jobService';
import { useFormik } from 'formik';
import * as yup from 'yup';

const Profile = () => {
    const [personalInfo, setPersonalInfo] = useState(false);
    const [country, setCountry] = useState([]);
    const prefix = process.env.REACT_APP_JOBS_PRIFIX

    const AddForm = useFormik({
        initialValues: {

            first_name: "",
            last_name: "",
            mobile_number: "",
            reside_type: "",
            email: "",
            Country: "",
        },
        enableReinitialize: true,
        validationSchema: yup.object({
            first_name: yup.string().required('Please Enter First Name'),
            last_name: yup.string().required('Please Enter Last Name'),
            mobile_number: yup.string().required('Please Enter Mobile Number'),
            email: yup.string().email('Please enter a valid email address').required('Please enter email address'),
            reside_type: yup.string().required('Please select Resident Type'),
            Country: yup.string().required('Please select Country'),

        }),

        onSubmit: async (values, { resetForm }) => {
            /* const response = await postApi(`/edit-team-member/${currentCustomerID}`, */
            const data = {
                first_name: values.first_name,
                last_name: values.last_name,
                // employee_mobile_number: values.mobile_number,
                email: values.email,
                country_id: values.Country,
                reside_type: values.reside_type,
            }


            const response = await postApi('/add-team-member', { ...data })
            if (response.status == true) {
                toast.success(response.message);
                resetForm({ values: '' });


            } else {
                console.log('inside abhi', response);
                toast.error(response.message);
            }
        }
    }
    );

    const GetCountrys = async () => {
        try {
            const response = await GetCountry();
            // console.log('response', response);
            if (response.status) {
                setCountry(response.data);
            }
        } catch (error) {
            toast.error("error :", error);
            console.error("🚀 ~ file: addEmployer.js:66 ~ GetCountry ~ error:", error)
        }
    }

    useEffect(() => {
        GetCountrys();
    }, []);

    return (
        <div className="main-container container-fluid px-0" style={{ width: '75rem' }}>
            <div className="page-header my-3 py-4">

                <h4 className="page-title mb-0 text-primary">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link style={{ color: "GrayText" }} className='breadcrumbslink' to={prefix + "/dashboard"} >Dashboard</Link></li>
                            <li className="breadcrumb-item" aria-current="page">Profile</li>
                        </ol>
                    </nav>
                </h4>

                <div style={{ width: "100%" }}>
                    <div className="col-md-12 tabsbtm">
                        <Tabs
                            defaultActiveKey="personal"
                            id="fill-tab-example"
                            className="mb-3"
                            fill
                        >
                            <Tab eventKey="personal" title="Personal Information">
                                <div className="main-container container-fluid px-0 ">
                                    <div className="page-header my-3 py-2">
                                        <div className="modal-body application-modal px-0">
                                            <div className="row">
                                                <div className="col-lg-12 col-xl-12">
                                                    <div className="tabsiconBox">
                                                        <div className="main-content-body main-content-body-contacts">
                                                            <div className='d-flex'>
                                                                <h6 className='me-3 mt-1 fs-18'>Personal Information</h6>
                                                                {!personalInfo && <span><i className='btn btn-sm btn-outline-primary fas fa-edit' onClick={() => setPersonalInfo(true)} /></span>}
                                                                {personalInfo && <span><i className='btn btn-sm btn-outline-danger fa fa-close me-3' onClick={() => setPersonalInfo(false)} />

                                                                </span>}
                                                            </div>
                                                            <div className="main-contact-info-body">
                                                                {/* {!personalInfo && */}
                                                                <div className="media-list p-0">
                                                                    <form onSubmit={AddForm.handleSubmit}>
                                                                        <div className="media py-4 mt-0">
                                                                            <div className="media-body">
                                                                                <div className="d-flex align-items-center">
                                                                                    <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa fa-user"></i> </div>
                                                                                    <div className="w-70"> <label>First Name : </label>
                                                                                        {!personalInfo && <span className="font-weight-normal1 fs-14">Lokendra</span>}
                                                                                        {personalInfo && <input className='form-control' placeholder='Enter Your Name' />}

                                                                                    </div>
                                                                                </div>
                                                                                <div className="d-flex align-items-center">
                                                                                    <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="far fa-user-circle"></i> </div>
                                                                                    <div className="w-70"> <label>Middle Name : </label>
                                                                                        {!personalInfo && <span className="font-weight-normal1 fs-14">SMT</span>}
                                                                                        {personalInfo && <input className='form-control' placeholder='Enter Your Name' />}                                                                                      </div>
                                                                                </div>
                                                                                <div className="d-flex align-items-center">
                                                                                    <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-user-tie"></i> </div>
                                                                                    <div className="w-70"> <label>Last Name:</label>
                                                                                        {!personalInfo && <span className="font-weight-normal1 fs-14">Kushwah</span>}
                                                                                        {personalInfo && <input className='form-control' placeholder='Enter Your Name' />}                                                                                     </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="media py-4 border-top mt-0">
                                                                            <div className="media-body">
                                                                                <div className="d-flex align-items-center">
                                                                                    <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fas fa-mail-bulk"></i> </div>
                                                                                    <div className="w-70"> <label>Personal Email :</label>
                                                                                        {/* <span className="font-weight-normal1 fs-14" style={{ textTransform: 'none' }}>Lokendrasmt@gmail.com</span> */}
                                                                                        {!personalInfo && <span className="font-weight-normal1 fs-14" style={{ textTransform: 'none' }}>Lokendrasmt@gmail.com</span>}
                                                                                        {personalInfo && <input className='form-control' type='email' placeholder='Enter Your Email' />}
                                                                                    </div>
                                                                                </div>
                                                                                <div className="d-flex align-items-center">
                                                                                    <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fab fa-adn"></i> </div>
                                                                                    <div className="w-70"> <label>Age :</label>
                                                                                        {/* <span className="font-weight-normal1 fs-14">25-08-2000</span> */}
                                                                                        {!personalInfo && <span className="font-weight-normal1 fs-14">25-08-2000</span>}
                                                                                        {personalInfo && <input className='form-control' placeholder='Enter Your Name' />}
                                                                                    </div>
                                                                                </div>
                                                                                <div className="d-flex align-items-center">
                                                                                    <div className="media-icon bg-primary-transparent border-primary me-3 mt-1"> <i className="fa-solid fa-home fa-fw"></i> </div>
                                                                                    <div className="w-70">
                                                                                        <label>Residential Address :</label>
                                                                                        {/* <span className="font-weight-normal1 fs-14">Om gurudev plaza, savitri Empire </span> */}
                                                                                        {!personalInfo && <span className="font-weight-normal1 fs-14">Om gurudev plaza, savitri Empire</span>}
                                                                                        {personalInfo && <input className='form-control' placeholder='Enter Your Name' />}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        {personalInfo && <button className='btn btn-sm btn-outline-primary m-3'> <i className='fas fa-edit' /> Update</button>}

                                                                    </form>
                                                                </div>
                                                                {/* } */}

                                                                {/* {personalInfo &&

                                                                    <div className="media-list p-0">
                                                                        <div className="media py-4 mt-0">
                                                                            <div className='col-md-12 row mb-2'>
                                                                                <div className="col-md-3">
                                                                                    <label>First Name 
                                                                                        {AddForm.touched.first_name && !AddForm.errors.first_name && <i className="fa-solid fa-circle-check"></i>}
                                                                                        </label>
                                                                                    <input type="text" className="form-control" placeholder="Enter Name" {...AddForm.getFieldProps("first_name")} />
                                                                                    {AddForm.touched.first_name && AddForm.errors.first_name ?
                                                                                        <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.first_name}</div> : ''}
                                                                                </div>
                                                                                <div className="col-md-3">
                                                                                    <label>Last Name
                                                                                         {AddForm.touched.last_name && !AddForm.errors.last_name && <i className="fa-solid fa-circle-check"></i>}
                                                                                         </label>
                                                                                    <input type="text" className="form-control" placeholder="Enter Name" {...AddForm.getFieldProps("last_name")} />
                                                                                    {AddForm.touched.last_name && AddForm.errors.last_name ?
                                                                                        <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.last_name}</div> : ''}
                                                                                </div>
                                                                                <div className="col-md-3">
                                                                                    <label>Mobile Number 
                                                                                        {AddForm.touched.email && !AddForm.errors.email && <i className="fa-solid fa-circle-check"></i>}
                                                                                        </label>
                                                                                    <input type="text" className="form-control" placeholder="Enter Mobile Number"
                                                                                        {...AddForm.getFieldProps("mobile_number")}
                                                                                    />
                                                                                    {AddForm.touched.mobile_number && AddForm.errors.mobile_number ?
                                                                                        <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.mobile_number}</div> : ''}
                                                                                </div>
                                                                                <div className="col-md-3">
                                                                                    <label>Email 
                                                                                        {AddForm.touched.email && !AddForm.errors.email && <i className="fa-solid fa-circle-check"></i>}
                                                                                        </label>
                                                                                    <input type="email" className="form-control" placeholder="Enter Email" {...AddForm.getFieldProps("email")} />
                                                                                    {AddForm.touched.email && AddForm.errors.email ?
                                                                                        <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.email}</div> : ''
                                                                                    }
                                                                                </div>
                                                                                <div className="col-md-3 mb-3">
                                                                                    <label>Country 
                                                                                        {AddForm.touched.Country && !AddForm.errors.Country && <i className="fa-solid fa-circle-check"></i>}
                                                                                        </label>
                                                                                    <select className="form-control mb-0" name="Country" {...AddForm.getFieldProps("Country")} >
                                                                                        <option>Select Country</option>
                                                                                        {country.map((e) => {
                                                                                            return (
                                                                                                <option key={e.id} value={e.id}>{e.name}</option>
                                                                                            )
                                                                                        })
                                                                                        }
                                                                                    </select>

                                                                                    {AddForm.touched.Country && AddForm.errors.Country ?
                                                                                        <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.Country}</div> : ''}
                                                                                </div>
                                                                                <div className="col-md-3 mb-3">
                                                                                    <label>Resident Type </label>
                                                                                    <select className="form-control mb-0" name="Resident Type" {...AddForm.getFieldProps("reside_type")} >
                                                                                        <option>Select Resident Type</option>
                                                                                        <option value="Resident">Resident</option>
                                                                                        <option value="Non-resident">Non-resident</option>
                                                                                    </select>

                                                                                    {AddForm.touched.reside_type && AddForm.errors.reside_type ?
                                                                                        <div className="invalid-feedback" style={{ display: "block" }}>{AddForm.errors.reside_type}</div> : ''}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <button className='btn btn-sm btn-outline-primary'> <i className='fas fa-edit' /> Update</button>
                                                                    </div>
                                                                } */}
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey="education" title="Educational Detail" />
                            <Tab eventKey="work" title="Work Information" />
                            <Tab eventKey="documents" title="Documents" />
                            <Tab eventKey="basic" title="Basic Information" />
                        </Tabs>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Profile