import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { Link, useLocation, useNavigate } from 'react-router-dom'
import * as yup from 'yup';
import { postApi } from '../service/employService';
import { setEmployerBusinessType, setEmployerSystemType } from '../../Redux/modalerrorReducer';
import { useDispatch } from 'react-redux';
import { Toast } from 'bootstrap';

const Verification = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const queryParams = new URLSearchParams(location.search);
    const customerId = queryParams.get('customer');
    const loanApplication = queryParams.get('type')
    const user_loan_id = queryParams.get('user_loan_id')
    const email = queryParams.get('email')

    const queryParamss = new URLSearchParams(window.location.search)?.toString()
    const type = new URLSearchParams(window.location.search).get('type')
    const [minutes, setMinutes] = useState("");
    const [seconds, setSeconds] = useState("");
    const [isInvalidEmail, setIsInvalidEmail] = useState(false);
    const [isRunning, setIsRunning] = useState(true);
    const [resendOTP, setResendOTP] = useState(false)
    const [handleButton, setHandleButton] = useState(false)
    const [count, setCount] = useState(0)
    const [handleinput, setHandleinput] = useState(false)
    const [showPassword, setshowPassword] = useState(false)
    const [showConfPassword, setshowConfPassword] = useState(false)

    const form = useFormik({
        initialValues: { otp1: '', otp2: '', otp3: '', otp4: '', otp5: '', otp6: '', password: "", confirmedPassword: "" },
        enableReinitialize: true,
        validationSchema: yup.object({
            otp1: !handleinput ? yup.string().required('Please Enter Six digit OTP ')
                .test('maxDigits', 'The value must be equal to 1 digits', (value) => value?.toString().length <= 1) : "",
            otp2: !handleinput ? yup.string().when('otp1', {
                is: (value) => value !== '',
                then: yup.string().required('Please Enter Six digit OTP ')
                    .test('maxDigits', 'The value must be equal to 1 digits', (value) => value?.toString().length <= 1),
            }) : "",
            otp3: !handleinput ? yup.string().when('otp2', {
                is: (value) => value !== '',
                then: yup.string().required('Please Enter Six digit OTP ')
                    .test('maxDigits', 'The value must be equal to 1 digits', (value) => value?.toString().length <= 1),
            }) : "",
            otp4: !handleinput ? yup.string().when('otp3', {
                is: (value) => value !== '',
                then: yup.string().required('Please Enter Six digit OTP ')
                    .test('maxDigits', 'The value must be equal to 1 digits', (value) => value?.toString().length <= 1),
            }) : "",
            otp5: !handleinput ? yup.string().when('otp4', {
                is: (value) => value !== '',
                then: yup.string().required('Please Enter Six digit OTP ')
                    .test('maxDigits', 'The value must be equal to 1 digits', (value) => value?.toString().length <= 1),
            }) : "",
            otp6: !handleinput ? yup.string().when('otp5', {
                is: (value) => value !== '',
                then: yup.string().required('Please Enter Six digit OTP ')
                    .test('maxDigits', 'The value must be equal to 1 digits', (value) => value?.toString().length <= 1),
            }) : "",
            password: handleinput ? yup.string().required('Please Enter Password') : "",
            confirmedPassword: handleinput ? yup.string().required('Please Enter Confirm Password').oneOf([yup.ref("password"), null], "Password Must Be Same") : "",
        }),
        onSubmit: async (values) => {
            const data = {
                otp: `${values.otp1}${values.otp2}${values.otp3}${values.otp4}${values.otp5}${values.otp6}`,
                email: email
            }
            const endpoint = "/email-verify"
            const response = await postApi(endpoint, data);
            if (response.status === true) {
                toast.success(response.message);
                form.setTouched({})
                form.setErrors({})
                form.validateForm();
                if (!response.isNewEmployee) {
                    let employer = {
                        user_type: response.user_type == 'EMPLOYER' ? response.user_type.toLowerCase() : response.user_type.toLowerCase(),
                        employer_token: response.token,
                        employer_id: response.employer_id,
                        company_name: response.company_name,
                        mobile_number: response.mobile_number,
                        logo: response.logo,
                        user_name: response.user_name,
                        role: response.role,
                        is_nfc_active: response.is_nfc_active,
                        business_type: response?.business_type,
                        plan: response.plan,
                        payment_status: response.payment_status,
                        basic_details_update: response.basic_details_update,
                        plan_expire_on: response.plan_expire_on,
                        employer_system_type: response.employer_system_type,
                    }

                    localStorage.setItem('employer', JSON.stringify(employer));
                    navigate(`/employer/verification/agreement?customer=${customerId}&type=${type}&user_loan_id=${user_loan_id}`)
                } else {
                    setHandleinput(true)
                }
            } else {
                setResendOTP(false)
                setHandleButton(false)
                form.setTouched({})
                form.setErrors({})
                form.validateForm();
                form.setFieldValue("otp1", "")
                form.setFieldValue("otp2", "")
                form.setFieldValue("otp3", "")
                form.setFieldValue("otp4", "")
                form.setFieldValue("otp5", "")
                form.setFieldValue("otp6", "")
                toast.error(response.message);
                setHandleButton(false)
            }
        },
    })

    useEffect(() => {
        let intervalId;
        if (isRunning) {
            intervalId = setInterval(() => {
                if (seconds > 0) {
                    setSeconds(seconds - 1);
                } else if (minutes > 0) {
                    setMinutes(minutes - 1);
                    setSeconds(59);
                } else {
                    setIsRunning(false);
                    // setOtpVerify(0)
                    setResendOTP(true)
                    setHandleButton(false)

                }
            }, 1000);
        }
        return () => clearInterval(intervalId);
    }, [isRunning, minutes, seconds]);

    const formatTime = () => {
        const formattedMinutes = minutes.toString().padStart(2, '0');
        const formattedSeconds = seconds.toString().padStart(2, '0');
        return `${formattedMinutes}:${formattedSeconds}`;
    };

    const handleOTP = async () => {
        try {
            const response = await postApi('/email-otp-send', {
                email: email,

            })
            if (response.status) {
                toast.success(response.message)
                setResendOTP(false)
                setHandleButton(false)
                setIsRunning(true);
                setMinutes(2)
                setSeconds(0)


            } else {
                setIsInvalidEmail(true)
                toast.error(response.message)
                setIsRunning(false)
            }

        } catch (error) {
            console.log(error.message)
        }
    }


    useEffect(() => {
        handleOTP()
    }, [])

    const eyeBtnStyle = {
        position: 'absolute',
        marginRight: '2px',
        marginTop: '0px',
    }

    const eyeBtnStyle1 = {
        position: 'absolute',
        marginLeft: '250px',
        marginTop: '16px',
    }

    const handlepassword = async () => {
        if (form.values.password && form.values.confirmedPassword) {
            const response = await postApi('/create-account', {
                email: email,
                password: form.values.password
            })
            if (response.status) {
                let employer = {
                    user_type: response.user_type == 'EMPLOYER' ? response.user_type.toLowerCase() : response.user_type,
                    employer_token: response.token,
                    employer_id: response.employer_id,
                    company_name: response.company_name,
                    mobile_number: response.mobile_number,
                    logo: response.logo,
                    user_name: response.user_name,
                    role: response.role,
                    is_nfc_active: response.is_nfc_active,
                    business_type: response?.business_type,
                    plan: response.plan,
                    payment_status: response.payment_status,
                    basic_details_update: response.basic_details_update,
                    plan_expire_on: response.plan_expire_on,
                    employer_system_type: response.employer_system_type,
                }

                localStorage.setItem('employer', JSON.stringify(employer));
                dispatch(setEmployerBusinessType(employer?.business_type));
                dispatch(setEmployerSystemType(employer?.employer_system_type))
                navigate(`${process.env.REACT_APP_EMPLOYER_PRIFIX}/verification/agreement?customer=${customerId}&type=${loanApplication}&user_loan_id=${user_loan_id}`)
            } else {
                toast.error(response.message)

            }
        }

    }

    return (
        <div className="page">
            <div className="page-single bg-back" /*  */ /* style={{ backgroundColor: '#eae6e6' }} */>
                <div className="container">
                    <div className="row">
                        <div className="col mx-auto">
                            {/* <div className="row justify-content-center">
                            <div className="col-xl-9 col-lg-12">
                                <div className="mb-2 br-1 text-center"><a href="">
                                    <img src={process.env.PUBLIC_URL + "/assets/img/logo-thirdroc.png"} className="header-brand-img loginlogo" alt="logo" />
                                </a></div>
                            </div>
                        </div> */}
                            <div className="row justify-content-center mb-2">
                                <div className="col-xl-11 col-lg-12">
                                    <div className="row p-0 m-0 bg-white br-3">
                                        <div className="col-lg-5 p-0">
                                            <div className="text-justified text-white  register-2 overflow-hidden" style={{ padding: '1rem 3rem 4rem 3rem' }}>
                                                <div className="custom-content">
                                                    <div className="mb-2 br-1">
                                                        <img src={process.env.PUBLIC_URL + "/assets/img/logo-thirdroc1.png"} className="header-brand-img loginlogo" alt="logo" />
                                                    </div>

                                                    <div className="fs-22 mb-4 font-weight-bold text-white"> Start Your Remarkable Journey With Us ! </div>
                                                    <div className="mb-2 br-1 text-center">
                                                        <img src="/assets/img/slider5.png" className="" alt="logo" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            !isInvalidEmail
                                                ? <div className="col-md-8 col-lg-7 p-0 mx-auto">
                                                    <div className="bg-white text-dark br-7 br-tl-0 br-bl-0">
                                                        <div className="form-group mt-4 d-flex justify-content-center">
                                                            <div className="text-center fs-20" style={{ marginLeft: '2rem' }}>
                                                                <i className="fas fa-lock fa-fw" /> Great! Almost Done

                                                            </div>
                                                        </div>
                                                        <div className="card-body">
                                                            <form className="row lendersignup" id='registrationForm' onSubmit={form.handleSubmit}>
                                                                <div className="text-center mb-4">
                                                                    <span><i class="fa-solid fa-envelope"></i> Enter the 6-digit code sent to your email ID</span>

                                                                </div>
                                                                {!handleinput ?
                                                                    <>
                                                                        <div className="d-flex justify-content-center">
                                                                            <input type="number" className="form-control col-md-1 mb-4"
                                                                                {...form.getFieldProps("otp1")}
                                                                                onInput={e => {
                                                                                    if (e.target.value === '' || /^\d{0,1}$/.test(e.target.value)) {
                                                                                        form.setFieldValue("otp1", e.target.value);
                                                                                        if (e.target.value.length === 1) {
                                                                                            document.querySelector('input[name="otp2"]').focus();
                                                                                        }
                                                                                    }
                                                                                }}
                                                                            />
                                                                            <input type="number" name="otp2" {...form.getFieldProps("otp2")} className="form-control col-md-1 mb-4 ms-2"
                                                                                onInput={e => {
                                                                                    if (e.target.value === '' || /^\d{0,1}$/.test(e.target.value)) {
                                                                                        form.setFieldValue("otp2", e.target.value);
                                                                                        if (e.target.value.length === 1) {
                                                                                            document.querySelector('input[name="otp3"]').focus();
                                                                                        }
                                                                                    }
                                                                                }}
                                                                            />
                                                                            <input type="number" name="otp3" {...form.getFieldProps("otp3")} className="form-control col-md-1 mb-4 ms-2"
                                                                                onInput={e => {
                                                                                    if (e.target.value === '' || /^\d{0,1}$/.test(e.target.value)) {
                                                                                        form.setFieldValue("otp3", e.target.value);
                                                                                        if (e.target.value.length === 1) {
                                                                                            document.querySelector('input[name="otp4"]').focus();
                                                                                        }
                                                                                    }
                                                                                }}
                                                                            />
                                                                            <input type="number" name="otp4" {...form.getFieldProps("otp4")} className="form-control col-md-1 mb-4 ms-2"
                                                                                onInput={e => {
                                                                                    if (e.target.value === '' || /^\d{0,1}$/.test(e.target.value)) {
                                                                                        form.setFieldValue("otp4", e.target.value);
                                                                                        if (e.target.value.length === 1) { document.querySelector('input[name="otp5"]').focus(); }
                                                                                    }
                                                                                }}
                                                                            />
                                                                            <input type="number" name="otp5" {...form.getFieldProps("otp5")} className="form-control col-md-1 mb-4 ms-2"
                                                                                onInput={e => {
                                                                                    if (e.target.value === '' || /^\d{0,1}$/.test(e.target.value)) {
                                                                                        form.setFieldValue("otp5", e.target.value);
                                                                                        if (e.target.value.length === 1) {
                                                                                            document.querySelector('input[name="otp6"]').focus();
                                                                                        }
                                                                                    }
                                                                                }}
                                                                            />
                                                                            <input type="number" name="otp6" {...form.getFieldProps("otp6")} className="form-control col-md-1 mb-4 ms-2"
                                                                                onChange={e => {
                                                                                    if (e.target.value === '' || /^\d{0,1}$/.test(e.target.value)) {
                                                                                        form.setFieldValue("otp6", e.target.value);
                                                                                    }
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        <div style={{ marginLeft: '195px' }}>
                                                                            {Object.values(form.errors).some((error) => error) && (
                                                                                <div className="invalid-feedback" style={{ display: "block" }}>
                                                                                    Please Enter Six digit OTP
                                                                                </div>
                                                                            )}
                                                                        </div></> :
                                                                    <>
                                                                        <div className="input-group" style={{ marginLeft: '10rem' }}>
                                                                            <div className="input-group-text"> <i className="fa-solid fa-lock fa-fw" aria-hidden="true" /> </div>
                                                                            <input className="form-control" name="password" {...form.getFieldProps("password")} type={showPassword ? "text" : "password"} placeholder="Please enter password" id="password-field" autoComplete="off" />
                                                                            {showPassword ?
                                                                                <span toggle="#password-field" onClick={() => setshowPassword(false)} style={eyeBtnStyle} className="fa fa-fw fa-eye field-icon toggle-password hideeyes"></span>
                                                                                :
                                                                                <span toggle="#password-field" onClick={() => setshowPassword(true)} style={eyeBtnStyle1} className="fa fa-fw fa-eye-slash field-icon toggle-password hideeyes1"></span>

                                                                            }
                                                                            {form.touched.password && form.errors.password ?
                                                                                <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.password}</div> : ''}
                                                                        </div>
                                                                        <div className="input-group mt-3" style={{ marginLeft: '10rem' }}>
                                                                            <div className="input-group-text"> <i className="fa-solid fa-lock fa-fw" aria-hidden="true" /> </div>
                                                                            <input className="form-control" {...form.getFieldProps("confirmedPassword")} type={showConfPassword ? "text" : "password"} name="confirmedPassword" placeholder="Confirm Password" id="password-field" />

                                                                            {showConfPassword ?
                                                                                <span toggle="#password-field" onClick={() => setshowConfPassword(false)} style={eyeBtnStyle} className="fa fa-fw fa-eye field-icon toggle-password hideeyes"></span>
                                                                                :
                                                                                <span toggle="#password-field" onClick={() => setshowConfPassword(true)} style={eyeBtnStyle1} className="fa fa-fw fa-eye-slash field-icon toggle-password hideeyes1"></span>
                                                                            }
                                                                            {form.touched.confirmedPassword && form.errors.confirmedPassword ?
                                                                                <div className="invalid-feedback" style={{ display: "block" }}>{form.errors.confirmedPassword}</div> : ''}
                                                                        </div>
                                                                    </>

                                                                }
                                                                {!handleinput && <div className='text-center mt-3'>
                                                                    {isRunning && <b>Time remaining: {formatTime()}</b>}
                                                                </div>}
                                                                {resendOTP && count <= 2 && !handleinput && seconds == "00" && <div className='text-primary d-flex justify-content-center'
                                                                    style={{ cursor: 'pointer' }}
                                                                    disabled={handleButton}
                                                                    onClick={() => { handleOTP(); setCount(count + 1) }}>Resend OTP</div>
                                                                }
                                                                <div type="submit" className="form-group text-center mb-3 mt-3">
                                                                    {!handleinput ? <button type='submit' className="btn btn-primary btn-lg w-60 br-7" disabled={handleButton}>
                                                                        <i className="fas fa-check fa-fw" /> Verify
                                                                    </button> :
                                                                        <button type='submit' className="btn btn-primary btn-lg w-60 br-7" onClick={() => handlepassword()} >
                                                                            <i className="fas fa-check fa-fw" /> Register
                                                                        </button>
                                                                    }
                                                                    {resendOTP && count == 3 && !handleinput && <button type='submit' className="btn btn-dark btn-lg w-60 br-7" disabled>Limit Exceeded</button>}
                                                                </div>
                                                                {/* <div type="submit" className="form-group text-center mb-3 mt-3"> <button type='submit' className="btn btn-primary btn-lg w-100 br-7">Verify</button> </div> */}
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                                : <div className="col-md-8 col-lg-7 p-0 mx-auto d-flex justify-content-center align-items-center text-center">
                                                    <div className=''>
                                                        <h1 className='' style={{ fontSize: "5rem" }}>401</h1>
                                                        <h3 className=''>Unauthorized Request</h3>
                                                    </div>
                                                </div>
                                        }



                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Verification